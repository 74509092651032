import React, { useEffect, useState, useRef } from "react"
import { usePromise } from "aldoo-ra/usePromise"
import Value from "aldoo-ra/Value"
import createDeploymentController from "./deployment-controller"

const AUTO_CHECK_FOR_UPDATES = 3000 // 3 seconds

// Create controller instance outside component
const controller = createDeploymentController()

// Singleton state holders outside the component
let appListData = null
let statusesData = null
let updateListeners = new Set()

const notifyListeners = () => {
  updateListeners.forEach((listener) => listener())
}

export function useAppUpdates() {
  const [refreshKey, setRefreshKey] = useState(0)

  useEffect(() => {
    const refresh = () => setRefreshKey((prev) => prev + 1)
    updateListeners.add(refresh)
    return () => updateListeners.delete(refresh)
  }, [])

  return {
    appList: appListData,
    statuses: statusesData,
    refreshKey,
  }
}

function AdminSiteUpdateScan() {
  const [, setUpdatesCount] = Value("site-updates")
  const [isAdminPath, setIsAdminPath] = useState(false)
  const getAppStatusesRef = useRef(null)
  const intervalIdRef = useRef(null)

  const { execute: executeRefresh, result: initialDeploymentData } = usePromise(
    controller.getAppList,
    false
  )

  const { execute: getAppStatuses, result: appStatuses } = usePromise(
    () => controller.getAppStatuses(appListData || []),
    true
  )

  // Store the latest getAppStatuses in a ref
  useEffect(() => {
    getAppStatusesRef.current = getAppStatuses
  }, [getAppStatuses])

  // Method to update the total updates count
  const updateTotalUpdatesCount = (statuses) => {
    if (!statuses) return
    const totalUpdates = Array.from(statuses.values()).reduce(
      (total, status) => {
        if (status && Array.isArray(status.updates)) {
          return total + status.updates.length
        }
        return total
      },
      0
    )
    setUpdatesCount(totalUpdates)
  }

  // Update latest statuses and the site-updates Value when new statuses are fetched
  useEffect(() => {
    if (!appStatuses) return
    statusesData = appStatuses
    updateTotalUpdatesCount(appStatuses)
    notifyListeners()
  }, [appStatuses])

  // Fetch initial app list on mount and handle loading
  useEffect(() => {
    // Execute initial fetch if we don't have data
    if (!initialDeploymentData && executeRefresh) {
      executeRefresh()
    }
    if (initialDeploymentData?.result?.environment?.repositories) {
      appListData = initialDeploymentData.result.environment.repositories
      notifyListeners()
    }
  }, [initialDeploymentData])

  // Listen for URL changes to update isAdminPath - runs only in browser
  useEffect(() => {
    // Initial check after mount
    const handleLocationChange = () => {
      const currentIsAdmin = window.location.pathname.includes("/admin")
      setIsAdminPath(currentIsAdmin)
    }

    // Run initial check
    handleLocationChange()

    // Set up listeners for navigation events
    window.addEventListener("popstate", handleLocationChange)

    // For single-page applications that use history API
    const originalPushState = window.history.pushState
    const originalReplaceState = window.history.replaceState

    window.history.pushState = function () {
      originalPushState.apply(this, arguments)
      handleLocationChange()
    }

    window.history.replaceState = function () {
      originalReplaceState.apply(this, arguments)
      handleLocationChange()
    }

    return () => {
      window.removeEventListener("popstate", handleLocationChange)
      window.history.pushState = originalPushState
      window.history.replaceState = originalReplaceState
    }
  }, [])

  // Start or stop checking based on isAdminPath
  useEffect(() => {
    const checkStatus = () => {
      if (appListData && getAppStatusesRef.current) {
        getAppStatusesRef.current()
      }
    }

    // Clear any existing interval
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }

    // Only set up interval if we're on an admin path
    if (isAdminPath) {
      // Do initial check
      checkStatus()
      // Set up interval for subsequent checks
      intervalIdRef.current = setInterval(checkStatus, AUTO_CHECK_FOR_UPDATES)
    }

    // Clean up on unmount or when isAdminPath changes
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = null
      }
    }
  }, [isAdminPath]) // Re-run when isAdminPath changes

  // The component doesn't render anything
  return null
}

export default AdminSiteUpdateScan
