import { url } from "./url"
export const previewTitle = (item) => {
  let title =
    item.content?.name ||
    item.content?.title ||
    item.content?.label ||
    item.content?.id

  if (!title) {
    //go through all values and find one that is other than the .type

    title = Object.values(item.content).find((value) => {
      if (typeof value !== "string") return
      return value !== item.content.type && !~value.indexOf("/")
    })
  }
  return title
}

export const previewImage = (item) => {
  if (!item) return

  const searchIn = item.content ?? item

  let { image } = searchIn
  //if there is no image, try to find a value that starts with images/ and use it as a preview
  if (!image) {
    image = Object.values(searchIn).find(
      (value) => typeof value === "string" && value.startsWith("images/")
    )
  }

  //get the first image if it is an array
  if (Array.isArray(image)) image = image[0]
  image = url(image)
  return image
}
