import React, { useState, useEffect, useMemo } from "react"
import Modal from "aldoo-ra/Modal"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"
import CMSRender from "aldoo-ra/CMS/cms-render"
import PubSub from "pubsub-js"
import parse from "html-react-parser"
import StatisticsCardsShort from "./StatisticsCardsShort.jsx"
import WaveBG from "./WaveBG"
import Image from "aldoo-ra/Image"

const PromoBanner = () => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // Subscribe to open event
    const token = PubSub.subscribe("open-promo-banner", () => {
      setIsOpen(true)
    })
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  // Define wave properties similar to the AquaMailFeaturesSection example
  const waveProps = useMemo(
    () => ({
      frequency: {
        default: 0.5,
        md: 0.8,
        lg: 1.0,
      },
      amplitude: {
        default: 40,
        md: 60,
        lg: 80,
      },
      phase: {
        default: 0,
        md: 400,
        lg: 300,
      },
      sampleRate: {
        default: 16,
        md: 48,
        lg: 48,
      },
    }),
    []
  )

  const waveStyle = useMemo(
    () => ({
      width: "100%",
      height: "230px",
    }),
    []
  )

  return (
    <CMSRender
      contentType="PromoBanner"
      match={{ "content.id": "FreeCardPromoBanner" }}
      renderItem={(banner) => {
        return (
          <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            variant="wide"
            className="w-[334px] md:w-full md:max-w-[800px] lg:max-w-[1188px] h-[640px] md:h-[500px] lg:h-[550px]"
            zIndex={400}
            hideCloseButton={false}
          >
            {/* Desktop layout (md and above) */}
            <div className="hidden md:flex relative flex-row w-full md:h-[500px] lg:h-[550px] justify-between gap-8 bg-theme_white overflow-hidden">
              {/* Left side content */}
              <div className="flex flex-col justify-start pt-7 lg:pt-14 z-20 pl-6 lg:pl-14 pb-4 lg:pb-8">
                {/* Title */}
                <div className="mb-3">
                  <Typography
                    component="h1"
                    variant="title_56"
                    className="hidden md:block text-text"
                  >
                    {parse(banner.title)}
                  </Typography>
                </div>
                
                {/* Subtitle */}
                <div className="md:mb-6 lg:mb-8">
                  <Typography 
                    variant="body_20_major" 
                    className="text-text my-0"
                  >
                    {parse(banner.subtitle)}
                  </Typography>
                </div>
                
                {/* Buttons */}
                <div className="flex flex-col lg:flex-row items-left lg:items-center gap-4 mb-4 lg:mb-8">
                  {banner.menu && banner.menu.length > 0 && (
                    <>
                      <Button 
                        variant="primary_accent_xl" 
                        className="!h-[64px] !w-auto flex-shrink-0"
                      >
                        {banner.menu[0].label}
                      </Button>
                      
                      <Button 
                        variant="text_button" 
                        className="!text-base underline underline-offset-2 ml-4"
                      >
                        {banner.menu[1].label}
                      </Button>
                    </>
                  )}
                </div>
                
                {/* Stats */}
                <div className="text-primary scale-[0.9] xl:scale-[0.65] transform origin-left">
                  <StatisticsCardsShort />
                </div>
                
                {/* Footnote */}
                <Typography 
                  variant="info_16" 
                  className="text-text mt-auto mb-0">
                  {banner.footnote}
                </Typography>
              </div>
              
              {/* Right side image */}
              <div className="relative md:w-[320px] lg:w-[358px] h-full flex-shrink-0 mr-16 lg:mr-[105px]">
                <img 
                  src={banner.image}
                  alt={banner.image_alt_text}
                  className="absolute w-full h-auto bottom-0 object-contain"
                />
              </div>
              
              {/* Wave background */}
              <WaveBG
                waveProps={waveProps}
                className="absolute bottom-0 left-0 right-0 text-wavy opacity-30 z-10"
                waveStyle={waveStyle}
              />
              
              {/* Bubbles background - left */}
              <Image
                id="PromoBannerBubblesLeft"
                className="absolute w-[184px] h-auto md:-left-8 lg:left-0 -top-4"
              />

              {/* Bubbles background - right */}
              <Image
                id="PromoBannerBubblesRight"
                className="absolute w-[115px] h-auto md:-right-10 lg:right-0 bottom-1 z-20"
              />
            </div>

            {/* ---------------------------------------------------------------------- */}
            {/* Mobile layout (below md) */}
            <div className="relative w-full h-full bg-theme_white overflow-hidden px-6 pt-10">
              <div className="relative flex md:hidden w-full h-full flex-col justify-between z-20">
                {/* Title section */}
                <div className="flex flex-col z-20">
                  <Typography 
                    component="h1"
                    variant="title_56" 
                    className="block md:hidden text-text my-0"
                  >
                    {parse(banner.mobile_title)}
                  </Typography>

                  <Typography 
                    variant="body_16" 
                    className="text-text mt-2 mb-0"
                  >
                    {parse(banner.subtitle)}
                  </Typography>
                </div>
                
                {/* Image */}
                <div className="relative w-[170px] h-[250px] flex justify-start overflow-hidden mb-5 mx-auto">
                  <img 
                    src={banner.image}
                    alt={banner.image_alt_text}
                    className="absolute w-full h-auto object-cover top-0"
                  />
                </div>
                
                {/* Statistics */}
                <div className="w-full flex justify-center scale-[0.8] -ml-6 mb-4">
                  <div className="text-primary">
                    <StatisticsCardsShort />
                  </div>
                </div>
                
                {/* Button section */}
                <Button 
                  variant="primary_accent_xl" 
                  className="!h-[56px] min-w-[192px] mx-auto"
                >
                  {banner.menu[0].label}
                </Button>
                
                <Button 
                  variant="text_button" 
                  className="!text-base underline underline-offset-2 mt-5"
                >
                  {banner.menu[1].label}
                </Button>

                {/* Footnote */}
                <Typography 
                  variant="info_14" 
                  className="text-text text-center mt-5">
                  {banner.footnote}
                </Typography>
              </div>
              
              {/* Wave background for mobile */}
              <WaveBG
                waveProps={{
                  ...waveProps,
                  amplitude: { ...waveProps.amplitude, default: 40 }
                }}
                className="absolute bottom-0 left-0 right-0 text-wavy opacity-30 z-0"
                waveStyle={{
                  width: "100%",
                  height: "360px",
                }}
              />
              
              {/* Bubbles background - scaled for mobile */}
              <Image
                id="PromoBannerBubblesLeft"
                className="absolute w-[190px] h-auto -left-7 top-0 opacity-80"
              />
              
              <Image
                id="PromoBannerBubblesRight"
                className="absolute w-[110px] h-auto -right-10 bottom-16 opacity-80 z-20"
              />
            </div>
          </Modal>
        )
      }}
    />
  )
}

export default PromoBanner