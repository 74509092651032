import Modal from "aldoo-ra/Modal"

export default function PaymentError({ store }) {
  const {
    paymentInfo,
    paymentError,
    setPaymentError,
    showCheckout,
    getFragment,
  } = store

  const PaymentErrorFragment = getFragment("payment-error")

  const handleBackToPayment = () => {
    setPaymentError(null)
    showCheckout()
  }

  if (!paymentError || !paymentInfo) return null

  return (
    <Modal
      isOpen={!!paymentError}
      variant="centered"
      className="max-w-[600px]"
      onClose={() => setPaymentError(null)}
    >
      <PaymentErrorFragment
        store={store}
        error={paymentError}
        handleBackToPayment={handleBackToPayment}
      />
    </Modal>
  )
}
