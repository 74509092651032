import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

export default function PaymentErrorFragment({
  store,
  handleBackToPayment,
  error,
}) {
  const texts = TextBundle({ id: "aldoo-store" })

  const getErrorMessage = (error) => {
    // Map common Stripe error codes to user-friendly messages
    const errorMessages = {
      card_declined: texts?.card_declined || "{{ Missing }} Your card has been declined.",
      expired_card: texts?.expired_card || "{{ Missing }} Your card has expired.",
      incorrect_cvc: texts?.incorrect_cvc || "{{ Missing }} The security code is incorrect.",
      processing_error:
        texts?.processing_error ||
        "{{ Missing }} An error occurred while processing your card.",
      insufficient_funds:
        texts?.insufficient_funds || "{{ Missing }} Insufficient funds in your account.",
      invalid_number: texts?.invalid_number || "{{ Missing }} The card number is invalid.",
      requires_payment_method:
        texts?.requires_payment_method || "{{ Missing }} Payment method required.",
      default:
        texts?.payment_error_default ||
        "{{ Missing }} There was an error processing your payment.",
    }

    if (typeof error === "string") return errorMessages[error] || error

    // Handle Stripe error object
    if (error?.code) return errorMessages[error.code] || error.message

    return errorMessages.default
  }

  return (
    <div className="p-6 flex flex-col items-center text-center">
      <ExclamationCircleIcon className="w-16 h-16 text-red-500 mb-4" />

      <Typography 
        variant="body_24_accent" 
        className="text-text mt-0"
      >
        {texts?.payment_error_title || "{{ Missing }} There was a problem with your payment"}
      </Typography>

      <Typography 
        variant="info_18" 
        className="mb-6 text-text/80"
      >
        {getErrorMessage(error)}
      </Typography>

      <Button
        variant="primary_s"
        onClick={handleBackToPayment}
        className="min-w-[200px]"
      >
        {texts?.back_to_payment || "{{ Missing }} Back to Payment"}
      </Button>
    </div>
  )
}
