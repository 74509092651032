//------------------------------------------------
// Field formatter
// This will convert the text field to an option field
// For example: if the field is a dropdown, it will convert the text to an option
// This is useful for the CMS field editors
// Example: if the field offers options,
// the text value "Option" will be converted to an option object like
//  { value: 'option', name: 'Option' }
// When exporting the data, the value will be converted back to the text
//  'Option'
//------------------------------------------------
import { isInternalType } from "../field-formatter"

export default {
  type: "Text",
  preFormat: (fieldValues, Type) => {
    // Don't change fields inside internally used types
    if (isInternalType(Type)) return fieldValues
    //No values, return
    if (!fieldValues) return fieldValues
    //each item has the following fields
    const fieldTypes = Type.fields
    for (const field of fieldTypes) {
      if (!field) continue
      if (field.type !== "Text") continue
      if (!field.options || !field.options.length) continue
      if (!fieldValues[field.id]) continue

      const itemValue = fieldValues[field.id]
      //convert text to option
      //for all other fields, we just save the value
      if (field.options && typeof itemValue === "string") {
        fieldValues[field.id] = { value: itemValue, name: itemValue }
      }
    }

    return fieldValues
  },
  postFormat: (fieldValues, Type) => {
    // Don't change fields inside internally used types
    if (isInternalType(Type)) return fieldValues
    //No values, return
    if (!fieldValues) return fieldValues
    //each item has the following fields
    const fieldTypes = Type.fields

    for (const field of fieldTypes) {
      if (!field) continue
      if (field.type !== "Text") continue
      if (!field.options) continue
      if (!fieldValues[field.id]) continue

      const itemValue = fieldValues[field.id]
      //convert option to text
      fieldValues[field.id] = Array.isArray(itemValue)
        ? itemValue[0]
        : itemValue
    }

    // Loop through all fields in the Type.fields
    return fieldValues
  },
}
