export const setParameter = (node, parameter) => {
  if (!node) return

  //the parameter id is in form of ae-color-blue where ae-color is the paraemter id
  const parameterID = parameter.split("-").slice(0, 2).join("-")

  // Find and remove any classes that start with "ae-color"
  const existingClasses = Array.from(node.classList)
  existingClasses.forEach((cls) => {
    //clean up the classes with the same parameter id
    if (cls.startsWith(parameterID)) node.classList.remove(cls)
  })

  //remove all styles from the style attribute
  //we need this to operate only with classes
  // node.removeAttribute("style")

  // Add the new class
  node.classList.add(parameter)
}
export const setParameterInSelection = (parameter) => {
  const selection = window.getSelection()
  if (selection.rangeCount <= 0) return

  const range = selection.getRangeAt(0)

  // Handle the case where the selection spans multiple nodes
  if (
    range.startContainer !== range.endContainer ||
    range.startContainer.nodeType !== Node.TEXT_NODE
  ) {
    const node = handleMultipleNodes(range, parameter)
    if (node) updateSelection(node, selection)
    return
  }

  const textNode = range.startContainer
  const parentNode = textNode.parentNode

  if (
    range.startOffset === 0 &&
    range.endOffset === textNode.textContent.length
  ) {
    if (parentNode && parentNode.tagName === "SPAN") {
      setParameter(parentNode, parameter)
      updateSelection(parentNode, selection)
    } else {
      const node = wrapTextNode(textNode, parameter)
      if (node) updateSelection(node, selection)
    }
    return
  }

  const node = splitAndWrapTextNode(
    textNode,
    range.startOffset,
    range.endOffset,
    parameter
  )
  if (node) updateSelection(node, selection)
}

// Function to find the nearest parent element of a specific tag or the immediate parent if no tag is specified
const findNearestParent = (node, tag) => {
  while (node && node.nodeType === Node.ELEMENT_NODE) {
    // Stop if we reach the topmost div with class "editor"
    if (node.classList.contains("editor")) {
      return null
    }

    if (tag) {
      if (node.tagName.toLowerCase() === tag.toLowerCase()) {
        return node
      }
    } else {
      // If no tag is provided, return the immediate parent element
      return node
    }

    node = node.parentNode
  }
  return null
}

const wrapTextNode = (textNode, parameter) => {
  if (!textNode || !textNode.parentNode) return null

  const span = document.createElement("span")
  span.textContent = textNode.textContent
  setParameter(span, parameter)
  textNode.parentNode.replaceChild(span, textNode)
  return span
}

const splitAndWrapTextNode = (textNode, startOffset, endOffset, parameter) => {
  if (!textNode || !textNode.parentNode) return null

  const parentNode = textNode.parentNode
  const fullText = textNode.textContent

  const beforeText = fullText.slice(0, startOffset)
  const selectedText = fullText.slice(startOffset, endOffset)
  const afterText = fullText.slice(endOffset)

  const beforeNode = beforeText ? document.createTextNode(beforeText) : null
  const afterNode = afterText ? document.createTextNode(afterText) : null

  const span = document.createElement("span")
  setParameter(span, parameter)
  span.textContent = selectedText

  if (beforeNode) parentNode.insertBefore(beforeNode, textNode)
  parentNode.insertBefore(span, textNode)
  if (afterNode) parentNode.insertBefore(afterNode, textNode)

  parentNode.removeChild(textNode)

  return span
}

const updateSelection = (node, selection) => {
  if (!node || node.nodeType !== Node.ELEMENT_NODE) return

  const newRange = document.createRange()
  newRange.selectNodeContents(node)
  selection.removeAllRanges()
  selection.addRange(newRange)
}

const handleMultipleNodes = (range, parameter) => {
  const nodes = []
  const treeWalker = document.createTreeWalker(
    range.commonAncestorContainer,
    NodeFilter.SHOW_TEXT,
    {
      acceptNode: (node) => {
        if (range.intersectsNode(node)) {
          return NodeFilter.FILTER_ACCEPT
        }
        return NodeFilter.FILTER_REJECT
      },
    }
  )

  while (treeWalker.nextNode()) {
    nodes.push(treeWalker.currentNode)
  }

  let firstWrappedNode = null

  nodes.forEach((node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.parentNode && node.parentNode.tagName === "SPAN") {
        setParameter(node.parentNode, parameter)
        if (!firstWrappedNode) firstWrappedNode = node.parentNode
      } else {
        const wrappedNode = wrapTextNode(node, parameter)
        if (!firstWrappedNode) firstWrappedNode = wrappedNode
      }
    }
  })

  return firstWrappedNode
}

export const unwrapSelection = (tagName = null) => {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return

  const range = selection.getRangeAt(0)
  const commonAncestor = range.commonAncestorContainer

  const lookIn =
    commonAncestor.nodeType === Node.TEXT_NODE
      ? commonAncestor.parentNode
      : commonAncestor

  const nearestParent = findNearestParent(lookIn, tagName)

  if (!nearestParent) return

  // Collect children of the nearestParent before moving them
  const children = Array.from(nearestParent.childNodes)

  // Move all children of the nearestParent one level up
  children.forEach((child) => {
    nearestParent.parentNode.insertBefore(child, nearestParent)
  })

  // Remove the old parent if it's now empty
  if (nearestParent.parentNode) {
    nearestParent.parentNode.removeChild(nearestParent)
  }

  // Update the selection range to encompass the unwrapped children
  if (children.length > 0) {
    const newRange = document.createRange()
    newRange.setStartBefore(children[0])
    newRange.setEndAfter(children[children.length - 1])
    selection.removeAllRanges()
    selection.addRange(newRange)
  }
}

export const wrapSelection = (tagName) => {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return null

  const range = selection.getRangeAt(0)
  const commonAncestor = range.commonAncestorContainer

  const lookIn =
    commonAncestor.nodeType === Node.TEXT_NODE
      ? commonAncestor.parentNode
      : commonAncestor

  // Check if there's already a parent <p> tag
  const nearestP = findNearestParent(lookIn, tagName)

  // If a parent <p> is found, return it without creating a new wrapper
  if (nearestP) return nearestP

  // Find the nearest parent span
  const nearestSpan = findNearestParent(commonAncestor, "span")

  if (nearestSpan) {
    // If a parent span is found, wrap the span in a <p> element
    const pWrapper = document.createElement(tagName)
    nearestSpan.parentNode.replaceChild(pWrapper, nearestSpan)
    pWrapper.appendChild(nearestSpan)

    // Adjust the selection to encompass the new <p> wrapper
    selection.removeAllRanges()
    const newRange = document.createRange()
    newRange.selectNodeContents(pWrapper)
    selection.addRange(newRange)

    return pWrapper
  } else {
    // If no span is found, wrap the text content directly
    if (
      range.startContainer === range.endContainer &&
      range.startContainer.nodeType === Node.TEXT_NODE
    ) {
      const wrapper = document.createElement(tagName)
      range.surroundContents(wrapper)

      // Adjust the selection to encompass the new <p> wrapper
      selection.removeAllRanges()
      const newRange = document.createRange()
      newRange.selectNodeContents(wrapper)
      selection.addRange(newRange)

      return wrapper
    }
  }

  // If the selection covers multiple nodes
  const wrapper = document.createElement(tagName)

  // Extract the contents of the selection
  const content = range.extractContents()

  // Append the extracted content to the new wrapper
  wrapper.appendChild(content)

  // Insert the new wrapper into the document
  range.insertNode(wrapper)

  // Adjust the selection to encompass the new wrapper
  selection.removeAllRanges()
  const newRange = document.createRange()
  newRange.selectNodeContents(wrapper)
  selection.addRange(newRange)

  return wrapper
}

export const replaceSelection = (tagName) => {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return

  const range = selection.getRangeAt(0)
  const commonAncestor = range.commonAncestorContainer

  // Get the element we want to replace
  const nearestParent = findNearestParent(
    commonAncestor.nodeType === Node.TEXT_NODE
      ? commonAncestor.parentNode
      : commonAncestor
  )

  // If there is no parent or it's not an element, we can't replace it
  if (!nearestParent) return

  // Create a new element with the specified tag
  const newElement = document.createElement(tagName)

  // Copy over attributes (like class, id, style, etc.) from the original element
  Array.from(nearestParent.attributes).forEach((attr) => {
    newElement.setAttribute(attr.name, attr.value)
  })

  // Move all child nodes from the old element to the new one
  while (nearestParent.firstChild) {
    newElement.appendChild(nearestParent.firstChild)
  }

  // Replace the old element with the new one
  nearestParent.parentNode.replaceChild(newElement, nearestParent)

  // Update the selection to encompass the new element
  selection.removeAllRanges()
  const newRange = document.createRange()
  newRange.selectNodeContents(newElement)
  selection.addRange(newRange)
}

export const replaceTag = (oldTag, newTag) => {
  const selection = window.getSelection()
  if (selection.rangeCount === 0) return

  const range = selection.getRangeAt(0)
  const commonAncestor = range.commonAncestorContainer

  const lookIn =
    commonAncestor.nodeType === Node.TEXT_NODE
      ? commonAncestor.parentNode
      : commonAncestor

  const nearestOldTag = findNearestParent(lookIn, oldTag)

  // If no <oldTag> is found, exit the function
  if (!nearestOldTag) return

  // Create a new element with the new tag
  const newElement = document.createElement(newTag)

  // Copy attributes from the old element to the new one
  Array.from(nearestOldTag.attributes).forEach((attr) => {
    newElement.setAttribute(attr.name, attr.value)
  })

  // Move the child nodes from the old element to the new one
  while (nearestOldTag.firstChild) {
    newElement.appendChild(nearestOldTag.firstChild)
  }

  // Replace the old element with the new one
  nearestOldTag.parentNode.replaceChild(newElement, nearestOldTag)

  // Update the selection to encompass the new element
  selection.removeAllRanges()
  const newRange = document.createRange()
  newRange.selectNodeContents(newElement)
  selection.addRange(newRange)
}
