// Split items into columns
const createColumns = (items, maxItemsPerColumn) => {
  const columns = []
  let currentColumn = []

  items.forEach((item, index) => {
    currentColumn.push(item)

    // Start a new column when we hit the max items or last item
    if (
      (maxItemsPerColumn !== -1 &&
        currentColumn.length === maxItemsPerColumn) ||
      index === items.length - 1
    ) {
      columns.push(currentColumn)
      currentColumn = []
    }
  })

  if (maxItemsPerColumn === -1) columns.push(currentColumn)

  return columns
}

export default createColumns