export default {
  default: {
    container: "max-w-4xl mx-auto bg-white px-4 py-8",
    title: "text-3xl sm:text-4xl md:text-5xl font-semibold text-center mb-8",
    item: "border-b border-gray-200 py-4",
    question:
      "flex justify-between items-center w-full text-left focus:outline-none",
    question_text: "text-xl md:text-2xl text-gray-900",
    toggle: "text-gray-600 text-2xl",
    motion_container: "overflow-hidden",
    answer: "mt-2 text-gray-600",
  },

  dark: {
    container: "max-w-4xl mx-auto bg-gray-900 px-4 py-8",
    title:
      "text-3xl sm:text-4xl md:text-5xl font-semibold text-center mb-8 text-white",
    item: "border-b border-gray-700 py-4",
    question:
      "flex justify-between items-center w-full text-left focus:outline-none",
    question_text: "text-xl md:text-2xl text-white",
    toggle: "text-gray-400 text-2xl",
    motion_container: "overflow-hidden",
    answer: "mt-2 text-gray-400",
  },

  minimal: {
    container: "max-w-4xl mx-auto px-4 py-4",
    title: "text-2xl font-semibold text-center mb-4",
    item: "border-b border-gray-100 py-2",
    question:
      "flex justify-between items-center w-full text-left focus:outline-none hover:text-blue-600",
    question_text: "text-lg text-gray-900",
    toggle: "text-gray-600",
    motion_container: "overflow-hidden",
    answer: "mt-2 text-gray-600 text-sm",
  },

  elevated: {
    container: "max-w-4xl mx-auto px-4 py-8",
    title: "text-3xl font-bold text-center mb-8",
    item: "shadow-md rounded-lg p-4 my-2 bg-white",
    question:
      "flex justify-between items-center w-full text-left focus:outline-none hover:text-blue-600",
    question_text: "text-xl font-semibold text-gray-900",
    toggle:
      "w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center text-gray-600",
    motion_container: "overflow-hidden",
    answer: "mt-4 text-gray-600 pl-2",
  },
}
