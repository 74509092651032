import React from "react"
import CMSData from "./cms-data"

const CMSRender = ({ renderItem, render, preProcessItem, ...props }) => {
  const { data, loading, error, isClient } = CMSData(props)

  //provide default preProcessItem function
  //this will change the data prior to rendering
  //for example link alterations like {{platform}}/login
  if (!preProcessItem) {
    preProcessItem = (item) => item
  }

  // Don't render anything during SSR to prevent hydration mismatches
  if (!isClient) {
    return (
      <div aria-busy="true" role="status">
        {/* Loading... */}
      </div>
    )
  }

  if (loading) return <div>{/* Loading... */}</div>
  if (error) return <div>{error}</div>
  if (!data.length) return

  if (render) return render(data)

  return data.map((item, index) => (
    <React.Fragment key={index}>
      {renderItem(preProcessItem(item), index, data)}
    </React.Fragment>
  ))
}

export default CMSRender
