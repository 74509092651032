import MenuGroup from "aldoo-ra/MenuGroup"
import PubSub from "pubsub-js"
import Button from "aldoo-ra/Button"
import SiteLanguageSelector from "../SiteLanguageSelector/button"
import Localization from "aldoo-ra/Localization"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import Value from "aldoo-ra/Value"
import DynamicSVG from "aldoo-ra/DynamicSVG"
import Icon from "aldoo-ra/Icon"

const HeaderMenuDesktop = () => {
  const [platform, setPlatform] = Value("site_platform")

  const { template } = Localization()

  return (
    <div
      className="fixed flex bg-theme_white flex-row h-[80px] w-full top-0 left-0 items-center justify-between px-4 lg:px-6 xl:px-px1_xl"
      style={{ zIndex: 190 }}
    >
      {/* Header Logo */}
      <div className="w-[104px] xl:w-[140px] flex-shrink-0 pb-[10px] xl:pb-3 mr-2">
        <MenuGroup name="Header Logo" renderAs="hlist.icons" />
      </div>

      {/* Central menu group */}
      <div className="flex items-center justify-center xl:gap-1 2xl:gap-3">
        {/* Header Products */}
        <MenuGroup
          name="Header Products"
          renderAs="panel"
          variant="aqua-panel-menu-lowercase"
          itemWidth="800px"
          itemsAsLinks={false}
          onItemClick={(item) => {
            const urlPlatform = item.link[0].url.split("/")[1]
            let currentUrl = window.location.href
            //also set the platform from the link[0].url
            setPlatform(urlPlatform)
            //if the user is a at a feature page, redirect to the feature page
            //redirect to the same page for the current platform
            if (window.location.pathname.includes("features")) {
              //replace the current platform with the new platform
              const newUrl = currentUrl.replace(
                window.location.pathname.split("/")[1],
                urlPlatform
              )

              //special case for advanced-features not available on ios
              //redirect to account-and-setup instead
              if (
                urlPlatform === "ios" &&
                window.location.pathname.includes("advanced-features")
              ) {
                window.location.href = newUrl.replace(
                  "advanced-features",
                  "account-and-setup"
                )
                return
              }

              //navigate to the new url
              window.location.href = newUrl
              return
            }
            //just navigate to the link[0].url when not in a feature page
            window.location.href = item.link[0].url
          }}
          rootItemRender={(item) => {
            return (
              <Button
                variant="nav_menuitem"
                className="flex items-center justify-center gap-[4px] px-0 xl:mr-4 2xl:mr-[68px]"
              >
                {item.label}
                <Icon id={platform} className="w-4 h-4" />

                <div className="w-5 h-5 flex items-center justify-center">
                  <ChevronDownIcon className="w-4 h-4" strokeWidth={3} />
                </div>
              </Button>
            )
          }}
        />

        {/* Features */}
        <MenuGroup
          name={"Header Features"}
          renderAs="panel"
          variant="aqua-panel-menu"
          preProcessItem={(item) => {
            if (item.items) {
              item.items = item.items
                .filter((item) => {
                  const { platforms } = item.options || {}
                  //filter out the items that don't have the platform in the options.platforms
                  //if the platform is all, return the item
                  if (!platforms) return true
                  //check if the current platform is in the platforms csv
                  return platforms.toLowerCase().includes(platform)
                })
                .map((i) => {
                  //replace the {{platform}} with the current platform inside the link[0].url
                  i.link[0].url = template(i.link[0].url, { platform })
                  return i
                })
            }
            //return the modified item
            return item
          }}
          rootItemRender={(item) => {
            return (
              <Button
                variant="nav_menuitem"
                className="flex items-center justify-center gap-[4px] px-0"
              >
                {item.label}

                <div className="w-6 h-6 flex items-center justify-center">
                  <ChevronDownIcon className="w-4 h-4" strokeWidth={3} />
                </div>
              </Button>
            )
          }}
        />

        {/* Header Pricing */}
        {/* <MenuGroup
          name="Header Pricing"
          renderAs="hlist"
          styleOverrides={{
            menu_item: "px-2",
          }}
          itemWidth="auto"
          itemRender={(item) => {
            return (
              <Button
                variant="nav_menuitem"
                className="flex items-center justify-center px-0"
              >
                {item?.label}
              </Button>
            )
          }}
        /> */}

        {/* Header Partners */}
        <MenuGroup
          name="Header Partners"
          renderAs="vlist.menu"
          itemWidth="auto"
          rootItemRender={(item) => {
            return (
              <Button
                variant="nav_menuitem"
                className="flex items-center justify-center gap-[4px] px-0"
              >
                {item.label}

                <div className="w-6 h-6 flex items-center justify-center">
                  <ChevronDownIcon className="w-4 h-4" strokeWidth={3} />
                </div>
              </Button>
            )
          }}
          itemRender={(item) => {
            return (
              <div className="flex flex-col gap-[8px] mb-2">
                <Button variant="nav_menuitem" className="text-left mb-[8px]">
                  {item.label}
                </Button>
              </div>
            )
          }}
        />

        {/* Header Resources */}
        <MenuGroup
          name="Header Resources"
          renderAs="vlist.menu"
          itemWidth="150px"
          maxItemsPerColumn={10}
          rootItemRender={(item) => {
            return (
              <Button
                variant="nav_menuitem"
                className="flex items-center justify-center gap-[4px] px-0"
              >
                {item.label}

                <div className="w-6 h-6 flex items-center justify-center">
                  <ChevronDownIcon className="w-4 h-4" strokeWidth={3} />
                </div>
              </Button>
            )
          }}
          itemRender={(item) => {
            return (
              <div className="flex flex-col gap-[8px] mb-2">
                <Button variant="nav_menuitem" className="text-left mb-[8px]">
                  {item.label}
                </Button>
              </div>
            )
          }}
        />
      </div>

      <div className="flex items-center justify-end 2xl:gap-3">
        <MenuGroup
          name="Header Download"
          renderAs="hlist"
          itemWidth="auto"
          itemsAsLinks={false}
          itemRender={(item) => {
            const url =
              (item?.link[0]?.parameters &&
                item?.link[0]?.parameters[platform]) ||
              item?.link[0]?.url
            return (
              <Button variant="primary_s">
                <a href={url} target="_blank">
                  {item?.label}
                </a>
              </Button>
            )
          }}
        />

        {/* <MenuGroup
          name="Header Profile"
          renderAs="hlist.icons"
          actionMap={{
            signin: () =>
              PubSub.publish("signin", {
                redirect: "/account",
              }),
          }}
          itemRender={(item) => {
            return (
              <DynamicSVG
                url={item.image}
                className="w-10 h-10 text-primary hover:text-primary_hov transition"
              />
            )
          }}
        /> */}

        <SiteLanguageSelector />
      </div>
    </div>
  )
}

export default HeaderMenuDesktop
