import React, { useState, useRef, useEffect } from "react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import VariantReader from "aldoo-ra/VariantReader"
import PubSub from "pubsub-js"

const RootMenuItem = ({
  label,
  children,
  rootItemRender,
  menuData,
  variant,
  styleOverrides,
  renderAs,
  hideDelay = 300,
  showDelay = 0,
  menuAlign = "left",
  skipRoot,
  getMenuID,
  showMenuMode = "hover",
  id,
  menuPosition = "bottom",
  containMenu = false,
  menuItemAnimationDelay = 50,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const hideTimeoutRef = useRef(null)
  const showTimeoutRef = useRef(null)
  const [menuID, setMenuID] = useState(null)
  const rootRef = useRef(null)
  const menuRef = useRef(null)
  const [animatedItems, setAnimatedItems] = useState([])

  const validPosition = ["left", "right", "top", "bottom"].includes(
    menuPosition.toLowerCase()
  )
    ? menuPosition.toLowerCase()
    : "bottom"

  useEffect(() => {
    const generatedID = id || Math.random().toString(36).substring(2, 9)
    setMenuID(generatedID)
    if (getMenuID) {
      getMenuID(generatedID)
    }
  }, [id, getMenuID])

  const animateList = () => {
    if (!menuRef.current) return

    const items = menuRef.current.querySelectorAll('li[role="menuitem"]')
    const itemsArray = Array.from(items)

    setAnimatedItems([])

    itemsArray.forEach((item, index) => {
      item.style.opacity = "0"
      item.style.transform = "translateY(10px)"
      item.style.transition = "opacity 0.2s ease-out, transform 0.2s ease-out"

      setTimeout(() => {
        item.style.opacity = "1"
        item.style.transform = "translateY(0)"
        setAnimatedItems((prev) => [...prev, index])
      }, index * menuItemAnimationDelay)
    })
  }

  useEffect(() => {
    if (isVisible) {
      animateList()
    } else {
      setAnimatedItems([])
    }
  }, [isVisible])

  useEffect(() => {
    const listener = PubSub.subscribe("menu:show", (msg, id) => {
      if (id === menuID) return
      hideMenu()
    })

    const hideListener = PubSub.subscribe("menu:hide", (msg, id) => {
      if (id !== menuID) return
      hideMenu()
    })

    const handleClickOutside = (event) => {
      if (showMenuMode !== "click" || !isVisible || !rootRef.current || !menuID)
        return

      const clickedMenuContainer = event.target.closest("[data-menu-container]")
      const clickedMenuID = clickedMenuContainer?.dataset?.menuId

      if (clickedMenuID && clickedMenuID !== menuID) {
        return
      }

      if (!rootRef.current.contains(event.target)) {
        hideMenu()
      }
    }

    if (showMenuMode === "click") {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      PubSub.unsubscribe(listener)
      PubSub.unsubscribe(hideListener)
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current)
      }
      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current)
      }
      if (showMenuMode === "click") {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }
  }, [showMenuMode, isVisible, menuID])

  const handleMouseEnter = () => {
    if (showMenuMode !== "hover") return

    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current)
      hideTimeoutRef.current = null
    }
    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current)
      showTimeoutRef.current = null
    }

    showTimeoutRef.current = setTimeout(() => {
      if (menuID) {
        PubSub.publish("menu:show", menuID)
      }
      setIsVisible(true)
    }, showDelay)
  }

  const handleMouseLeave = () => {
    if (showMenuMode !== "hover") return

    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current)
      showTimeoutRef.current = null
    }
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current)
      hideTimeoutRef.current = null
    }

    hideTimeoutRef.current = setTimeout(() => {
      setIsVisible(false)
    }, hideDelay)
  }

  const handleClick = (e) => {
    if (showMenuMode !== "click") return
    e.stopPropagation()
    if (menuID) {
      PubSub.publish("menu:show", menuID)
    }
    setIsVisible(!isVisible)
  }

  const hideMenu = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current)
      hideTimeoutRef.current = null
    }
    if (showTimeoutRef.current) {
      clearTimeout(showTimeoutRef.current)
      showTimeoutRef.current = null
    }
    setIsVisible(false)
  }

  const variants = VariantReader.useVariant({
    componentName: "Menu",
    variant: variant || renderAs,
    styleOverrides,
  })

  const { root, root_item, root_toggle_icon, submenu } = variants

  const getIconRotation = () => {
    if (!isVisible) return ""
    switch (validPosition) {
      case "right":
        return "rotate-90"
      case "left":
        return "-rotate-90"
      case "top":
        return "rotate-180"
      default:
        return "rotate-180"
    }
  }

  const rootRender = rootItemRender ? (
    rootItemRender(menuData)
  ) : (
    <>
      <span>{menuData?.label}</span>
      <ChevronDownIcon
        className={`${
          root_toggle_icon || "w-5 h-5 ml-2"
        } transition-transform duration-200 ${getIconRotation()}`}
      />
    </>
  )

  if (skipRoot) return children

  const stripPositioningClasses = (classString) => {
    const positioningClasses = [
      "absolute",
      "relative",
      "fixed",
      "sticky",
      "static",
      "top-",
      "bottom-",
      "left-",
      "right-",
      "inset-",
      "translate-",
      "-translate-",
      "z-",
    ]

    return classString
      .split(" ")
      .filter(
        (className) =>
          !positioningClasses.some(
            (posClass) =>
              className.startsWith(posClass) ||
              className.startsWith("-" + posClass)
          )
      )
      .join(" ")
  }

  const getPositionClasses = () => {
    const basePositionClasses = (() => {
      switch (validPosition) {
        case "left":
          return "right-full -translate-x-1"
        case "right":
          return "left-full translate-x-1"
        case "top":
          return "bottom-full -translate-y-1"
        default: // bottom
          return "top-full translate-y-1"
      }
    })()

    const nonContainedClasses = containMenu ? "" : "absolute"

    const verticalAlignClasses = (() => {
      if (["left", "right"].includes(validPosition)) {
        return "top-0"
      }
      return ""
    })()

    return `${nonContainedClasses} ${basePositionClasses} ${verticalAlignClasses}`
  }

  const getAlignmentClasses = () => {
    if (!["bottom", "top"].includes(validPosition)) return ""
    return menuAlign === "right" ? "right-0 left-auto" : "left-0"
  }

  const getTransitionClasses = () => {
    if (!containMenu) return ""
    return "transition-all duration-200 overflow-hidden"
  }

  const getMenuStyles = () => {
    if (!containMenu) return undefined

    return {
      maxHeight: isVisible ? "1000px" : "0",
      opacity: isVisible ? 1 : 0,
      margin: isVisible ? undefined : 0,
      padding: isVisible ? undefined : 0,
      border: isVisible ? undefined : "none",
    }
  }

  const handleSubmenuMouseEnter = () => {
    if (showMenuMode === "hover") {
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current)
        hideTimeoutRef.current = null
      }
      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current)
        showTimeoutRef.current = null
      }
    }
  }

  return (
    <div
      ref={rootRef}
      {...(menuID
        ? {
            id: `menu-${menuID}`,
            "data-menu-container": true,
            "data-menu-id": menuID,
          }
        : {})}
      className={`${root} ${containMenu ? "" : "relative"}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className={root_item}>{rootRender}</div>
      {(isVisible || (containMenu && isVisible)) && (
        <div
          ref={menuRef}
          className={`${stripPositioningClasses(
            submenu
          )} ${getPositionClasses()} ${getAlignmentClasses()} ${getTransitionClasses()}`}
          style={getMenuStyles()}
          data-menu-container={true}
          data-menu-id={menuID}
          onMouseEnter={handleSubmenuMouseEnter}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default RootMenuItem
