//get the API for the model
import { getApi } from "aldoo-ra/CMS/field-api-resolver"

export default {
  type: "DatabaseModel",
  preFormat: async (fieldValues, Type) => {
    if (!fieldValues) return fieldValues
    //the incoming fieldValues from the server is the _id
    //we need to get the object behind this _id

    //each item has the following fields
    const fieldTypes = Type.fields
    for (const field of fieldTypes) {
      if (!field) continue
      if (field.type !== "DatabaseModel") continue
      if (!fieldValues[field.id]) continue

      const itemValue = fieldValues[field.id]

      //if the itemValue has an _id, we don't need to fetch it
      if (itemValue._id) continue

      //model - the name of the mongoose model
      //item - the _id of the item

      const { model, item } = itemValue
      const ModelAPI = getApi(model)
      const { Get } = ModelAPI

      let response = await Get({ match: { _id: item } })
      //set the response
      fieldValues[field.id] = response && response.length ? response[0] : null
    }

    return fieldValues
  },
  postFormat: (fieldValues, Type) => {
    if (!fieldValues) return fieldValues

    //each item has the following fields
    const fieldTypes = Type.fields
    for (const field of fieldTypes) {
      if (!field) continue
      if (field.type !== "DatabaseModel") continue

      const itemValue = fieldValues[field.id]

      //the value is already formatted
      if (typeof itemValue === "string" || !itemValue || !itemValue._id)
        continue

      const model =
        typeof field.item === "string" ? field.item : field.item.type

      const formatted = {
        model, //this is the model name
        item: itemValue._id,
      }

      //when we post format this field, we need to store just the id over the database
      fieldValues[field.id] = formatted
    }

    return fieldValues
  },
}
