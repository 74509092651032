import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import { ShoppingBagIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { navigate } from "aldoo-ra/Navigate"
import TextBundle from "aldoo-ra/CMS/text-bundle"

export default function ShoppingCartEmpty({ store }) {
  const texts = TextBundle({ id: "shopping_cart_empty" })

  return (
    <div className="flex flex-col items-center justify-center  bg-gray-50 rounded-[24px] py-16 px-4">
      <div className="bg-primary/10 rounded-full p-6 mb-6">
        <ShoppingBagIcon className="w-12 h-12 text-primary" />
      </div>

      <Typography 
        component="h1"
        variant="h4" 
        className="xl:!text-[42px] text-center text-text mb-8"
      >
        {texts?.title || "{{ Missing}} title"}
      </Typography>

      <Typography
        variant="info_18"
        className="text-center text-text/80 max-w-[500px] my-0 mb-8"
      >
        {texts?.paragraph || "{{ Missing}} paragraph"}
      </Typography>

      <Button
        variant="secondary_s"
        className="gap-2"
        onClick={() => {
          store.hideCart()
          navigate("/pricing")
        }}
      >
        {texts?.cta_start || "{{ Missing}} cta text"}
        <ArrowRightIcon className="w-5 h-5 mt-1" />
      </Button>
    </div>
  )
}
