import { navigate } from "vike/client/router"

const processLink = (link) => {
  //if the link is relative, navigate to it, otherwise open it in a new tab
  if (link.startsWith("/")) return navigate(link)
  //open the link in a new tab
  window.open(link)
}
const resolveActionHandler = ({
  actionMap,
  itemsAsLinks = false,
  onItemClick,
}) => {
  return (item) => {
    let action

    //resolve the action from the actionMap
    if (item.options?.action) {
      action =
        typeof item.options.action === "function"
          ? item.options.action
          : actionMap?.[item.options.action]
    }
    //if no option action is found, check the itemsAsLinks flag
    if (!action && itemsAsLinks && item.link && item.link[0]?.url) {
      //if the item has a link, navigate to it
      action = () => processLink(item.link[0].url)
    }
    //run the action
    action?.(item)

    //hide the menu if the hideOnItemClick flag is set
    onItemClick && onItemClick(item)
  }
}

export default resolveActionHandler
