/**
 * This module is the entry point for the VariantReader module.
 * It exports the VariantReader class that manages component variants.
 */
import { useState, useEffect } from 'react';

// Use Vite's glob import for variants
const defaultVariants = import.meta.glob('/aldoo-ra/*/variants.jsx', { eager: true });
const userVariants = import.meta.glob('/components/Variants/*.jsx', { eager: true });


// Helper to normalize paths to component names
const getComponentName = (path) => {
    if (path.includes('/Variants/')) {
        return path.split('/Variants/')[1].replace('.jsx', '');
    }
    return path.split('/').slice(-2)[0];
};

// Deep merge helper function
const deepMergeVariants = (defaultValues, userValues) => {
    // Handle null/undefined cases
    if (!defaultValues) return userValues;
    if (!userValues) return defaultValues;

    // Create a new object to store merged results
    const merged = { ...defaultValues };

    // Iterate through user values
    Object.keys(userValues).forEach(key => {
        if (userValues[key] && typeof userValues[key] === 'object' && !Array.isArray(userValues[key])) {
            // If both values are objects, recursively merge them
            if (defaultValues[key] && typeof defaultValues[key] === 'object' && !Array.isArray(defaultValues[key])) {
                merged[key] = deepMergeVariants(defaultValues[key], userValues[key]);
            } else {
                // If default value isn't an object or doesn't exist, use user value
                merged[key] = userValues[key];
            }
        } else {
            // For non-objects (including arrays), use user value
            merged[key] = userValues[key];
        }
    });

    return merged;
};

// Create maps of variants
const defaultVariantMap = Object.entries(defaultVariants).reduce((acc, [path, module]) => {
    const componentName = getComponentName(path);
    acc[componentName] = module.default;
    return acc;
}, {});

const userVariantMap = Object.entries(userVariants).reduce((acc, [path, module]) => {
    const componentName = getComponentName(path);
    acc[componentName] = module.default;
    return acc;
}, {});

export class VariantReader {
    static #variantCache = new Map();

    /**
     * React hook for using variants in components
     * @param {string} componentName - Name of the component
     * @param {string} variantName - Name of the variant
     * @param {Object} styleOverrides - Style overrides for the variant
     * @returns {string} Variant className string
     */
    static useVariant({
        //The name of the component to look for variants
        componentName,
        //default variant name is 'primary'
        variant = 'primary',
        //the user may provide style overrides
        styleOverrides = {}
    }) {

        const [variantClass, setVariantClass] = useState('');

        useEffect(() => {
            this.getVariant(componentName, variant).then(setVariantClass);
        }, [componentName, variant]);

        let result = variantClass

        // Check cache first, so we don't spend 1 frame to get the variant
        // which can cause flickering
        if (this.#variantCache.has(componentName))
            result = this.#variantCache.get(componentName)[variant] || {};

        //apply style overrides to the variant object
        if (typeof result === 'object') {
            return { ...result, ...styleOverrides };
        }

        return result
    }

    /**
     * Reads variants for a specific component
     * @param {string} componentName - Name of the component (e.g., "Button")
     * @returns {Promise<Object>} Component variants
     */
    static async readVariants(componentName) {
        // Check cache first
        if (this.#variantCache.has(componentName)) {
            return this.#variantCache.get(componentName);
        }

        try {
            // Get default variants for the component
            const defaultComponentVariants = defaultVariantMap[componentName] || {};

            // Get user-defined variants for the component
            const userComponentVariants = userVariantMap[componentName] || {};

            // Merge variants, with user variants taking precedence
            const mergedVariants = deepMergeVariants(
                defaultComponentVariants,
                userComponentVariants
            );

            this.#variantCache.set(componentName, mergedVariants);
            return mergedVariants;
        } catch (error) {
            console.error(`Error loading variants for ${componentName}:`, error);
            return {};
        }
    }

    /**
     * Gets a specific variant for a component
     * @param {string} componentName - Name of the component
     * @param {string} variantName - Name of the variant
     * @returns {Promise<string>} Variant className string
     */
    static async getVariant(componentName, variantName = 'primary') {
        const variants = await this.readVariants(componentName);
        return variants[variantName] || variants.primary || '';
    }

    /**
     * Clears the variant cache
     */
    static clearCache() {
        this.#variantCache.clear();
    }
}

export default VariantReader;