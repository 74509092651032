/**
 * Sometimes the fields like DatabaseModel need an external api
 * to know how to manage their data.
 * The type definitions specify apis that are found by a key.
 * The DatabaseModel ( or any other editor ) can get the apis by this key
 */

const displayFormatters = {}

/**
 *  Add a function that will be given an instance of the field and should return a
 * string representation of the field
 * @param {*} key
 * @param {*} formatter
 */
export const addDisplayFormatter = (key, formatter) => {
  displayFormatters[key] = formatter
}

/**
 *  Get the formatter for the given key
 * @param {*} key
 * @returns
 */
export const getDisplayFormatter = (key) => {
  return displayFormatters[key] || (() => {})
}
