import React from "react"
import Button from "aldoo-ra/Button"
import { showNotification } from "aldoo-ra/Notification"

export default function CopyPaste({ setLocalFieldValues, localFieldValues }) {
  return (
    <div>
      <Button
        variant="outlined"
        className="w-32 mr-5 text-admin_text"
        onClick={async () => {
          const clipboard = await navigator.clipboard.readText()
          const text = clipboard.trim()

          if (!text) {
            showNotification({
              message: "Clipboard is empty",
              className: "bg-red-500",
            })
            return
          }

          const copiedValues = JSON.parse(text)

          //remove the _id from the copied values
          delete copiedValues._id

          setLocalFieldValues({
            ...copiedValues,
          })

          //notify the user
          showNotification({
            message: "Pasted",
          })
        }}
      >
        Paste
      </Button>
      <Button
        variant="outlined"
        className="w-32 mr-5 text-admin_text"
        onClick={() => {
          navigator.clipboard.writeText(JSON.stringify(localFieldValues))
          showNotification({
            message: "Copied",
          })
        }}
      >
        Copy
      </Button>
    </div>
  )
}
