import React from 'react'
import ImageEditor from 'aldoo-ra/CMS/field-editors/image'
import Spinner  from 'aldoo-ra/Spinner'
import { getFieldByLocalizationKey } from '../get-cms-info-by-key'
/**
 * TextItemEditor Component
 *
 * @param {Object} props
 * @param {Object} translations - The translation data for the languages
 * @param {Function} handleContentChange - Function to handle content change for the selected language
 * @param {String} selectedLang - The currently selected language code
 * @param {String} primaryLanguage - The primary language code
 * @param {Object} controller - The controller object containing necessary methods and data for translations
 * @param {Array} languages - Array of language objects with code and name
 */
export default function ImageItemEditor({
  localizationKey,
  translations,
  handleContentChange,
  selectedLang,
  controller,
}) {
  //get the field by name from the fieldInfo
  let field = getFieldByLocalizationKey(localizationKey)

  //set a default field if not found
  if (!field) field = { multiple: false, default: '' }

  //not yet loaded
  if (!field) return <Spinner />

  const defaultValue = field.multiple ? [] : ''
  const value = translations[selectedLang]?.content || defaultValue

  return (
    <div className="min-h-[500px] ">
      <div className="flex justify-between flex-col gap-2">
        {/* Display the selected language name */}
        <span className="font-semibold text-admin_text text-lg">
          {controller.getLanguageName(selectedLang)}
        </span>

        <ImageEditor
          field={field}
          value={value}
          onChange={(changed) => {
            if (changed === undefined) return

            if (changed) {
              // Remove the progress and file properties
              delete changed.file
              delete changed.progress
            }

            handleContentChange(changed)
          }}
        />
      </div>
    </div>
  )
}
