import Modal from "aldoo-ra/Modal"
import { useState, useEffect, useRef } from "react"
import Button from "aldoo-ra/Button"
import DropDown from "aldoo-ra/Dropdown"
import { showPromptDialog } from "aldoo-ra/PromptDialog"
//Editors
import TextItemEditor from "./item-editors/text"
import ImageItemEditor from "./item-editors/image"
import VideoItemEditor from "./item-editors/video"
import FileItemEditor from "./item-editors/file"
import ObjectItemEditor from "./item-editors/object"

const typeToComponentMap = {
  text: TextItemEditor,
  image: ImageItemEditor,
  video: VideoItemEditor,
  file: FileItemEditor,
  object: ObjectItemEditor,
}

// Resolve the delta between the original and updated translations
const resolveTranslationDelta = (original, updated) => {
  const delta = {}
  for (const lang in updated) {
    if (
      JSON.stringify(updated[lang].content) !==
      JSON.stringify(original[lang]?.content)
    ) {
      if (!delta[lang]) delta[lang] = original[lang] || {}
      delta[lang].content = updated[lang].content
    }
  }
  return delta
}

export default function LocalizationItemDialog({
  isOpen,
  onClose,
  onSave,
  item,
  controller,
  parentObject,
}) {
  const languages = controller.languages
  const [selectedLang, setSelectedLang] = useState(null) // Default selected language
  const [translations, setTranslations] = useState({})
  const [searchTerm, setSearchTerm] = useState("") // Search term for filtering languages
  const [filterOption, setFilterOption] = useState("all") // "all" by default
  const textAreaRef = useRef(null) // Ref to focus textarea
  const [pendingLanguages, setPendingLanguages] = useState([]) // Languages that contain unsaved changes
  const [filteredLanguages, setFilteredLanguages] = useState([]) // Filtered languages
  const [primaryLanguage, setPrimaryLanguage] = useState(
    controller.primaryLanguage
  ) // Primary
  const EditorComponent = useRef(null)

  //Select the primary language by default
  useEffect(() => {
    setSelectedLang(primaryLanguage)
  }, [primaryLanguage])

  useEffect(() => {
    if (!controller.primaryLanguage) return
    setPrimaryLanguage(controller.primaryLanguage)
  }, [controller.primaryLanguage])

  //reset the pending on open
  useEffect(() => {
    if (isOpen) {
      setPendingLanguages([])
    } else {
      setSelectedLang(primaryLanguage)
    }
  }, [isOpen])

  useEffect(() => {
    if (!item) return
    setTranslations(JSON.parse(JSON.stringify(item.translations)))

    //resolve the editor component
    if (typeToComponentMap[item.type]) {
      EditorComponent.current = typeToComponentMap[item.type]
    }
  }, [item])

  //Apply default value if it exists
  const handleLanguageSelect = (languageCode) => {
    setSelectedLang(languageCode)
    // Set the auto translate option to the selected language
    controller.autoTranslateOptions.find(
      (item) => item.value === "key"
    ).name = `Translate in ${
      languages.find((lang) => lang.code === languageCode).name
    }`
  }

  const handleContentChange = (value, targetLanguage) => {
    if (!targetLanguage) targetLanguage = selectedLang
    setTranslations({
      ...translations,
      [targetLanguage]: {
        ...translations[targetLanguage],
        content: value,
      },
    })

    //if the new content is the same as the current item.translation, remove it from pending
    //this uses direct comparison and treats the content as string
    if (
      value === item?.translations[targetLanguage]?.content ||
      (value == "" && !item?.translations[targetLanguage]?.content)
    ) {
      setPendingLanguages(
        pendingLanguages.filter((lang) => lang !== targetLanguage)
      )
      return
    }

    // Add the language to the pending languages if it doesn't exist
    if (!pendingLanguages.includes(targetLanguage)) {
      setPendingLanguages([...pendingLanguages, targetLanguage])
    }
  }

  const checkForBraces = (content) => {
    // The content is not an array, so no need to check
    if (!Array.isArray(content)) return false

    // Check if a key contains braces
    const problematicKeys = content.filter((item) => {
      return item.key.includes("{") || item.key.includes("}")
    })

    if (problematicKeys.length > 0) {
      const keysList = problematicKeys.map((item) => item.key).join(", ")
      return showPromptDialog({
        title: "Error",
        showNoBtn: false,
        yesLabel: "OK",
        message: `The content contains braces '{' or '}' in the following keys: ${keysList}. Please remove them before saving.`,
      })
    }
  }

  const handleSave = async () => {
    const delta = resolveTranslationDelta(item.translations, translations)
    //for all languages in the delta, postFormat the content
    // If there are no changes, return

    for (const lang in delta) {
      //check if we have braces in the keys
      if (checkForBraces(delta[lang].content)) return
      delta[lang].content = controller.applyPostFormatters(
        delta[lang].content,
        item.type
      )
    }

    // Save the item using the controller
    await controller.saveLocalizationItem({
      key: item.key,
      translations: delta,
    })

    //get the latest item version from the server
    const updatedItem = await controller.getLocalizationKey(item.key)

    const result = updatedItem[0]
    //preformat the content in all languages in the result
    //only for text types
    for (const lang in result.translations) {
      result.translations[lang].content = controller.applyPreFormatters(
        result.translations[lang].content,
        item.type
      )
    }

    //pass on the saved item
    if (onSave) onSave(result)
    //clear the pending languages
    setPendingLanguages([])
  }

  const handleDelete = () => {
    showPromptDialog({
      title: "Delete Localization Key",
      message: "Are you sure you want to delete this localization key?",
      onConfirm: () => {
        controller.deleteLocalizationKeys([item.key])
        onClose()
      },
    })
  }

  /**
   * Check if the content is valid
   * @param {*} content
   * @returns
   */
  const isContentValid = (content) => {
    const value = content
    if (value === undefined || value === null) return false
    if (typeof value === "string" && value.trim() === "") return false
    if (Array.isArray(value) && value.length === 0) return false
    return true
  }

  // Define filter options
  const [filterOptions, setFilterOptions] = useState([
    { name: "Completed", count: 0, key: "completed_only" },
    { name: "Empty", count: 0, key: "empty_only" },
    { name: "All", count: 0, key: "all" },
  ])

  useEffect(() => {
    const result = languages.filter((lang) => {
      const hasTranslation =
        translations && isContentValid(translations[lang.code]?.content)
      const matchesSearchTerm = lang.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())

      // Check if the language is pending
      if (pendingLanguages.includes(lang.code)) return true

      if (filterOption === "completed_only" && !hasTranslation) return false
      if (filterOption === "empty_only" && hasTranslation) return false

      return matchesSearchTerm
    })
    setFilteredLanguages(result)
  }, [searchTerm, filterOption, translations, pendingLanguages])

  // Update the count of each filter option
  useEffect(() => {
    filterOptions.forEach((option) => {
      option.count = languages.filter((lang) => {
        // Check if the language is pending
        if (option.key === "all" && pendingLanguages.includes(lang.code))
          return true
        const hasTranslation =
          translations && isContentValid(translations[lang.code]?.content)
        if (option.key === "completed_only" && !hasTranslation) return false
        if (option.key === "empty_only" && hasTranslation) return false
        return true
      }).length
    })
  }, [
    controller.selectedAutoTranslateOption,
    translations,
    filteredLanguages,
    pendingLanguages,
    selectedLang,
  ])

  //start the auto translate on options change
  useEffect(() => {
    if (!controller.selectedAutoTranslateOption) return
    //do the auto translation
    controller
      .autoTranslate(
        item.key,
        primaryLanguage,
        selectedLang,
        controller.selectedAutoTranslateOption
      )
      .then((translatedItem) => {
        if (!translatedItem || !translatedItem.translations) return
        //update the new translations for this item
        setTranslations(translatedItem.translations)
      })
  }, [controller.selectedAutoTranslateOption])

  return (
    <Modal
      title="Localization Key"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEscape={true}
      className="w-full md:w-[1400px] sm:w-[500px] md:absolute h-full md:h-auto sm:max-w-[95%] bg-admin_paper text-sm sm:text-base z-200"
    >
      <div className="flex flex-row w-full border-b border-neutral_1 items-center ">
        {/* Search Input */}
        <div className="flex items-center p-2">
          <input
            type="text"
            className="text-admin_text bg-admin_canvas outline-none px-2 py-1 border border-neutral_2 rounded"
            placeholder="Search languages..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Translation Key on the far right */}
        <div className="flex-grow">
          <span className=" text-neutral_3 text-lg ml-5">key:</span>
          <span className="font-semibold text-admin_text text-lg ml-2">
            {item?.key}
          </span>
        </div>
      </div>

      <div className="flex">
        {/* Language List */}
        <div className="flex flex-col w-1/4 h-full overflow-y-auto border-r border-neutral_1">
          {/* DropDown to filter languages */}
          <div className="p-2">
            <DropDown
              isMenu={true}
              placeholder="Filter"
              options={filterOptions}
              onChange={(item) => setFilterOption(item.key)}
              value={filterOptions.find(
                (option) => option.key === filterOption
              )}
              menuPosition="bottom"
              onItemRender={(item) => item.name + ` - ${item.count}`}
            />
          </div>

          {/* Filtered language list */}
          <div className="flex flex-col w-full h-[500px] overflow-y-auto border-r border-neutral_1">
            {filteredLanguages.map((lang) => (
              <button
                key={lang.code}
                className={`text-left text-admin_text p-2 flex items-center justify-between ${
                  selectedLang === lang.code
                    ? "bg-admin_canvas text-admin_text"
                    : "hover:bg-primary hover:text-white"
                }`}
                onClick={() => handleLanguageSelect(lang.code)}
              >
                <span>{lang.name}</span>
                {!pendingLanguages.includes(lang.code) &&
                  translations &&
                  isContentValid(translations[lang.code]?.content) && (
                    <span className="text-green-500">&#10003;</span>
                  )}

                {pendingLanguages.includes(lang.code) && (
                  <div className="w-2 h-2 bg-warning rounded-full"></div>
                )}
              </button>
            ))}
          </div>
        </div>

        {/* Translation Content */}
        <div className="flex flex-col w-3/4 p-4">
          {EditorComponent.current && (
            <EditorComponent.current
              title="Hello"
              localizationKey={item?.key}
              parentObject={parentObject}
              controller={controller}
              selectedLang={selectedLang}
              translations={translations}
              textAreaRef={textAreaRef}
              handleContentChange={handleContentChange}
              primaryLanguage={primaryLanguage}
            />
          )}

          <div className="mt-4 flex justify-between">
            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={handleDelete}
            >
              Delete
            </Button>

            <Button
              variant="action"
              className="text-admin_text"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
