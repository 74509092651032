//------------------------------------------------
// Field formatter
// This will convert the text field to an option field
// For example: if the field is a dropdown, it will convert the text to an option
// This is useful for the CMS field editors
// Example: if the field offers options,
// the text value "Option" will be converted to an option object like
//  { value: 'option', name: 'Option' }
// When exporting the data, the value will be converted back to the text
//  'Option'
//------------------------------------------------
export default {
  type: "ContentType",
  preFormat: (fieldValues, Type) => {
    //implement the preformat function
    if (Type.type !== "ContentType") return fieldValues

    const items = fieldValues.fields

    if (!items) return fieldValues

    //each item has the following fields
    const itemFields = Type.fields.find((item) => item.id === "fields").item
      .fields

    for (const item of items) {
      const fieldIDs = Object.keys(item)
      for (const fieldID of fieldIDs) {
        const field = itemFields.find((field) => field.id === fieldID)
        if (!field) continue
        //convert text to option
        //for all other fields, we just save the value
        if (field.options && typeof item[fieldID] === "string") {
          item[fieldID] = { value: item[fieldID], name: item[fieldID] }
        }
      }
    }

    fieldValues.fields = items

    return fieldValues
  },
  postFormat: (fieldValues, Type) => {
    if (Type.type !== "ContentType") return fieldValues
    const items = fieldValues.fields
    //each item has the following fields
    const itemFields = Type.fields.find((item) => item.id === "fields").item
      .fields

    for (const item of items) {
      const fieldIDs = Object.keys(item)
      for (const fieldID of fieldIDs) {
        const field = itemFields.find((field) => field.id === fieldID)
        if (!field) continue
        if (!field.options) continue

        item[fieldID] = Array.isArray(item[fieldID])
          ? item[fieldID][0].value
          : item[fieldID].value
      }
    }

    fieldValues.fields = items

    // Loop through all fields in the Type.fields
    return fieldValues
  },
}
