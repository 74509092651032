import axios from "axios"
import config from "aldoo-ra/Config"
import { useEffect } from "react"
import Value from "aldoo-ra/Value"
import diagnostics from "./diagnostics"

const { PLATFORM_API_URL, PLATFORM_API_VERSION, API_DIAGNOSTICS } = config

//constructs an api end point to the API
export const ep = (name) =>
  `${PLATFORM_API_URL}/${PLATFORM_API_VERSION}/${name}`

//If the sanitize function is called on an object
//if will remove the ignored fields
const IGNORED_FIELDS_ON_SANITIZE = ["_id"]

//Send only meaningful parameters to the rest api backend
//@param payload Table Returns a table that has the ignore fields stripped
const sanitize = (payload) => {
  const result = {}
  const keys = Object.keys(payload)
  for (const key of keys) {
    const value = payload[key]
    //null and undefined values
    if (value === null || value === undefined) continue

    //Empty string values
    if (
      key &&
      !(typeof value == "string" && value.length === 0) &&
      !~IGNORED_FIELDS_ON_SANITIZE.indexOf(key)
    ) {
      //if the value is an object, sanitize it
      if (
        typeof value === "object" &&
        !Array.isArray(value) &&
        !(typeof window !== "undefined" && value instanceof File)
      ) {
        result[key] = sanitize(value)
      } else {
        result[key] = value
      }
    }
  }
  return result
}

//This is a reference to the Value('user-credentials') object
//It changes based on the user login state
let USER_CREDENTIALS = null
let frameWait = true

let API_AUTH_KEY = null
//set the API_AUTH_KEY prior to making any api calls
export const setAPIAuthKey = (key) => {
  API_AUTH_KEY = key
}

/**
 * This is added to the application start up and listens for
 * available user credentials. Those are then used to make
 * api calls to the backend
 */
export const AldooAPI = () => {
  const [auth] = Value("user-credentials")

  useEffect(() => {
    //here we get the value from the auth object
    //if it's not available, we try go get the value immediately
    USER_CREDENTIALS = auth
  }, [auth])
}

/**
 * All API calls are made using the post method
 * @param {*} url
 * @param {*} payload
 * @param {*} options
 * @returns
 */
const post = async (url, payload, options) => {
  //Here we wait for a single frame to pass
  //so that React can update it's effects
  if (frameWait) {
    await new Promise((resolve) => setTimeout(resolve, 0))
    frameWait = false
  }

  //options = { headers: { Authorization: `Bearer ${token}` } }
  if (!options) options = {}
  //check for headers and add the USER_CREDENTIALS
  if (!options.headers) options.headers = {}
  //check if we have user credentials, if so add them to the headers
  if (USER_CREDENTIALS) {
    const { auth_token } = USER_CREDENTIALS
    auth_token && (options.headers["Authorization"] = auth_token)
  }

  if (API_AUTH_KEY) {
    options.headers["aldoo-api-auth-key"] = API_AUTH_KEY
  }

  const response = await axios.post(url, payload, options)

  //if enabled, print the diagnostics for the requests
  if (API_DIAGNOSTICS)
    diagnostics({
      response: response.data,
      url,
      payload,
      options,
    })

  return response.data
}

/**
 * This constructs an api call to a specific end point
 * Then the actions in the local module api reuse that for code clarity
 * @param {*} endPoint
 * @returns
 */
export const api = (endPoint) => {
  return async (payload, options) => {
    return await post(ep(endPoint), payload, options)
  }
}

export const generateCRUDInterface = (api, model) => {
  const Get = async (get) => {
    get = {
      ...get,
      model,
      //filter out deleted items
      ...{ match: { ...get.match, deleted: { $ne: true } } },
    }
    return await api({ get })
  }

  const Create = async (item) => {
    const create = {
      model,
      data: item,
    }
    return await api({ create })
  }

  const Update = async (item) => {
    const update = {
      model,
      data: item,
    }
    return await api({ update })
  }

  const SoftDelete = async (item) => {
    const payload = {
      model,
      mode: "soft",
      match: { _id: item._id },
    }
    return await api({ delete: payload })
  }

  const HardDelete = async (item) => {
    const payload = {
      model,
      mode: "hard",
      match: { _id: item._id },
    }
    return await api({ delete: payload })
  }

  const Save = async (item) => {
    if (!item) return
    if (item._id) return await Update(item)
    if (!item._id) return await Create(item)
  }

  return {
    Get,
    Create,
    Update,
    SoftDelete,
    HardDelete,
    Save,
  }
}
