export default {
  default: {
    container: "flex items-center gap-2",
    wrapper: "flex flex-col",
    controlsWrapper: "flex items-center gap-2",
    buttonVariant: "default",
    inputVariant: "default",
    input: "w-20 text-center",
    button: "w-8 h-8 flex items-center justify-center",
    decrementButton: "", // Additional classes specific to decrement button
    incrementButton: "", // Additional classes specific to increment button
  },

  compact: {
    container: "flex items-center gap-1",
    wrapper: "flex flex-col",
    controlsWrapper: "flex items-center gap-1",
    buttonVariant: "compact",
    inputVariant: "compact",
    input: "w-16 text-center",
    button: "w-6 h-6 flex items-center justify-center",
    decrementButton: "",
    incrementButton: "",
  },

  primary: {
    container: "flex items-center gap-2",
    wrapper: "flex flex-col",
    controlsWrapper: "flex items-center gap-2",
    buttonVariant: "primary",
    inputVariant: "primary",
    input: "w-20 text-center",
    button: "w-8 h-8 flex items-center justify-center",
    decrementButton: "",
    incrementButton: "",
  },

  minimal: {
    container: "flex items-center gap-1",
    wrapper: "flex flex-col",
    controlsWrapper: "flex items-center gap-1",
    buttonVariant: "minimal",
    inputVariant: "minimal",
    input: "w-16 text-center",
    button: "w-6 h-6 flex items-center justify-center",
    decrementButton: "",
    incrementButton: "",
  },
}
