import { VariantReader } from "aldoo-ra/VariantReader"
import Spinner  from "aldoo-ra/Spinner"

export default function Button({
  variant = "primary",
  styleOverrides,
  loading,
  children,
  onClick,
  disabled,
  className = "",
}) {
  const variants = VariantReader.useVariant({
    componentName: "Button",
    variant,
    styleOverrides,
  })
  const finalClassName = className ? `${variants} ${className}` : variants

  return (
    <button disabled={disabled} onClick={onClick} className={finalClassName}>
      {loading ? <Spinner /> : children}
    </button>
  )
}
