import { api } from "aldoo-ra/API"
// Construct the endpoints
const localizationAPI = api("localization")
const taskAPI = api("task-progress")
const devOpsAPI = api("aldoo-devops")

// Default timeout and polling intervals (in milliseconds)
const DEFAULT_TIMEOUT = 5 * 60 * 1000 // 5 minutes
const DEFAULT_POLL_INTERVAL = 2000 // 2 seconds

// Operation specific timeouts and polling intervals
const OPERATION_CONFIG = {
  SCRIPT: {
    timeout: 10 * 60 * 1000, // 10 minutes
    pollInterval: 2000, // 2 seconds
  },
}

// Define the localization methods
export const GetSupportedLanguages = async () => {
  return await localizationAPI({ languages: true })
}

export const GetLocalizationKeys = async (language, keys) => {
  return await localizationAPI({ get: { language, keys } })
}

export const GetLocalizationKey = async (key) => {
  return await localizationAPI({ get: { expand: true, keys: [key] } })
}

export const SaveLocalizationItem = async (item) => {
  return await localizationAPI({ save: item })
}

export const GetLanguageStatistics = async () => {
  return await localizationAPI({ statistics: true })
}

export const DeleteLocalizationKeys = async (keys, language) => {
  return await localizationAPI({ delete: { keys, language } })
}

export const DeleteLanguage = async (code) => {
  return await localizationAPI({ delete: { language: code } })
}

export const ListLocalizationKeys = async (selector) => {
  return await localizationAPI({ get: { expand: true, ...selector } })
}

export const AddLocalizationKey = async ({
  auth,
  key,
  type,
  content,
  language,
}) => {
  return await localizationAPI({
    save: { key, type, content, language, ...auth },
  })
}

export const AddLanguages = async (payload) => {
  return await localizationAPI({ languages_save: payload })
}

export const SaveLanguage = async (payload) => {
  return await localizationAPI({ language_save: payload })
}

export const AutoTranslateKey = async ({
  key,
  languageIn,
  languageOut,
  option,
}) => {
  return await localizationAPI({
    auto_translate: { key, languageIn, languageOut, option },
  })
}

export const AutoTranslateLanguage = async ({
  languageIn,
  languageOut,
  option,
}) => {
  return await localizationAPI({
    auto_translate_language: { languageIn, languageOut, option },
  })
}

export const ExportLocalization = async ({ option, selectedLanguages }) => {
  return await localizationAPI(
    { export: { option, selectedLanguages } },
    { responseType: "arraybuffer" }
  )
}

export const ExportLocalizationStats = async ({
  option,
  selectedLanguages,
}) => {
  return await localizationAPI({ export_stats: { option, selectedLanguages } })
}

export const UploadImportStats = async (file) => {
  const formData = new FormData()
  formData.append("import_stats", true)
  formData.append("file", file)
  return await localizationAPI(formData, {
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export const ImportLocalization = async ({
  taskProgressId,
  selectedLanguages,
}) => {
  return await localizationAPI({
    import: { taskProgressId, selectedLanguages },
  })
}

export const GetTaskProgress = async (taskID) => {
  return await taskAPI({ get: { id: taskID } })
}

export const StopTaskProgress = async (taskID) => {
  return await taskAPI({ stop: { id: taskID } })
}

// DevOps API Methods

/**
 * Polls task status until completion or timeout
 * @param {object} response - Initial API response
 * @param {number} timeout - Timeout in milliseconds
 * @param {number} pollInterval - Polling interval in milliseconds
 * @param {Function} onResponse - Callback for each response
 * @returns {Promise<object>} - Task status result or error
 */
const pollTaskStatus = async ({
  response,
  timeout = DEFAULT_TIMEOUT,
  pollInterval = DEFAULT_POLL_INTERVAL,
  onResponse,
}) => {
  // Return immediately if there's an error or no task ID
  if (response.error || !response.task) {
    return response
  }

  const startTime = Date.now()
  const taskId = response.task

  while (true) {
    // Check if we've exceeded the timeout
    if (Date.now() - startTime > timeout) {
      return { error: "timeout" }
    }

    // Get task status
    const taskStatus = await GetTaskProgress(taskId)

    if (onResponse) onResponse(taskStatus)

    // If there's an error getting status, return it
    if (taskStatus.error) return taskStatus

    // If task is completed or failed, return the status
    if (
      taskStatus.status === "finished" ||
      taskStatus.status === "failed" ||
      taskStatus.status === "error"
    ) {
      return taskStatus
    }

    // Wait for the polling interval
    await new Promise((resolve) => setTimeout(resolve, pollInterval))
  }
}

/**
 * Run script with task polling
 * @param {object} run - Script parameters
 * @param {number} timeout - Timeout in milliseconds
 * @param {Function} onResponse - Callback for each response
 * @returns {Promise<object>} - Task result
 */
export const RunScript = async ({
  script,
  env,
  timeout = OPERATION_CONFIG.SCRIPT.timeout,
  onResponse,
}) => {
  const response = await devOpsAPI({
    run: {
      script,
      env,
    },
  })

  return await pollTaskStatus({
    response,
    timeout,
    pollInterval: OPERATION_CONFIG.SCRIPT.pollInterval,
    onResponse,
  })
}

/**
 * Scan for new localization keys using the tasks API
 * @param {Function} onResponse - Callback for task progress updates
 * @returns {Promise<object>} - Scan results
 */
export const ScanForRebuildKeys = async (onResponse) => {
  try {
    const response = await RunScript({
      script: "cms-rebuild-localization",
      env: {
        mode: "scan",
      },
      onResponse,
    })

    return {
      result: response.status === "finished",
      newKeysCount: response.result?.newKeysCount || 0,
      taskID: response.id,
      error: response.error,
    }
  } catch (error) {
    console.error("Failed to scan for new keys", error)
    return {
      result: false,
      error: error.message || "Failed to scan for new keys",
    }
  }
}

/**
 * Start the rebuild process using the tasks API
 * @param {Function} onResponse - Callback for task progress updates
 * @returns {Promise<object>} - Rebuild task information
 */
/**
 * Start the rebuild process using the tasks API
 * @param {Function} onResponse - Callback for task progress updates
 * @param {boolean} report - Whether to include detailed reporting
 * @returns {Promise<object>} - Rebuild task information
 */
export const RebuildLocalizationKeys = async (onResponse, report = true) => {
  try {
    const response = await RunScript({
      script: "cms-rebuild-localization",
      env: {
        mode: "rebuild",
        report
      },
      onResponse,
    })

    return {
      result: response.status === "finished" || response.status === "running",
      taskID: response.id,
      error: response.error,
    }
  } catch (error) {
    console.error("Failed to start rebuild", error)
    return {
      result: false,
      error: error.message || "Failed to start rebuild process",
    }
  }
}

// Export the API
export default {
  GetSupportedLanguages,
  GetLocalizationKeys,
  GetLocalizationKey,
  SaveLocalizationItem,
  GetLanguageStatistics,
  DeleteLocalizationKeys,
  DeleteLanguage,
  ListLocalizationKeys,
  AddLocalizationKey,
  AddLanguages,
  SaveLanguage,
  AutoTranslateKey,
  AutoTranslateLanguage,
  ExportLocalization,
  ExportLocalizationStats,
  UploadImportStats,
  ImportLocalization,
  GetTaskProgress,
  StopTaskProgress,
  // DevOps API methods
  RunScript,
  ScanForRebuildKeys,
  RebuildLocalizationKeys,
}
