import React, { useState, useRef, useEffect } from "react"

// Helper function to get YouTube video ID from URL
const getYouTubeVideoID = (url) => {
  const regExp =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/
  const match = url?.match(regExp)
  return match ? match[1] : null
}

const VideoThumbnail = ({
  videoUrl,
  className = "",
  width = 128,
  height = 128,
  showPlayIcon = false,
  onClick,
}) => {
  const [thumbnail, setThumbnail] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const videoRef = useRef(null)
  const attemptRef = useRef(0)
  const maxAttempts = 3

  const isYouTube =
    videoUrl?.includes("youtube.com") || videoUrl?.includes("youtu.be")

  useEffect(() => {
    if (!videoUrl) {
      setError("No video URL provided")
      setLoading(false)
      return
    }

    if (isYouTube) {
      const videoId = getYouTubeVideoID(videoUrl)
      if (videoId) {
        const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
        setThumbnail(thumbnailUrl)
        setLoading(false)
      } else {
        setError("Invalid YouTube URL")
        setLoading(false)
      }
      return
    }

    const generateThumbnail = () => {
      const video = videoRef.current

      const handleLoadedMetadata = () => {
        video.currentTime = video.duration / 2
      }

      const handleError = () => {
        attemptRef.current++
        if (attemptRef.current < maxAttempts) {
          video.currentTime =
            (video.duration || 10) * (attemptRef.current / maxAttempts)
        } else {
          setError("Failed to generate thumbnail")
          setLoading(false)
        }
      }

      const handleTimeUpdate = () => {
        try {
          const canvas = document.createElement("canvas")
          canvas.width = video.videoWidth || width
          canvas.height = video.videoHeight || height
          const ctx = canvas.getContext("2d")
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
          setThumbnail(canvas.toDataURL())
          setLoading(false)
          cleanup()
        } catch (err) {
          handleError()
        }
      }

      const cleanup = () => {
        video.removeEventListener("loadedmetadata", handleLoadedMetadata)
        video.removeEventListener("timeupdate", handleTimeUpdate)
        video.removeEventListener("error", handleError)
      }

      video.addEventListener("loadedmetadata", handleLoadedMetadata)
      video.addEventListener("timeupdate", handleTimeUpdate)
      video.addEventListener("error", handleError)

      return cleanup
    }

    if (videoRef.current) {
      return generateThumbnail()
    }
  }, [videoUrl, width, height, isYouTube])

  return (
    <div
      className={`relative ${className}`}
      style={{ width, height }}
      onClick={onClick}
    >
      {loading && (
        <div className="absolute inset-0 bg-gray-100 animate-pulse rounded" />
      )}

      {error && (
        <div className="absolute inset-0 bg-gray-200 flex items-center justify-center rounded">
          <span className="text-xs text-gray-500">{error}</span>
        </div>
      )}

      {thumbnail && (
        <div className="relative w-full h-full">
          <img
            src={isYouTube ? thumbnail : thumbnail}
            alt="Video thumbnail"
            className="w-full h-full object-cover rounded"
          />
          {showPlayIcon && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="w-10 h-10 bg-black bg-opacity-50 rounded-full flex items-center justify-center">
                <div className="w-0 h-0 border-t-6 border-t-transparent border-l-10 border-l-white border-b-6 border-b-transparent ml-1" />
              </div>
            </div>
          )}
        </div>
      )}

      {!isYouTube && (
        <video
          ref={videoRef}
          src={videoUrl}
          className="hidden"
          crossOrigin="anonymous"
        />
      )}
    </div>
  )
}

export default VideoThumbnail
