const modelTypes = {}

export default function DatabaseModelTypeResolver() {
  // List all available type names
  const availableTypeNames = () => {
    if (!modelTypes || typeof modelTypes !== "object") return []
    return Object.keys(modelTypes)
  }

  // Add a new type to our model types
  const addType = (typeName, typeObject) => {
    //if the first argument is an object, then we assume that the object is the type object
    if (typeof typeName === "object") {
      typeObject = typeName
      typeName = typeObject?.type
    }

    if (!typeName || typeof typeName !== "string") {
      throw new Error("Type name must be a non-empty string")
    }

    if (!typeObject || typeof typeObject !== "object") {
      throw new Error("Type object must be a valid object")
    }

    //simple set
    modelTypes[typeName] = typeObject
    return modelTypes
  }

  const getType = (typeName) => {
    if (!typeName) return null
    return modelTypes[typeName] || null
  }

  const removeType = (typeName) => {
    if (!typeName || !modelTypes[typeName]) return false
    delete updatedTypes[typeName]
    return true
  }

  return {
    availableTypeNames,
    addType,
    getType,
    removeType,
    types: modelTypes, // Expose the current types object for reference
  }
}
