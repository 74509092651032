/**
 * FieldEditorResolver accepts a field type and returns the appropriate field editor component.
 */

//Import the available editors
import BooleanEditor from "./field-editors/boolean"
import FileEditor from "./field-editors/file"
import TextEditor from "./field-editors/text"
import DateEditor from "./field-editors/date"
import ImageEditor from "./field-editors/image"
import VideoEditor from "./field-editors/video"
import ArrayEditor from "./field-editors/array"
import ArrayContentEditor from "./field-editors/array-content"
import ObjectEditor from "./field-editors/object"
import BlogContentEditor from "./field-editors/blog-content"
import DatabaseModelEditor from "./field-editors/database-model"

//Create a map of field types to their respective editor components
const fieldTypeToEditorMap = {
  Boolean: BooleanEditor,
  File: FileEditor,
  Text: TextEditor,
  Number: TextEditor, // Use TextEditor for number types as well
  Image: ImageEditor,
  Video: VideoEditor,
  Object: ObjectEditor,
  Array: ArrayEditor,
  //the array editor for the cms content type
  ArrayContent: ArrayContentEditor,
  Content: ArrayContentEditor,
  BlogEditor: BlogContentEditor,
  DateEditor: DateEditor,
  DateRangeEditor: DateEditor,
  DatabaseModel: DatabaseModelEditor,
}

/**
 * Get an editor component based on the field type
 * @param {*} type
 */
const getFieldEditor = (field, dataItem) => {
  //invalid
  if (!field) return
  const { type, fieldEditor } = field
  //the resolved type is the type of the field
  let resolved = type
  //if the field.type is an array, then check if this is array
  //of content type or a simple array
  if (type == "Array") resolved = dataItem ? "ArrayContent" : "Array"
  //if the field has a field editor, use that
  if (fieldEditor && fieldEditor !== "Default") resolved = fieldEditor
  //get the component type off the LUT
  return fieldTypeToEditorMap[resolved]
}

/**
 * Check if the field has editor assigned to it
 * @param {*} field
 */
const hasFieldEditor = (field) => {
  return fieldTypeToEditorMap[field.type]
}

/**
 * Set an editor component based on the field type
 * @param {*} type
 * @param {*} editor
 */
const addFieldEditor = (type, editor) => {
  fieldTypeToEditorMap[type] = editor
}

export { getFieldEditor, addFieldEditor, hasFieldEditor }
