import React from "react"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { ShoppingCartIcon } from "@heroicons/react/24/solid"

export default function ShoppingCartTitle({ store }) {
  const texts = TextBundle({ id: "aldoo-store" })

  return (
    <div className="flex flex-row items-center gap-2">
      <ShoppingCartIcon className="w-6 h-6" />
      {texts?.shopping_cart}
    </div>
  )
}
