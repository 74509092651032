const enabledPlugins = {}
//this is called when we need to get the current state for history purposes
let getState
//Define how we get the state externally
export const CommanderStateResolve = (getter) => (getState = getter)

export const plugins = (plugins) => {
  //add the plugins to the enabled plugins, this is an object
  //where plugins = { pluginID: plugin, ... }
  // Loop through the plugins and add them to the enabledPlugins object
  for (const [pluginID, plugin] of Object.entries(plugins)) {
    enabledPlugins[pluginID] = plugin
  }
}
export const runCommand = (command, data) => {
  const parts = command.split(".")
  const pluginID = parts[0]
  const action = parts[1]
  let plugin = enabledPlugins[pluginID]
  if (!plugin) return
  // Handle plugin options
  let options

  // Check if the plugin is an array with options
  if (Array.isArray(plugin)) {
    options = plugin.length > 1 && plugin[1]
    plugin = plugin[0]
  }

  //if options.useHistory is an array, check if the action is in the array
  if (
    options &&
    Array.isArray(options.useHistory) &&
    !options.useHistory.includes(action)
  ) {
    return plugin({ action, data })
  }

  //Run the plugin by checking the history setting
  if (!options || options.useHistory !== false) {
    //Store the before and after state
    const beforeState = getState && getState()
    const result = plugin({ action, data })
    const afterState = getState && getState()

    // Only add to history if the command actually changed something
    if (beforeState !== afterState) {
      runCommand("history.add", beforeState)
      runCommand("history.add", afterState)
    }
    return result
  }

  //Run directly if we're not using history
  return plugin({ action, data })
}
