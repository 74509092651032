import React, { useRef, useEffect } from "react"
import { motion } from "framer-motion"
import { XMarkIcon } from "@heroicons/react/24/outline"
import Typography from "aldoo-ra/Typography"
import OnKey from "aldoo-ra/OnKey"
import { VariantReader } from "aldoo-ra/VariantReader"

const Modal = ({
  isOpen,
  onClose,
  onCloseBtnClick,
  title,
  children,
  closeOnClickOutside = false,
  allowCloseBtn = true,
  className = "",
  zIndex = 400,
  closeOnEscape = false,
  headerContent = null,
  variant = "default",
  styleOverrides,
}) => {
  const modalRef = useRef()
  //read styles
  const variants = VariantReader.useVariant({
    componentName: "Modal",
    variant,
    styleOverrides,
  })

  OnKey("Escape", () => {
    if (closeOnEscape) onClose()
  })

  // Control body scroll when modal opens/closes
  useEffect(() => {
    if (isOpen) {
      // Store current scroll position and padding
      const scrollY = window.scrollY
      const paddingRight = document.body.style.paddingRight
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth

      // Prevent background scroll
      document.body.style.position = "fixed"
      document.body.style.top = `-${scrollY}px`
      document.body.style.width = "100%"
      // Add padding to prevent content shift
      document.body.style.paddingRight = `${scrollbarWidth}px`

      return () => {
        // Restore scroll position when modal closes
        document.body.style.position = ""
        document.body.style.top = ""
        document.body.style.width = ""
        document.body.style.paddingRight = paddingRight
        window.scrollTo(0, scrollY)
      }
    }
  }, [isOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        closeOnClickOutside &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        onClose()
      }
    }
    if (isOpen) document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen, onClose, closeOnClickOutside])

  return (
    <div
      className={variants.overlay}
      style={{
        zIndex,
        display: isOpen ? "flex" : "none",
      }}
    >
      <motion.div
        ref={modalRef}
        className={`${variants.container} ${className}`}
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.5 }}
      >
        <div className={variants.header}>
          {allowCloseBtn && (
            <button
              className={variants.closeButton}
              onClick={() => {
                onCloseBtnClick && onCloseBtnClick()
                onClose && onClose()
              }}
            >
              <XMarkIcon className={variants.closeIcon} />
            </button>
          )}
          <Typography className={variants.title}>{title}</Typography>
          {headerContent && (
            <div className={variants.headerContent}>{headerContent}</div>
          )}
        </div>
        <div className={variants.contentWrapper}>
          <div className={variants.content}>{children}</div>
        </div>
      </motion.div>
    </div>
  )
}

export default Modal
