/**
 * A Localization component that will expose a t() function to translate strings
 */

import { useEffect, useRef } from "react"
import { usePromise } from "aldoo-ra/usePromise"
import { GetSupportedLanguages, GetLocalizationKeys } from "./api"
import Value from "aldoo-ra/Value"

//PERSIST Locally? so we don't have to fetch them every time
//Add a cache break for the translations
const translations = {}
let languages = []
let prevLanguage = null

let languagesDownloadInitialized = false

export default function Localization(options) {
  //incoming texts to load on mount
  const texts = options?.texts
  //get the current language from local storage
  const [, setRefresh] = Value("localization-update", 0)
  //the current language state stored
  const [currentLanguage, setCurrentLanguage] = Value({
    key: "current-site-language",
    defaultValue: "en",
    persistKey: "current-site-language",
  })
  //loading flag to prevent multiple loads
  const loading = useRef(false)

  //init the prev language only once when there is a valid current langauge
  useEffect(() => {
    if (prevLanguage || !currentLanguage) return
    prevLanguage = currentLanguage
  }, [currentLanguage])

  //TODO: Design a cache strategy to save the translations locally
  //load from local storage
  // useEffect(() => {
  //   const storedTranslations = localStorage.getItem('translations') || '{}'
  //   Object.assign(translations, JSON.parse(storedTranslations))
  // }, [])

  const { result, execute, pending } = usePromise(
    GetSupportedLanguages,
    true,
    false
  )

  useEffect(() => {
    if (!result) return
    //set the languages
    languages = result
  }, [result])

  useEffect(() => {
    if (languagesDownloadInitialized) return
    languagesDownloadInitialized = true
    if (execute) execute()
  }, [])

  const refreshLanguages = () => {
    execute()
    setRefresh((update) => update + 1)
  }

  //If texts are provided on mount, load them
  useEffect(() => {
    if (!texts || !texts.length) return
    if (loading.current) return
    //load the default language
    load(texts, currentLanguage)
  }, [])

  //the current language
  const setLanguage = (lang) => {
    //store the previous language
    prevLanguage = currentLanguage
    setCurrentLanguage(lang)
    //refresh the keys for the new language
    refreshKeys(lang)
  }

  const getLanguage = () => {
    //get the current language from local storage
    return currentLanguage
  }

  const getPrevLanguage = () => {
    return prevLanguage
  }

  /**
   * Translate a string with optional data for the interpolation placeholders
   * Example: t('hello {{name}}', {name: 'John'}) => 'hello John'
   * @param {*} key
   * @param {*} data
   * @returns
   */
  const t = (key, data) => translateWithLanguage(key, getLanguage(), data)

  /**
   * Load and translate - this will load the keys and translate the string
   * @param {*} key
   * @param {*} data
   */
  const lt = async (key, data) => {
    //reset the loading flag, so we can load
    //this will allow parallel loading of keys
    loading.current = false
    //load the key
    await load([key])

    let result = t(key, data)

    //if the result is the same as the {{key}}, return the key
    if (result === `{{${key}}}`) return key
    //translate using the current language
    return result
  }

  /**
   * Function that replaces placeholders in a template string with provided JSX elements.
   * @param {string} template - The template string with placeholders (e.g., "Available on {{ios}} and {{android}}")
   * @param {Object} replacements - An object containing JSX elements for placeholders (e.g., { ios: <div>IOS</div>, android: <div>Android</div> })
   * @returns {Array} An array of JSX elements and strings with placeholders replaced.
   */
  const tjsx = (template, replacements) => {
    // Split the template by placeholders ({{key}}) but exclude the placeholders themselves from the split array
    const parts = template.split(/(\{\{\w+\}\})/)

    return parts.map((part, index) => {
      // Check if part matches the placeholder format (e.g., {{ios}})
      const match = part.match(/^\{\{(\w+)\}\}$/)
      if (match) {
        // Extract the key from the placeholder format
        const key = match[1]
        // Return the replacement JSX if available, or the placeholder text as a fallback
        return replacements[key] || <span key={index}>{`{{${key}}}`}</span>
      }
      // Return plain text parts wrapped in <span>
      return <span key={index}>{part}</span>
    })
  }

  // Get nested object value using dot notation
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((current, key) => {
      return current && current[key] !== undefined ? current[key] : undefined
    }, obj)
  }

  /**
   *  Translate a string with a specific language and optional data for the interpolation placeholders
   * @param {*} key
   * @param {*} lang
   * @param {*} data
   * @returns
   */
  const translateWithLanguage = (key, lang, data) => {
    const translation = translations[lang] || {}
    const previousLanguageTranslation = translations[getPrevLanguage()] || {}
    const translated =
      translation[key] || previousLanguageTranslation[key] || key

    if (!data) return translated

    if (typeof translated === "string") {
      // Extract all placeholders from the string
      const placeholders = translated.match(/{{[\w.]+}}/g) || []

      return placeholders.reduce((acc, placeholder) => {
        // Extract the path from placeholder (remove {{ and }})
        const path = placeholder.slice(2, -2)
        // Get the value using dot notation
        const value = getNestedValue(data, path)
        // Replace the placeholder with the value if found, otherwise keep placeholder
        return acc.replace(
          new RegExp(placeholder.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
          value !== undefined ? value : placeholder
        )
      }, translated)
    }

    return translated
  }

  /**
   * Replace placeholders in a template string with provided data
   * Example: template('hello {{name}}', {name: 'John'}) => 'hello John'
   * @param {string} template - The template string with placeholders
   * @param {Object} data - An object containing values for placeholders
   * @returns {string} The template with placeholders replaced
   */
  const template = (template, data) => {
    if (!data) return template

    // Extract all placeholders from the string
    const placeholders = template.match(/{{[\w.]+}}/g) || []

    return placeholders.reduce((acc, placeholder) => {
      // Extract the path from placeholder (remove {{ and }})
      const path = placeholder.slice(2, -2)
      // Get the value using dot notation
      const value = getNestedValue(data, path)
      // Replace the placeholder with the value if found, otherwise keep placeholder
      return acc.replace(
        new RegExp(placeholder.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"), "g"),
        value !== undefined ? value : placeholder
      )
    }, template)
  }

  const refreshKeys = async (language) => {
    //when setting a new language, get the available keys in the translations
    //and refetch the keys from the server for that language
    //for all currently loaded languages get their keys
    const keys = Object.keys(translations).reduce((acc, lang) => {
      Object.keys(translations[lang]).forEach((key) => acc.add(key))
      return acc
    }, new Set())
    //no keys, return
    if (!keys.size) return

    //load the keys for the current language
    return await load([...keys], language)
  }

  /**
   * Load keys that will be used with the t() function
   * This can be done on page load to load only the keys that will be used
   * You can also call load() anywhere to preload keys before rendering
   * @param {*} keys
   * @returns
   */
  const load = async (keys, language) => {
    const lang = language ?? getLanguage()
    //get the translation for the current language
    let translation = translations[lang] || {}
    //get the missing keys
    const missingKeys = keys.filter((key) => !translation[key])
    //if there are no missing keys, return
    if (!missingKeys.length) return
    //if the keys are already loading, return
    if (loading.current) return
    //load the missing keys
    const loadedKeys = await loadKeys(lang, missingKeys)
    //update the translation after the load is over
    translation = translations[lang] || {}
    //append the loaded keys to the translation
    Object.assign(translation, loadedKeys)
    //set the new keys
    translations[lang] = translation
    //update the state, this will trigger a re-render
    setRefresh((update) => update + 1)
    //TODO: Design a cache strategy to save the translations locally
    //save the local storage
    // localStorage.setItem('translations', JSON.stringify(translations))
    //return what was loaded
    return loadedKeys
  }

  const loadKeys = async (lang, keys) => {
    loading.current = true
    //load the keys from the server
    const loadedKeys = await GetLocalizationKeys(lang, keys)
    loading.current = false
    return loadedKeys
  }

  const languageName = (lang) => {
    if (!languages || !languages.length) return ""
    return languages.find((item) => item?.code === lang)?.displayName || ""
  }

  const currentLanguageName = () => languageName(currentLanguage)

  return {
    currentLanguageName,
    languageName,
    refreshLanguages,
    languages,
    currentLanguage,
    //set the current language
    setLanguage,
    //translate a string
    t,
    //load the localization key and translate
    lt,
    //a version of the t() that can replace placeholders with JSX elements
    //returning a JSX source. Example tjsx('hello {{name}}', {name: <span>John</span>})
    tjsx,
    //translate given a language parameter
    translateWithLanguage,
    //just handle template replacements without translation
    template,
    //load translations keys prior to rendering
    //the loaded keys are stored locally for faster access
    load,
  }
}
