import parse from "html-react-parser"

// Helper function to handle item rendering
const renderMenuItem = (item) => {
  if (!item) return null
  if (item.render) return item.render(item)
  return <span>{parse(item.label)}</span>
}

export default renderMenuItem
