import renderPanelMenuItem from "../items/render-panel-menu-item"
import VariantReader from "aldoo-ra/VariantReader"
import RootMenuItem from "../items/root-menu-item"
import { useState } from "react"
import PubSub from "pubsub-js"

// Component for rendering items in panel mode
const PanelItems = (parameters) => {
  const {
    items,
    hideOnItemClick,
    itemRender,
    renderAs,
    variant,
    handleAction,
    styleOverrides,
  } = parameters

  const variants = VariantReader.useVariant({
    componentName: "Menu",
    variant: variant || renderAs,
    styleOverrides,
  })

  const { menu, menu_item, menu_item_image } = variants

  const [menuID, setMenuID] = useState(null)

  return (
    <RootMenuItem {...parameters} getMenuID={setMenuID}>
      <ul className={menu}>
        {items.map((item, index) => (
          <li
            key={index}
            className={"relative" + menu_item}
            role="menuitem"
            onClick={() => {
              handleAction(item)
              if (hideOnItemClick) {
                PubSub.publish("menu:hide", menuID)
              }
            }}
          >
            {item.image && (
              <img
                src={item.image}
                alt={item.label}
                className={"relative " + menu_item_image}
                style={{ zIndex: 2 }}
              />
            )}
            {itemRender
              ? itemRender(item, index)
              : renderPanelMenuItem(item, variants)}
          </li>
        ))}
      </ul>
    </RootMenuItem>
  )
}

export default PanelItems
