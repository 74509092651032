// variants/Icon.jsx - Icon variants definition
export default {
  xs: "w-4 h-4",
  sm: "w-6 h-6",
  md: "w-8 h-8",
  lg: "w-10 h-10",
  xl: "w-12 h-12",
  loading: "animate-pulse bg-neutral_1 rounded",
  // Social media icons
  social:
    "w-10 h-10 text-admin_text hover:text-primary transition-colors duration-200",
  "social-active": "w-10 h-10 text-primary",
  // Header icons
  header: "w-8 h-8 text-admin_text",
  "header-active": "w-8 h-8 text-primary",
  // Navigation icons
  navigation: "w-5 h-5 text-admin_text",
  // Button icons
  button: "w-5 h-5", // For icons inside buttons
  "button-lg": "w-6 h-6", // For icons inside large buttons
  // FAB icons
  fab: "w-6 h-6 text-admin_text_inv",
}
