import parse from "html-react-parser"
import { addDisplayFormatter } from "aldoo-ra/CMS/field-display-format-resolver"
import StorePriceFormatter from "aldoo-ra/Admin/Store/Prices/formatters/store-price-formatter"
import { StorePrice } from "aldoo-ra/Admin/content-types"
const { postFormat, preFormat } = StorePriceFormatter
//TODO: Those should be taken from the server
const currencySymbols = [
  { currency: "usd", symbol: "$", template: "{currency}{price}" },
  { currency: "eur", symbol: "€", template: "{currency}{price}" },
  { currency: "gbp", symbol: "£", template: "{currency}{price}" },
  { currency: "bgn", symbol: "лв", template: "{price}{currency}" },
]

/**
 * Format a given price and currency
 * Currency objects are also supported
 * @param {*} price
 * @param {*} currency
 * @returns
 */
export const priceDisplayFormat = (price, currency) => {
  //invalid input
  if (price == undefined && !currency) return ""

  //if the price is a currency object
  if (price?.amount !== undefined && price?.currency !== undefined) {
    const original = { ...price }
    price = original.amount
    currency = original.currency
  }

  //invalid input
  if (price == undefined || !currency) return ""

  currency = currency.toLowerCase()
  const display = currencySymbols.find((item) => item.currency === currency)
  //no template found
  if (!display) {
    return `${price}${currency}`
  }
  //apply the display template
  return display.template
    .replace("{price}", parseFloat(price).toFixed(2))
    .replace("{currency}", display.symbol)
}

const Badge = ({ children, className = "" }) =>
  `<div class="badge text-[14px] px-2 py-1 rounded-md ${className}">${children}</div>`

const PrimaryBadge = () =>
  Badge({
    children: "Primary",
    className:
      "text-white bg-primary rounded-[15px] py-1 px-3 mr-4 w-[80px] text-center badge-primary",
  })

const PaymentMethodBadge = ({ required }) =>
  required
    ? Badge({
        children: "Require Payment Method",
        className: "text-text bg-gray-300",
      })
    : Badge({
        children: "No Credit Card Required",
        className: "text-text bg-gray-300",
      })

const getTrialPeriod = (item) => {
  const { trial_duration, trial_duration_in_days } = item

  if (
    !trial_duration ||
    trial_duration === "no-trial" ||
    !trial_duration_in_days
  ) {
    return ""
  }

  return trial_duration === "custom"
    ? `with a ${trial_duration_in_days} days trial`
    : `with a 1 ${trial_duration} trial`
}

export const getPaymentDuration = (duration, duration_in_days) => {
  if (duration === "one-time-payment" || duration === undefined) return ""

  const table = { [365]: "year", [30]: "month", [7]: "week", [1]: "day" }
  duration = table[parseInt(duration)] || duration

  return `/ ${duration !== "custom" ? duration : `${duration_in_days} days`}`
}

export const priceOverview = (item) => {
  if (!item?.currency) return "Add a price model"

  const isSubscription =
    item.duration !== undefined && item.duration !== "one-time-payment"
  const isFree = parseInt(item.amount) === 0

  if (isFree) {
    return parse(`${item.primary ? PrimaryBadge() : ""} <b>Free</b>`)
  }

  const paymentType = isSubscription
    ? "<b>Subscription</b>"
    : "One time <b>Purchase</b>"
  const paymentDuration = getPaymentDuration(
    item.duration,
    item.duration_in_days
  )
  const trial = isSubscription ? getTrialPeriod(item) : ""
  const paymentMethodBadge = isSubscription
    ? PaymentMethodBadge({ required: item.requires_payment_method })
    : ""

  const content = `
    <div className="relative flex flex-row items-center gap-2 ${
      item.primary ? "border-2 border-primary p-3 rounded-lg" : ""
    }">
      ${paymentType} for <b>${priceDisplayFormat(
    item.amount,
    item.currency
  )}</b> ${paymentDuration} ${trial}
      ${paymentMethodBadge}
      ${
        item.primary
          ? Badge({ children: "Primary", className: "text-white bg-gray-700" })
          : ""
      }
    </div>
  `

  return parse(content)
}

export const priceSearchResult = (item) => {
  let data = { ...item }

  let primaryPrice = data.price_model?.find((price) => price.primary)

  if (typeof primaryPrice?.duration === "string") {
    data = postFormat(data, StorePrice)
    //set again but this time formatter
    primaryPrice = data.price_model.find((price) => price.primary)
  }

  if (!primaryPrice) return "No price configuration"
  const purchaseType = parseInt(primaryPrice.duration)
    ? "Subscription"
    : "One-time Purchase"
  const table = { [365]: "year", [30]: "month", [7]: "week", [1]: "day" }

  let period = primaryPrice.duration
  period = table[parseInt(period)] || (period ? `${period} days` : "")
  period = period ? `/ ${period}` : ""
  const timing = primaryPrice.auto_renew ? "Auto-Renew" : ""
  return `${priceDisplayFormat(
    primaryPrice
  )} ${period} - ${purchaseType} ${timing}`
}

//register the formatter so the content type fields can use it
addDisplayFormatter("priceSearchResult", priceSearchResult)
