export default {
  default: {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70",
    container:
      "bg-white max-h-full md:max-h-[calc(100vh-2rem)] overflow-hidden md:rounded-lg custom-scrollbar overflow-y-scroll border border-bordered shadow-lg overflow-hidden w-full",
    header: "relative flex justify-between items-center p-4",
    closeButton: "absolute right-4 top-4 hover:text-white z-10",
    closeIcon: "h-7 w-7 text-admin_text_secondary hover:text-admin_text",
    title: "flex-1 text-lg font-bold text-admin_text mt-[2px]",
    headerContent: "flex items-center",
    contentWrapper: "overflow-hidden",
    content: "px-4 pb-4 sm:px-5 sm:pb-5",
  },

  compact: {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70",
    container:
      "bg-white max-h-full md:max-h-[calc(80vh)] overflow-y-auto md:rounded-md border border-bordered shadow-lg w-full max-w-[50%] p-0 m-0",
    header: "relative flex justify-between items-center p-3",
    closeButton: "absolute right-3 top-3 hover:text-white z-10",
    closeIcon: "h-5 w-5 text-admin_text_secondary hover:text-admin_text",
    title: "flex-1 text-base font-bold text-admin_text",
    headerContent: "flex items-center",
    contentWrapper: "overflow-hidden",
    content: "pb-3  sm:pb-4",
  },

  //the content is not scrollable
  "compact-fixed": {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-70",
    container:
      "bg-white max-h-full md:max-h-[calc(80vh)] overflow-hidden md:rounded-md border border-bordered shadow-lg w-full max-w-[50%] p-0 m-0",
    header: "relative flex justify-between items-center p-3",
    closeButton: "absolute right-3 top-3 hover:text-white z-10",
    closeIcon: "h-5 w-5 text-admin_text_secondary hover:text-admin_text",
    title: "flex-1 text-base font-bold text-admin_text",
    headerContent: "flex items-center",
    contentWrapper: "overflow-hidden",
    content: "pb-3  sm:pb-4",
  },

  centered: {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-80",
    container:
      "bg-white max-h-full md:max-h-[calc(100vh-4rem)] overflow-y-auto md:rounded-lg border border-bordered shadow-xl overflow-hidden w-full max-w-2xl mx-4",
    header: "relative flex justify-between items-center p-5",
    closeButton: "absolute right-5 top-5 hover:text-white z-10",
    closeIcon: "h-7 w-7 text-admin_text_secondary hover:text-admin_text",
    title: "flex-1 text-xl font-bold text-admin_text",
    headerContent: "flex items-center",
    contentWrapper: "overflow-hidden",
    content: "px-5 pb-5 sm:px-6 sm:pb-6",
  },

  fullScreen: {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-90",
    container: "bg-white w-full h-full overflow-hidden",
    header: "relative flex justify-between items-center p-4 md:p-6",
    closeButton:
      "absolute right-4 md:right-6 top-4 md:top-6 hover:text-white z-10",
    closeIcon: "h-8 w-8 text-admin_text_secondary hover:text-admin_text",
    title: "flex-1 text-xl md:text-2xl font-bold text-admin_text",
    headerContent: "flex items-center",
    contentWrapper: "overflow-hidden h-[calc(100vh-80px)]",
    content: "px-4 pb-4 md:px-6 md:pb-6",
  },
}
