import React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import Spinner from "aldoo-ra/Spinner"

const CheckoutFormFooter = ({ store, handleBackToCart, isReady }) => {
  const texts = TextBundle({ id: "aldoo-store" })
  const { getCartTotalValue, paymentInfo } = store

  return (
    <div className="w-full flex flex-col items-end">
      {/* Total Amount Display */}
      <div className="flex md:flex-col items-end justify-start gap-4 md:gap-0 md:mt-3 mx-auto md:mx-0">
        <Typography 
          variant="info_18_accent"
          className="text-text my-0" 
        >
          {texts?.total}:
        </Typography>

        <Typography 
          variant="h3"
          className="text-text"
        >
          {getCartTotalValue()}
        </Typography>
      </div>

      <div className="w-full flex flex-col-reverse md:flex-row items-center justify-between md:gap-2 mt-4 md:mt-3">
        {/* Back to Cart Button */}
        <Button
          variant="text_button"
          onClick={handleBackToCart}
          className="flex items-center"
        >
          <ChevronLeftIcon className="w-5 h-5 mr-1" />
          <Typography variant="body_20">
            {texts?.back_to_cart || "{{ Missing }} Back to Cart"}
          </Typography>
        </Button>

        {/* Payment Button */}
        <Button
          variant="primary_s"
          type="submit"
          disabled={!isReady}
          className="w-full md:w-auto md:min-w-[250px]"
        >
          <Spinner
            variant="loading"
            className={`${!isReady ? "block" : "hidden"} w-5 h-5 mr-2 text-white`}
          />

          {isReady && (
            <>
              <Typography variant="body_20_accent">
                {paymentInfo.hasTrial
                  ? texts?.save_payment_method
                  : texts?.make_payment}
              </Typography>
              <ChevronRightIcon className="ml-1 w-5 h-5" />
            </>
          )}
        </Button>
      </div>
    </div>
  )
}

export default CheckoutFormFooter
