import { Logout } from '../api'
import PlatformAuth from 'aldoo-ra/PlatformAuth'
import Value from 'aldoo-ra/Value'


const useLogout = () => {
    //currently logged in user credentials
    const [, setAuth] = PlatformAuth.useAuth()
    const [, setProfile] = Value("user-profile")

    return async () => {
        //logout the user
        const response = await Logout()
        if (!response.result) return
        //reset the user credentials
        setAuth(null)
        setProfile(null)
    }
}

export default useLogout