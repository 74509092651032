import React, { useState, useEffect } from "react"
import LocalizationController from "./localization-controller"
import LocalizationItemDialog from "./localization-item-dialog"
import { usePromise } from "aldoo-ra/usePromise"
import { getFieldByLocalizationKey } from "./get-cms-info-by-key"

/**
 * Localization Item Editor Component
 * Accepts an item and allows editing and saving of its localization fields.
 *
 * @param {Object} item - The localization item to be edited
 * @param {Function} onSave - Callback when the item is saved
 * @param {Function} onClose - Callback when the editor is closed
 */
export default function LocalizationItemEditor({
  localizationKey,
  onSave,
  onClose,
  isOpen,
  defaultValue,
  parentObject,
}) {
  // Instantiate the controller and destructure its methods
  const controller = LocalizationController()
  const {
    getLanguageStatistics,
    getLocalizationKeyPreFormat,
    saveLocalizationItem,
    applyPostFormatters,
    applyPreFormatters,
    setPrimaryLanguage,
    setLanguages,
    primaryLanguage,
  } = controller

  const [localizationItem, setLocalizationItem] = useState()

  const { result: languages, execute: getLanguages } = usePromise(
    getLanguageStatistics,
    true
  )

  const itemFieldInfo = getFieldByLocalizationKey(localizationKey)

  // Localization field type
  const localizationFieldType = itemFieldInfo?.type?.toLowerCase()

  const {
    result: serverResult,
    pending,
    execute: getServerLocalizationItem,
  } = usePromise(async () => {
    let item = await getLocalizationKeyPreFormat(localizationKey)

    // The item doesn't exist, create it
    if (!item) {
      // Create new localization item
      await saveLocalizationItem({
        key: localizationKey,
        language: primaryLanguage,
        content: applyPostFormatters(defaultValue, itemFieldInfo.type),
        type: localizationFieldType,
      })

      // Retrieve the item again
      item = await getLocalizationKeyPreFormat(localizationKey)

      // Now, apply the preFormatters to the item content
      item.content = applyPreFormatters(item.content, localizationFieldType)
    }

    return item
  }, true)

  useEffect(() => {
    if (!isOpen) return
    getLanguages()
  }, [isOpen])

  useEffect(() => {
    if (!languages) return

    // Find the primary language and set it to the controller
    const primaryLanguageObj = languages?.languages.find(
      (language) => language.primary
    )
    setPrimaryLanguage(primaryLanguageObj?.code)
    setLanguages(languages?.languages)

    // Next, get the localization item
    getServerLocalizationItem()
  }, [languages])

  useEffect(() => {
    serverResult && setLocalizationItem(serverResult)
  }, [serverResult])

  return (
    <LocalizationItemDialog
      controller={controller}
      parentObject={parentObject}
      isOpen={isOpen}
      item={localizationItem}
      onClose={onClose}
      onSave={onSave}
    />
  )
}
