import React from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import Typography from "aldoo-ra/Typography"
import DynamicSVG from "aldoo-ra/DynamicSVG"
export default function TrustPilotRate() {
  return (
    <CMSRender
      contentType={"MenuItem"}
      match={{ "content.name": "TrustPilotRate" }}
      renderItem={(item) => {
        return (
          <div className="flex flex-row justify-center items-center w-full xl:h-20">
            <Typography 
              variant="body_20_major" className="whitespace-nowrap my-0">
              {item.label}
            </Typography>

            <a
              href={item.link[0].url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex-shrink-0"
            >
              <DynamicSVG
                //don't recolor, use the original colors
                recolor={false}
                url={item.image}
                className="relative -top-[2px] w-[100px] h-auto ml-3 flex-shrink-0"
              />
            </a>
          </div>
        )
      }}
    />
  )
}
