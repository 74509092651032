import config from "aldoo-ra/Config"
const { CMS_STATIC_URL, PLATFORM_API_URL } = config
const STATIC_URL = CMS_STATIC_URL
  ? CMS_STATIC_URL
  : PLATFORM_API_URL + "/cms/static"

//Return the full CMS URL for a given relative path
export const url = (path) => {
  if (!path) return ""

  if (Array.isArray(path) && path.length > 0) {
    path = path[0]
  }

  //if the path is already a full URL, return it
  if (path.startsWith("http") || path.startsWith("blob")) return path
  //otherwise, prepend the base URL
  return STATIC_URL + "/" + path
}

//Return the filename from a path
//e.g. filename('path/to/file.jpg') returns 'file.jpg'
export const filename = (path) => {
  if (!path) return ""
  //if this is youtube or vimeo, return the full URL
  if (path.includes("youtube") || path.includes("youtu.be")) return path

  return path.split("/").pop()
}

/**
 * Recursively transforms media URLs in objects and arrays
 * @param {any} data - The data to process
 * @param {function} transformFunction - URL transformation function
 * @returns {any} - Transformed data
 */
export const transformUrls = (data, transformFunction) => {
  // Handle null or undefined
  if (data == null) {
    return data
  }

  // Handle arrays
  if (Array.isArray(data)) {
    return data.map((item) => transformUrls(item, transformFunction))
  }

  // Handle objects
  if (typeof data === "object") {
    return Object.entries(data).reduce((acc, [key, value]) => {
      acc[key] = transformUrls(value, transformFunction)
      return acc
    }, {})
  }

  // Handle strings - check for media paths
  if (typeof data === "string") {
    const mediaPatterns = ["files/", "images/", "videos/"]
    if (mediaPatterns.some((pattern) => data.startsWith(pattern))) {
      return transformFunction(data)
    }
  }

  // Return unchanged value for other types
  return data
}

/**
 * Transforms media URLs in a content object.
 * This will go through all fields and check if they are media URLs.
 * If so, it will transform then using the url() function converting them to full URLs.
 * @param {*} content
 * @returns
 */
export const absoluteURLFields = (content) => {
  return transformUrls(content, url)
}
