// PlatformDetector.js
import { useEffect } from "react"
import Value from "aldoo-ra/Value"

export const getPlatform = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  if (/iphone|ipad|ipod/.test(userAgent)) {
    return "ios"
  }
  if (/android/.test(userAgent)) {
    return "android"
  }
  if (/mac/.test(userAgent) && !/iphone|ipad|ipod/.test(userAgent)) {
    return "mac"
  }
  if (/win/.test(userAgent)) {
    return "windows"
  }
  if (/linux/.test(userAgent)) {
    return "linux"
  }
  return "unknown"
}

const PlatformDetector = () => {
  const [detectedPlatform, setDetectedPlatform] = Value({
    key: "detected_platform",
    defaultValue: null,
    persistKey: "detected_platform",
  })

  useEffect(() => {
    const detected = getPlatform()
    setDetectedPlatform(detected)
  }, [])

  return null
}

export default PlatformDetector
