import React from "react"
import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"

const PaymentResultVerifyLoading = () => {
  const texts = TextBundle({ id: "aldoo-store" })
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Typography variant="h1">
        {texts?.verifying_payment || "{{ Missing }} Verifying Payment"}
      </Typography>

      <Typography 
        variant="info_18" 
        className="text-text"
      >
        {texts?.please_wait || "{{ Missing }} Please wait"}
      </Typography>
    </div>
  )
}

export default PaymentResultVerifyLoading
