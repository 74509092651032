// Constants
const duration_lut = {
    [7]: "week",
    [30]: "month",
    [365]: "year",
}

// Validation
const validateInput = (fieldValues, Type) => {
    if (Type.type !== "StorePrice" || !fieldValues) return false

    const priceModelField = Type.fields.find(field => field?.id === "price_model")
    if (!priceModelField || !fieldValues[priceModelField.id]) return false

    return fieldValues[priceModelField.id]
}

// Pre-resolution helpers
const preResolveDuration = currency => {
    if (typeof currency.duration === "string") return currency

    const originalDuration = currency.duration
    const matchingDuration = Object.keys(duration_lut)
        .find(item => parseInt(originalDuration) === parseInt(item))

    currency.duration = matchingDuration
        ? duration_lut[matchingDuration]
        : "one-time-payment"

    const isCustomDuration = !isNaN(parseInt(originalDuration))
        && parseInt(originalDuration) !== 0
        && !duration_lut[matchingDuration]

    if (isCustomDuration) {
        currency.duration = "custom"
        currency.duration_in_days = originalDuration
    }

    return currency
}

const preResolveTrialDuration = currency => {
    if (typeof currency.trial_duration === "string") return currency

    const originalTrialDuration = currency.trial_duration
    const matchingTrialDuration = Object.keys(duration_lut)
        .find(item => parseInt(originalTrialDuration) === parseInt(item))

    currency.trial_duration = matchingTrialDuration
        ? duration_lut[matchingTrialDuration]
        : "no-trial"

    const isCustomTrialDuration = !isNaN(parseInt(originalTrialDuration))
        && parseInt(originalTrialDuration) !== 0
        && !duration_lut[matchingTrialDuration]

    if (isCustomTrialDuration) {
        currency.trial_duration = "custom"
        currency.trial_duration_in_days = originalTrialDuration
    }

    return currency
}

// Post-resolution helpers
const postResolveDuration = currency => {
    const { duration, duration_in_days } = currency

    if (duration === "custom" && duration_in_days) {
        currency.duration = parseInt(duration_in_days)
        delete currency.duration_in_days
        return currency
    }

    const durationEntry = Object.entries(duration_lut)
        .find(([_, value]) => value === duration)

    if (durationEntry) {
        currency.duration = parseInt(durationEntry[0])
        return currency
    }

    if (duration === "one-time-payment") currency.duration = 0
    return currency
}

const postResolveTrialDuration = currency => {
    const { trial_duration, trial_duration_in_days } = currency

    if (trial_duration === "custom") {
        currency.trial_duration = parseInt(trial_duration_in_days || 0)
        delete currency.trial_duration_in_days
        return currency
    }

    const trialDurationEntry = Object.entries(duration_lut)
        .find(([_, value]) => value === trial_duration)

    if (trialDurationEntry) {
        currency.trial_duration = parseInt(trialDurationEntry[0])
        return currency
    }

    if (trial_duration === "no-trial") currency.trial_duration = 0
    return currency
}

// Main formatter
export default {
    preFormat: (fieldValues, Type) => {
        const priceModel = validateInput(fieldValues, Type)
        if (!priceModel) return fieldValues

        fieldValues.price_model = priceModel.map(currency => {
            const updated = { ...currency }

            preResolveDuration(updated)
            preResolveTrialDuration(updated)

            return updated
        })

        return fieldValues
    },

    postFormat: (fieldValues, Type) => {
        const priceModel = validateInput(fieldValues, Type)
        if (!priceModel) return fieldValues

        fieldValues.price_model = priceModel.map(currency => {
            const updated = { ...currency }

            postResolveDuration(updated)
            postResolveTrialDuration(updated)

            return updated
        })

        return fieldValues
    }
}