import { useState, useEffect, useRef, useCallback } from "react"
import PubSub from "pubsub-js"
import { navigate } from "aldoo-ra/Navigate"
import { showPromptDialog } from "aldoo-ra/PromptDialog"
import { showMessageDialog } from "aldoo-ra/MessageDialog"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import PlatformAuth from "aldoo-ra/PlatformAuth"
import Value from "aldoo-ra/Value"
const { SetBreakAwaitAuth, useSigninWithProvider, useLogout } = PlatformAuth

//dialog type enum
const DialogType = {
  MAGIC_LINK: "magicLink",
  PROVIDER: "provider",
  SUCCESS: "success",
  SIGNIN: "signin",
}

let instance = null

const useSigninController = () => {
  // State for dialog management
  const [provider, setProvider] = useState({})
  // DialogType
  const [dialog, setDialog] = useState("")
  //localization for this component
  const texts = TextBundle({ id: "signin" })

  // Authentication hooks
  const {
    state: signinState,
    error,
    signin: signinWithProvider,
  } = useSigninWithProvider()

  const [auth] = PlatformAuth.useAuth()

  const [userProfile] = Value("user-profile")
  const redirectTarget = useRef(null)

  const errorToMessage = (error) => {
    return error
  }

  //respond to errors
  useEffect(() => {
    if (!error) return
    showMessageDialog({
      title: instance?.data?.error_dialog_title,
      message: errorToMessage(error),
    })
  }, [error, instance?.data])

  //Interface
  const isDialogOpen = (dialogType) => dialog === dialogType
  const performLogout = useLogout()
  //return to the sign-in dialog
  const backToSignIn = () => {
    SetBreakAwaitAuth(true)
    setDialog("signin")
  }
  const closeDialog = () => setDialog("")
  const loginConfirm = () => {
    //set the new url on login confirm
    if (redirectTarget.current) {
      window.location.href = redirectTarget.current
    }
    setDialog("")
  }

  const logout = () => {
    //confirm before logout
    showPromptDialog({
      title: instance.data?.logout_dialog_title,
      message: instance.data?.logout_dialog_message,
      onConfirm: async () => {
        await performLogout()
        navigate("/")
      },
    })
  }

  //resolve the dialog type based on the provider
  const resolveDialogForProvider = (provider) => {
    if (provider.id === "email") return DialogType.MAGIC_LINK
    return DialogType.PROVIDER
  }

  // Start signing in with a provider
  const signin = async (selectedProvider, data) => {
    //set any parameters
    selectedProvider.data = data
    //set the active provider
    setProvider(selectedProvider)
    //reflect the dialog type based on the provider
    setDialog(resolveDialogForProvider(selectedProvider))
    // Trigger sign-in
    await signinWithProvider(selectedProvider)
  }

  useEffect(() => {
    // Subscribe to events, invoke the signin dialog from anywhere
    // once the login is done, you'll be redirected to the target redirect
    const signinToken = PubSub.subscribe("signin", (msg, data) => {
      const { redirect } = data || {}
      redirectTarget.current = redirect
      if (auth) return navigate(redirect || "/")
      // If not signed in, show the sign-in dialog
      setDialog("signin")
    })

    // Unsubscribe from events
    return () => PubSub.unsubscribe(signinToken)
  }, [auth])

  // Track sign-in state
  useEffect(() => {
    if (signinState === "success") setDialog(DialogType.SUCCESS)
  }, [signinState])

  instance = {
    data: texts,
    dialogType: dialog,
    provider,
    userProfile,
    logout,
    signin,
    closeDialog,
    loginConfirm,
    backToSignIn,
    DialogType,
    isDialogOpen,
  }

  return instance
}

export default useSigninController
