import React, { useState } from "react"
import Modal from "aldoo-ra/Modal"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import Button from "aldoo-ra/Button"
import {
  PlusIcon,
  CreditCardIcon,
  WalletIcon,
} from "@heroicons/react/24/outline"
import Checkbox from "aldoo-ra/Checkbox"
import usePaymentSubmission from "./use-payment-submission"

const PaymentMethodIcon = ({ type }) => {
  switch (type?.toLowerCase()) {
    case "card":
      return <CreditCardIcon className="h-5 w-5 text-gray-500" />
    default:
      return <WalletIcon className="h-5 w-5 text-gray-500" />
  }
}

const PaymentMethodDetails = ({ method }) => {
  if (!method) return null

  if (method?.type?.toLowerCase() === "card") {
    return (
      <>
        <p className="font-medium">
          {method.brand} •••• {method.last4}
        </p>
        <p className="text-sm text-gray-500">
          Expires {method.expiryMonth}/{method.expiryYear}
        </p>
      </>
    )
  }

  return (
    <>
      <p className="font-medium">{method.displayName || method.type}</p>
      {method.details && (
        <p className="text-sm text-gray-500">{method.details}</p>
      )}
    </>
  )
}

const ReusePaymentDialog = ({ store, onClose, onSelectNewPayment }) => {
  const texts = TextBundle({ id: "aldoo-store" })
  const [useSaved, setUseSaved] = useState(true)
  const [useNew, setUseNew] = useState(false)
  const [loading, setLoading] = useState(false)

  const { handleSubmitWithSavedMethod } = usePaymentSubmission(store)

  const { paymentInfo, setPaymentInfo, onPaymentError } = store
  const { savedPaymentMethod } = paymentInfo

  const handleSavedToggle = (checked) => {
    setUseSaved(checked)
    if (checked) setUseNew(false)
  }

  const handleNewToggle = (checked) => {
    setUseNew(checked)
    if (checked) setUseSaved(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (useNew) {
      onSelectNewPayment()
      return
    }

    if (useSaved) {
      try {
        setLoading(true)
        await handleSubmitWithSavedMethod()
        onClose()
      } catch (error) {
        console.error("Payment failed:", error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      title={texts?.selectPaymentMethod || "Select Payment Method"}
      isOpen={!!savedPaymentMethod}
      onClose={onClose}
      variant="centered"
      className="max-w-[500px]"
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div className="p-4 border rounded-lg hover:bg-gray-50">
            <div className="flex items-center">
              <Checkbox
                isOn={useSaved}
                onChange={handleSavedToggle}
                label={
                  <div className="flex items-center space-x-3 ml-3">
                    <PaymentMethodIcon type={savedPaymentMethod?.type} />
                    <div>
                      <PaymentMethodDetails method={savedPaymentMethod} />
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          <div className="p-4 border rounded-lg hover:bg-gray-50">
            <div className="flex items-center">
              <Checkbox
                isOn={useNew}
                onChange={handleNewToggle}
                label={
                  <div className="flex items-center space-x-3 ml-3">
                    <PlusIcon className="h-5 w-5 text-gray-500" />
                    <div>
                      <p className="font-medium">
                        {texts?.newPaymentMethod || "New Payment Method"}
                      </p>
                      <p className="text-sm text-gray-500">
                        {texts?.addNewCard || "Add a new payment method"}
                      </p>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-3">
          <Button
            type="button"
            variant="outlined"
            onClick={onClose}
            disabled={loading}
          >
            {texts?.cancel || "Cancel"}
          </Button>
          <Button type="submit" disabled={loading || (!useSaved && !useNew)}>
            {loading
              ? texts?.processing || "Processing..."
              : useSaved
              ? texts?.payWithSaved || "Pay with Saved Method"
              : texts?.continue || "Continue"}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default ReusePaymentDialog
