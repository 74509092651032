export default {
  "vlist.menu": {
    root: "relative inline-block",
    root_item: "flex items-center cursor-pointer px-2 py-2 rounded-md ",
    submenu:
      "absolute top-full mt-1 px-6 pt-6 pb-5 bg-white shadow-lg rounded-[16px] z-10",
    menu: "list-none space-y-5 p-0 m-0",
    submenu_item_offset: "ml-5 mt-5",
    menu_column: "border-none",
    menu_item:
      "hover:bg-neutral_1 flex flex-col p-0 m-0 items-left justify-between rounded-md cursor-pointer text-admin_text",
  },

  vlist: {
    menu_column: "",
  },

  "hlist-side-menu-mobile": {
    menu: "flex flex-row items-center gap-1 my-0",
    menu_item:
      "flex items-center list-none text-gray_2 hover:text-primary cursor-pointer px-4 py-0 my-0",
  },

  hlist: {
    menu: "flex flex-row items-start py-0 my-0",
    menu_item:
      "flex items-center list-none cursor-pointer px-4 py-0 my-0",
  },

  "hlist.icons": {
    menu: "flex flex-row items-center gap-5 py-0 my-0",
    menu_item: "py-0 my-0",
  },

  // Used in navbar - uppercase (Features group)
  "aqua-panel-menu": {
    root: "relative inline-block",
    root_item: "flex items-center cursor-pointer px-2 xl:px-4 py-2 rounded-md ",
    submenu:
      "absolute top-full mt-1 p-5 left-0 bg-white shadow-lg rounded-[16px] z-10",
    menu: "grid grid-cols-2 gap-1 min-w-[700px] m-0 p-0",
    menu_item_image:
      "mr-2 w-[45px] h-[45px] bg-white p-2 rounded-[9px] shadow-md",
    menu_item:
      "m-0 flex flex-row rounded-[9px] p-2 hover:bg-primary_lt cursor-pointer",
    menu_item_title: "text-lg font-semibold text-primary uppercase",
    menu_item_description: "text-sm text-primary",
  },

  // Used in navbar - no uppercase (Products group)
  "aqua-panel-menu-lowercase": {
    root: "relative inline-block",
    root_item: "flex items-center cursor-pointer px-2 xl:px-4 py-2 rounded-md ",
    submenu:
      "absolute top-full mt-1 p-5 left-0 bg-white shadow-lg rounded-[16px] z-10",
    menu: "grid grid-cols-2 gap-1 min-w-[700px] m-0 p-0",
    menu_item_image:
      "mr-2 w-[45px] h-[45px] bg-white p-2 rounded-[9px] shadow-md",
    menu_item:
      "m-0 flex flex-row rounded-[9px] p-2 hover:bg-primary_lt cursor-pointer",
    menu_item_title: "text-lg font-semibold text-primary",
    menu_item_description: "text-sm text-primary",
  },

  // Used in hamburger menu (Pricing group)
  "aqua-panel-menu-v-item": {
    menu: "m-0 p-0",
    menu_item:
      "m-0 font-normal flex items-left cursor-pointer  text-[20px] leading-tight text-primary hover:text-primary_hov transition-all duration-300",
  },

  // Used in hamburger menu (Features group)
  "aqua-panel-menu-v": {
    root: "relative inline-block",
    root_item:
      "font-normal flex items-center cursor-pointer text-[20px] leading-tight text-primary hover:text-primary_hov transition-all duration-300",
    root_toggle_icon: "w-[18px] h-[18px] ml-[6px]",
    submenu: "bg-theme_white z-10 px-0 pt-5 my-0",
    menu: "grid grid-cols-1 w-full m-0 p-0",
    menu_item_image:
      "w-[40px] h-[40px] bg-white p-2 rounded-[9px] shadow-md mr-3",
    menu_item:
      "m-0 flex flex-row rounded-[9px] p-2 hover:bg-primary_lt cursor-pointer",
    menu_item_title: "text-[20px] font-semibold text-primary uppercase tracking-widest mb-1",
    menu_item_description: "text-sm font-normal text-primary",
  },

  // Used in hamburger menu (Products group - not capitalized)
  "aqua-panel-menu-v-products": {
    root: "relative inline-block",
    root_item:
      "font-normal flex items-center cursor-pointer text-[20px] leading-tight text-primary hover:text-primary_hov transition-all duration-300",
    root_toggle_icon: "w-[18px] h-[18px] ml-[6px]",
    submenu: "bg-theme_white z-10 px-0 pt-5 my-0",
    menu: "grid grid-cols-1 w-full m-0 p-0",
    menu_item_image:
      "w-[40px] h-[40px] bg-white p-2 rounded-[9px] shadow-md mr-3",
    menu_item:
      "m-0 flex flex-row rounded-[9px] p-2 hover:bg-primary_lt cursor-pointer",
    menu_item_title: "text-[20px] font-semibold text-primary tracking-widest mb-1",
    menu_item_description: "text-sm font-normal text-primary",
  },
  
 //used in hamburger menu (Partners, Resources groups)
  "aqua-panel-menu-v-lowercase": {
    root: "relative inline-block",
    root_item:
      "font-normal flex items-center cursor-pointer text-[20px] leading-tight text-primary hover:text-primary_hov transition-all duration-300",
    root_toggle_icon: "w-[18px] h-[18px] ml-[6px]",
    submenu: "my-0 p-4 pb-0 z-10",
    menu: "grid grid-cols-1 m-0 p-0",
    menu_item_image:
      "mr-2 w-[45px] h-[45px] bg-white p-2 rounded-[9px] shadow-md",
    menu_item:
      "flex flex-row rounded-[9px] hover:bg-primary_lt cursor-pointer py-2 mt-2",
    menu_item_title: "text-[20px] leading-tight font-normal text-primary",
    menu_item_description: "text-sm text-primary",
  },

  vlist_footer: {
    menu_column: "hidden",
    menu_item: "px-0 my-4",
  },
}
