import React, { useEffect, useState } from "react"
import config from "aldoo-ra/Config"

// GTM tracker class to handle initialization and events
class TagManager {
  static initialized = false

  // Initialize GTM with the provided ID
  static initialize(gtmId, additionalEvents = []) {
    if (this.initialized || !gtmId) return
    if (typeof window === "undefined") return

    // Create dataLayer array if it doesn't exist
    window.dataLayer = window.dataLayer || []

    // Push initial GTM event
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })

    // Push any additional events
    additionalEvents.forEach((event) => {
      window.dataLayer.push(event)
    })

    // Create and add the GTM script
    const script = document.createElement("script")
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`

    // Insert the script into the head
    const firstScript = document.getElementsByTagName("script")[0]
    firstScript.parentNode.insertBefore(script, firstScript)

    this.initialized = true
  }

  // Method to push events to the dataLayer
  static pushEvent(event) {
    if (!window.dataLayer) return
    window.dataLayer.push(event)
  }

  // Generic method to track any custom event
  static trackEvent(eventName, eventParams = {}) {
    if (!this.initialized) return
    if (!eventName) return

    this.pushEvent({
      event: eventName,
      ...eventParams,
    })
  }

  // Method to track purchase events
  static trackPurchase(
    productId,
    price,
    quantity,
    currency = "USD",
    additionalParams = {}
  ) {
    if (!this.initialized) return
    if (!productId || typeof price !== "number" || typeof quantity !== "number")
      return

    this.pushEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          products: [
            {
              id: productId,
              price: price,
              quantity: quantity,
              ...additionalParams.product,
            },
          ],
          actionField: {
            id: additionalParams.transactionId || `T-${Date.now()}`,
            revenue: price * quantity,
            currency: currency,
            ...additionalParams.transaction,
          },
        },
      },
    })
  }

  // Method to handle cookie consent
  static handleCookieConsent(consentSettings) {
    if (!this.initialized) return
    if (!consentSettings) return

    this.setConsent({
      analytics: consentSettings.analytics || false,
      ads: consentSettings.marketing || false,
      preferences: consentSettings.preferences || false,
    })
  }

  // Method to set consent preferences
  static setConsent({ analytics = false, ads = false, preferences = false }) {
    if (!this.initialized) return

    this.pushEvent({
      event: "consent_update",
      consentAnalytics: analytics,
      consentAds: ads,
      consentPreferences: preferences,
    })
  }

  // Method to add noscript iframe
  static addNoScriptIframe(gtmId) {
    if (!gtmId || typeof document === "undefined") return

    // Check if noscript iframe already exists
    if (document.querySelector(`iframe[src*="${gtmId}"]`)) return

    // Create noscript element
    const noscriptContainer = document.createElement("noscript")
    const iframe = document.createElement("iframe")

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`
    iframe.height = "0"
    iframe.width = "0"
    iframe.style.display = "none"
    iframe.style.visibility = "hidden"

    noscriptContainer.appendChild(iframe)

    // Add after the opening body tag
    if (document.body) {
      document.body.prepend(noscriptContainer)
    }
  }

  // Debug method to verify GTM implementation
  static debug() {
    console.group("Google Tag Manager Status:")
    console.log("TagManager initialized:", this.initialized)

    if (typeof window === "undefined") {
      console.log("Running in server environment, browser data not available")
      console.groupEnd()
      return {
        initialized: this.initialized,
        environment: "server",
      }
    }

    const gtmId = config?.GTM_ID || "GTM-ID Not Found"

    console.log("GTM ID:", gtmId)
    console.log("dataLayer exists:", typeof window.dataLayer !== "undefined")

    if (window.dataLayer) {
      console.log("dataLayer length:", window.dataLayer.length)
      console.log(
        "Last dataLayer event:",
        window.dataLayer[window.dataLayer.length - 1]
      )
    }

    console.log(
      "GTM script loaded:",
      !!document.querySelector('script[src*="googletagmanager.com/gtm.js"]')
    )

    console.log(
      "GTM container exists:",
      typeof window.google_tag_manager !== "undefined"
    )

    if (window.google_tag_manager) {
      console.log(
        "GTM container for this ID exists:",
        typeof window.google_tag_manager[gtmId] !== "undefined"
      )
    }

    console.log(
      "GTM noscript iframe exists:",
      !!document.querySelector('iframe[src*="googletagmanager.com/ns.html"]')
    )

    console.groupEnd()

    return {
      initialized: this.initialized,
      environment: "client",
      gtmId: gtmId,
      dataLayerExists: typeof window.dataLayer !== "undefined",
      dataLayerLength: window.dataLayer ? window.dataLayer.length : 0,
      scriptLoaded: !!document.querySelector(
        'script[src*="googletagmanager.com/gtm.js"]'
      ),
      containerExists: typeof window.google_tag_manager !== "undefined",
      specificContainerExists: window.google_tag_manager
        ? typeof window.google_tag_manager[gtmId] !== "undefined"
        : false,
      iframeExists: !!document.querySelector(
        'iframe[src*="googletagmanager.com/ns.html"]'
      ),
    }
  }
}

// Expose TagManager to window for console debugging
if (typeof window !== "undefined") {
  window.TagManager = TagManager
}

// Main GTMTracker component
const GTMTracker = () => {
  const gtmId = config.GTM_ID
  const [isClient, setIsClient] = useState(false)

  // Effect for client-side detection
  useEffect(() => {
    setIsClient(true)
  }, [])

  // Effect for GTM initialization
  useEffect(() => {
    // Early return if no GTM ID
    if (!gtmId) {
      console.warn("GTM initialization failed: No GTM ID provided in config")
      return
    }

    // Early return if not client side
    if (!isClient) return

    // Initial consent state
    const initialEvents = [
      {
        event: "consent_loaded",
        consentAnalytics: false,
        consentAds: false,
        consentPreferences: false,
      },
    ]

    // Initialize GTM
    TagManager.initialize(gtmId, initialEvents)

    // Add noscript iframe
    TagManager.addNoScriptIframe(gtmId)

    // Log initialization status
    // console.log(`GTM (${gtmId}) initialized:`, TagManager.initialized)
    // const status = TagManager.debug()
    // console.log(status)

    // Return cleanup function
    return () => {
      // No real cleanup needed for GTM as it's a global script
    //   console.log("GTMTracker component unmounted")
    }
  }, [gtmId, isClient])

  // No need to render anything visible
  return null
}

// Export the component and TagManager class for external use
export default GTMTracker
export { TagManager }
