import Modal from "aldoo-ra/Modal"

export default function ShoppingCart({ store }) {
  //get the ProductComponent from the store
  //this is how we render the products in the cart
  //the project can override this component
  const { getFragment, cart, cartVisible, hideCart } = store

  //Fragments
  const EmptyCart = getFragment("shopping-cart-empty")
  const ShoppingCartProductList = getFragment("shopping-cart-product-list")
  const ShoppingCartFooter = getFragment("shopping-cart-footer")
  const ShoppingCartTitle = getFragment("shopping-cart-title")
  const ShoppingCartHeaderContent = getFragment("shopping-cart-header-content")

  if (!cartVisible) return null

  return (
    <Modal
      title={<ShoppingCartTitle store={store} />}
      isOpen={cartVisible}
      variant="centered"
      className=" max-w-[1200px]"
      onClose={hideCart}
      headerContent={
        cart.length > 0 && <ShoppingCartHeaderContent store={store} />
      }
    >
      {/* Empty Cart */}
      {cart.length == 0 && <EmptyCart store={store} />}

      {cart.length > 0 && (
        <>
          <ShoppingCartProductList store={store} />
          <ShoppingCartFooter store={store} />
        </>
      )}
    </Modal>
  )
}
