//use this flag to break the await auth chain
let BreakAwaitAuth = false
//External systems can break the await auth chain
const SetBreakAwaitAuth = (value) => (BreakAwaitAuth = value)
const GetBreakAwaitAuth = () => BreakAwaitAuth


export default {
    SetBreakAwaitAuth,
    GetBreakAwaitAuth
}
