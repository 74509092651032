import { priceDisplayFormat } from "aldoo-ra/Admin/Store/Prices/display-formatter"
import { url } from "aldoo-ra/CMS/url"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import { XMarkIcon } from "@heroicons/react/24/outline"
import NumericStepper from "aldoo-ra/NumericStepper"
/**
 * This is how we render a product in the Shopping Cart
 */
export default function Product({ product }) {
  return (
    <div className="relative flex items-start md:items-center justify-start bg-primary/5 rounded-[10px] gap-5 p-5">
      <img
        className="w-20 h-20 object-cover rounded-[5px] outline outline-primary shadow-lg outline-2"
        src={url(product.thumbnail)}
        alt={product.name}
      />

      {/* Product Name and Price */}
      <div className="w-full flex flex-col md:flex-row items-end md:items-center">
        <Typography 
          variant="body_20_accent"
          className="!text-[16px] lg:!text-[18px] xl:!text-[20px] text-text"
        >
          {product.name}
        </Typography>

        {/* Price */}
        <Typography 
          variant="body_20_accent"
          className="!text-[16px] lg:!text-[18px] xl:!text-[20px] text-text my-0 md:ml-auto"
        >
          {priceDisplayFormat(product.amount, product.currency)}
        </Typography>
      </div>

      {/* <NumericStepper
        variant="compact"
        readOnly
        styleOverrides={{
          buttonVariant: "outlined",
        }}
        min={1}
        max={10}
        value={product.quantity}
        onStep={(direction) => {
          direction > 0 ? product.add() : product.remove()
        }}
      /> */}
      
      {/* <Button
        className="absolute md:relative w-8 h-8 top-6 md:top-auto right-6 md:right-auto items-center rounded-full outline outline-primary hover:bg-white"
        variant=""
        onClick={() => product.clear()}
      >
        <XMarkIcon className="mx-auto w-5 h-5 text-primary" />
      </Button> */}
    </div>
  )
}
