import Value from "aldoo-ra/Value"
import config from "aldoo-ra/Config"

//This is the key that stores the credentials in the local storage
export const persistKey = config.APP_NAME.toLowerCase().replace(" ", "-") + "-ck"


/**
 * Get the current user credentials that are synced
 * on the local storage
 * @returns
 */
export default function useAuth() {
    if (import.meta.env.SSR) return []
    //TODO: Security: allow option to specify encryption when a persistKey is provided
    const [auth, setAuth] = Value({
        key: "user-credentials",
        defaultValue: null,
        persistKey
    })

    return [auth, setAuth]
}