import { useEffect } from "react"
import { usePromise } from "aldoo-ra/usePromise"
import { GetCMSType } from "./api"
import Value from "aldoo-ra/Value"

let typesInitialized = false

export default function TypeResolver() {
  const [types, setTypes] = Value("content-types-atom")

  const { result, pending, execute, reset, refresh } = usePromise(
    GetCMSType,
    true
  )

  const hasDuplicates = (contentType) => {
    // Check if a content with the same name exists
    return Object.values(types).some(
      (item) => item.type === contentType.type && item._id !== contentType._id
    )
  }

  const isTypeUsed = (type) => {
    // Check if a content type is used in any other content type as a dependency
    const usedInTypes = []

    Object.values(types).forEach((contentType) => {
      contentType?.fields?.forEach((field) => {
        if (!field.item) return
        if (
          (field.type === "Array" || field.type === "Content") &&
          field.item.type === type
        ) {
          const { type, label } = contentType
          usedInTypes.push({ type, label })
        }
      })
    })
    return usedInTypes
  }

  // Recursive function to resolve array item fields for a given content type
  const resolveArrayItemFields = (types) => {
    const resolveFieldItems = (field) => {
      if (
        (field.type === "Array" || field.type === "Content") &&
        typeof field.item === "string"
      ) {
        const resolvedItemType = { ...types[field.item] }

        if (resolvedItemType) {
          field.item = resolvedItemType
          field.item.fields?.forEach((nestedField) => {
            resolveFieldItems(nestedField)
          })
        }
      }
    }

    const resolvedTypes = { ...types } // Create a copy for immutability

    // Iterate over each content type and check its fields
    Object.keys(resolvedTypes).forEach((typeKey) => {
      const contentType = resolvedTypes[typeKey]
      // Iterate through each field of the content type
      contentType?.fields?.forEach((field) => {
        resolveFieldItems(field) // Recursively resolve any array field items
      })
    })

    return resolvedTypes
  }

  // Function to get a specific content type by its name
  const getContentType = (type) => {
    if (!type) return
    return types && types[type]
  }

  const convertToLookUpMap = (data) => {
    const lookupMap = {}
    const copy = [...data]

    copy.forEach((item) => {
      // Skip deleted items
      if (item.deleted) return
      // Add the content type to the lookup map
      if (!item.content) {
        console.log("Warning: No content for type", item)
        return
      }

      lookupMap[item.content.type] = {
        ...item.content,
        _id: item._id,
      }
    })
    const resolved = resolveArrayItemFields(lookupMap)
    setTypes(resolved)
  }

  const getContentTypeUnresolved = (type) => {
    const resolvedType = getContentType(type)

    if (!resolvedType || typeof resolvedType !== "object") {
      return null
    }

    const newType = {
      ...resolvedType,
      fields: resolvedType?.fields?.map((field) => {
        if (
          (field?.type === "Array" || field?.type === "Content") &&
          typeof field?.item === "object"
        ) {
          return {
            ...field,
            item: field.item.type,
          }
        }
        return field
      }),
    }

    return newType
  }

  // Function to list all loaded types
  const availableTypeNames = () => {
    if (!types || typeof types !== "object") return []
    return Object.keys(types)
  }

  const refreshTypes = () => {
    if (reset) reset()
    typesInitialized = false
    if (refresh) refresh()
  }

  // Load types when the component is first mounted
  useEffect(() => {
    if (!result && !pending && !typesInitialized) {
      typesInitialized = true
      execute()
      return
    }
    if (result && result.data) {
      convertToLookUpMap(result.data)
    }
  }, [result])

  return {
    types,
    hasDuplicates,
    getContentType,
    getContentTypeUnresolved,
    availableTypeNames,
    isTypeUsed,
    refreshTypes,
  }
}
