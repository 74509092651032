import React from "react"
import Modal from "aldoo-ra/Modal" // Assuming Modal component is already implemented
import Button from "aldoo-ra/Button" // Assuming Button component is already implemented
import ProgressBar from "aldoo-ra/ProgressBar" // Assuming ProgressBar component is already implemented

const ProgressDialog = ({
  isOpen,
  title,
  message,
  progress,
  onCancel,
  onClose,
}) => {
  if (!isOpen) return null // Don't render if dialog is not open

  return (
    <Modal
      isOpen={isOpen}
      allowCloseBtn={false}
      title={title}
      className="w-[320px] sm:w-[500px] bg-admin_paper text-admin_text text-sm sm:text-base p-4 shadow-lg"
    >
      <div className="pt-10 flex flex-col items-center space-y-10">
        {/* Message and Progress */}
        <ProgressBar progress={progress} title={message} />

        {/* Cancel Button */}
        <Button
          variant="outlined-primary"
          className="w-full max-w-[200px]"
          onClick={() => {
            onCancel()
            onClose()
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

export default ProgressDialog
