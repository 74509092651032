import { api } from "aldoo-ra/API"

// Construct the API endpoints
const userAPI = api("user")
const deviceAPI = api("user-device")

export const IsDeviceLogged = async (auth) => {
  return await deviceAPI({ login_state: auth })
}

export const GetUserProfile = async (profile) => {
  return await userAPI({ profile })
}

export const ChangeUsername = async (name) => {
  return await userAPI({ profile: { update: { name } } })
}

export const UserPreferences = async (selector) => {
  return await userAPI({ preferences: { data: selector } })
}

export const Login = async (credentials, userFields) => {
  credentials = userFields ? { ...credentials, ...userFields } : credentials
  return await userAPI({ login: credentials })
}

/**
 * Logout the user from the system
 * @returns
 */
export const Logout = async () => {
  return await userAPI({ logout: true })
}

export const VerifyCredentials = async (credentials) => {
  return await userAPI({ verify: credentials })
}

export const CreateDevice = async ({ fid }) => {
  const payload = {
    create: {
      platform: "Web",
      model: navigator.userAgent,
      fid,
    },
  }
  return await deviceAPI(payload)
}

export const RequestInternalID = async (auth) => {
  return await userAPI({ internal_id: auth })
}

export const CreateUser = async (auth) => {
  return await userAPI({ create: auth })
}

// Export the API methods as default
export default {
  IsDeviceLogged,
  GetUserProfile,
  UserPreferences,
  Login,
  Logout,
  VerifyCredentials,
  CreateDevice,
  RequestInternalID,
  CreateUser,
  ChangeUsername,
}
