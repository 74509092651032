export default {
  // Base sizes
  sm: "w-4 h-4",
  md: "w-6 h-6",
  lg: "w-8 h-8",
  xl: "w-12 h-12",

  // Thicknesses are controlled by the stroke opacity and width
  thin: "[&>svg>path.track]:opacity-20 [&>svg>path]:stroke-[6]",
  regular: "[&>svg>path.track]:opacity-25 [&>svg>path]:stroke-[8]",
  thick: "[&>svg>path.track]:opacity-30 [&>svg>path]:stroke-[10]",

  // Speeds
  slow: "[&>svg]:animate-[spin_2s_linear_infinite]",
  normal: "[&>svg]:animate-[spin_1s_linear_infinite]",
  fast: "[&>svg]:animate-[spin_0.5s_linear_infinite]",

  // Color schemes
  primary: "text-primary stroke-primary",
  secondary: "text-secondary stroke-secondary",
  success: "text-green-500 stroke-green-500",
  danger: "text-red-500 stroke-red-500",
  warning: "text-yellow-500 stroke-yellow-500",
  info: "text-blue-500 stroke-blue-500",
  light: "text-gray-100 stroke-gray-100",
  dark: "text-gray-800 stroke-gray-800",

  // Common combinations
  "primary-thin":
    "w-6 h-6 text-primary stroke-primary [&>svg]:animate-[spin_1s_linear_infinite] [&>svg>path.track]:opacity-20 [&>svg>path]:stroke-[6]",
  "secondary-thick":
    "w-6 h-6 text-secondary stroke-secondary [&>svg]:animate-[spin_1s_linear_infinite] [&>svg>path.track]:opacity-30 [&>svg>path]:stroke-[10]",
  "danger-fast":
    "w-6 h-6 text-red-500 stroke-red-500 [&>svg]:animate-[spin_0.5s_linear_infinite] [&>svg>path.track]:opacity-25 [&>svg>path]:stroke-[8]",

  // Special use cases
  button:
    "w-4 h-4 text-white stroke-white [&>svg]:animate-[spin_1s_linear_infinite] [&>svg>path.track]:opacity-20 [&>svg>path]:stroke-[6]",
  loading:
    "w-8 h-8 text-primary stroke-primary [&>svg]:animate-[spin_1s_linear_infinite] [&>svg>path.track]:opacity-25 [&>svg>path]:stroke-[8]",
  minimal:
    "w-4 h-4 text-gray-400 stroke-gray-400 [&>svg]:animate-[spin_1s_linear_infinite] [&>svg>path.track]:opacity-20 [&>svg>path]:stroke-[6]",

  // With backgrounds
  "card-loading":
    "w-6 h-6 text-primary stroke-primary [&>svg]:animate-[spin_1s_linear_infinite] [&>svg>path.track]:opacity-25 [&>svg>path]:stroke-[8] bg-white rounded-full p-1",
  overlay:
    "w-12 h-12 text-white stroke-white [&>svg]:animate-[spin_1s_linear_infinite] [&>svg>path.track]:opacity-25 [&>svg>path]:stroke-[8] bg-black/50 rounded-full p-2",
}
