// components/Typography/index.jsx
import React from "react"
import VariantReader from "aldoo-ra/VariantReader"

const Typography = ({
  variant = "txt",
  styleOverrides,
  component,
  children,
  className = "",
  ...props
}) => {
  // Get the variant class from VariantReader
  let variantClass = VariantReader.useVariant({
    componentName: "Typography",
    variant,
    styleOverrides,
  })

  // Determine which HTML element to render based on variant or component prop
  const getComponent = () => {
    if (component) return component

    if (variant.startsWith("h1")) return "h1"
    if (variant.startsWith("h2")) return "h2"
    if (variant.startsWith("h3")) return "h3"
    if (variant.startsWith("body")) return "p"
    if (variant.startsWith("btn")) return "span"
    if (variant.startsWith("txt")) return "span"

    return "p"
  }

  const Component = getComponent()

  //convert empty object to empty string
  if (typeof variantClass === "object" && !Object.keys(variantClass).length) {
    variantClass = ""
  }

  const finalClassName = `${variantClass} ${className}`.trim()

  return (
    <Component className={finalClassName} {...props}>
      {children}
    </Component>
  )
}

export default Typography
