import { setParameterInSelection } from "../tools"

/**
 * The action can be Left | Center | Right
 * @param {*} param0
 */
const PrintSelection = ({ action }) => {
 setParameterInSelection(action)
}

export default PrintSelection
