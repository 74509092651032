export default {
    preFormat: (fieldValues, Type) => {
        if (Type.type !== "StoreProduct") return fieldValues;
        if (!fieldValues) return fieldValues;

        const fieldTypes = Type.fields;
        for (const field of fieldTypes) {
            if (!field) continue;
            if (!fieldValues[field.id]) continue;

            // Handle price field
            if (field.id === "price") {
                if (fieldValues[field.id].model === "StorePrice") {
                    fieldValues[field.id] = fieldValues[field.id].item;
                }
            }

            // Handle bundle items
            if (field.id === "bundle" && Array.isArray(fieldValues.bundle)) {
                fieldValues.bundle = fieldValues.bundle.map(item => {
                    const bundleItem = { ...item };

                    // Convert product DatabaseModel to ID
                    if (bundleItem.product?.model === "StoreProduct") {
                        bundleItem.product = bundleItem.product.item;
                    }

                    // Convert price DatabaseModel to ID if it exists
                    if (bundleItem.price?.model === "StorePrice") {
                        bundleItem.price = bundleItem.price.item;
                    }

                    return bundleItem;
                });
            }
        }

        return fieldValues;
    },

    postFormat: (fieldValues, Type) => {
        if (Type.type !== "StoreProduct") return fieldValues;
        if (!fieldValues) return fieldValues;

        const fieldTypes = Type.fields;
        for (const field of fieldTypes) {
            if (!field) continue;
            if (!fieldValues[field.id]) continue;

            // Handle price field
            if (field.id === "price") {
                if (fieldValues[field.id].model === "StorePrice") {
                    fieldValues[field.id] = fieldValues[field.id].item;
                } else if (fieldValues[field.id]._id) {
                    fieldValues[field.id] = fieldValues[field.id]._id;
                }
            }

            // Handle bundle items
            if (field.id === "bundle" && Array.isArray(fieldValues.bundle)) {
                fieldValues.bundle = fieldValues.bundle.map(item => {
                    const bundleItem = { ...item };

                    // Convert product reference to ID
                    if (bundleItem.product?.model === "StoreProduct") {
                        bundleItem.product = bundleItem.product.item;
                    } else if (bundleItem.product?._id) {
                        bundleItem.product = bundleItem.product._id;
                    }

                    // Convert price reference to ID if it exists
                    if (bundleItem.price?.model === "StorePrice") {
                        bundleItem.price = bundleItem.price.item;
                    } else if (bundleItem.price?._id) {
                        bundleItem.price = bundleItem.price._id;
                    }

                    return bundleItem;
                });
            }
        }

        return fieldValues;
    }
}