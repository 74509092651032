import Text from "aldoo-ra/Text"

export default function Copyright() {
  return (
    <Text
      id="site_copyright"
      variant="info_12"
      className="text-gray_2 my-0"
      data={{
        product: (
          <a key="product-home" href="/" className="text-primary">
            Aqua Mail
          </a>
        ),
        year: new Date().getFullYear(),
      }}
    />
  )
}
