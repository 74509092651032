import React, { useState, useEffect, useCallback } from "react"
import Checkbox from "aldoo-ra/Checkbox"

export default function BooleanEditor({
  onChange,
  value,
  field,
  parentObject,
}) {
  const normalizeBoolean = (val) => {
    if (typeof val === "string") {
      return val === "true"
    }
    return !!val
  }

  const defaultValue =
    field.default !== undefined ? normalizeBoolean(field.default) : false

  const [localValue, setLocalValue] = useState(
    value === undefined || value === "" ? defaultValue : normalizeBoolean(value)
  )

  // Call onChange with initial value after mount
  useEffect(() => {
    const initialValue =
      value === undefined || value === ""
        ? defaultValue
        : normalizeBoolean(value)
    onChange?.(initialValue)
  }, []) // Empty dependency array ensures this only runs once after mount

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  useEffect(() => {
    const newValue =
      value === undefined || value === ""
        ? defaultValue
        : normalizeBoolean(value)
    if (newValue !== localValue) {
      setLocalValue(newValue)
    }
  }, [value, defaultValue, localValue])

  // Memoize the handler to prevent unnecessary re-renders
  const handleCheckboxChange = useCallback(
    (isChecked) => {
      console.log("isChecked", isChecked);

      setLocalValue(isChecked)
      if (onChange) {
        onChange(isChecked)
      }
    },
    [onChange]
  )

  // Create a single handler for the container click
  const handleContainerClick = useCallback(
    (e) => {
      // Prevent double triggers if the click is on the actual checkbox
      if (e.target.closest(".checkbox-control")) {
        return
      }
      handleCheckboxChange(!localValue)
    },
    [handleCheckboxChange, localValue]
  )

  return (
    // Add role="button" and tabIndex for accessibility
    <div
      onClick={handleContainerClick}
      role="button"
      tabIndex={0}
      className="cursor-pointer"
      // Add keyboard support
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault()
          handleContainerClick(e)
        }
      }}
    >
      <div className="flex flex-col mb-2">
        <label className="block font-semibold text-admin_text">
          {field.label}
          {isRequired && <span className="ml-2 text-error">(required)</span>}
        </label>
        {field.description && (
          <label className="block text-sm text-admin_text">
            {field.description}
          </label>
        )}
      </div>
      <Checkbox
        className="checkbox-control" // Add this class to help prevent double triggers
        label={localValue ? "Yes" : "No"}
        isOn={localValue}
        onChange={handleCheckboxChange}
      />
    </div>
  )
}
