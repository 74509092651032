import React, { useState, useEffect } from "react"
import Button from "aldoo-ra/Button"
import {
  getFormatter,
  postFormatFields,
  preFormatFields,
} from "../field-formatter"
import LocalizationController from "aldoo-ra/Localization/localization-controller"
import ContentImportDialog from "../dialogs/content-import-dialog"
import GroupController from "./groups"
import validateFields from "./validate-fields"
import ImportJSON from "./import-json"
import CopyPaste from "./copy-paste"

const truncate = (str, n = 80) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str
}

export default function ContentEditor({
  contentType,
  fieldValues = {},
  validators,
  formatters,
  onClickSave,
  onSave,
  saveLabel = "Save",
  title,
  allowTypeImport = false,
  allowContentImport = false,
  parentValues = null,
  onError,
  dataItem,
  //Usually when editing types, the fields don't expose the settings
  enableFieldSettings = true,
  variant = "default",
}) {
  const [localFieldValues, setLocalFieldValues] = useState({}) // Local copy of field values
  const [errors, setErrors] = useState({}) // Validation errors
  const localizationController = LocalizationController()
  const [showContentImportDialog, setShowContentImportDialog] = useState(false)

  //parent values getter
  if (!parentValues) parentValues = () => localFieldValues

  const save = async () => {
    if (
      !(await validateFields({
        contentType,
        localFieldValues,
        validators,
        parentValues,
        setErrors,
      }))
    ) {
      announceError()
      return
    }

    //there is no save method
    if (!onSave) return

    // Post format the field values before saving
    const postFormattedValues = await postFormatFields(
      { ...localFieldValues },
      contentType
    )

    // Run Type.formatter if specified (this is usually used to format DatabaseModel types)
    if (contentType.formatter) {
      const formatter = getFormatter(contentType.formatter)
      if (formatter?.postFormat) {
        await formatter.postFormat(postFormattedValues, contentType)
      }
    }

    // Run any custom post formatters
    formatters &&
      (await Promise.all(
        formatters.map(async (formatter) => {
          if (formatter.postFormat) {
            await formatter.postFormat(postFormattedValues, contentType)
          }
        })
      ))

    //return the save response to the caller
    return await onSave(postFormattedValues)
  }

  const { render: renderGroups } = GroupController({
    contentType,
    localFieldValues,
    setLocalFieldValues,
    parentValues,
    errors,
    setErrors,
    enableFieldSettings,
    dataItem,
    save,
    variant,
  })

  //handle on error
  const announceError = () => {
    if (!onError) return
    if (Object.keys(errors).length === 0) return
    //check if any error exists
    const hasError = Object.values(errors).some((error) => error !== null)
    if (!hasError) return
    //announce the error
    onError(errors)
  }

  const [showImportModal, setShowImportModal] = useState(false)

  const syncLocalizedFields = async (fieldValues, contentType) => {
    //find all fields that are localized and update their values
    const keys = dataItem.localization?.map((item) => item.localizationKey)

    if (!keys || keys.length == 0) return

    const result = await localizationController.getLocalizationKeys("en", keys)

    //update the field values
    for (const key in result) {
      const content = result[key]
      const fieldKey = key.split("-")[1]
      //skip the content if it's the same as the key
      //this means the field is not localized
      if (content === `{{${key}}}`) continue
      const fieldType = contentType.fields
        .find((field) => field.id === fieldKey)
        .type.toLowerCase()

      fieldValues[fieldKey] = localizationController.applyPreFormatters(
        content,
        fieldType
      )
    }
  }

  useEffect(() => {
    if (!fieldValues) return

    ~(async () => {
      //sync the localized fields if the localization is enabled
      if (contentType.fields?.localization && dataItem) {
        await syncLocalizedFields(fieldValues, contentType)
      }

      const preFormattedValues = await preFormatFields(
        { ...fieldValues },
        contentType
      )

      //run Type.formatter if specified ( this is usually used to format DatabaseModel types )
      if (contentType.formatter) {
        const formatter = getFormatter(contentType.formatter)
        if (formatter?.preFormat) {
          await formatter.preFormat(preFormattedValues, contentType)
        }
      }

      //run any custom pre formatters
      // Using Promise.all with map
      formatters &&
        (await Promise.all(
          formatters?.map(async (formatter) => {
            if (!formatter.preFormat) return
            return formatter.preFormat(preFormattedValues, contentType)
          })
        ))

      //assign all the preformatted values to the local field values
      setLocalFieldValues((prev) => preFormattedValues) // Set the initial field values on mount
    })()

    return () => {
      setLocalFieldValues({}) // Clean up when the component unmounts or modal closes
    }
  }, [fieldValues])

  const handleSaveClick = async () => {
    if (!onClickSave) return
    if (
      !(await validateFields({
        contentType,
        localFieldValues,
        validators,
        setErrors,
        parentValues,
      }))
    ) {
      announceError()
      return
    }

    const result = await save()
    onClickSave(result)
    if (!result) return
    setLocalFieldValues({}) // Clear values after saving if needed
  }

  return (
    <div className="p-4 relative">
      {/* Top Bar */}
      <div className="w-full flex justify-between items-start mb-5">
        <div className="h-[3em]">
          <h2 className="text-xl text-admin_text font-bold">{`${
            truncate(title ?? contentType?.type) ?? ""
          }`}</h2>
          {contentType?.label && (
            <div className="text text-admin_text">{`${contentType?.label}`}</div>
          )}
        </div>
        {/* Buttons ToolBar */}
        <div className="absolute top-5 right-5 flex flex-row">
          <CopyPaste
            setLocalFieldValues={setLocalFieldValues}
            localFieldValues={localFieldValues}
          />
          {allowTypeImport && (
            <Button
              variant="outlined"
              className="w-32 mr-5 text-admin_text"
              onClick={() => setShowImportModal(true)}
            >
              Import
            </Button>
          )}
          {allowContentImport && (
            <Button
              variant="outlined"
              className="w-32 mr-5 text-admin_text"
              onClick={() => setShowContentImportDialog(true)}
            >
              Import
            </Button>
          )}
          {onClickSave && (
            <Button
              variant="action"
              className="w-32 mx-auto"
              onClick={handleSaveClick}
            >
              {saveLabel}
            </Button>
          )}
        </div>
      </div>

      {/* Add a container for the groups */}
      <div className="flex flex-col h-[70vh] overflow-y-auto">
        {/* Render groups */}
        {renderGroups()}
      </div>

      {/* Import JSON modal */}
      <ImportJSON
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        contentType={contentType}
        setLocalFieldValues={setLocalFieldValues}
      />

      {/* Content Import Dialog will import items from a csv */}
      <ContentImportDialog
        contentType={contentType}
        isOpen={allowContentImport && showContentImportDialog}
        onClose={() => setShowContentImportDialog(false)}
      />
    </div>
  )
}
