//------------------------------------------------
// Field formatter
// This will convert the text field to an option field
// For example: if the field is a dropdown, it will convert the text to an option
// This is useful for the CMS field editors
// Example: if the field offers options,
// the text value "Option" will be converted to an option object like
//  { value: 'option', name: 'Option' }
// When exporting the data, the value will be converted back to the text
//  'Option'
//------------------------------------------------

import { isInternalType } from "../field-formatter"

export default {
  //fields inside instances of ContentType
  type: "Content",
  preFormat: (fieldValues, Type) => {
    // Don't change fields inside internally used types
    if (isInternalType(Type)) return
    //implement
    const fieldTypes = Type.fields

    for (const field of fieldTypes) {
      if (!field) continue
      if (!field.options) continue
      const value = fieldValues[field.id]

      //convert [ 'value1' ] to { value: 'value1', name: 'value1' }
      if (Array.isArray(value) && typeof value[0] === "string") {
        fieldValues[field.id] = { value: value, name: value }
      }
      //multiple values like [ [ 'value1', 'value2' ], [ 'value3' ] ]
      if (Array.isArray(value)) {
        //also handle nested arrays
        if (Array.isArray(value[0])) {
          fieldValues[field.id] = value.map((innerArray) =>
            innerArray.map((item) => ({ value: item, name: item }))
          )
        } else {
          fieldValues[field.id] = value.map((item) => ({
            value: item,
            name: item,
          }))
        }
      }
    }

    return fieldValues
  },
  postFormat: (fieldValues, Type) => {
    if (isInternalType(Type)) return fieldValues
    //implement postformat
    const fieldTypes = Type.fields

    for (const field of fieldTypes) {
      if (!field) continue

      const value = fieldValues[field.id]
      //------------------------------V
      //convert { value: 'value1', name: 'value1' } to 'value1'
      //------------------------------V
      if (field.options && value && typeof value === "object") {
        fieldValues[field.id] = value.value
      }
      //------------------------------V
      //convert [ { value: 'value1', name: 'value1' }, { value: 'value2', name: 'value2' } ] to [ 'value1', 'value2' ]
      //------------------------------V
      if (field.options && value && Array.isArray(value)) {
        // Check if the array is a nested array of objects
        if (Array.isArray(value[0])) {
          //This will modify [[{ value: 'value1', name: 'value1' }]] to [['value1']]
          fieldValues[field.id] = value.map((innerArray) =>
            innerArray.map((item) => item.value)
          )
        } else if (
          value.every((item) => typeof item === "object" && item.value)
        ) {
          fieldValues[field.id] = value.map((item) => item.value)
        }
      }

      //------------------------------
      // Remove null values and empty arrays
      //------------------------------
      if (value === null || (Array.isArray(value) && value.length === 0)) {
        fieldValues[field.id] = undefined
      }
    }

    return fieldValues
  },
}
