import config from "aldoo-ra/Config"
import NotificationProvider from "aldoo-ra/Notification"
import useThemeController from "aldoo-ra/ThemeController"
import Chat from "aldoo-ra/Chat"
import ContactPanel from "../components/ContactPanel"
import ContactPanelPartners from "../components/ContactPanelPartners"
import EnsureStableLogin from "aldoo-ra/EnsureStableLogin"
import MessageDialogProvider from "aldoo-ra/MessageDialog"
import PromptDialogProvider from "aldoo-ra/PromptDialog"
import PageViewTracker from "aldoo-ra/Tracking/PageViewTracker"
import FacebookTracking from "aldoo-ra/Tracking/facebook"
import GoogleTracking from "aldoo-ra/Tracking/google"
import GTMTracker from "aldoo-ra/GTMTracker"
import React from "react"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import useSentry from "../components/SentryWrapper"
import Signin from "aldoo-ra/Signin"
import AldooStore from "aldoo-ra/Store"
import "./Layout.css"
import PlatformDetector from "aldoo-ra/PlatformDetector"
import PlatformManager from "../components/Site/PlatformManager"
import AldooBoot from "aldoo-ra/Boot"
import PromoBanner from "../components/Site/PromoBanner"

function PageLayout({ children }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        margin: "auto",
      }}
    >
      {children}
    </div>
  )
}

function Content({ children }) {
  return (
    <div
      className="bg-white"
      style={{
        minHeight: "100vh",
      }}
    >
      {children}
    </div>
  )
}

export function Layout({ children }) {
  AldooBoot()
  PlatformManager()
  return (
    <React.StrictMode>
      <PageLayout>
        <EnsureStableLogin />
        <NotificationProvider />
        <MessageDialogProvider />
        <PromptDialogProvider />
        <GTMTracker />
        <GoogleTracking />
        <FacebookTracking />
        <PageViewTracker />
        <Navbar />
        <Content>{children}</Content>
        <Signin />
        <AldooStore />
        <ContactPanel />
        <ContactPanelPartners />
        <PromoBanner />
        <Footer />
      </PageLayout>
    </React.StrictMode>
  )
}
