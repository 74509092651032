import React from 'react'

const extensionColors = {
  doc: '#2A579A', // Blue for DOC files
  docx: '#2A579A', // Blue for DOC files
  pdf: '#D32F2F', // Red for PDF files
  xlsx: '#1E824C', // Green for XLS files
  xls: '#1E824C', // Green for XLS files
  png: '#FFB300', // Yellow for PNG files
  jpg: '#FFB300', // Yellow for JPG files
  jpeg: '#FFB300', // Yellow for JPG files
  webp: '#FFB300', // Yellow for JPG files
  // Add more extensions as needed
  default: '#555555', // Default grey for unknown formats
}

export default function FileIcon({ extension }) {
  const fileColor =
    extensionColors[extension.toLowerCase()] || extensionColors.default

  return (
    <div className="flex flex-col w-full h-full justify-center items-center rounded-md overflow-hidden border border-neutral_1">
      {/* The Icon Shape */}
      <div
        className="flex-grow w-full flex justify-center items-center"
        style={{
          backgroundColor: fileColor,
        }}
      >
        {/* Three horizontal lines */}
        <div className="space-y-2">
          <div className="w-12 h-1 bg-white rounded-full"></div>
          <div className="w-12 h-1 bg-white rounded-full"></div>
          <div className="w-12 h-1 bg-white rounded-full"></div>
        </div>
      </div>

      {/* File extension */}
      <div
        className="w-full text-center text-white text-sm font-semibold py-1"
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          width: '100%',
        }}
      >
        {extension.toUpperCase()}
      </div>
    </div>
  )
}
