import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import PubSub from "pubsub-js"
import MenuGroup from "aldoo-ra/MenuGroup"
import DynamicSVG from "aldoo-ra/DynamicSVG"
import SiteLanguageSelector from "../SiteLanguageSelector/button"
import Value from "aldoo-ra/Value"
import Localization from "aldoo-ra/Localization"
import Icon from "aldoo-ra/Icon"
import Button from "aldoo-ra/Button"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import Typography from "aldoo-ra/Typography"

const HamburgerMenu = ({ isOpen, setIsOpen }) => {
  const [platform, setPlatform] = Value("site_platform")
  const { template } = Localization()

  const toggleMenu = (event) => {
    event.stopPropagation()
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  return (
    <div className="relative" onClick={(e) => e.stopPropagation()}>
      {/* Hamburger Button */}
      <button
        className="p-2 rounded-md text-text hover:text-blue_primary"
        onClick={toggleMenu}
        aria-label="Toggle Menu"
      >
        {isOpen ? (
          <XMarkIcon className="w-6 h-6" />
        ) : (
          <Bars3Icon className="w-6 h-6" />
        )}
      </button>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="fixed bottom-[80px] inset-x-0 top-0 z-40 bg-white px-2 pt-[90px] flex flex-col gap-4 overflow-y-auto" style={{ boxShadow: 'inset 0 -10px 10px -10px rgba(0, 0, 0, 0.15)' }}>
          {/* Menu Groups */}
          <div className="flex flex-col gap-10 px-4">
            <MenuGroup
              name="Header Products"
              renderAs="panel"
              variant="aqua-panel-menu-v-products"
              showMenuMode="click"
              containMenu
              onItemClick={item=>{
                closeMenu()

                const urlPlatform = item.link[0].url.split("/")[1]
                let currentUrl = window.location.href
                //also set the platform from the link[0].url
                setPlatform(urlPlatform)
                //if the user is a at a feature page, redirect to the feature page
                //redirect to the same page for the current platform
                if (window.location.pathname.includes("features")) {
                  //replace the current platform with the new platform
                  const newUrl = currentUrl.replace(
                    window.location.pathname.split("/")[1],
                    urlPlatform
                  )

                  //special case for advanced-features not available on ios
                  //redirect to account-and-setup instead
                  if (
                    urlPlatform === "ios" &&
                    window.location.pathname.includes("advanced-features")
                  ) {
                    window.location.href = newUrl.replace(
                      "advanced-features",
                      "account-and-setup"
                    )
                    return
                  }

                  //navigate to the new url
                  window.location.href = newUrl
                  return
                }
                //just navigate to the link[0].url when not in a feature page
                window.location.href = item.link[0].url
              }}

              rootItemRender={(item) => {
                return (
                  <Button
                    variant="nav_menuitem"
                    className="flex items-center justify-center gap-[5px] !text-[20px] px-0 lg:mr-10 xl:mr-[68px]"
                  >
                    {item.label}

                    <Icon id={platform} className="w-5 h-5 mb-1" />

                    <div className="w-5 h-5 flex items-center justify-center">
                      <ChevronDownIcon className="w-4 h-4" strokeWidth={3} />
                    </div>
                  </Button>
                )
              }}
            />

            <MenuGroup
              name="Header Features"
              renderAs="panel"
              variant="aqua-panel-menu-v"
              showMenuMode="click"
              containMenu
              onItemClick={closeMenu}
              preProcessItem={(item) => {
                if (item.items) {
                  item.items = item.items
                    .filter((item) => {
                      const { platforms } = item.options || {}
                      if (!platforms) return true
                      return platforms.toLowerCase().includes(platform)
                    })
                    .map((i) => {
                      i.link[0].url = template(i.link[0].url, { platform })
                      return i
                    })
                }
                return item
              }}
            />

            {/* <MenuGroup
              name="Header Pricing"
              renderAs="hlist"
              variant="aqua-panel-menu-v-item"
              onItemClick={closeMenu}
            /> */}

            <MenuGroup
              name="Header Partners"
              renderAs="panel"
              variant="aqua-panel-menu-v-lowercase"
              showMenuMode="click"
              containMenu
              onItemClick={closeMenu}
            />

            <MenuGroup
              name="Header Resources"
              renderAs="panel"
              variant="aqua-panel-menu-v-lowercase"
              showMenuMode="click"
              containMenu
              onItemClick={closeMenu}
            />
          </div>

          {/* Account and Language Selector */}
          <div className="mt-20 ml-1">
            {/* <MenuGroup
              name="Header Profile"
              renderAs="hlist.icons"
              actionMap={{
                signin: () =>
                  PubSub.publish("signin", {
                    redirect: "/account",
                  }),
              }}
              onItemClick={closeMenu}
              itemRender={(item) => (
                <DynamicSVG
                  url={item.image}
                  className="w-6 h-6 text-primary hover:text-primary_hov ml-2 mb-6 transition"
                />
              )}
            /> */}

            <SiteLanguageSelector
              menuPosition="top"
              showMenuMode="click"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default HamburgerMenu