import Value from "aldoo-ra/Value"
import { useEffect } from "react"
import { GetUserProfile } from "../api"
import { usePromise } from "aldoo-ra/usePromise"

export default function Profile() {
    //get the user-credentials value and watch for changes
    //if there is a valid credentials object, we will get the user profile
    const [auth] = Value("user-credentials")
    const [, setProfile] = Value({
        key: "user-profile",
        defaultValue: null,
        persistKey: "user-profile",
    })


    const { result, execute, pending, reset } = usePromise(async () => {
        //iek is inflight encryption key used to decode passwords and texts
        return await GetUserProfile({
            fields: "name avatar email devices iek",
        })
    }, true)

    useEffect(() => {
        //reset the profile if there are no credentials
        if (!auth) {
            reset()
            return setProfile(undefined)
        }
        if (auth && !pending && !result) execute()
        if (result && !result.error) setProfile(result)

    }, [auth, result])

}