import React, { useState } from "react"
import { XMarkIcon, ClipboardIcon } from "@heroicons/react/24/outline"

// Helper function to get YouTube video ID from URL
const getYouTubeVideoID = (url) => {
  const regExp =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/
  const match = url.match(regExp)
  return match ? match[1] : null
}

const VideoPreview = ({ isOpen, videoUrl, fileName, onClose }) => {
  const [copySuccess, setCopySuccess] = useState({
    filename: false,
    url: false,
  })

  const handleCopyFileName = async () => {
    try {
      await navigator.clipboard.writeText(fileName)
      setCopySuccess((prev) => ({ ...prev, filename: true }))
      setTimeout(
        () => setCopySuccess((prev) => ({ ...prev, filename: false })),
        2000
      )
    } catch (err) {
      console.error("Failed to copy filename:", err)
    }
  }

  const handleCopyVideoUrl = async () => {
    try {
      await navigator.clipboard.writeText(videoUrl)
      setCopySuccess((prev) => ({ ...prev, url: true }))
      setTimeout(
        () => setCopySuccess((prev) => ({ ...prev, url: false })),
        2000
      )
    } catch (err) {
      console.error("Failed to copy video URL:", err)
    }
  }

  if (!isOpen) return null

  const isYouTubeVideo =
    videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be")

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex flex-col z-50"
      style={{ zIndex: 1000 }}
      onClick={onClose}
    >
      {/* Top control bar */}
      <div
        className="w-full bg-black bg-opacity-70 p-4 flex items-center gap-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center gap-2">
          <button
            onClick={handleCopyFileName}
            className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200 text-white"
            aria-label="Copy filename"
          >
            <ClipboardIcon className="w-5 h-5" />
          </button>
          <span className="text-white text-sm">
            {copySuccess.filename ? "Copied!" : fileName}
          </span>
        </div>

        <div className="flex items-center gap-2">
          <button
            onClick={handleCopyVideoUrl}
            className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200 text-white"
            aria-label="Copy video URL"
          >
            <ClipboardIcon className="w-5 h-5" />
          </button>
          <span className="text-white text-sm">
            {copySuccess.url ? "Copied!" : "Video URL"}
          </span>
        </div>

        <div className="flex-1 flex justify-end">
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200"
            aria-label="Close preview"
          >
            <XMarkIcon className="w-6 h-6 text-white" />
          </button>
        </div>
      </div>

      {/* Video container */}
      <div
        className="flex-1 flex items-center justify-center p-4"
        onClick={(e) => e.stopPropagation()}
      >
        {isYouTubeVideo ? (
          <iframe
            width="1000"
            height="563"
            src={`https://www.youtube.com/embed/${getYouTubeVideoID(videoUrl)}`}
            title={fileName}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="max-w-full max-h-full rounded-lg shadow-lg"
          />
        ) : (
          <video
            controls
            src={videoUrl}
            className="max-w-full max-h-full rounded-lg shadow-lg"
            autoPlay
          >
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  )
}

export default VideoPreview
