import React from "react"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import Localization from "aldoo-ra/Localization"

export default function ShoppingCartHeaderContent({ store }) {
  const texts = TextBundle({ id: "aldoo-store" })
  const { t } = Localization()
  const { cart } = store

  return (
    <div className="flex mt-8 md:mt-0">
      {/* Number of items in cart */}
      <div className="h-10 md:h-12 flex items-center justify-center rounded-full bg-primary/5 px-5 py-3 mr-5">
        <Typography 
          variant="info_18"
          className="text-center text-text/80"
        >
          {t(texts?.items, { count: cart.length })}
        </Typography>
      </div>
      
      <Button
        variant="secondary_s"
        className="gap-2 md:mr-16"
        onClick={() => {
          store.emptyCart()
        }}
      >
        {texts?.empty_cart}
      </Button>
    </div>
  )
}
