import React from "react"
import { useState, useRef, useEffect, useMemo } from "react"
import debounce from 'lodash/debounce'

const generateWavePath = ({
  frequency = 1,
  amplitude = 70,
  phase = 0,
  sampleRate = 32,
  width,
  height,
}) => {
  const baseY = height / 2
  const points = []
  points.push(`M 0 ${height}`)
  points.push(`L 0 ${baseY}`)

  // Adjust sample rate based on screen width
  const adjustedSampleRate = Math.min(sampleRate, Math.floor(width / 50))
  const step = width / adjustedSampleRate

  // Ensure the last point is exactly at width
  for (let x = 0; x <= width + step; x += step) {
    const normalizedX = (x / width) * (Math.PI * 2 * frequency)
    const y = baseY + amplitude * Math.sin(normalizedX + phase)
    points.push(`L ${Math.min(x, width)} ${y}`)
  }

  // Force last point to be at exactly width
  const finalY = baseY + amplitude * Math.sin((Math.PI * 2 * frequency) + phase)
  points.push(`L ${width} ${finalY}`)
  points.push(`L ${width} ${height}`)
  points.push("Z")

  return points.join(" ")
}

const getResponsiveValue = (value, screenWidth) => {
  if (typeof value === 'object') {
    // Added a small buffer zone around the md breakpoint (768px)
    if (screenWidth >= 1080) return value.lg || value.md || value.sm || value.default
    if (screenWidth >= 768) {
      // For screens just at/above the md breakpoint, interpolate values
      if (screenWidth < 781) {
        const progress = (screenWidth - 768) / (781 - 768)
        const smValue = value.sm || value.default
        const mdValue = value.md || value.sm || value.default
        return smValue + (mdValue - smValue) * progress
      }
      return value.md || value.sm || value.default
    }
    if (screenWidth >= 360) return value.sm || value.default
    return value.default
  }
  return value
}

export default function WaveBG({ className, waveProps, waveClass, waveStyle }) {
  const waveRef = useRef(null)
  const [viewBox, setViewBox] = useState({ width: 1200, height: 400 })
  const [wavePath, setWavePath] = useState("")
  
  // Initialize dimensions with window width if available
  const [dimensions, setDimensions] = useState(() => {
    if (typeof window !== 'undefined') {
      return {
        width: window.innerWidth,
        height: parseInt(waveStyle.height)
      }
    }
    return { width: 1200, height: 400 }
  })

  // Update dimensions and generate wave path
  const updateWave = () => {
    if (!waveRef.current) return

    const elWidth = waveRef.current.clientWidth || window.innerWidth
    const elHeight = waveRef.current.clientHeight || parseInt(waveStyle.height)
    
    const width = waveStyle.width.includes("%")
      ? (parseInt(waveStyle.width) / 100) * elWidth
      : parseInt(waveStyle.width)
    const height = waveStyle.height.includes("%")
      ? (parseInt(waveStyle.height) / 100) * elHeight
      : parseInt(waveStyle.height)

    setDimensions({ width, height })
    setViewBox({ width, height })

    const currentProps = {
      ...waveProps,
      frequency: getResponsiveValue(waveProps.frequency, width),
      amplitude: getResponsiveValue(waveProps.amplitude, width),
      phase: getResponsiveValue(waveProps.phase, width),
      sampleRate: getResponsiveValue(waveProps.sampleRate, width),
      width,
      height,
    }

    const path = generateWavePath(currentProps)
    setWavePath(path)
  }

  // Initial setup
  useEffect(() => {
    updateWave()
  }, [])

  // Handle resize
  useEffect(() => {
    const debouncedUpdateWave = debounce(updateWave, 150)
    window.addEventListener('resize', debouncedUpdateWave)
    
    return () => {
      window.removeEventListener('resize', debouncedUpdateWave)
      debouncedUpdateWave.cancel()
    }
  }, [waveProps, waveStyle])

  return (
    <div
      ref={waveRef}
      className={`absolute bottom-0 left-0 right-0 overflow-hidden ${className}`}
      style={{ height: waveStyle.height }}
    >
      <svg
        style={waveStyle}
        viewBox={`0 0 ${viewBox.width} ${viewBox.height}`}
        preserveAspectRatio="none"
      >
        <path d={wavePath} fill="currentColor" />
      </svg>
    </div>
  )
}