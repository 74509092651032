import { useState, useEffect, useRef, useCallback } from "react"
import { showNotification } from "aldoo-ra/Notification"
import TypeResolver from "../type-resolver"
import DatabaseModelTypeResolver from "aldoo-ra/CMS/database-model-type-resolver"

import {
  GetCMSContent,
  SaveCMSContent,
  GetCMSType,
  SaveCMSType,
  DeleteCMSType,
  DeleteCMSContent,
  RestoreCMSContent,
  RestoreCMSType,
  GetCMSTypeUsage,
  UploadCMSFile,
} from "../api"

//import the content types so they get exported to the CMS
import { StorePrice, StoreProduct } from "aldoo-ra/Admin/content-types"

export default function CMSController() {
  //add the db types
  const { addType } = DatabaseModelTypeResolver()
  useEffect(() => {
    addType(StorePrice)
    addType(StoreProduct)
  }, [])

  const refreshCallbacks = useRef([])
  const refreshContents = useCallback(() => {
    // Call all the refresh callbacks
    refreshCallbacks.current.forEach((callback) => callback())
  })

  useEffect(() => {
    //clear the refresh callbacks when the component is unmounted
    return () => {
      refreshCallbacks.current = []
    }
  }, [])

  const { refreshTypes } = TypeResolver()

  const handleApiError = (message = "Failed to process request") => {
    showNotification({
      message,
      className: "bg-red-500 text-white",
    })
  }

  // Generalized API function with an options object
  const callApi = async ({
    apiMethod, // The API method to be called (required)
    params = {}, // Parameters to pass to the API method (optional, default: empty object)
    successMessage, // Message to display on successful operation (optional)
    errorMessage = "An error occurred", // Message to display on error (optional, default: 'An error occurred')
    refresh = true, // Whether to trigger refreshContents callback after success (optional, default: true)
    autoShowNotifications = true,
  }) => {
    try {
      const response = await apiMethod(params)
      if (!response.error) {
        if (successMessage && autoShowNotifications) {
          showNotification({
            message: successMessage,
            className: "bg-primary text-admin_text_inv",
          })
        }
        if (refresh && refreshContents) {
          refreshContents() // Refresh the contents if needed
        }
        return response
      } else {
        if (autoShowNotifications)
          handleApiError(response.error || errorMessage)
        //return the response in case the caller wants to handle the error
        return response
      }
    } catch (error) {
      handleApiError(errorMessage)
    }
  }

  // API methods for different actions using callApi with options
  const getContent = (selector) => {
    return callApi({
      apiMethod: GetCMSContent,
      params: selector,
      errorMessage: "Failed to get content",
    })
  }

  const saveContent = (content, autoShowNotifications = true) => {
    return callApi({
      apiMethod: SaveCMSContent,
      params: content,
      autoShowNotifications,
      successMessage: "Content saved successfully",
      errorMessage: "Failed to save content",
    })
  }

  const getType = (selector) => {
    return callApi({
      apiMethod: GetCMSType,
      params: selector,
      errorMessage: "Failed to get type",
    })
  }

  const saveType = async (type) => {
    const _id = type._id
    //remove the _id field from the type object
    delete type._id

    const payload = {
      _id,
      content: type,
    }

    const result = await callApi({
      apiMethod: SaveCMSType,
      params: { payload },
      successMessage: "Type saved successfully",
      errorMessage: "Failed to save type",
    })
    if (!result.error) refreshTypes()
  }

  const deleteType = async (id, mode = "soft") => {
    const result = await callApi({
      apiMethod: DeleteCMSType,
      params: { itemId: id, mode },
      successMessage: "Type deleted successfully",
      errorMessage: "Failed to delete type",
    })
    if (result.error) return
    refreshTypes()
    refreshContents()
  }

  const restoreType = async (id) => {
    const result = await callApi({
      apiMethod: RestoreCMSType,
      params: { itemId: id },
      successMessage: "Type restored successfully",
      errorMessage: "Failed to restore type",
    })
    if (!result.error) refreshTypes()
  }

  const getTypeUsage = async ({ itemId, type }) => {
    return callApi({
      apiMethod: GetCMSTypeUsage,
      params: { itemId, type },
      errorMessage: "Failed to get type usage",
    })
  }

  const deleteContent = async (id, mode = "soft") => {
    const result = await callApi({
      apiMethod: DeleteCMSContent,
      params: { itemId: id, mode },
      successMessage: "Content deleted successfully",
      errorMessage: "Failed to delete content",
    })
    if (!result.error) refreshContents()
  }

  const restoreContent = async (id) => {
    const result = await callApi({
      apiMethod: RestoreCMSContent,
      params: { itemId: id },
      successMessage: "Content restored successfully",
      errorMessage: "Failed to restore content",
    })
    if (!result.error) refreshContents()
  }

  // Callback for refreshing contents
  const onRefreshContents = (callback) => {
    refreshCallbacks.current = refreshCallbacks.current || []
    //add the callback if it is not already added
    if (refreshCallbacks.current.indexOf(callback) < 0)
      refreshCallbacks.current.push(callback)
  }

  const uploadFile = async ({ file, category, setProgress }) => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("category", category)

    try {
      const response = await UploadCMSFile(formData, setProgress)
      //return the path if the upload was successful
      if (response.result) return response.path
    } catch (error) {
      handleApiError("Failed to upload file: " + error.message)
    }
  }

  const uploadFiles = async ({
    uploadItems,
    category,
    sourceItems,
    setItems,
  }) => {
    const promises = uploadItems.map(async (item) => {
      const path = await uploadFile({
        file: item.file,
        category,
        setProgress: (progress) => {
          //find the item in the localValue array and update the progress

          if (Array.isArray(sourceItems)) {
            const index = sourceItems.indexOf(item)
            if (index < 0) return
            sourceItems[index].progress = progress
            setItems([...sourceItems])
          } else {
            //single item
            sourceItems.progress = progress
            setItems({ ...sourceItems })
          }
        },
      })

      return { ...item, path }
    })

    const uploaded = await Promise.all(promises)

    //Single item passed as sourceItems
    if (!Array.isArray(sourceItems)) {
      const match = uploaded.find(
        (uploadedItem) => uploadedItem.url === sourceItems.url
      )
      //a match is found, update the url
      if (match) {
        sourceItems.url = match.path
        delete sourceItems.progress
        delete sourceItems.file
      }
      setItems({ ...sourceItems })
      return
    }

    //Multiple items passed as sourceItems
    //update the urls in the sourceItems by matching the urls and setting the path as the new url
    const updated = sourceItems.map((item) => {
      const match = uploaded.find((img) => img.url === item.url)
      //a match is found, update the url
      if (match) {
        item.url = match.path
        delete item.progress
        delete item.file
      }
      return item
    })
    setItems([...updated])
  }

  return {
    uploadFiles,
    uploadFile,
    onRefreshContents,
    getContent,
    saveContent,
    getType,
    saveType,
    deleteType,
    deleteContent,
    restoreContent,
    restoreType,
    getTypeUsage,
  }
}
