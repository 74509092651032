import React, { useState, useEffect, useRef } from "react"
import { PencilIcon, FolderIcon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import Modal from "aldoo-ra/Modal"
import ContentEditor from "aldoo-ra/CMS/content-editor"
import Typography from "aldoo-ra/Typography"
import { getApi } from "aldoo-ra/CMS/field-api-resolver"
import { showNotification } from "aldoo-ra/Notification"
import { getFormatter } from "aldoo-ra/CMS/field-formatter"
import { getDisplayFormatter } from "aldoo-ra/CMS/field-display-format-resolver"
import DatabaseModelSelectDialog from "aldoo-ra/CMS/dialogs/database-model-select-dialog"
import DatabaseModelTypeResolver from "aldoo-ra/CMS/database-model-type-resolver"
import _ from "lodash"

function DatabaseModelEditor({
  onChange,
  value,
  field,
  parentValues,
  parentObject,
}) {
  const [showDialog, setShowDialog] = useState(false)
  const [selectContentDialog, setSelectContentDialog] = useState(false)
  const [localValue, setLocalValue] = useState(value || null)
  const [fieldType, setFieldType] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  // Add ref to track if the change is internal
  const isInternalChange = useRef(false)

  const { getType } = DatabaseModelTypeResolver()

  // Set field type on mount and when field changes
  useEffect(() => {
    try {
      if (typeof field.item === "string") {
        const resolvedType = getType(field.item)
        if (!resolvedType) {
          throw new Error(`Could not resolve type for ${field.item}`)
        }
        setFieldType(resolvedType)
      } else {
        setFieldType(field.item)
      }
    } catch (err) {
      setError(err.message)
      showNotification({
        message: `Error resolving field type: ${err.message}`,
        className: "bg-red-500 text-white",
      })
    }
  }, [field, getType])

  // Handle external value changes
  useEffect(() => {
    if (!isInternalChange.current && !_.isEqual(value, localValue)) {
      setLocalValue(value || null)
    }
    isInternalChange.current = false
  }, [value])

  // Unified function to update value and notify parent
  const updateValue = (newValue) => {
    isInternalChange.current = true
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  const handleCloseDialog = () => {
    setShowDialog(false)
    setError(null)
  }

  const handleSaveModel = async (newValues) => {
    setIsLoading(true)
    setError(null)

    try {
      const api = getApi(fieldType.type)
      if (!api) {
        throw new Error(`API not found for ${fieldType.type}`)
      }

      const updatedValues = {
        ...newValues,
        _id: localValue?._id,
      }

      const saveResponse = await api.Save(updatedValues)
      if (saveResponse.error) {
        throw new Error(saveResponse.error)
      }

      if (saveResponse.result) {
        try {
          const [updated] = await api.Get({
            match: { _id: saveResponse.result },
          })
          if (!updated) {
            throw new Error("Failed to fetch updated model")
          }
          updateValue(updated)
        } catch (fetchError) {
          throw new Error(
            `Failed to fetch updated model: ${fetchError.message}`
          )
        }
      }

      showNotification({
        message: `${fieldType.type} ${
          !updatedValues._id ? "created" : "updated"
        } successfully`,
        className: "bg-primary text-white",
      })
      handleCloseDialog()
    } catch (err) {
      setError(err.message)
      showNotification({
        message: err.message,
        className: "bg-red-500 text-white",
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleModelSelect = (selectedModel) => {
    updateValue(selectedModel)
    setSelectContentDialog(false)
  }

  const overview = getDisplayFormatter(field?.overview || fieldType?.type)

  return (
    <div className="database-model-editor w-full">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center justify-between p-4 border rounded-lg bg-gray-50">
          <Typography className="flex-1">
            {localValue ? overview && overview(localValue) : "Select a model"}
          </Typography>

          <Button
            variant="outlined"
            size="sm"
            className="ml-4"
            onClick={() => setShowDialog(true)}
            disabled={isLoading}
          >
            <PencilIcon className="w-6 h-6 mr-2" />
          </Button>

          <Button
            variant="outlined"
            size="sm"
            className="ml-4"
            onClick={() => setSelectContentDialog(true)}
            disabled={isLoading}
          >
            <FolderIcon className="w-6 h-6 mr-2" />
          </Button>
        </div>

        <div className="flex flex-col">
          <label className="block font-semibold text-admin_text">
            {field.label}
            {isRequired && <span className="ml-2 text-error">(required)</span>}
          </label>
          {field.description && (
            <label className="block text-sm text-admin_text">
              {field.description}
            </label>
          )}
          {error && <div className="text-sm text-error mt-1">{error}</div>}
        </div>
      </div>

      {showDialog && fieldType && (
        <Modal
          isOpen={showDialog}
          onClose={handleCloseDialog}
          closeOnEscape
          className="md:w-[80%] sm:w-[500px] pb-10 md:absolute md:top-[65px] h-full md:h-auto bg-admin_canvas text-sm sm:text-base z-200"
        >
          <ContentEditor
            title={`Edit ${field.label}`}
            contentType={fieldType}
            parentValues={parentValues}
            fieldValues={localValue}
            formatters={[getFormatter(fieldType?.type)]}
            onSave={handleSaveModel}
            onClickSave={handleCloseDialog}
            onError={(errors) => {
              const errorMessage = Array.isArray(errors)
                ? errors.join(", ")
                : "Validation failed. Please check the form."
              setError(errorMessage)
              showNotification({
                message: errorMessage,
                className: "bg-red-500 text-white",
              })
            }}
            isLoading={isLoading}
          />
        </Modal>
      )}

      {selectContentDialog && fieldType && (
        <DatabaseModelSelectDialog
          type={fieldType?.type}
          isOpen={selectContentDialog}
          onClose={() => setSelectContentDialog(false)}
          searchFields={field.searchFields}
          overview={getDisplayFormatter(field.overview)}
          onSelect={handleModelSelect}
          disabled={isLoading}
        />
      )}
    </div>
  )
}

export default DatabaseModelEditor
