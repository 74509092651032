//define the api interface used by the CMS
//export the interface the the global API object so other systems can use it
//-------------------------------------
// Content Type System
//-------------------------------------
import { api, generateCRUDInterface } from "aldoo-ra/API"
//construct the endpoint
const crudAPI = api("crud")

//Given the crud API, generate the CRUD interface
const crud = generateCRUDInterface(crudAPI, "StorePrice")

//This is an object with the following methods:
// Get - get a list of items
// Create - create a new item
// Update - update an existing item
// SoftDelete - soft delete an item
// HardDelete - hard delete an item
// Save - create or update an item, if the item has an _id it will update, otherwise it will create
export default crud
