export default {
  aqua: {
    container:
      "w-full flex flex-col items-center md:bg-primary_lt px-4 pt-[56px] pb-[90px] md:pb-[120px] lg:pb-[227px]  md:px-[100px] lg:px-[200px]",
    title:
      "w-full text-[32px] sm:text-[40px] xl:text-[72px] leading-tight tracking-custom_1 font-bold text-text text-center mb-10 lg:mb-20",
    item: "w-full max-w-w1_xl bg-theme_white rounded-[12px] shadow-md mb-4 overflow-hidden",
    question:
      "w-full max-w-w1_xl flex justify-between items-center p-8 text-[24px]",
    question_text:
      "text-[24px] md:text-[18px] lg:text-[20px] leading-normal tracking-custom_1 font-normal text-left text-text hover:text-primary",
    question_text_selected:
      "text-[24px] md:text-[18px] lg:text-[20px] leading-normal tracking-custom_1 text-left text-primary font-bold",
    toggle: "text-primary text-[26px] font-light",
    motion_container: "w-full overflow-hidden bg-theme_white",
    answer:
      "w-full text-gray_1 text-[18px] sm:text-[22px] md:text-[18px] lg:text-[20px] leading-normal tracking-custom_1 font-normal px-8 pb-4",
  },
}
