import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"

const MessageDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false)
  const [state, setState] = useState({
    title: "",
    message: "",
    onConfirm: () => {},
    onClose: () => {},
    btnLabel: "",
  })

  const show = ({ title, message, onConfirm, onClose, btnLabel }) => {
    setIsOpen(true)
    setState({
      title,
      message,
      onConfirm,
      onClose,
      btnLabel,
    })
  }

  const { title, message, onConfirm, onClose, btnLabel } = state

  useImperativeHandle(ref, () => ({
    show,
  }))

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose()
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center"
      style={{ zIndex: 1000 }}
    >
      <div className="bg-admin_paper rounded-lg shadow-lg w-[340px] sm:w-[400px] p-6">
        <Typography variant="h3" className="mb-4">
          {title}
        </Typography>
        <hr className="border-t border-bordered mb-4" />
        <Typography className="mb-6 text-base text-admin_text_secondary">
          {message}
        </Typography>
        <div className="flex justify-end space-x-4 mt-5">
          <Button
            variant="action"
            className="w-1/4 py-2 px-4 rounded-full"
            onClick={() => {
              setIsOpen(false)
              if (onConfirm) onConfirm()
            }}
          >
            {btnLabel || "Ok"}
          </Button>
        </div>
      </div>
    </div>
  )
})

let messageInstance = null

export const showMessageDialog = (options) => {
  if (messageInstance === null) return
  messageInstance.show(options)
}

export const showMessageDialogAsync = (options) => {
  return new Promise((resolve) => {
    showMessageDialog({
      ...options,
      onConfirm: () => resolve(true),
    })
  })
}

const MessageDialogProvider = () => {
  const messageRef = useRef()

  useEffect(() => {
    messageInstance = messageRef.current
    return () => {
      messageInstance = null
    }
  }, [])

  return <MessageDialog ref={messageRef} />
}

export default MessageDialogProvider
