import { useState } from "react"
import PlatformAuth from "aldoo-ra/PlatformAuth"
import { Login, VerifyCredentials } from "../api"
import awaitAuth from "./await-auth"



//"name", "avatar", "email"
const fetch_user_fields = ["_id"]

const useLoginWithCredentials = () => {
    const [state, setState] = useState()
    const [error, setError] = useState(false)

    const [, setAuth] = PlatformAuth.useAuth()

    const startLogin = async (credentials) => {
        //we are starting the login
        setState("pending")
        //reset the error
        setError(undefined)
        // Try to login with the credentials
        const response = await Login(credentials, { fetch_user_fields })

        // The login was successful
        if (response.result) {
            //append the _id to the credentials
            setAuth({
                ...credentials,
                _id: response.user._id,
                auth_token: response.auth_token,
            })
            //mark the login as successful
            return setState("success")
        }

        //The device is not yet logged in
        if (
            response.error === "device_not_logged"
        ) {
            //we are waiting for the user to log in
            setState("waiting-auth")
            //check the device login state with the api
            const awaitResult = await awaitAuth(credentials)
            //the await completed, but the device is not logged in
            if (!awaitResult.login) return setState(undefined)

            //if there is no userID, get it from the server
            if (!credentials._id) {
                const { _id } = await VerifyCredentials(credentials)
                credentials._id = _id
            }
            //set the local authorization object
            setAuth({
                ...credentials,
                auth_token: awaitResult.auth_token,
            })
            //mark the login as successful
            return setState("success")
        }

        //handle other errors
        if (response.error) {
            //The interface will get the error as code/id and will localize it
            setError(response.error)
            //reset the state
            return setState(undefined)
        }
    }

    return { state, error, startLogin }
}

export default useLoginWithCredentials