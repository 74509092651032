import { useState } from "react"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import AldooStoreAPI from "aldoo-ra/Store/api"

const usePaymentSubmission = (store) => {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const { onPaymentSuccess, paymentInfo, onPaymentError } = store

  const startTrialSubscription = async (setupIntentId) => {
    try {
      const response = await AldooStoreAPI.startTrialSubscription({
        pid: paymentInfo.pid,
        setupIntentId,
        promo_code: paymentInfo.promoCode,
      })
      if (response.error) throw new Error(response.error)
    } catch (error) {
      throw error
    }
  }

  const handleSubmitWithSavedMethod = async () => {
    if (!stripe) return
    setLoading(true)
    try {
      // First determine if we're dealing with a SetupIntent or PaymentIntent
      const isSetupIntent = paymentInfo.clientSecret.startsWith("seti_")
      const isPaymentIntent = paymentInfo.clientSecret.startsWith("pi_")
      let result

      const paymentMethodOptions = {
        payment_method: paymentInfo.savedPaymentMethod.id,
      }

      if (isSetupIntent) {
        // Use confirmCardSetup for setup intents
        result = await stripe.confirmCardSetup(
          paymentInfo.clientSecret,
          paymentMethodOptions
        )
      } else if (isPaymentIntent) {
        // Use confirmCardPayment for payment intents
        result = await stripe.confirmCardPayment(
          paymentInfo.clientSecret,
          paymentMethodOptions
        )
      } else if (paymentInfo.type === "free_subscription") {
        // Free subscriptions don't need confirmation
        result = { error: null }
      } else {
        throw new Error("Unknown intent type")
      }

      if (result.error) {
        throw result.error
      }
      onPaymentSuccess()
    } catch (error) {
      onPaymentError(error)
      throw error
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    setLoading(true)
    if (!stripe) return
    if (!elements) return
    const return_url = `${window.location.origin}/payment-result`

    try {
      // Regular payment flow with elements
      if (paymentInfo.type === "trial_setup") {
        // Handle trial setup
        const setupResult = await stripe.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url,
          },
        })

        if (setupResult.error) return onPaymentError(setupResult.error)

        // Check if we already have a succeeded setup intent
        const retrieveResult = await stripe.retrieveSetupIntent(
          paymentInfo.clientSecret
        )
        const { setupIntent } = retrieveResult

        if (setupIntent.status === "succeeded") {
          // If setup is already successful, just start the trial
          await startTrialSubscription(setupIntent.id)
        } else {
          // Otherwise, confirm the setup first
          const { setupIntent: confirmedSetup, error: setupError } =
            await stripe.confirmSetup({
              elements,
              redirect: "if_required",
              confirmParams: {
                return_url,
              },
            })

          if (setupError) throw setupError
          // Setup successful, now start the trial subscription
          await startTrialSubscription(confirmedSetup.id)
        }
      } else {
        // Handle regular payment
        const result = await stripe.confirmPayment({
          elements,
          redirect: "if_required",
          confirmParams: {
            return_url,
          },
        })

        if (result.error) return onPaymentError(result.error)
      }

      // Payment is successful
      onPaymentSuccess()
    } catch (err) {
      onPaymentError(err)
    } finally {
      setLoading(false)
    }
  }

  return {
    handleSubmit,
    handleSubmitWithSavedMethod,
    loading,
    setLoading,
    ready,
    setReady,
    stripe,
    elements,
    isReady: stripe && ready && !loading,
  }
}

export default usePaymentSubmission
