import React, { useState, useEffect, useRef } from "react"
import { api } from "aldoo-ra/API"
import LoadingOverlay from "aldoo-ra/LoadingOverlay"
import Button from "aldoo-ra/Button"
import Input from "aldoo-ra/Input"
import Localization from "aldoo-ra/Localization"
import DropDownSelect from "aldoo-ra/DropdownSelect"
import Typography from "aldoo-ra/Typography"
import { showMessageDialog } from "aldoo-ra/MessageDialog"
import emailValidator from "email-validator"

const mailAPI = api("aldoo-mailer")

const MailTemplateTester = ({ editor, parentObject }) => {
  const { languages: availableLanguages } = Localization()
  const [sending, setSending] = useState(false)
  const [email, setEmail] = useState("")
  const [languages, setLanguages] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState("")
  const { id } = parentObject

  useEffect(() => {
    if (!availableLanguages) return
    const formattedLanguages = availableLanguages.map((lang) => ({
      value: lang.code,
      label: lang.name,
    }))
    setLanguages(formattedLanguages)
  }, [availableLanguages])

  const sendTestEmail = async (template, email, language) => {
    if (!emailValidator.validate(email)) {
      showMessageDialog({
        title: "Invalid Email",
        message: "Please enter a valid email address",
        variant: "error",
      })
      return
    }

    if (!selectedLanguage) {
      showMessageDialog({
        title: "Missing Language",
        message: "Please select a language",
        variant: "error",
      })
      return
    }

    setSending(true)

    const response = await mailAPI({
      test_mail_template: {
        template,
        email,
        language: selectedLanguage[0].value,
      },
    })

    if (response.result) {
      showMessageDialog({
        title: "Message Sent",
        message: `Check ${email} to review the template`,
        variant: "success",
      })
    }
    setSending(false)
  }

  return (
    <div className="flex flex-col gap-6 p-4">
      {/* Controls Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Email Input */}
        <div className="flex flex-col gap-2">
          <Typography variant="txt1-s" className="text-admin_text">
            Email Address
          </Typography>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email address"
            className="w-full"
          />
        </div>

        {/* Language Selector */}
        <div className="flex flex-col gap-2">
          <Typography variant="txt1-s" className="text-admin_text">
            Language
          </Typography>
          <DropDownSelect
            options={languages}
            value={selectedLanguage}
            onChange={(value) => setSelectedLanguage(value)}
            className="w-full"
          />
        </div>

        {/* Send Button - Aligned with inputs */}
        <div className="flex flex-col justify-end">
          <Button
            variant="action"
            onClick={() => sendTestEmail(id, email, selectedLanguage)}
            className="w-full md:w-auto"
          >
            Send Test Email
          </Button>
        </div>
      </div>

      {/* Description */}
      <Typography variant="txt1-s" className="text-admin_text mt-2">
        {editor.description}
      </Typography>

      {/* Loading Overlay */}
      <LoadingOverlay
        title="Sending Test Email"
        message={`Please wait while we send the test email to ${email}`}
        loading={sending}
      />
    </div>
  )
}

export default MailTemplateTester
