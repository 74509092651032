/**
 * This file is called in the main react react entry point
 */
import React from "react"
import PlatformAuth from "aldoo-ra/PlatformAuth"
import { AldooAPI } from "aldoo-ra/API"
import useThemeController from "aldoo-ra/ThemeController"
import PlatformDetector from "aldoo-ra/PlatformDetector"
import AdminSiteUpdateScan from "aldoo-ra/Admin/Updates/Deployment/admin-site-update-scan"

const AldooBoot = () => {
  PlatformDetector()
  //Initialize the platform auth system
  PlatformAuth.useAuth()
  // useSentry()
  //theme controller
  useThemeController()
  AldooAPI()
  //use profile support
  //this will listen for credential changes and update the user profile automatically
  PlatformAuth.useProfile()
  //Check for updates only in development
  AdminSiteUpdateScan()
}

export default AldooBoot
