import { CreateDevice } from "../api"
import { sleep } from "aldoo-ra/Time"
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// Make sure that a device exists
const ensureDevice = async (auth) => {

    let deviceID = null

    //check if we have a deviceID in the auth and use it
    if (auth?.deviceID && auth.deviceID !== "") deviceID = auth.deviceID

    //valid deviceID found in auth, write it to the local storage
    if (deviceID) {
        localStorage.setItem("deviceID", deviceID)
        //return the deviceID once we have saved it
        return deviceID
    }

    //check if we have a deviceID in the local storage
    deviceID = localStorage.getItem("deviceID")
    //if so, return it
    if (deviceID) return deviceID

    //compute the device fingerprint
    const fp = await FingerprintJS.load();
    const { visitorId: fid } = await fp.get();

    //infinite retry, obtain new device
    while (true) {
        const response = await CreateDevice({ fid })
        //we have a response
        if (response) {
            //get the device id
            const { deviceID } = response
            //save it the local storage
            localStorage.setItem("deviceID", deviceID)
            //return the response
            return deviceID
        }
        //wait for a second before retrying
        await sleep(1000)
    }

}

export default ensureDevice