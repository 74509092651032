// link-dialog.jsx
import React, { useState, useEffect, useRef } from "react"
import Button from "aldoo-ra/Button"
import Input from "aldoo-ra/Input"
import Checkbox from "aldoo-ra/Checkbox"

const LinkDialog = ({
  defaultUrl = "",
  defaultIsDownload = false,
  defaultOpenInSameTab = false,
  onClose,
}) => {
  const [url, setUrl] = useState(defaultUrl)
  const [isDownload, setIsDownload] = useState(defaultIsDownload)
  const [openInSameTab, setOpenInSameTab] = useState(defaultOpenInSameTab)
  const dialogRef = useRef(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!url.trim()) return
    onClose({ url: url.trim(), isDownload, openInSameTab })
  }

  // Handle clicking outside dialog
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose(null)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [onClose])

  // Handle escape key
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose(null)
      }
    }

    document.addEventListener("keydown", handleEscape)
    return () => document.removeEventListener("keydown", handleEscape)
  }, [onClose])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      style={{ zIndex: 1000 }}
    >
      <div
        ref={dialogRef}
        className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4"
      >
        {/* Dialog Header */}
        <div className="px-6 py-4 border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-900">
            {defaultUrl ? "Edit Link" : "Add Link"}
          </h3>
        </div>

        {/* Dialog Content */}
        <form onSubmit={handleSubmit}>
          <div className="px-6 py-4 space-y-4">
            <Input
              label="URL"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://"
              autoFocus
              required
            />

            <div className="flex flex-row items-center justify-between gap-2">
              <Checkbox
                isOn={isDownload}
                onChange={setIsDownload}
                label="This is a Download"
                labelPosition="right"
                variant="default"
              />

              <Checkbox
                isOn={openInSameTab}
                onChange={setOpenInSameTab}
                label="Open in Same Tab"
                labelPosition="right"
                variant="default"
              />
            </div>
          </div>

          {/* Dialog Footer */}
          <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-3">
            <Button variant="outlined" onClick={() => onClose(null)}>
              Cancel
            </Button>
            <Button variant="action" onClick={handleSubmit}>
              {defaultUrl ? "Update" : "Add"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LinkDialog
