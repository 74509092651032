import { VariantReader } from "aldoo-ra/VariantReader"

export default function Spinner({
  variant = "primary",
  size,
  thickness,
  speed = "normal",
  color,
  styleOverrides,
  className = "",
}) {
  // Get base variant styles
  const variantStyles = VariantReader.useVariant({
    componentName: "Spinner",
    variant,
    styleOverrides,
  })

  // Allow composition of variants
  const sizeStyles =
    size &&
    VariantReader.useVariant({
      componentName: "Spinner",
      variant: size,
    })

  const thicknessStyles =
    thickness &&
    VariantReader.useVariant({
      componentName: "Spinner",
      variant: thickness,
    })

  const speedStyles =
    speed &&
    VariantReader.useVariant({
      componentName: "Spinner",
      variant: speed,
    })

  const colorStyles =
    color &&
    VariantReader.useVariant({
      componentName: "Spinner",
      variant: color,
    })

  // Combine all styles
  const combinedStyles = [
    variantStyles,
    sizeStyles,
    thicknessStyles,
    speedStyles,
    colorStyles,
    className,
  ]
    .filter(Boolean)
    .join(" ")

  return (
    <div className={combinedStyles}>
      <svg
        aria-hidden="true"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-full h-full"
      >
        <path
          className="track"
          d="M 50,50 m 0,-45 a 45,45 0 1 1 -0.1,0"
          stroke="currentColor"
          strokeWidth="10"
          fill="none"
        />
        <path
          d="M 50,50 m 0,-45 a 45,45 0 0 1 45,45"
          stroke="currentFill"
          strokeWidth="10"
          strokeLinecap="round"
          fill="none"
        />
      </svg>
    </div>
  )
}
