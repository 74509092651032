export default {
  //Used in search bar, user details, etc. -------------------------------
  aqua_input: {
    root: "text-text",
    input_wrapper: "relative flex items-center",
    icon_wrapper:
      "absolute left-5 flex items-center pointer-events-none text-primary",
    input:
      "h-[40px] md:h-[44px] lg:h-[48px] rounded-full w-full px-4 py-2 text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] text-gray_1 bg-theme_white focus:bg-theme_white border border-gray_2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-none",
    input_with_icon: "pl-14",
    textarea:
      "w-full px-3 py-2 focus:transparent border border-none rounded-md focus:outline-none focus:ring-1 focus:border-none",
  },
}
