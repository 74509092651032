import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

export default function PaymentErrorFragment({
  store,
  handleBackToPayment,
  error,
}) {
  const texts = TextBundle({ id: "aldoo-store" })

  const getErrorMessage = (error) => {
    // Map common Stripe error codes to user-friendly messages
    const errorMessages = {
      card_declined: texts?.card_declined || "Your card has been declined.",
      expired_card: texts?.expired_card || "Your card has expired.",
      incorrect_cvc: texts?.incorrect_cvc || "The security code is incorrect.",
      processing_error:
        texts?.processing_error ||
        "An error occurred while processing your card.",
      insufficient_funds:
        texts?.insufficient_funds || "Insufficient funds in your account.",
      invalid_number: texts?.invalid_number || "The card number is invalid.",
      requires_payment_method:
        texts?.requires_payment_method || "Payment method required.",
      default:
        texts?.payment_error_default ||
        "There was an error processing your payment.",
    }

    if (typeof error === "string") return errorMessages[error] || error

    // Handle Stripe error object
    if (error?.code) return errorMessages[error.code] || error.message

    return errorMessages.default
  }

  return (
    <div className="p-6 flex flex-col items-center text-center">
      <ExclamationCircleIcon className="w-16 h-16 text-red-500 mb-4" />

      <Typography variant="body1-accent-xl" className="mb-4">
        {texts?.payment_error_title || "There was a problem with your payment"}
      </Typography>

      <Typography variant="body1" className="mb-6 text-gray-600">
        {getErrorMessage(error)}
      </Typography>

      <Button
        variant="action"
        onClick={handleBackToPayment}
        className="min-w-[200px]"
      >
        <Typography variant="">
          {texts?.back_to_payment || "Back to Payment"}
        </Typography>
      </Button>
    </div>
  )
}
