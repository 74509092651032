// DatePicker with Range Support
import React, { useEffect, useState, useRef } from "react"
// Main DatePicker Component
import moment from "moment"
import {
  XMarkIcon,
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/24/outline"

const defaultStyles = {
  // Layout & Containers
  container: {
    wrapper: "relative",
    modal: "fixed inset-0 bg-black/50 flex items-center justify-center z-50",
    mainContainer:
      "bg-admin_paper rounded-[28px] max-w-[360px] w-full shadow-xl relative",
    header: "px-6 pt-6",
    body: "px-6 pb-6",
    footer: "flex items-center justify-between p-4 border-t border-text/10",
  },

  // Input Display
  input: {
    wrapper:
      "cursor-pointer p-4 bg-admin_paper rounded-lg border border-gray-200",
    label: "text-sm text-admin_text/60 mb-1",
    value: "text-lg text-admin_text",
  },

  // Header Elements
  header: {
    title: "text-admin_text text-base font-normal",
    closeButton: "w-6 h-6 text-admin_text/60 cursor-pointer",
    selectedDate: "text-[32px] text-admin_text font-normal mb-4",
    monthSelector:
      "text-admin_text hover:bg-text/10 px-4 py-2 rounded-lg mb-4 flex items-center",
  },

  // Calendar Grid
  calendar: {
    weekdayHeader: "text-center text-sm py-2 text-admin_text/60",
    daysGrid: "grid grid-cols-7",
    dayButton: {
      base: "relative aspect-square flex items-center justify-center text-sm text-admin_text rounded-full hover:bg-primary",
      selected: "text-white bg-primary",
      today: "border border-primary text-primary",
      otherMonth: "text-admin_text/40",
      inRange: "bg-primary/20",
      dayWrapper: "w-10 h-10 flex items-center justify-center rounded-full",
    },
  },

  // Month/Year Selector
  monthSelector: {
    container: "absolute inset-0 bg-admin_paper z-10 p-6",
    header: {
      wrapper: "flex items-center justify-between mb-6",
      format: "text-admin_text/60",
      closeButton: "w-6 h-6 text-admin_text/60 cursor-pointer",
    },
    selectors: {
      wrapper: "flex gap-4 mb-6",
      button: {
        base: "w-full text-left px-4 py-2 rounded-lg",
        active: "bg-text/10",
        disabled: "text-admin_text/40",
      },
    },
    monthList: {
      wrapper: "space-y-2",
      item: {
        base: "w-full px-4 py-3 rounded-lg text-left flex items-center",
        selected: "bg-primary/10",
        hover: "hover:bg-text/5",
        checkmark: "w-5 h-5 text-primary mr-2",
      },
    },
  },

  // Footer Buttons
  buttons: {
    base: "px-6 py-2 rounded-full",
    primary: "text-primary hover:bg-primary/10",
    secondary: "text-primary hover:bg-text/10",
  },
}

// MonthSelector Component
const MonthSelector = ({ viewDate, onSelect, onClose, styles }) => {
  const [showMonths, setShowMonths] = useState(true)
  const currentYear = moment().year()
  const yearsRange = Array.from({ length: 15 }, (_, i) => currentYear - 7 + i)

  return (
    <div
      className={`relative flex flex-col h-full ${styles.container.mainContainer}`}
    >
      {/* Header */}
      <div className="flex items-center justify-between p-4 border-b border-text/10">
        <span className="text-admin_text/60">MM/DD/YYYY</span>
        <button onClick={onClose}>
          <XMarkIcon className="w-6 h-6 text-admin_text/60" />
        </button>
      </div>

      {/* Month/Year Selection */}
      <div className="flex-1 flex flex-col p-4">
        <div className="flex items-center gap-4 mb-6">
          {/* Month Dropdown */}
          <div className="relative flex-1">
            <button
              onClick={() => setShowMonths(true)}
              className={`
                w-full px-4 py-2 rounded-lg text-admin_text flex items-center justify-between
                ${showMonths ? "bg-text/10" : "hover:bg-text/5"}
              `}
              style={{ height: "48px" }} // Adjust height to match the date picker
            >
              <span>{viewDate.format("MMM")}</span>
              <ChevronDownIcon className="w-5 h-5" />
            </button>
          </div>

          {/* Year Selection */}
          <div className="flex-1">
            <button
              onClick={() => setShowMonths(false)}
              className={`
                w-full px-4 py-2 rounded-lg text-admin_text flex items-center justify-between
                ${!showMonths ? "bg-text/10" : "hover:bg-text/5"}
              `}
              style={{ height: "48px" }} // Adjust height to match the date picker
            >
              <span>{viewDate.format("YYYY")}</span>
              <ChevronDownIcon className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Scrollable Container */}
        <div className="overflow-y-auto max-h-[calc(480px)] scrollbar-thin scrollbar-thumb-text/10 scrollbar-track-transparent">
          {showMonths ? (
            /* Months List */
            <div className="space-y-1">
              {moment.months().map((month, index) => {
                const isSelected = index === viewDate.month()
                return (
                  <button
                    key={month}
                    onClick={() => onSelect({ type: "month", value: index })}
                    className={`
                      w-full px-4 py-3 rounded-lg text-left
                      ${
                        isSelected
                          ? "text-admin_text bg-text/10"
                          : "text-admin_text hover:bg-text/5"
                      }
                    `}
                  >
                    {month}
                  </button>
                )
              })}
            </div>
          ) : (
            /* Years List */
            <div className="space-y-1">
              {yearsRange.map((year) => {
                const isSelected = year === viewDate.year()
                return (
                  <button
                    key={year}
                    onClick={() => onSelect({ type: "year", value: year })}
                    className={`
                      w-full px-4 py-3 rounded-lg text-left
                      ${
                        isSelected
                          ? "text-admin_text bg-text/10"
                          : "text-admin_text hover:bg-text/5"
                      }
                    `}
                  >
                    {year}
                  </button>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const DatePicker = ({
  value,
  onChange,
  range = false,
  styles = defaultStyles,
  dateFormat = "DD.MM.YY",
  monthFormat = "MMMM YYYY",
  showToday = true,
}) => {
  // Use ref to track previous value for comparison
  const prevValueRef = useRef(null)

  // Initialize dates with state initializer function
  const [dates, setDates] = useState(() => {
    const initialStartDate = value?.startDate
      ? moment(value.startDate)
      : moment(value || new Date())
    const initialEndDate = value?.endDate
      ? moment(value.endDate)
      : range
      ? null
      : initialStartDate

    return {
      start: initialStartDate,
      end: initialEndDate,
    }
  })

  const [isOpen, setIsOpen] = useState(false)
  const [showMonthSelector, setShowMonthSelector] = useState(false)
  const [hoverDate, setHoverDate] = useState(null)
  const [selectionStep, setSelectionStep] = useState("start")
  const [viewDate, setViewDate] = useState(() =>
    moment(dates.start || new Date())
  )

  // Compare values for equality
  const areValuesEqual = (val1, val2) => {
    if (val1 === val2) return true
    if (!val1 || !val2) return false

    if (val1.startDate && val2.startDate) {
      const startEqual = moment(val1.startDate).isSame(
        moment(val2.startDate),
        "day"
      )
      const endEqual =
        val1.endDate && val2.endDate
          ? moment(val1.endDate).isSame(moment(val2.endDate), "day")
          : val1.endDate === val2.endDate
      return startEqual && endEqual
    }

    return moment(val1).isSame(moment(val2), "day")
  }

  // Handle external value changes
  useEffect(() => {
    // Skip if value hasn't changed
    if (areValuesEqual(value, prevValueRef.current)) {
      return
    }

    const newStartDate = value?.startDate
      ? moment(value.startDate)
      : value
      ? moment(value)
      : moment(new Date())

    const newEndDate = value?.endDate
      ? moment(value.endDate)
      : range
      ? null
      : newStartDate

    const startChanged = !newStartDate.isSame(dates.start, "day")
    const endChanged =
      newEndDate !== dates.end &&
      (!dates.end || !newEndDate || !newEndDate.isSame(dates.end, "day"))

    if (startChanged || endChanged) {
      setDates({
        start: newStartDate,
        end: newEndDate,
      })
      setViewDate(newStartDate)
    }

    prevValueRef.current = value
  }, [value, range])

  const handleDateSelect = (date) => {
    if (!range) {
      setDates({ start: date, end: date })
      onChange?.(date.toDate())
      return
    }

    if (selectionStep === "start" || !dates.start) {
      setDates((prev) => ({ ...prev, start: date, end: null }))
      setSelectionStep("end")
    } else {
      const newDates = date.isBefore(dates.start)
        ? { start: date, end: dates.start }
        : { start: dates.start, end: date }

      setDates(newDates)
      setSelectionStep("start")
      onChange?.({
        startDate: newDates.start.toDate(),
        endDate: newDates.end.toDate(),
      })
    }
  }

  const handleTodayClick = () => {
    const today = moment()
    if (!range) {
      setDates({ start: today, end: today })
      onChange?.(today.toDate())
    } else {
      setDates({ start: today, end: null })
    }
    setViewDate(today)
  }

  const isInRange = (date) => {
    if (!range || !dates.start) return false
    const end = dates.end || hoverDate
    if (!end) return false

    if (hoverDate) {
      return hoverDate.isBefore(dates.start)
        ? date.isBetween(hoverDate, dates.start, "day", "[]")
        : date.isBetween(dates.start, hoverDate, "day", "[]")
    }

    const isEndBeforeStart = dates.end?.isBefore(dates.start, "day")
    const [effectiveStart, effectiveEnd] = isEndBeforeStart
      ? [dates.end, dates.start]
      : [dates.start, dates.end]

    return date.isBetween(effectiveStart, effectiveEnd, "day", "[]")
  }

  const isRangeStart = (date) => dates.start && date.isSame(dates.start, "day")
  const isRangeEnd = (date) => dates.end && date.isSame(dates.end, "day")

  const handleDateSelection = ({ type, value: selectedValue }) => {
    const newDate = viewDate.clone()
    if (type === "month") {
      newDate.month(selectedValue)
    } else if (type === "year") {
      newDate.year(selectedValue)
    }
    setViewDate(newDate)
    setShowMonthSelector(false)
  }

  const generateDays = () => {
    const start = viewDate.clone().startOf("month").startOf("week")
    const end = viewDate.clone().endOf("month").endOf("week")
    const days = []
    let day = start.clone()

    while (day.isSameOrBefore(end)) {
      days.push(day.clone())
      day.add(1, "day")
    }
    return days
  }

  const getRangeStyle = (date) => {
    if (!range || !dates.start) return ""
    const end = dates.end || hoverDate
    if (!end) return ""

    const isStart = isRangeStart(date)
    const isEnd =
      isRangeEnd(date) || (hoverDate && date.isSame(hoverDate, "day"))
    const inRange = isInRange(date)
    const isSameStartEnd =
      dates.start && dates.end && dates.start.isSame(dates.end, "day")
    const isEndBeforeStart = hoverDate
      ? hoverDate.isBefore(dates.start, "day")
      : dates.end && dates.end.isBefore(dates.start, "day")

    let classes = ["rounded-none", "bg-primary", "text-white"]

    if (isSameStartEnd && isStart)
      return classes.concat("rounded-full").join(" ")
    if (isStart && !isEndBeforeStart)
      return classes.concat("rounded-l-full").join(" ")
    if (isEnd && !isEndBeforeStart)
      return classes.concat("rounded-r-full").join(" ")
    if (isEndBeforeStart && isStart)
      return classes.concat("rounded-r-full").join(" ")
    if (isEndBeforeStart && isEnd)
      return classes.concat("rounded-l-full").join(" ")
    if (inRange) return classes.join(" ")

    return ""
  }

  const formatDisplayDate = () => {
    if (!range) return dates.start?.format(dateFormat) || "Select date"
    if (!dates.start) return "Select dates"
    if (!dates.end) return `${dates.start.format(dateFormat)} - Select end date`
    return `${dates.start.format(dateFormat)} - ${dates.end.format(dateFormat)}`
  }

  return (
    <div className={styles.container.wrapper}>
      <div onClick={() => setIsOpen(true)} className={styles.input.wrapper}>
        <div className={styles.input.label}>Select date{range ? "s" : ""}</div>
        <div className={styles.input.value}>{formatDisplayDate()}</div>
      </div>

      {isOpen && (
        <div className={styles.container.modal}>
          <div className={styles.container.mainContainer}>
            {showMonthSelector ? (
              <MonthSelector
                viewDate={viewDate}
                onSelect={handleDateSelection}
                onClose={() => setShowMonthSelector(false)}
                styles={styles}
              />
            ) : (
              <>
                <div className={styles.container.header}>
                  <div className="flex items-center justify-between mb-4">
                    <h2 className={styles.header.title}>
                      Select date{range ? "s" : ""}
                    </h2>
                    <XMarkIcon
                      className={styles.header.closeButton}
                      onClick={() => setIsOpen(false)}
                    />
                  </div>

                  <div className={styles.header.selectedDate}>
                    {formatDisplayDate()}
                  </div>

                  <button
                    onClick={() => setShowMonthSelector(true)}
                    className={styles.header.monthSelector}
                  >
                    {viewDate.format(monthFormat)}
                    <ChevronDownIcon className="w-5 h-5 ml-2" />
                  </button>
                </div>

                <div className={styles.container.body}>
                  <div className="grid grid-cols-7 mb-2">
                    {moment.weekdaysMin().map((day) => (
                      <div key={day} className={styles.calendar.weekdayHeader}>
                        {day}
                      </div>
                    ))}
                  </div>

                  <div className={styles.calendar.daysGrid}>
                    {generateDays().map((date, idx) => {
                      const isSelected = isRangeStart(date) || isRangeEnd(date)
                      const isToday = date.isSame(moment(), "day")
                      const isCurrentMonth = date.isSame(viewDate, "month")

                      return (
                        <button
                          key={idx}
                          onClick={() => handleDateSelect(date)}
                          onMouseEnter={() =>
                            selectionStep === "end" && setHoverDate(date)
                          }
                          onMouseLeave={() => setHoverDate(null)}
                          className={`${styles.calendar.dayButton.base} ${
                            !isCurrentMonth
                              ? styles.calendar.dayButton.otherMonth
                              : ""
                          } ${getRangeStyle(date)}`}
                        >
                          <div
                            className={`
                              ${styles.calendar.dayButton.dayWrapper}
                              ${isSelected ? "" : ""}
                              ${
                                isToday && !isSelected && !isInRange(date)
                                  ? styles.calendar.dayButton.today
                                  : ""
                              }
                            `}
                          >
                            {date.format("D")}
                          </div>
                        </button>
                      )
                    })}
                  </div>
                </div>

                <div className={styles.container.footer}>
                  <button
                    onClick={handleTodayClick}
                    className={`${styles.buttons.base} ${styles.buttons.secondary}`}
                  >
                    Today
                  </button>

                  <div className="flex gap-4">
                    <button
                      onClick={() => {
                        setIsOpen(false)
                        setSelectionStep("start")
                      }}
                      className={`${styles.buttons.base} ${styles.buttons.secondary}`}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        if (!range) {
                          onChange?.(dates.start.toDate())
                        } else if (dates.start && dates.end) {
                          onChange?.({
                            startDate: dates.start.toDate(),
                            endDate: dates.end.toDate(),
                          })
                        }
                        setIsOpen(false)
                        setSelectionStep("start")
                      }}
                      className={`${styles.buttons.base} ${styles.buttons.primary}`}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default DatePicker
