import { useEffect } from "react"
import config from "aldoo-ra/Config"
const { FACEBOOK_PIXEL, FACEBOOK_OPTIONS, TRACKING_GDPR } = config
import PubSub from "pubsub-js"

export default function FacebookTracking({ children }) {
  useEffect(() => {
    if (!FACEBOOK_PIXEL) return

    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        //This is initialized through GTM
        ReactPixel.init(FACEBOOK_PIXEL, undefined, FACEBOOK_OPTIONS)
        ReactPixel.pageView()
        if (TRACKING_GDPR) ReactPixel.revokeConsent()

        PubSub.subscribe("Tracking_PageView", () => {
          ReactPixel.pageView()
        })

        PubSub.subscribe("Tracking_NewAccountCreated", () => {
          ReactPixel.track("CompleteRegistration")
        })

        PubSub.subscribe("Tracking_InitiateCheckout", (_, data) => {
          ReactPixel.track("InitiateCheckout", {
            price: data.price,
            currency: data.currency,
          })
        })

        PubSub.subscribe("Tracking_Subscribed", (_, data) => {
          ReactPixel.track("Subscribe", {
            price: data.price,
            currency: data.currency,
          })
        })

        PubSub.subscribe("Tracking_Purchase", (_, data) => {
          ReactPixel.track("Purchase", {
            price: data.price,
            currency: data.currency,
          })
        })
      })
  }, [])

  return children || null
}
