import React from "react"
import Menu from "aldoo-ra/Menu"
import { PhotoIcon, EyeIcon } from "@heroicons/react/24/outline"
import Tooltip from "aldoo-ra/Tooltip"
import Button from "aldoo-ra/Button"

// Define the toolbar menu data
const featuresMenu = {
  label: "Editor Toolbar",
  items: [
    {
      label: "Add Image",
      options: { action: "insertImage" },
      render: (item) => {
        return (
          <div className="flex flex-row items-center">
            <Tooltip content="Add Image">
              <PhotoIcon className="w-8 h-8" />
            </Tooltip>
          </div>
        )
      },
    },
    {
      label: "Add Button",
      options: { action: "insertButton" },
      render: (item) => {
        return (
          <div className="flex flex-row items-center">
            <Tooltip content="Add Button">
              <svg
                className="w-8 h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <rect
                  x="2"
                  y="6"
                  width="20"
                  height="12"
                  rx="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <text
                  x="12"
                  y="14"
                  fontSize="6"
                  textAnchor="middle"
                  fill="currentColor"
                  stroke="none"
                >
                  BTN
                </text>
              </svg>
            </Tooltip>
          </div>
        )
      },
    },
    {
      label: "Add Link",
      options: { action: "insertLink" },
      render: (item) => {
        return (
          <div className="flex flex-row items-center">
            <Tooltip content="Add Link">
              <svg
                className="w-8 h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </Tooltip>
          </div>
        )
      },
    },
    {
      label: "Copy HTML",
      options: { action: "copyHtmlContent" },
    },
    {
      label: "Edit HTML",
      options: { action: "editHtmlContent" },
    },
  ],
}

const historyMenu = {
  label: "History",
  items: [
    {
      label: "Undo",
      options: { action: "undo" },
      render: (item) => {
        return (
          <div className="flex items-center gap-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-6 h-6"
            >
              <path d="M3 7v6h6" />
              <path d="M21 17a9 9 0 00-9-9 9 9 0 00-6 2.3L3 13" />
            </svg>
            <span>{item.label}</span>
          </div>
        )
      },
    },
    {
      label: "Redo",
      options: { action: "redo" },
      render: (item) => {
        return (
          <div className="flex items-center gap-2">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-6 h-6"
            >
              <path d="M21 7v6h-6" />
              <path d="M3 17a9 9 0 019-9 9 9 0 016 2.3L21 13" />
            </svg>
            <span>{item.label}</span>
          </div>
        )
      },
    },
  ],
}

const fileMenu = {
  label: "File",
  items: [
    {
      label: "Preview",
      options: { action: "togglePreview" },
      render: (item) => {
        return (
          <div className="flex flex-row items-center">
            <Tooltip content="Preview Page">
              <EyeIcon className="w-8 h-8" />
            </Tooltip>
          </div>
        )
      },
    },
    {
      label: "Save",
      options: { action: "save" },
      render: (item) => {
        return (
          <Button
            variant="outlined"
            className="flex justify-center bg-primary px-4 py-2 rounded-full gap-2"
          >
            {item.label}
          </Button>
        )
      },
    },
  ],
}

// Toolbar component
const Toolbar = ({ editor }) => {
  // Define the action map for the toolbar
  const actionMap = {
    togglePreview: editor.togglePreview,
    insertImage: editor.insertImage,
    insertButton: editor.insertButton,
    insertLink: editor.insertLink,
    copyHtmlContent: editor.copyHtmlContent,
    editHtmlContent: editor.editHtmlContent,
    undo: editor.undo,
    redo: editor.redo,
    save: editor.save,
  }

  //if editor.readOnly, remove the save button
  if (editor.readOnly) {
    fileMenu.items = fileMenu.items.filter(
      (item) => item.options?.action !== "save"
    )
  }

  return (
    <div
      className="bg-admin_paper px-5 w-full flex flex-row"
      style={{ zIndex: 100 }}
    >
      <Menu
        actionMap={actionMap}
        menuData={featuresMenu}
        renderAs="hlist"
        itemWidth="150px"
      />
      <Menu
        actionMap={actionMap}
        menuData={historyMenu}
        renderAs="hlist"
        itemWidth="150px"
      />
      <Menu
        actionMap={actionMap}
        menuData={fileMenu}
        renderAs="hlist"
        itemWidth="150px"
      />
    </div>
  )
}

export default Toolbar
