// BeginCheckout.jsx
import React, { useEffect, useState } from "react"
import Modal from "aldoo-ra/Modal"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import config from "aldoo-ra/Config"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import AldooStoreAPI from "aldoo-ra/Store/api"
import Value from "aldoo-ra/Value"

const { STRIPE_API_KEY } = config
const stripePromise = loadStripe(STRIPE_API_KEY)
let paymentLoading = false

export default function BeginCheckout({ store }) {
  const texts = TextBundle({ id: "aldoo-store" })
  const [paymentInfo] = Value("aldoo-payment-info")
  const [showingNewPayment, setShowingNewPayment] = useState(false)

  const {
    cart,
    setPaymentInfo,
    checkoutVisible,
    hideCheckout,
    getElement,
    getFragment,
    cartChanged,
  } = store

  // Get components
  const CheckoutForm = getElement("checkout-form")
  const ReusePaymentDialog = getElement("reuse-payment-dialog")
  const CheckoutPreparingPayment = getFragment("checkout-preparing-payment")
  const CheckoutContainer = getFragment("checkout-container")

  useEffect(() => {
    const initializePayment = async () => {
      if (paymentInfo && !cartChanged) {
        setPaymentInfo({ ...paymentInfo })
        return
      }
      if (!cart || cart.length === 0) return
      if (paymentLoading) return

      paymentLoading = true
      const cartItem = cart[0]

      try {
        const response = await AldooStoreAPI.initializePayment({
          pid: cartItem.pid,
          promo_code: store.promoCode,
        })
        paymentLoading = false

        if (response.error) {
          console.error("Failed to initialize payment:", response.error)
          return
        }

        response.pid = cartItem.pid
        response.promoCode = store.promoCode
        setPaymentInfo(response)
      } catch (error) {
        console.error("Failed to initialize payment:", error)
        paymentLoading = false
      }
    }

    if (checkoutVisible) initializePayment()
  }, [checkoutVisible])

  if (paymentInfo && !paymentInfo.requiresPayment) return null
  if (!checkoutVisible) return null

  const shouldShowReuseDialog =
    paymentInfo?.savedPaymentMethod && !showingNewPayment

  return (
    <Modal
      title={paymentInfo && texts?.payment}
      isOpen={checkoutVisible}
      variant="big"
      className="md:w-[550px] min-h-[500px]"
      onClose={hideCheckout}
    >
      {!paymentInfo && <CheckoutPreparingPayment />}
      {paymentInfo && paymentInfo.clientSecret && (
        <CheckoutContainer store={store}>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: paymentInfo.clientSecret,
              loader: "always",
              appearance: {
                variables: {
                  colorDanger: "#df1b41",
                  colorBackground: "#f9f9f9",
                  colorText: "#090909",
                },
              },
            }}
          >
            {shouldShowReuseDialog ? (
              <ReusePaymentDialog
                store={store}
                onClose={() => {
                  if (!showingNewPayment) hideCheckout()
                }}
                onSelectNewPayment={() => setShowingNewPayment(true)}
              />
            ) : (
              <CheckoutForm store={store} />
            )}
          </Elements>
        </CheckoutContainer>
      )}
    </Modal>
  )
}
