import React, { useRef, useState, useEffect } from "react"
import ReactDOM from "react-dom"

const Tooltip = ({ children, text, content, position = "top" }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [tooltipStyles, setTooltipStyles] = useState({})
  const containerRef = useRef()
  const tooltipRef = useRef()

  useEffect(() => {
    if (isVisible) {
      const containerRect = containerRef.current.getBoundingClientRect()
      const tooltipRect = tooltipRef.current.getBoundingClientRect()

      let top, left

      switch (position) {
        case "top":
          top = containerRect.top - tooltipRect.height - 8 // 8px for margin
          left =
            containerRect.left + containerRect.width / 2 - tooltipRect.width / 2
          break
        case "bottom":
          top = containerRect.bottom + 8
          left =
            containerRect.left + containerRect.width / 2 - tooltipRect.width / 2
          break
        case "left":
          top =
            containerRect.top +
            containerRect.height / 2 -
            tooltipRect.height / 2
          left = containerRect.left - tooltipRect.width - 8
          break
        case "right":
          top =
            containerRect.top +
            containerRect.height / 2 -
            tooltipRect.height / 2
          left = containerRect.right + 8
          break
        default:
          top = containerRect.bottom + 8
          left =
            containerRect.left + containerRect.width / 2 - tooltipRect.width / 2
      }

      // Adjust for window boundaries
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight

      if (top < 0) top = 8
      if (left < 0) left = 8
      if (top + tooltipRect.height > viewportHeight)
        top = viewportHeight - tooltipRect.height - 8
      if (left + tooltipRect.width > viewportWidth)
        left = viewportWidth - tooltipRect.width - 8

      setTooltipStyles({ top: `${top}px`, left: `${left}px` })
    }
  }, [isVisible, position])

  const tooltipElement = (
    <div
      ref={tooltipRef}
      style={{ ...tooltipStyles, whiteSpace: "pre-wrap", zIndex: 1001 }}
      className="fixed w-max bg-admin_canvas text-admin_text text-sm px-2 py-1 rounded shadow-lg z-50"
    >
      {content ? content : text}
    </div>
  )

  return (
    <div className="relative inline-block">
      <div
        ref={containerRef}
        className="hover:relative"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && ReactDOM.createPortal(tooltipElement, document.body)}
    </div>
  )
}

export default Tooltip
