import Modal from "aldoo-ra/Modal"

export default function PaymentSuccess({ store }) {
  //Store interface
  const {
    paymentReceipt,
    emptyCart,
    paymentSuccessVisible,
    setPaymentSuccessVisible,
    getElement,
    getFragment,
  } = store

  const ProductPostPurchase = getElement("product-post-purchase")
  const PaymentSuccessFragment = getFragment("payment-success")

  const handleClose = () => {
    setPaymentSuccessVisible(false)
    emptyCart()

    //check if the current url is /payment-result
    //if so, redirect to the home page
    //This happens when third-party payment providers redirect to /payment-result
    //and we have no previous state to go back to
    setTimeout(() => {
      if (window.location.pathname === "/payment-result") {
        window.location.href = "/"
      }
    }, 500)
  }

  //the component is not visible
  if (!paymentSuccessVisible || !paymentReceipt) return null

  const { cart } = paymentReceipt

  return (
    <Modal
      isOpen={paymentSuccessVisible}
      variant="medium"
      className="md:max-w-[550px]"
      onClose={handleClose}
    >
      <PaymentSuccessFragment store={store}>
        {ProductPostPurchase && (
          <ProductPostPurchase
            store={store}
            product_id={cart[0].product_id}
            onAction={handleClose}
          />
        )}
      </PaymentSuccessFragment>
    </Modal>
  )
}
