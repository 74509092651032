import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import { Cog6ToothIcon } from "@heroicons/react/24/outline"
import { LanguageIcon as LocalizationIcon } from "@heroicons/react/24/outline"
import OptionsMenu from "../OptionsMenu"
import Checkbox from "../Checkbox" // Assuming you have a Checkbox component
import Tooltip from "../Tooltip"
import Button from "aldoo-ra/Button"
import LocalizationItemEditor from "../Localization/localizaiton-item-editor"
import generateLocalizationKey from "./generate-localization-key"
import { showPromptDialog } from "aldoo-ra/PromptDialog"

export default function FieldSettingsButton({
  dataItem,
  field,
  fieldValues,
  setFieldValues,
  onSettingChange,
  contentType,
  //this is a reference to the save method for the content
  //this is used to save the content before adding localization
  onContentSave,
}) {
  //invalid data item passed
  if (!dataItem) return null

  // Do not show the settings button for these field types
  if (
    field.type === "Array" ||
    field.type === "Content" ||
    field.type === "Group" ||
    field.type === "Number" ||
    field.type === "Boolean" ||
    field.type === "DatabaseModel"
  )
    return

  //check if this field is localized by looking into the localization array
  const isLocalized = dataItem.localization?.find(
    (item) => item.field === field.id
  )

  const localizationKey = generateLocalizationKey({
    type: contentType.type,
    fieldID: field.id,
    instanceID: dataItem._id,
  })

  const [showMenu, setShowMenu] = useState(false)
  const cogButtonRef = useRef(null) // Ref for the cog button
  const [refresh, setRefresh] = useState(false)
  const [showLocalizationEditor, setShowLocalizationEditor] = useState(false)
  const toggleMenu = () => setShowMenu((prev) => !prev)

  const changeSetting = (value) => {
    if (!value) {
      //remove the localization item
      const updatedLocalization = dataItem.localization?.filter(
        (item) => item.field !== field.id
      )
      dataItem.localization = updatedLocalization
      //announce the change
      if (onSettingChange) onSettingChange(dataItem, field)
      setRefresh(!refresh)
    } else {
      const addLocalization = () => {
        //add the localization item
        dataItem.localization = dataItem.localization || []
        dataItem.localization.push({
          field: field.id,
          localizationKey,
        })
        //announce the change
        if (onSettingChange) onSettingChange(dataItem, field)
        setRefresh(!refresh)
      }

      //-----------------------------------
      //if there is no id, the content is not saved
      //-----------------------------------
      if (!dataItem._id) {
        showPromptDialog({
          title: "Save the content first",
          message:
            "Please save the content before adding localization. Save now?",
          onConfirm: async () => {
            const newItem = await onContentSave()
            if (!newItem) return
            //assign the new instance id
            dataItem = newItem
            //open the editor if the content is saved
            addLocalization()
          },
        })
        return
      }

      //we have a valid id, proceed to add localization
      addLocalization()
    }
  }

  const editLocalization = () => {
    //proceed to edit localization
    setShowLocalizationEditor(true)
  }

  // Menu options
  const menuOptions = [
    {
      id: "localization",
      render: () => (
        <Checkbox
          label="Localization"
          isOn={isLocalized}
          onChange={(value) => {
            changeSetting(value)
          }}
        />
      ),
    },
    // Add more options as needed...
  ]

  return (
    <div className="relative justify-end items-center flex flex-row mt-5">
      {/* Send the current values to the localization item editor */}
      <LocalizationItemEditor
        parentObject={dataItem.content}
        isOpen={showLocalizationEditor}
        localizationKey={localizationKey}
        onClose={() => setShowLocalizationEditor(false)}
        defaultValue={fieldValues[field.id]}
        onSave={(item) => {
          fieldValues[field.id] = item.translations.en.content
          setFieldValues({ ...fieldValues })
          setRefresh(!refresh)
        }}
      />

      {/* Features */}
      <div className="mr-3">
        {/* Show an icon if localized */}
        {isLocalized && (
          <Tooltip
            content="Edit Localization"
            position="top"
            className="cursor-pointer"
          >
            <Button
              variant="outlined"
              className="w-24 flex flex-row text-admin_text justify-center items-center"
              onClick={editLocalization}
            >
              <div className="text mr-3 ">Edit</div>
              <LocalizationIcon className="text-admin_text w-6 h-6" />
            </Button>
          </Tooltip>
        )}
      </div>
      <div className="text-neutral-500 hover:text-primary">
        {/* OptionsMenu */}
        {showMenu && (
          <OptionsMenu
            title="Features"
            options={menuOptions}
            onItemSelect={() => setShowMenu(false)} // Close the menu on item select
            menuPosition="top" // Position the menu relative to the button
            anchorRef={cogButtonRef} // Pass the cog button ref to the menu
            hideOnItemSelect={true} // Hide menu when an item is selected
            onClose={() => setShowMenu(false)} // Handle menu close
          />
        )}

        {/* Cog button */}
        <button
          className=""
          onClick={toggleMenu}
          ref={cogButtonRef} // Assign ref to the cog button
        >
          <Cog6ToothIcon className="text-admin_text w-6 h-6" />
        </button>
      </div>
    </div>
  )
}
