// components/Icon.jsx
import React from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import VariantReader from "aldoo-ra/VariantReader"
import DynamicSVG from "aldoo-ra/DynamicSVG"

const Icon = ({
  id,
  variant = "md",
  styleOverrides,
  recolor = false,
  className = "",
  alt = "icon",
}) => {
  // Get variant class from VariantReader
  const variantClass = VariantReader.useVariant({
    componentName: "Icon",
    variant,
    styleOverrides,
  })
  // Handle custom sizes via className
  const hasCustomSize = className.includes("w-") || className.includes("h-")
  // Combine classes, prioritizing custom sizes
  const finalClassName = hasCustomSize
    ? className
    : `${variantClass} ${className}`.trim()

  return (
    <CMSRender
      contentType="Icon"
      match={{
        "content.id": id,
      }}
      page={1}
      itemsPerPage={1}
      renderItem={(icon) => {
        const isSVG = icon.image.includes(".svg")
        return isSVG ? (
          <DynamicSVG
            url={icon.image}
            recolor={recolor}
            alt={icon.title || alt}
            className={finalClassName}
          />
        ) : (
          <img
            src={icon.image}
            alt={icon.title || alt}
            className={finalClassName}
          />
        )
      }}
    />
  )
}

export default Icon
