import React from "react"
import Typography from "aldoo-ra/Typography"
import Spinner from "aldoo-ra/Spinner"
import TextBundle from "aldoo-ra/CMS/text-bundle"

const CheckoutPreparingPayment = () => {
  const texts = TextBundle({ id: "aldoo-store" })
  return (
    <div className="w-full h-[400px] justify-center flex flex-col items-center">
      <Typography 
        variant="info_18" 
        className="text-center text-text"
      >
        {texts?.loading_payment_info || "{{ Missing }} Preparing payment..."}
      </Typography>
      <Spinner variant="loading" className="mx-auto w-[50px] h-[50px]" />
    </div>
  )
}

export default CheckoutPreparingPayment
