import { useState, useEffect, useCallback } from "react"
import PubSub from "pubsub-js"

// Initialize store outside the hook
const store = new Map()

/**
 * Get a value synchronously from the store/localStorage
 */
const getValue = (key, persistKey = null) => {
  // First check store
  if (store.has(key)) return store.get(key)

  // Then check localStorage
  if (persistKey && typeof window !== "undefined") {
    try {
      const value = localStorage.getItem(persistKey)
      if (value) {
        const parsed = JSON.parse(value)
        store.set(key, parsed)
        return parsed
      }
    } catch (e) {
      console.warn("Failed to get value:", e)
    }
  }

  return null
}

/**
 * Set a value synchronously in the store/localStorage
 */
const setValue = (key, value, persistKey = null) => {
  store.set(key, value)

  if (persistKey && typeof window !== "undefined") {
    try {
      localStorage.setItem(persistKey, JSON.stringify(value))
    } catch (e) {
      console.warn("Failed to persist value:", e)
    }
  }

  PubSub.publish(key, value)
}

/**
 * Custom hook to manage a value stored in a global store with PubSub subscription.
 */
const useValue = (options, defaultValue = null, persistKey = null) => {
  let key

  if (typeof options === "string") {
    key = options
  } else if (typeof options === "object") {
    key = options.key
    defaultValue = options.defaultValue
    persistKey = options.persistKey
  }

  // Get initial value synchronously
  const [value, setValueState] = useState(() => {
    const existingValue = getValue(key, persistKey)
    if (existingValue !== null) return existingValue
    if (defaultValue !== null) {
      setValue(key, defaultValue, persistKey)
      return defaultValue
    }
    return null
  })

  useEffect(() => {
    // Subscribe to changes
    const token = PubSub.subscribe(key, (_, newValue) => {
      setValueState(newValue)
    })

    return () => {
      PubSub.unsubscribe(token)
    }
  }, [key])

  const updateValue = useCallback(
    (newValue) => {
      const resolvedValue =
        typeof newValue === "function" ? newValue(store.get(key)) : newValue
      setValue(key, resolvedValue, persistKey)
    },
    [key, persistKey]
  )

  return [value, updateValue]
}

export default useValue
