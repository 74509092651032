import parse from "html-react-parser"
const renderPanelMenuItem = (item, variant) => {
  if (!item) return null
  if (item.render) return item.render(item)
  return (
    <div className="flex flex-col">
      <span className={variant.menu_item_title || "font-bold uppercase mb-2"}>
        {parse(item.label)}
      </span>
      <span className={variant.menu_item_description || ""}>
        {item.description}
      </span>
    </div>
  )
}

export default renderPanelMenuItem
