import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import Input from "aldoo-ra/Input"
import Localization from "aldoo-ra/Localization"

export default function ShoppingCartFooter({ store }) {
  const texts = TextBundle({ id: "aldoo-store" })
  const { t } = Localization()

  const {
    setPromoCode,
    setPromoCodeError,
    promoCode,
    getCartTotalValue,
    beginCheckout,
    applyPromoCode,
    promoCodeError,
    getPromoCodeInfo,
    resetPromoCode,
  } = store

  return (
    <div className="flex flex-row  h-[100px] justify-between mt-4">
      <div className="flex flex-col gap-0 ">
        <div className="flex flex-row items-center gap-2 ">
          {/* Promo Code */}
          <Input
            label={`${texts?.promo_code}`}
            // variant="floating"
            className="md:w-[150px] "
            showClearButton
            placeholder={texts?.promo_code_placeholder}
            value={promoCode}
            onClear={resetPromoCode}
            onChange={(e) => {
              setPromoCodeError(null)
              setPromoCode(e.target.value.toUpperCase())
            }}
          />

          <Button
            variant="outlined"
            className="mt-5 h-[50px] w-[150px] flex flex-row justify-center items-center"
            onClick={applyPromoCode}
          >
            <Typography variant="">{texts?.apply || "Apply"}</Typography>
          </Button>
        </div>

        {/* Promo Code Error message */}
        {
          <Typography variant="body1-accent-s">
            {!promoCodeError
              ? getPromoCodeInfo() &&
                store.promoCode &&
                t(texts?.discount, {
                  discount: getPromoCodeInfo(),
                })
              : texts[promoCodeError] || promoCodeError}
          </Typography>
        }
      </div>

      {/* Total */}
      <div className="flex flex-col  justify-between">
        <Typography className="m-0 p-0" variant="body1-accent-s">
          {texts?.total}:
        </Typography>
        <Typography className="m-0 p-0" variant="h3">
          {getCartTotalValue()}
        </Typography>
      </div>
      <Button
        variant="action"
        className="w-1/3 flex flex-row justify-center items-center"
        //TODO: Here would would plugin in some sales offers
        //TODO: Before the final checkout
        onClick={() => beginCheckout()}
      >
        <Typography variant="">{texts?.checkout}</Typography>
        <ChevronRightIcon className="ml-5 w-5 h-5" />
      </Button>
    </div>
  )
}
