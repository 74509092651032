// Helper function to evaluate `visibleIf`
const evaluateVisibleIf = (visibleIf, fieldValues) => {
  if (!visibleIf) return true
  return Object.entries(visibleIf).every(([fieldID, condition]) => {
    let fieldValue = fieldValues[fieldID]

    const values = Array.isArray(fieldValue) ? fieldValue : [fieldValue]

    for (const item of values) {
      let value = item
      if (typeof item === "object" && item.value !== undefined) {
        value = item.value
      }

      if (condition.eq && condition.eq === value) return true
      if (condition.ne && condition.ne === value) return false
      if (condition.in && Array.isArray(condition.in)) {
        return condition.in.includes(value)
      }
      if (condition.nin && Array.isArray(condition.nin)) {
        return !condition.nin.includes(value)
      }
    }
    return true
  })
}

export default evaluateVisibleIf