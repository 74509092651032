import React, { useState, useEffect, forwardRef } from "react"
import VariantReader from "aldoo-ra/VariantReader"

const Checkbox = forwardRef(
  (
    {
      label,
      isOn = false,
      onChange,
      labelPosition = "right",
      labelClassName = "",
      name = "",
      className = "",
      render,
      variant = "default",
      styleOverrides = {},
      required = false,
      disabled = false,
    },
    ref
  ) => {
    const variants = VariantReader.useVariant({
      componentName: "Checkbox",
      variant,
      styleOverrides,
    })

    const [checked, setChecked] = useState(isOn)

    const handleToggle = () => {
      if (disabled) return
      setChecked(!checked)
      if (!onChange) return
      onChange(!checked)
    }

    useEffect(() => {
      setChecked(isOn)
    }, [isOn])

    // If render prop is provided, use it instead of default markup
    if (render) {
      return render({
        isOn: checked,
        toggle: handleToggle,
        disabled,
      })
    }

    // Default checkbox markup
    return (
      <div
        className={`${variants.container} ${
          disabled ? variants.disabled : ""
        } ${className}`.trim()}
        onClick={handleToggle}
      >
        {/* If the label is positioned on the left */}
        {label && labelPosition === "left" && (
          <label
            className={`${variants.label} ${variants.label_left} ${labelClassName}`.trim()}
          >
            {label} {required && <span className={variants.required}>*</span>}
          </label>
        )}

        {/* Checkbox itself */}
        <div
          role="checkbox"
          aria-checked={checked}
          aria-disabled={disabled}
          ref={ref}
          tabIndex={disabled ? -1 : 0}
          className={`${variants.checkbox} ${
            checked ? variants.checkbox_checked : variants.checkbox_unchecked
          }`}
        >
          {checked && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={variants.checkmark}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
          {!checked && <div className={variants.placeholder}></div>}
        </div>

        {/* If the label is positioned on the right */}
        {label && labelPosition === "right" && (
          <label
            className={`${variants.label} ${variants.label_right} ${labelClassName}`.trim()}
          >
            {label} {required && <span className={variants.required}>*</span>}
          </label>
        )}
      </div>
    )
  }
)

Checkbox.displayName = "Checkbox"
export default Checkbox
