import { useState } from 'react'
import { CreateUser, VerifyCredentials } from '../api'
import awaitAuth from "./await-auth"
import PlatformAuth from "aldoo-ra/PlatformAuth"

const useCreateUserWithCredentials = () => {
    const [state, setState] = useState()
    const [error, setError] = useState()
    //set the credentials once the user is created
    const [, setAuth] = PlatformAuth.useAuth()

    const createUser = async function (credentials) {
        setState(undefined)
        setState("pending")

        const response = await CreateUser(credentials)

        if (response.error) {
            setState(undefined)
            setError(response.error)
            return
        }

        setState("waiting-auth")
        const awaitResult = await awaitAuth(credentials)

        if (!awaitResult.login) {
            setState(undefined)
            return
        }

        //if there is no userID, get it from the server
        if (!credentials._id) {
            const { _id } = await VerifyCredentials(credentials)
            credentials._id = _id
        }
        //set the local authorization object
        setAuth({
            ...credentials,
            auth_token: awaitResult.auth_token,
        })
        //mark the login as successful
        setState("success")
    }

    return { state, error, createUser }
}

export default useCreateUserWithCredentials