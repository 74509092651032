import React, { useState } from "react"
import FileUpload from "../../FileUpload"
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import Tooltip from "../../Tooltip"
import { url, filename } from "../url"
import { useFieldEditorController } from "../controllers/field-file-editor-controller"
import { useFieldDragController } from "../controllers/field-drag-controller"
import VideoPreview from "aldoo-ra/CMS/preview/video-preview"
import VideoThumbnail from "aldoo-ra/CMS/preview/video-thumbnail"

// Helper function to get YouTube video ID from URL
const getYouTubeVideoID = (url) => {
  const regExp =
    /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/
  const match = url?.match(regExp)
  return match ? match[1] : null
}

export default function VideoEditor({
  onChange,
  value,
  field,
  showInfo = true,
  readOnly = false,
  parentObject,
}) {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [currentPreviewVideo, setCurrentPreviewVideo] = useState(null)
  const [currentPreviewFileName, setCurrentPreviewFileName] = useState(null)
  const [showYouTubeModal, setShowYouTubeModal] = useState(false)
  const [youtubeUrl, setYoutubeUrl] = useState("")

  const {
    localValue,
    setLocalValue,
    handleChange,
    onRemoveItem,
    errors,
    setErrors,
  } = useFieldEditorController({
    value,
    field,
    onChange,
    category: "videos",
  })

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  // Drag controller
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, setLocalValue)

  const openPreview = (video, fileName) => {
    setCurrentPreviewVideo(video)
    setCurrentPreviewFileName(fileName)
    setIsPreviewOpen(true)
  }

  const closePreview = () => {
    setIsPreviewOpen(false)
    setCurrentPreviewVideo(null)
    setCurrentPreviewFileName(null)
  }

  const addYouTubeUrl = () => {
    if (!youtubeUrl.trim()) return

    const videoId = getYouTubeVideoID(youtubeUrl.trim())
    if (!videoId) {
      setErrors(["Invalid YouTube URL"])
      return
    }

    const newVideo = {
      url: youtubeUrl.trim(),
      file: { name: youtubeUrl.trim() },
    }

    if (field.multiple) {
      const original = localValue || []
      setLocalValue([...original, newVideo])
    } else {
      setLocalValue(newVideo)
    }

    setYoutubeUrl("")
    setShowYouTubeModal(false)
  }

  const renderItem = (item, index) => {
    if (!item) return null
    return (
      <Tooltip key={index} content={filename(url(item.url))} position="top">
        <div
          className="relative flex-none w-32 h-32 border border-neutral_2 rounded-md overflow-hidden group"
          draggable
          onDragStart={() => (!readOnly ? handleDragStart(index) : null)}
          onDragOver={(e) => {
            if (readOnly) return
            e.preventDefault()
            handleDragOver(index)
          }}
          onDragEnd={!readOnly ? handleDragEnd : null}
        >
          <VideoThumbnail
            videoUrl={url(item.url)}
            className="cursor-pointer"
            width={128}
            height={128}
            onClick={() => openPreview(url(item.url), filename(item.url))}
          />

          {/* Upload progress */}
          {item.progress > 0 && item.progress < 100 && (
            <div
              className="absolute top-0 left-0 right-0 bg-primary"
              style={{ width: `${item.progress}%` }}
            >
              <div className="ml-1 w-full text-xs text-white">
                {item.progress}%
              </div>
            </div>
          )}

          {!readOnly && (
            <>
              <div className="absolute top-1 left-1 bg-gray-500 text-white rounded-full p-1 cursor-move opacity-0 group-hover:opacity-100 transition-opacity">
                <Bars3Icon className="w-4 h-4" />
              </div>
              <button
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-700 focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity"
                onClick={() => onRemoveItem(index)}
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            </>
          )}
          <span className="mt-2 text-center text-xs text-admin_text block">
            {filename(item.url)}
          </span>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className="video-editor">
      <div className="flex flex-row justify-between items-center">
        {/* Label and description */}
        {showInfo && (
          <div className="flex flex-col mb-2">
            <label className="block font-semibold text-admin_text">
              {field.label}
              {isRequired && (
                <span className="ml-2 text-error">(required)</span>
              )}
            </label>
            {field.description && (
              <label className="block text-sm text-admin_text">
                {field.description}
              </label>
            )}
          </div>
        )}

        {/* File Upload and YouTube URL */}
        {!readOnly && (
          <div className="flex flex-row items-center space-x-4">
            <FileUpload
              showSelectedFiles={false}
              multiple={field.multiple}
              required={field.required}
              formats={field.formats}
              maxSize={field.maxFileSize}
              onError={(errors) => setErrors(errors)}
              onFileChange={handleChange}
            />

            <Button
              variant="outlined"
              className="text-admin_text mb-4"
              onClick={() => setShowYouTubeModal(true)}
            >
              Add YouTube
            </Button>
          </div>
        )}
      </div>

      {!readOnly && field.multiple && localValue?.length > 0 && (
        <div className="text-sm text-admin_text mb-2 flex flex-row items-center">
          <Button
            variant="outlined"
            className="text-admin_text mr-5"
            onClick={() => {
              setLocalValue(field.multiple ? [] : null)
            }}
          >
            {`${field.multiple ? "Clear All" : "Clear"}`}
          </Button>
          {field.multiple && (
            <div className="text-sm text-admin_text">
              {localValue.length} {localValue.length === 1 ? "video" : "videos"}
            </div>
          )}
        </div>
      )}

      <div className="flex gap-4 overflow-x-auto py-4">
        {/* Render video items */}
        {Array.isArray(localValue)
          ? localValue?.map((item, index) => renderItem(item, index))
          : renderItem(localValue, 0)}
      </div>

      {errors.length > 0 && (
        <div className="text-error text-center w-full mb-4">
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {/* Full screen video preview */}
      {isPreviewOpen && (
        <VideoPreview
          isOpen={isPreviewOpen}
          videoUrl={currentPreviewVideo}
          fileName={currentPreviewFileName}
          onClose={closePreview}
        />
      )}

      {/* YouTube URL Modal */}
      {showYouTubeModal && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-admin_paper p-6 rounded-lg shadow-lg relative w-[500px]">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-red-600"
              onClick={() => setShowYouTubeModal(false)}
            >
              <XMarkIcon className="w-6 h-6" />
            </button>
            <h3 className="text-lg font-semibold text-admin_text mb-4">
              Add YouTube Video URL
            </h3>
            <input
              type="text"
              value={youtubeUrl}
              onChange={(e) => setYoutubeUrl(e.target.value)}
              className="text-admin_text bg-admin_paper w-full px-4 py-2 border border-neutral_1 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
              placeholder="Enter YouTube URL"
            />
            <div className="flex justify-end mt-4">
              <Button className="w-32" variant="action" onClick={addYouTubeUrl}>
                Add
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
