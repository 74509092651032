import { useEffect } from "react"
import Value from "aldoo-ra/Value"

const platformMapping = {
  ios: "ios",
  android: "android",
  windows: "android",
  linux: "android", // Maps linux to windows in the site platform
  mac: "ios",
  unknown: "android",
}

const PlatformUrlManager = () => {
  const [detectedPlatform] = Value({
    key: "detected_platform",
  })

  const [sitePlatform, setSitePlatform] = Value({
    key: "site_platform",
    persistKey: "site_platform",
  })

  useEffect(() => {
    // Check URL platform override
    const urlPlatform = window.location.pathname.split("/")[1]
    let resolvedPlatform
    // Set site platform based on URL or mapped detected platform
    if (platformMapping[urlPlatform]) {
      resolvedPlatform = platformMapping[urlPlatform]
    } else {
      resolvedPlatform = platformMapping[detectedPlatform]
    }

    setSitePlatform(resolvedPlatform)

    // Update URL if on home page
    if (resolvedPlatform && window.location.pathname === "/") {
      window.history.replaceState(null, "", `/${resolvedPlatform}`)
    }
  }, [detectedPlatform])

  return null
}

export default PlatformUrlManager
