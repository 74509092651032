const observeImageRemoval = (
  selectedImage,
  removeExistingGizmo,
  editorContainer,
  handleScroll
) => {
  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      // Check if the selected image is removed
      if (mutation.type === "childList") {
        mutation.removedNodes.forEach((removedNode) => {
          if (removedNode === selectedImage) {
            removeExistingGizmo()
            editorContainer.removeEventListener("scroll", handleScroll)
            observer.disconnect() // Stop observing after the image is removed
          }
        })
      }
    }
  })

  // Start observing the parent node of the selected image
  if (selectedImage.parentNode) {
    observer.observe(selectedImage.parentNode, { childList: true })
  }
}

const observeImageChanges = (selectedImage, gizmo) => {
  const observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      updateGizmoPosition(gizmo, selectedImage)
    })
  })

  observer.observe(selectedImage, {
    attributes: true,
    attributeFilter: ["style", "class"], // Only watch for style and class changes
    childList: false,
    subtree: false,
  })

  return observer
}

const TransformGizmo = (selectedImage) => {
  if (!selectedImage) return

  // Remove any existing gizmos first
  removeExistingGizmo()

  // Create gizmo container
  const gizmo = document.createElement("div")
  gizmo.className =
    "ae-transform-gizmo absolute border-2 border-blue-500 pointer-events-none"
  gizmo.style.position = "absolute"

  // Add resize handles to corners and edges
  const handlePositions = [
    "top-left",
    "top",
    "top-right",
    "left",
    "right",
    "bottom-left",
    "bottom",
    "bottom-right",
  ]

  handlePositions.forEach((position) => {
    const handle = document.createElement("div")
    handle.className = `
      absolute w-3 h-3 bg-white border-2 border-blue-500 rounded-sm
      cursor-${getCursorStyle(position)} pointer-events-auto
    `

    // Position the handle
    switch (position) {
      case "top-left":
        handle.style.top = "-6px"
        handle.style.left = "-6px"
        break
      case "top":
        handle.style.top = "-6px"
        handle.style.left = "50%"
        handle.style.transform = "translateX(-50%)"
        break
      case "top-right":
        handle.style.top = "-6px"
        handle.style.right = "-6px"
        break
      case "left":
        handle.style.left = "-6px"
        handle.style.top = "50%"
        handle.style.transform = "translateY(-50%)"
        break
      case "right":
        handle.style.right = "-6px"
        handle.style.top = "50%"
        handle.style.transform = "translateY(-50%)"
        break
      case "bottom-left":
        handle.style.bottom = "-6px"
        handle.style.left = "-6px"
        break
      case "bottom":
        handle.style.bottom = "-6px"
        handle.style.left = "50%"
        handle.style.transform = "translateX(-50%)"
        break
      case "bottom-right":
        handle.style.bottom = "-6px"
        handle.style.right = "-6px"
        break
    }

    handle.addEventListener("mousedown", (e) => {
      e.stopPropagation()
      initResize(e, selectedImage, position)
    })

    gizmo.appendChild(handle)
  })

  const editorContainer = document.querySelector(".editor")

  // Add scroll event listener
  const handleScroll = () => {
    requestAnimationFrame(() => {
      updateGizmoPosition(gizmo, selectedImage)
    })
  }
  editorContainer.addEventListener("scroll", handleScroll)

  // Set up mutation observers
  const styleObserver = observeImageChanges(selectedImage, gizmo)
  const removalObserver = observeImageRemoval(
    selectedImage,
    () => {
      removeExistingGizmo()
      styleObserver.disconnect()
    },
    editorContainer,
    handleScroll
  )

  // Initial position
  updateGizmoPosition(gizmo, selectedImage)

  // Add gizmo to editor container
  editorContainer.appendChild(gizmo)

  // Handle clicks outside
  const handleClickOutside = (e) => {
    if (!gizmo.contains(e.target) && e.target !== selectedImage) {
      removeExistingGizmo()
      document.removeEventListener("mousedown", handleClickOutside)
      editorContainer.removeEventListener("scroll", handleScroll)
      styleObserver && styleObserver.disconnect()
      removalObserver && removalObserver.disconnect()
    }
  }

  document.addEventListener("mousedown", handleClickOutside)

  // Set up resize observer for the editor container
  const resizeObserver = new ResizeObserver(() => {
    requestAnimationFrame(() => {
      updateGizmoPosition(gizmo, selectedImage)
    })
  })

  resizeObserver.observe(editorContainer)

  // Return cleanup function
  return () => {
    removeExistingGizmo()
    document.removeEventListener("mousedown", handleClickOutside)
    editorContainer.removeEventListener("scroll", handleScroll)
    styleObserver.disconnect()
    removalObserver.disconnect()
    resizeObserver.disconnect()
  }
}

// Helper function to get appropriate cursor style
const getCursorStyle = (position) => {
  switch (position) {
    case "top-left":
    case "bottom-right":
      return "nw-resize"
    case "top-right":
    case "bottom-left":
      return "ne-resize"
    case "top":
    case "bottom":
      return "ns-resize"
    case "left":
    case "right":
      return "ew-resize"
    default:
      return "move"
  }
}

// Helper function to remove existing gizmo
const removeExistingGizmo = () => {
  const existingGizmo = document.querySelector(".ae-transform-gizmo")
  if (existingGizmo) {
    existingGizmo.remove()
  }
}

// Helper function to update gizmo position
const updateGizmoPosition = (gizmo, image) => {
  const editorContainer = document.querySelector(".editor")
  if (!editorContainer) return
  const editorRect = editorContainer.getBoundingClientRect()
  const imageRect = image.getBoundingClientRect()

  // Calculate position relative to the editor container
  const top = imageRect.top - editorRect.top + editorContainer.scrollTop
  const left = imageRect.left - editorRect.left + editorContainer.scrollLeft

  gizmo.style.left = `${left}px`
  gizmo.style.top = `${top}px`
  gizmo.style.width = `${imageRect.width}px`
  gizmo.style.height = `${imageRect.height}px`
}

// Initialize resize functionality
const initResize = (e, image, position) => {
  e.preventDefault()
  const startX = e.clientX
  const startY = e.clientY
  const startWidth = image.offsetWidth
  const startHeight = image.offsetHeight

  const onMouseMove = (moveEvent) => {
    moveEvent.preventDefault()
    const deltaX = moveEvent.clientX - startX
    const deltaY = moveEvent.clientY - startY
    let newWidth = startWidth
    let newHeight = startHeight

    // Calculate new dimensions based on handle position
    switch (position) {
      case "right":
        newWidth = Math.max(50, startWidth + deltaX)
        break
      case "left":
        newWidth = Math.max(50, startWidth - deltaX)
        break
      case "bottom":
        newHeight = Math.max(50, startHeight + deltaY)
        break
      case "top":
        newHeight = Math.max(50, startHeight - deltaY)
        break
      case "bottom-right":
        newWidth = Math.max(50, startWidth + deltaX)
        newHeight = Math.max(50, startHeight + deltaY)
        break
      case "bottom-left":
        newWidth = Math.max(50, startWidth - deltaX)
        newHeight = Math.max(50, startHeight + deltaY)
        break
      case "top-right":
        newWidth = Math.max(50, startWidth + deltaX)
        newHeight = Math.max(50, startHeight - deltaY)
        break
      case "top-left":
        newWidth = Math.max(50, startWidth - deltaX)
        newHeight = Math.max(50, startHeight - deltaY)
        break
    }

    // Remove any existing width/height classes
    const currentWidthClass = Array.from(image.classList).find((cls) =>
      cls.startsWith("w-")
    )
    if (currentWidthClass) {
      image.classList.remove(currentWidthClass)
    }

    // Update image size using absolute pixels
    image.style.width = `${Math.round(newWidth)}px`
    image.style.height = `${Math.round(newHeight)}px`
    // image.style.objectFit = "cover"

    // Update gizmo position
    const gizmo = document.querySelector(".ae-transform-gizmo")
    if (gizmo) {
      updateGizmoPosition(gizmo, image)
    }
  }

  const onMouseUp = () => {
    document.removeEventListener("mousemove", onMouseMove)
    document.removeEventListener("mouseup", onMouseUp)
  }

  document.addEventListener("mousemove", onMouseMove)
  document.addEventListener("mouseup", onMouseUp)
}

export default TransformGizmo
