import React, { useEffect } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useState } from "react"
import Modal from "aldoo-ra/Modal"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import config from "aldoo-ra/Config"
import Value from "aldoo-ra/Value"
import AldooStoreAPI from "aldoo-ra/Store/api"
import { store } from "aldoo-ra/Store"

const { STRIPE_API_KEY } = config
const stripePromise = loadStripe(STRIPE_API_KEY)
let setupLoading = false

// The inner form component that uses Stripe hooks
const PaymentMethodForm = ({ onSuccess, onCancel, paymentMethods }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)
  const [ready, setReady] = useState(false)
  const [makeDefault, setMakeDefault] = useState(false)
  const [error, setError] = useState(null)

  const isReady = stripe && ready && !loading

  // Function to check if a payment method already exists
  const isDuplicateMethod = (newMethod) => {
    return paymentMethods.some((method) => {
      // Check if both methods are of type 'card' and have the necessary card details
      if (method.type === "card" && newMethod?.card) {
        return (
          method.card?.last4 === newMethod.card?.last4 &&
          method.card?.exp_month === newMethod.card?.exp_month &&
          method.card?.exp_year === newMethod.card?.exp_year
        )
      }
      // Add checks for other types of payment methods if needed
      return false
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    setError(null)

    if (!stripe || !elements) {
      setLoading(false)
      return
    }

    try {
      // Validate the form
      const { error: submitError } = await elements.submit()
      if (submitError) {
        throw submitError
      }

      // Confirm the setup intent
      const { error: confirmError, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {},
      })

      if (confirmError) {
        throw confirmError
      }

      // Retrieve the setup intent details
      const { setupIntent: setupIntentDetails } =
        await stripe.retrieveSetupIntent(setupIntent.client_secret)

      if (!setupIntentDetails || !setupIntentDetails.payment_method) {
        throw new Error("Failed to retrieve payment method details")
      }

      // Check if payment_method_details exists and contains card details
      const paymentMethodDetails =
        setupIntentDetails.payment_method_details || {}
      const paymentMethodType = paymentMethodDetails.type || "unknown"
      const paymentMethodCard = paymentMethodDetails.card || null

      const paymentMethod = {
        id: setupIntentDetails.payment_method,
        type: paymentMethodType,
        card: paymentMethodCard,
      }

      // Check for duplicate payment method
      if (isDuplicateMethod(paymentMethod)) {
        setError("This method is already added to your account.")
        return
      }

      // Attach the payment method to the user's account
      const response = await AldooStoreAPI.attachPaymentMethod({
        payment_method_id: paymentMethod.id,
        makeDefault,
      })

      if (response.error) {
        throw new Error(response.error)
      }

      window.dispatchEvent(new CustomEvent("payment-methods-updated"))
      onSuccess?.(paymentMethod)
    } catch (err) {
      setError(err.message)
      store.logPaymentError(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="w-full max-w-lg">
      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-700 rounded-md">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <PaymentElement
            onReady={() => setReady(true)}
            options={{
              layout: {
                type: "tabs",
                defaultCollapsed: false,
              },
            }}
          />
        </div>

        <div className="mb-4">
          <label className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={makeDefault}
              onChange={(e) => setMakeDefault(e.target.checked)}
              className="form-checkbox h-4 w-4 text-blue-600"
            />
            <span className="text-sm text-gray-700">
              Make this my default payment method
            </span>
          </label>
        </div>

        <div className="flex justify-end space-x-3">
          {onCancel && (
            <button
              type="button"
              onClick={onCancel}
              className="px-6 py-2 rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200
                transition-colors duration-200"
            >
              Cancel
            </button>
          )}
          <button
            type="submit"
            disabled={!isReady}
            className={`px-6 py-2 rounded-md text-white font-medium
              ${
                isReady
                  ? "bg-blue-600 hover:bg-blue-700"
                  : "bg-gray-400 cursor-not-allowed"
              }
              transition-colors duration-200`}
          >
            {loading ? (
              <span className="flex items-center space-x-2">
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                    fill="none"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Processing...</span>
              </span>
            ) : (
              "Add Payment Method"
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

// The main component that handles setup and modal
const AddPaymentMethod = ({ isOpen, onClose, paymentMethods }) => {
  const texts = TextBundle({ id: "aldoo-store" })
  const [setupIntent, setSetupIntent] = Value({
    key: "payment-setup-intent",
    defaultValue: null,
  })
  const { getFragment } = store

  const PreparingPayment = getFragment("checkout-preparing-payment")
  const PaymentContainer = getFragment("checkout-container")

  useEffect(() => {
    const initializeSetup = async () => {
      if (setupIntent || setupLoading) return
      setupLoading = true

      try {
        const response = await AldooStoreAPI.initializePaymentMethodSetup()
        setupLoading = false

        if (response.error) {
          console.error("Failed to initialize setup:", response.error)
          return
        }

        setSetupIntent(response)
      } catch (error) {
        console.error("Failed to initialize setup:", error)
        setupLoading = false
      }
    }

    if (isOpen) initializeSetup()
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Modal
      title={setupIntent && texts?.addPaymentMethod}
      isOpen={isOpen}
      variant="centered"
      className="md:max-w-[600px] min-h-[500px]"
      onClose={onClose}
    >
      {!setupIntent && <PreparingPayment />}
      {setupIntent && setupIntent.clientSecret && (
        <PaymentContainer store={store}>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: setupIntent.clientSecret,
              loader: "always",

              appearance: {
                variables: {
                  colorDanger: "#df1b41",
                  colorBackground: "#f9f9f9",
                  colorText: "#090909",
                },
              },
            }}
          >
            <PaymentMethodForm
              paymentMethods={paymentMethods}
              onSuccess={() => {
                onClose()
                setSetupIntent(null)
              }}
              onCancel={onClose}
            />
          </Elements>
        </PaymentContainer>
      )}
    </Modal>
  )
}

export default AddPaymentMethod
