import { preFormatValue } from "../field-formatters/file-with-type"
import { useState } from "react"

function ImportJSON({
  showImportModal,
  setShowImportModal,
  contentType,
  setLocalFieldValues,
}) {
  const [importData, setImportData] = useState("")
  if (!showImportModal) return null

  // Handle the import of JSON data
  const handleImport = () => {
    try {
      const parsedData = JSON.parse(importData)

      // Prepare an empty object to store the parsed field values
      const newFieldValues = {}

      //for contentType.fields, set the values from the imported data
      contentType.fields.forEach((field) => {
        if (
          field.type === "Image" ||
          field.type === "Video" ||
          field.type === "File" ||
          field.type === "Audio"
        ) {
          newFieldValues[field.id] = preFormatValue(parsedData[field.id])
        } else {
          //make sure the fields are preformatted
          if (field.id == "fields") {
            //make sure the field type field is { value: string, name: string }
            for (const item of parsedData[field.id]) {
              if (item.group) {
                item.group = {
                  value: item.group,
                  name: parsedData.groups.find(
                    (group) => group.id === item.group
                  ).label,
                }
              }

              //make sure the field type field is { value: string, name: string }
              if (item.type) item.type = { value: item.type, name: item.type }
            }
          }

          newFieldValues[field.id] = parsedData[field.id]
        }
      })

      setLocalFieldValues(newFieldValues)

      // Close the modal and clear the import data
      setShowImportModal(false)
    } catch (error) {
      console.error("Invalid JSON format", error)
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-admin_paper p-6 rounded-lg shadow-lg w-1/2">
        <h2 className="text-xl text-admin_text font-bold mb-4">
          Import Content Type
        </h2>
        <textarea
          value={importData}
          onChange={(e) => setImportData(e.target.value)}
          className="bg-admin_canvas  text-admin_text w-full h-60 p-2 border border-neutral_1 rounded-md focus:outline-none"
          placeholder="Paste JSON content here..."
        />
        <div className="flex justify-end mt-4">
          <Button
            variant="outlined"
            className="mr-2 text-admin_text"
            onClick={() => setShowImportModal(false)}
          >
            Cancel
          </Button>
          <Button variant="action" onClick={handleImport}>
            Import
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ImportJSON
