import React, { useState, useEffect, useRef } from "react"
import HtmlEditor from "aldoo-ra/HtmlEditor"
import "./style.css"
import Toolbar from "./toolbar"
import SelectionMenu from "./selection-menu"
import ReactDOM from "react-dom"

import Align from "./plugins/align"
import TypographyPlugin from "./plugins/typography"
import Color from "./plugins/color"
import PrintSelection from "./plugins/print-selection"
import FontSize from "./plugins/font-size"
import History from "./plugins/history"
import Image from "./plugins/image"
import Button from "./plugins/button"
import Link from "./plugins/link"
import PagePreview from "./plugins/page-preview"
import Typography from "aldoo-ra/Typography"

import { runCommand, plugins, CommanderStateResolve } from "aldoo-ra/Commander"
//Register the plugins with the Commander
plugins({
  align: Align,
  typography: TypographyPlugin,
  color: Color,
  print: PrintSelection,
  fontSize: FontSize,
  history: History,
  button: [Button, { useHistory: ["add", "style", "align"] }], // Add this line
  //enabled history only for those commands
  image: [Image, { useHistory: ["add"] }],
  link: [Link, { useHistory: ["add"] }], // Add this line
  pagePreview: PagePreview,
})

const BlogEditor = ({
  content,
  title,
  parentObject,
  onSave,
  readOnly = false,
}) => {
  const editorRef = useRef(null)

  const [isHTMLEditorOpen, setIsHTMLEditorOpen] = useState(false)
  const [selectionMenu, setSelectionMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  })

  //resolve the title from the parent object if provided
  if (!title && parentObject) {
    title = parentObject.name || parentObject.title
  }

  //set the commander getState function
  CommanderStateResolve(() => editorRef.current.innerHTML)

  const [selectionRange, setSelectionRange] = useState(null)

  // Function to update the selection range when the user selects text or places the cursor
  const updateSelection = () => {
    const selection = window.getSelection()
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      // Only update if the range is inside the editor
      if (editorRef.current.contains(range.commonAncestorContainer)) {
        setSelectionRange(range)
      }
    }
  }

  // Attach event listeners to track selection changes
  useEffect(() => {
    const editor = editorRef.current

    if (editor) {
      // Listen for selection changes and update the range
      editor.addEventListener("mouseup", updateSelection)
      editor.addEventListener("keyup", updateSelection)
      editor.addEventListener("focus", updateSelection)
    }

    // Clean up event listeners on component unmount
    return () => {
      if (editor) {
        editor.removeEventListener("mouseup", updateSelection)
        editor.removeEventListener("keyup", updateSelection)
        editor.removeEventListener("focus", updateSelection)
      }
    }
  }, [])

  //attach the content data to the editor innerHTML
  useEffect(() => {
    if (editorRef.current && content) {
      editorRef.current.innerHTML = content
    }
  }, [content])

  // Function to sanitize HTML content
  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    const elements = doc.body.querySelectorAll("*")

    elements.forEach((el) => {
      // Remove style attributes
      el.removeAttribute("style")

      // Get classes and filter only those that start with "ae-"
      const classList = Array.from(el.classList).filter((cls) =>
        cls.startsWith("ae-")
      )
      el.className = classList.join(" ")
    })

    return doc.body.innerHTML
  }

  // Handle the paste event
  const handlePaste = (event) => {
    event.preventDefault()

    const clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData("text/html")

    // If there is HTML content, sanitize it
    if (pastedData) {
      const sanitizedContent = sanitizeHTML(pastedData)

      // Insert the sanitized content at the cursor position
      document.execCommand("insertHTML", false, sanitizedContent)
    } else {
      // If no HTML content, fallback to plain text
      const textData = clipboardData.getData("text/plain")
      document.execCommand("insertText", false, textData)
    }
  }

  //Init the editor
  useEffect(() => {
    const editor = editorRef.current
    if (editor) editor.addEventListener("paste", handlePaste)

    //Clean up the history
    runCommand("history.clear")

    // Cleanup event listener on component unmount
    return () => {
      if (editor) {
        editor.removeEventListener("paste", handlePaste)
      }
    }
  }, [])

  // Function to generate HTML with raw Tailwind classes
  const getRawHTML = (node, isRoot = true) => {
    if (!node) return ""
    if (node.nodeType === Node.TEXT_NODE) {
      return node.textContent
    }

    const copyAttributes = ["class", "src", "alt", "href"]

    if (node.nodeType === Node.ELEMENT_NODE) {
      const tagName = node.tagName.toLowerCase()
      const attributes = Array.from(node.attributes)
        .filter((attr) => copyAttributes.includes(attr.name))
        .map((attr) => `${attr.name}="${attr.value}"`)
        .join(" ")

      const children = Array.from(node.childNodes)
        .map((child) => getRawHTML(child, false))
        .join("")

      if (isRoot) {
        return `${children}`
      } else {
        return `<${tagName} ${attributes}>${children}</${tagName}>`
      }
    }

    return ""
  }

  const handleTextSelection = () => {
    const selection = window.getSelection()
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      if (!range.collapsed) {
        setSelectionMenu({ visible: true })
      } else {
        setSelectionMenu({ visible: false })
      }
    }
  }

  const insertImage = () => {
    // Run the image plugin with the selection range and setSelectionRange callback
    runCommand("image.add", {
      selectionRange,
      setSelectionRange,
    })
  }

  const insertButton = () => {
    // Run the button plugin with the selection range and setSelectionRange callback
    runCommand("button.add", {
      selectionRange,
      setSelectionRange,
    })
  }

  // Add this function with your other insert functions
  const insertLink = async () => {
    const result = await runCommand("link.add", {
      selectionRange,
      setSelectionRange,
    })

    // If you need to do anything with the result (the created link element)
    if (result) {
      // Optional: do something with the newly created link
    }
  }

  const editHtmlContent = () => {
    setIsHTMLEditorOpen(true)
  }

  const copyHtmlContent = () => {
    const editor = editorRef.current
    if (!editor) return

    // Get the HTML content with the raw Tailwind classes
    const rawHtml = getRawHTML(editor)

    navigator.clipboard
      .writeText(rawHtml)
      .then(() => alert("HTML content copied to clipboard!"))
      .catch((err) => alert("Failed to copy content: " + err))
  }

  const handleImageClick = (event) => {
    if (readOnly) return
    //add the gizmo for the image transformation

    runCommand("image.transformGizmo", event.target)
    runCommand("image.optionsMenu", event.target)
  }

  useEffect(() => {
    const editor = editorRef.current
    if (!editor) return
    editor.addEventListener("mouseup", handleTextSelection)
    editor.addEventListener("keyup", handleTextSelection)
    editor.addEventListener("click", (e) => {
      if (
        e.target.tagName === "IMG" &&
        e.target.classList.contains("editor-image")
      ) {
        handleImageClick(e)
      }
    })

    return () => {
      if (!editor) return
      editor.removeEventListener("mouseup", handleTextSelection)
      editor.removeEventListener("keyup", handleTextSelection)
      editor.removeEventListener("click", handleImageClick)
    }
  }, [])

  // Add the button click handler function
  // Add the button click handler function
  const handleButtonClick = (event) => {
    if (readOnly) return

    // Prevent the default link behavior
    event.preventDefault()

    // Get the actual button element if clicked on wrapper
    const buttonElement = event.target.classList.contains("editor-button")
      ? event.target
      : event.target.querySelector(".editor-button")

    // Show the button options menu
    runCommand("button.optionsMenu", buttonElement)
  }

  useEffect(() => {
    const editor = editorRef.current
    if (!editor) return
    editor.addEventListener("mouseup", handleTextSelection)
    editor.addEventListener("keyup", handleTextSelection)
    editor.addEventListener("click", (e) => {
      if (
        e.target.tagName === "IMG" &&
        e.target.classList.contains("editor-image")
      ) {
        handleImageClick(e)
      }
      if (
        (e.target.tagName === "A" &&
          e.target.classList.contains("editor-button")) ||
        e.target.classList.contains("editor-button-wrapper")
      ) {
        handleButtonClick(e)
      }
    })

    return () => {
      if (!editor) return
      editor.removeEventListener("mouseup", handleTextSelection)
      editor.removeEventListener("keyup", handleTextSelection)
      editor.removeEventListener("click", handleImageClick)
      editor.removeEventListener("click", handleButtonClick)
    }
  }, [])

  // Add this function with your other handlers
  // In your BlogEditor component
  const handleLinkClick = (event) => {
    if (readOnly) return

    // Get the actual link element, even if it's wrapped or aligned
    const linkElement = event.target.closest(".editor-link")
    if (!linkElement || linkElement.classList.contains("editor-button")) return

    event.preventDefault()
    runCommand("link.edit", { target: linkElement })
  }

  // Update your click event listener
  useEffect(() => {
    const editor = editorRef.current
    if (!editor) return

    const handleClick = (e) => {
      if (
        e.target.tagName === "IMG"
        // e.target.classList.contains("editor-image")
      ) {
        handleImageClick(e)
      } else if (
        (e.target.tagName === "A" &&
          e.target.classList.contains("editor-button")) ||
        e.target.classList.contains("editor-button-wrapper")
      ) {
        handleButtonClick(e)
      } else if (
        e.target.closest(".editor-link") || // This will catch aligned links too
        (e.target.tagName === "A" && e.target.classList.contains("editor-link"))
      ) {
        handleLinkClick(e)
      }
    }

    editor.addEventListener("mouseup", handleTextSelection)
    editor.addEventListener("keyup", handleTextSelection)
    editor.addEventListener("click", handleClick)

    return () => {
      if (!editor) return
      editor.removeEventListener("mouseup", handleTextSelection)
      editor.removeEventListener("keyup", handleTextSelection)
      editor.removeEventListener("click", handleClick)
    }
  }, [])

  const handleUpdate = (newContent) => {
    // Update the HTML content in the editor
    editorRef.current.innerHTML = newContent

    // Close the editor
    setIsHTMLEditorOpen(false)
  }

  // Add undo/redo functions
  const undo = () => {
    const restore = runCommand("history.undo")
    restore !== undefined && (editorRef.current.innerHTML = restore)
  }

  const redo = () => {
    const restore = runCommand("history.redo")
    restore && (editorRef.current.innerHTML = restore)
  }

  const save = () => {
    if (readOnly) return
    onSave(editorRef.current.innerHTML)
  }

  const togglePreview = () => {
    runCommand("pagePreview.toggle", {
      getContent: () => editorRef.current.innerHTML,
      openHtmlEditor: editHtmlContent,
    })
  }

  const editor = {
    runCommand,
    insertImage,
    insertLink,
    insertButton,
    copyHtmlContent,
    editHtmlContent,
    undo,
    redo,
    save,
    readOnly,
    togglePreview,
  }

  return (
    <div
      className="min-w-[500px] wysiwyg-editor top-5"
      style={{ position: "relative" }}
    >
      {/* //add title here */}
      <Typography variant="h5" className="text-center mb-5">
        {title}
      </Typography>

      {!readOnly && <Toolbar editor={editor} />}
      <div
        className="text-admin_text editor border border-neutral_1 rounded-[10px] p-2  overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas"
        ref={editorRef}
        contentEditable={!readOnly}
        style={{
          // whiteSpace: "pre-wrap",
          minHeight: "400px",
          height: "80vh",
          position: "relative",
        }}
      ></div>

      {!readOnly && (
        <SelectionMenu
          visible={selectionMenu.visible}
          runCommand={runCommand}
        />
      )}

      <HtmlEditor
        source={editorRef.current?.innerHTML}
        isOpen={isHTMLEditorOpen}
        onUpdate={handleUpdate}
        onClose={() => setIsHTMLEditorOpen(false)}
      />
    </div>
  )
}

export default BlogEditor
