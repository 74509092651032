import { api, generateCRUDInterface } from "aldoo-ra/API"
//construct the endpoint
const crudAPI = api("crud")

//Given the crud API, generate the CRUD interface
const crud = generateCRUDInterface(crudAPI, "StoreProduct")

//override the get method to populate the price
crud.Get = async (get) => {
  get = {
    ...get,
    model: "StoreProduct",
    populate: [
      {
        path: "price", // Populate main product price
      },
      {
        path: "bundle.product", // Populate bundle products
        populate: {
          path: "price", // And their prices
        },
      },
      {
        path: "bundle.price", // Populate bundle item price overrides
      },
    ],
    //filter out deleted items
    ...{ match: { ...get.match, deleted: { $ne: true } } },
  }
  return await crudAPI({ get })
}

export default crud
