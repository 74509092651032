//This is an alias to the config in the root project folder
import config from "../../config"
import deployment from "../../deployment"

// Helper function to recursively merge nested objects
const mergeDeep = (target, source) => {
  for (const key of Object.keys(source)) {
    //if the key is an object and both source and target are objects
    if (source[key] instanceof Object && target[key] instanceof Object) {
      mergeDeep(target[key], source[key])
      continue
    }
    //no need to check if target[key] is an object because it will be overwritten
    target[key] = source[key]
  }
}

//Apply deployment
mergeDeep(config, deployment)

export default config
