import React, { useState, useEffect, useRef } from "react"
import {
  XMarkIcon,
  Bars3Icon,
  PencilIcon,
  FolderIcon,
} from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import ContentEditor from "../content-editor/index"
import TypeResolver from "../type-resolver"
import Modal from "aldoo-ra/Modal"
import Input from "aldoo-ra/Input"
import Controller from "../controllers/cms-controller"
import { useFieldDragController } from "../controllers/field-drag-controller"
import ContentSelectorDialog from "../dialogs/content-selector-dialog"
import { showNotification } from "aldoo-ra/Notification"
import { previewTitle } from "../preview"

const applyIValues = (targetObject, content) => {
  //no need to apply IValues if they are not present
  if (!content.ivalues || !Object.keys(content.ivalues).length || !targetObject)
    return
  //get the key
  const key = targetObject._id.toString()
  //get the values
  const values = content.ivalues[key]
  //if there are no values, return
  if (!values) return
  //apply the values
  Object.assign(targetObject.content, values)
}

export default function ArrayContentEditor({
  onChange,
  value,
  field,
  parentValues,
  parentObject,
}) {
  // Holds the local list of array values
  const [localValue, setLocalValue] = useState()
  // Shows the type editor dialog for the selected item
  const [showContentEditorDialog, setShowContentEditorDialog] = useState(false)
  // Holds the index of the selected item
  const [selectedIndex, setSelectedIndex] = useState(null)
  // This will hold our selected item instance
  const [selectedInstance, setSelectedInstance] = useState(null)
  // This will hold refs to the input controls that define the item labels in the control render
  const inputRefs = useRef([])
  // Get a content type definition by name
  const { getContentType } = TypeResolver()
  //Content selector Dialog
  const [showContentDialog, setShowContentDialog] = useState(false)
  // This is the CMS controller that will save / load the content instances
  const controller = Controller()
  // Drag controller support
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, setLocalValue)

  const loadingInstances = useRef(false)

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  // Load content instances for array items from data array
  const loadItemInstances = async (data) => {
    if (loadingInstances.current) return

    loadingInstances.current = true

    const loadedItems = await Promise.all(
      data.map(async (item) => {
        if (item._id) {
          const result = await controller.getContent({
            match: { _id: item._id },
          })
          //apply the ivalues
          applyIValues(result.data[0], parentValues())
          const content = result.data[0]?.content
          if (!content) return item

          return {
            _id: item._id,
            itemLabel: previewTitle(result.data[0]),
            ...result.data[0],
          }
        }
        return item
      })
    )

    setLocalValue(loadedItems)

    loadingInstances.current = false
  }

  useEffect(() => {
    if (!value || !value.length) {
      if (field.type === "Content" && !localValue) {
        setLocalValue([{ _id: null, itemLabel: "" }])
      }
      return
    }

    //Calculate the new value
    const newValue = localValue?.map((item) => ({
      _id: item._id,
      label:
        item.content?.name || item.content?.title || item.content?.label || "",
    }))

    //check if the local value is the same as the new value
    if (
      localValue &&
      value &&
      JSON.stringify(localValue) === JSON.stringify(newValue)
    )
      return

    loadItemInstances(value)
  }, [value, field])

  useEffect(() => {
    if (!localValue) return

    //if localValue is empty array allow it trigger change
    if (localValue.length === 0) return onChange([])

    const newValue = localValue.map((item) => ({
      _id: item._id,
      label:
        item.content?.name || item.content?.title || item.content?.label || "",
    }))

    //the new value is invalid
    if (!newValue[0]?._id) return

    // Check if the local value is different from the parent value
    if (JSON.stringify(newValue) === JSON.stringify(value)) return

    // Update parent state whenever localValue changes
    onChange(newValue)
  }, [localValue])

  // Handle value changes from an input to the label
  const handleLabelChange = (updatedValue, index) => {
    const updatedValues = [...localValue]

    if (updatedValues[index]) {
      updatedValues[index].itemLabel = updatedValue
      // If the item has content, also update the name in the content
      if (
        updatedValues[index].content &&
        Object.keys(updatedValues[index].content).includes("name")
      ) {
        updatedValues[index].content.name = updatedValue
      }
    }
    setLocalValue(updatedValues)
  }

  const loadContent = (index) => {
    setSelectedIndex(index) // Track the selected index
    setShowContentDialog(true)
  }

  // Add a new input for an array item
  const addNewInput = async () => {
    setLocalValue((prevValue) => {
      const newValue = [...(prevValue || []), { _id: null, itemLabel: "" }]
      // Focus the new input in the next tick
      setTimeout(() => {
        inputRefs.current[newValue.length - 1]?.focus()
      }, 10)
      return newValue
    })
  }

  // Remove an input from the array by index
  const removeInput = (index) => {
    if (localValue.length === 1) {
      setLocalValue([])
    } else {
      const updatedValues = localValue.filter((_, i) => i !== index)
      setLocalValue(updatedValues)
    }
  }

  // Open modal for editing selected array item
  const handleEdit = (index) => {
    const selectedItem = localValue[index]
    if (!selectedItem) return
    setSelectedIndex(index) // Track the selected index
    setSelectedInstance(selectedItem)
    setShowContentEditorDialog(true)
  }

  // Close the edit modal
  const handleCloseDialog = () => {
    setShowContentEditorDialog(false)
    setSelectedInstance(null)
    setSelectedIndex(null)
  }

  // When the save button in the type editor is clicked
  const handleSaveItem = async (content) => {
    const updatedValues = [...localValue]
    //remove the itemLabel from the content
    delete content.itemLabel
    delete content._id

    const payload = {
      //if we have a selectedInstance, add it to the payload
      ...selectedInstance,
      content: { type: field.item.type, ...content },
    }

    //delete the _id if it is null since the db will also return _id as null
    if (payload._id === null) delete payload._id

    // Create a new instance if it doesn't already exist
    const newInstance = await controller.saveContent({
      payload,
    })

    const _id = newInstance.result

    updatedValues[selectedIndex] = {
      _id,
      content,
      itemLabel: previewTitle({ content }),
    }

    //handle the values marked with instance
    for (const itemField of field.item.fields) {
      //skip if the field is not marked as instance
      if (!itemField.instance) continue
      //if this is an instance field, save the content to the parentValues ivalues
      const pValues = parentValues()
      pValues.ivalues = pValues.ivalues || {}
      //use the instance id as the primary key for the ivalues
      pValues.ivalues[_id] = pValues.ivalues[_id] || {}
      //save the value
      pValues.ivalues[_id][itemField.id] = content[itemField.id]
    }

    setLocalValue(updatedValues)
    handleCloseDialog()
  }

  const renderItem = (item, index) => (
    <div
      key={index}
      className="mt-2 gap-2 flex flex-row items-center w-full"
      draggable
      onDragStart={() => handleDragStart(index)}
      onDragOver={(e) => {
        e.preventDefault()
        handleDragOver(index)
      }}
      onDragEnd={handleDragEnd}
    >
      {localValue.length > 1 && (
        <Bars3Icon className="w-5 h-5 cursor-move text-gray-500 mr-2" />
      )}

      <Input
        ref={(el) => (inputRefs.current[index] = el)}
        multiline={field.multiline}
        labelClassName="text-admin_text"
        className="flex-grow text-admin_text"
        value={item?.itemLabel || ""}
        onChange={(e) => handleLabelChange(e.target.value, index)}
      />

      <Button
        variant="text"
        className="ml-5 text-admin_text"
        onClick={() => handleEdit(index)}
      >
        <PencilIcon className="mx-auto w-6 h-6" />
      </Button>

      {/* Load existing content button */}
      <Button
        variant="text"
        className="ml-5 text-admin_text"
        onClick={() => loadContent(index)} // New button to load existing content
      >
        <FolderIcon className="mx-auto w-6 h-6" />
      </Button>

      <Button
        type="outline"
        className="text-admin_text  hover:text-white hover:bg-red-700 rounded-full p-2 ml-2"
        onClick={() => {
          removeInput(index)
        }}
      >
        <XMarkIcon className="w-6 h-6" />
      </Button>
    </div>
  )

  const resolveContentType = (instance) => {
    //the field.item is already a content type
    if (instance && field.item?.type) return field.item

    const typeName =
      instance?.content?.type ||
      instance?.type?.value ||
      instance?.type ||
      field.item
    return getContentType(typeName)
  }

  const getFieldValues = (instance) => {
    if (instance?.content) return { ...instance.content, _id: instance._id }

    if (Object.keys(instance).includes("name") && !instance.name)
      instance.name = instance.itemLabel

    return instance
  }

  return (
    <div className="array-editor">
      <span className="ml-10 text-sm text-admin_text">
        {localValue?.length} {localValue?.length === 1 ? "item" : "items"}
      </span>

      {localValue?.map((item, index) => renderItem(item, index))}

      <div className="flex flex-row justify-between items-center mt-4">
        <div className="flex flex-col">
          <label className="block font-semibold text-admin_text">
            {field.label}
            {isRequired && <span className="ml-2 text-error">(required)</span>}
          </label>
          {field.description && (
            <label className="block text-sm text-admin_text">
              {field.description}
            </label>
          )}
        </div>

        {
          <div className="flex flex-grow justify-end items-center gap-5 w-[55%]">
            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={addNewInput}
            >
              Add Item
            </Button>

            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={() => setLocalValue([])}
            >
              Clear All
            </Button>
          </div>
        }
      </div>

      {/* Select content instance  */}
      {showContentDialog && (
        <ContentSelectorDialog
          parentValues={parentValues}
          //it's either a content type or a type name string
          type={field.item.type || field.item}
          isOpen={showContentDialog}
          onClose={() => setShowContentDialog(false)}
          onSelect={(item) => {
            //find the selected index and set the content
            const updatedValues = [...localValue]

            updatedValues[selectedIndex] = {
              _id: item._id,
              content: item.content,
              itemLabel:
                item.content.name ||
                item.content.title ||
                item.content.label ||
                "",
            }

            //update the local value
            setLocalValue(updatedValues)
          }}
        />
      )}

      {showContentEditorDialog && (
        <Modal
          isOpen={showContentEditorDialog}
          onClose={handleCloseDialog}
          closeOnEscape
          className="md:w-[80%] sm:w-[500px] pb-10 md:absolute md:top-[65px] h-full md:h-auto bg-admin_canvas text-sm sm:text-base z-200"
        >
          <ContentEditor
            enableFieldSettings={!!selectedInstance?.content}
            dataItem={selectedInstance}
            title={`Edit ${selectedInstance?.itemLabel ?? "Item"}`}
            contentType={resolveContentType(selectedInstance)}
            fieldValues={getFieldValues(selectedInstance)}
            parentValues={parentValues}
            onSave={handleSaveItem}
            onClickSave={handleCloseDialog}
            onError={(errors) => {
              showNotification({
                message: Object.values(errors).find((e) => e),
                className: "bg-red-500 text-white",
              })
            }}
          />
        </Modal>
      )}
    </div>
  )
}
