import React, { useState, useEffect, useRef } from "react"
import parse from "html-react-parser"
import {
  ComputerDesktopIcon,
  DevicePhoneMobileIcon,
  CodeBracketIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import Menu from "aldoo-ra/Menu"

const PREVIEW_MODES = {
  DESKTOP: { name: "desktop", width: 1280 },
}

const PreviewDialog = ({ content, onClose, openHtmlEditor }) => {
  const [previewMode, setPreviewMode] = useState(PREVIEW_MODES.DESKTOP)
  const dialogRef = useRef(null)
  const contentRef = useRef(null)

  // Handle escape key
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === "Escape") {
        onClose()
      }
    }
    document.addEventListener("keydown", handleEscape)
    return () => document.removeEventListener("keydown", handleEscape)
  }, [onClose])

  // Handle clicking outside dialog
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [onClose])

  // Update content
  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.innerHTML = content || ""
    }
  }, [content])

  const handleSourceView = () => {
    onClose()
    openHtmlEditor()
  }

  const getPreviewTitle = () => {
    if (previewMode.name === "desktop") {
      return "Desktop Preview (Full Width)"
    }
    return `${previewMode.label} (${previewMode.width}x${previewMode.height})`
  }

  const render = (item) => {
    return (
      <div className="flex flex-row justify-between items-center gap-2 w-full p-2 hover:bg-gray-100">
        <div className="flex items-center gap-2">
          {item.icon}
          <span>{item.label}</span>
        </div>
        {item.options?.device && (
          <span className="text-sm text-gray-400">
            {item.options.device.width}x{item.options.device.height}
          </span>
        )}
      </div>
    )
  }

  const menuData = {
    label: "Mobile Devices",
    render,
    icon: <DevicePhoneMobileIcon className="w-5 h-5" />,
    items: [
      {
        label: "Phones",
        render,
        items: [
          {
            label: "iPhone 14",
            render,
            options: {
              action: "selectDevice",
              device: { label: "iPhone 14", width: 390, height: 844 },
            },
          },
          {
            label: "iPhone 14 Pro Max",
            render,
            options: {
              action: "selectDevice",
              device: { label: "iPhone 14 Pro Max", width: 430, height: 932 },
            },
          },
          {
            label: "Pixel 7",
            render,
            options: {
              action: "selectDevice",
              device: { label: "Pixel 7", width: 412, height: 915 },
            },
          },
          {
            label: "Samsung S23",
            render,
            options: {
              action: "selectDevice",
              device: { label: "Samsung S23", width: 360, height: 780 },
            },
          },
        ],
      },
      {
        label: "Tablets",
        render,
        items: [
          {
            label: "iPad",
            render,
            options: {
              action: "selectDevice",
              device: { label: "iPad", width: 820, height: 1180 },
            },
          },
          {
            label: "iPad Pro",
            render,
            options: {
              action: "selectDevice",
              device: { label: "iPad Pro", width: 1024, height: 1366 },
            },
          },
          {
            label: "Samsung Tab S8",
            render,
            options: {
              action: "selectDevice",
              device: { label: "Samsung Tab S8", width: 1440, height: 900 },
            },
          },
        ],
      },
    ],
  }

  const actionMap = {
    selectDevice: ({ options }) => {
      setPreviewMode({ name: "mobile", ...options.device })
    },
  }

  return (
    <div
      className="fixed inset-0 bg-black/80 flex items-center justify-center backdrop-blur-sm"
      style={{ zIndex: 1100 }}
    >
      <div
        ref={dialogRef}
        className="bg-gray-900 w-full h-full flex flex-col text-white"
      >
        {/* Toolbar */}
        <div className="flex items-center justify-between p-4 border-b border-gray-700">
          {/* Left side - empty to maintain centering */}
          <div className="w-[156px]"></div>

          {/* Center - Title */}
          <h2 className="text-lg font-medium text-gray-200">
            {getPreviewTitle()}
          </h2>

          {/* Right side - buttons */}
          <div className="flex items-center gap-4">
            <button
              onClick={() => setPreviewMode(PREVIEW_MODES.DESKTOP)}
              className={`p-2 rounded transition-colors ${
                previewMode.name === "desktop"
                  ? "bg-gray-700 text-white"
                  : "text-gray-400 hover:bg-gray-800 hover:text-white"
              }`}
              title="Desktop view"
            >
              <ComputerDesktopIcon className="w-5 h-5" />
            </button>

            <div className="relative">
              <Menu
                menuData={menuData}
                actionMap={actionMap}
                renderAs="vlist.menu"
                itemWidth="250px"
                submenuMode="vertical"
                menuAlign="right"
                rootItemRender={(item) => (
                  <div className="flex flex-row justify-between items-center gap-2 w-full p-2">
                    <div className="flex items-center gap-2">{item.icon}</div>
                  </div>
                )}
                itemRender={(item) => (
                  <div className="flex flex-row justify-between items-center gap-2 w-full p-2 hover:bg-gray-100">
                    <div className="flex items-center gap-2">
                      {item.icon}
                      <span>{item.label}</span>
                    </div>
                    {item.options?.device && (
                      <span className="mr-5 text-sm text-gray-400">
                        {item.options.device.width}x{item.options.device.height}
                      </span>
                    )}
                  </div>
                )}
              />
            </div>

            <button
              onClick={handleSourceView}
              className="p-2 rounded text-gray-400 hover:bg-gray-800 hover:text-white transition-colors"
              title="View source"
            >
              <CodeBracketIcon className="w-5 h-5" />
            </button>

            <button
              onClick={onClose}
              className="p-2 rounded text-gray-400 hover:bg-gray-800 hover:text-white transition-colors"
              title="Close preview"
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Preview Content */}
        <div className="flex-1 overflow-auto bg-gray-800 p-4">
          <div className="flex justify-center min-h-full">
            <div
              style={{
                width:
                  previewMode.name === "desktop"
                    ? "100%"
                    : `${previewMode.width}px`,
                maxWidth:
                  previewMode.name === "desktop"
                    ? "72rem"
                    : `${previewMode.width}px`,
                height:
                  previewMode.name === "mobile"
                    ? `${previewMode.height}px`
                    : "auto",
              }}
              className="bg-white shadow-xl rounded-lg transition-all duration-300 h-full"
            >
              <div
                ref={contentRef}
                className="p-6 text-gray-900 overflow-auto h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreviewDialog
