import React, { useState, useEffect, useRef } from "react"
import Papa from "papaparse"
import Button from "aldoo-ra/Button"
import { postFormatFields } from "../field-formatter"
import Controller from "../controllers/cms-controller"
import Modal from "aldoo-ra/Modal"
import Checkbox from "../../Checkbox"
import generateLocalizationKey from "../generate-localization-key"
import LocalizationController from "../../Localization/localization-controller"
import { postFormatValue } from "../field-formatters/file-with-type"
import ProgressDialog from "../../ProgressDialog"

// String similarity function (Levenshtein distance or similar heuristic)
const stringSimilarity = (str1, str2) => {
  if (!str1 || !str2) return 0

  str1 = str1.toLowerCase()
  str2 = str2.toLowerCase()

  let longer = str1
  let shorter = str2
  if (str1.length < str2.length) {
    longer = str2
    shorter = str1
  }

  const longerLength = longer.length
  if (longerLength === 0) return 1.0

  const editDistance = (longer, shorter) => {
    let costs = []
    for (let i = 0; i <= longer.length; i++) {
      let lastValue = i
      for (let j = 0; j <= shorter.length; j++) {
        if (i === 0) costs[j] = j
        else {
          if (j > 0) {
            let newValue = costs[j - 1]
            if (longer.charAt(i - 1) !== shorter.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1
            costs[j - 1] = lastValue
            lastValue = newValue
          }
        }
      }
      if (i > 0) costs[shorter.length] = lastValue
    }
    return costs[shorter.length]
  }

  return (longerLength - editDistance(longer, shorter)) / longerLength
}

const ContentImportDialog = ({ contentType, isOpen, onClose, onImport }) => {
  const [csvData, setCsvData] = useState(null)
  const [fieldMappings, setFieldMappings] = useState({})
  const [localizedFields, setLocalizedFields] = useState({}) // New state for localization
  const [error, setError] = useState(null)
  const [csvText, setCsvText] = useState("") // New state for pasted CSV data
  const controller = Controller()
  const localizationController = LocalizationController()
  const shouldBreakSave = useRef(false)

  const [totalItemsCount, setTotalItemsCount] = useState(0)
  const [currentProcessingItemIndex, setCurrentProcessingItemIndex] = useState()

  // Load CSV data from file
  const handleFileUpload = (event) => {
    const file = event.target.files[0]
    if (!file) return

    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (result) => {
        setCsvData(result.data)
        autoMapFields(result.data)
      },
      error: (err) => {
        setError("Error parsing CSV")
        console.error(err)
      },
    })
  }

  // Load CSV data from pasted text
  const handlePasteCsv = () => {
    if (!csvText) return

    Papa.parse(csvText, {
      header: true,
      dynamicTyping: true,
      complete: (result) => {
        setCsvData(result.data)
        autoMapFields(result.data)
      },
      error: (err) => {
        setError("Error parsing CSV")
        console.error(err)
      },
    })
  }

  // Automatically map CSV headers to content fields based on similarity
  const autoMapFields = (parsedData) => {
    const csvHeaders = Object.keys(parsedData[0])
    const newFieldMappings = {}

    contentType.fields.forEach((field) => {
      let bestMatch = ""
      let bestScore = 0

      csvHeaders.forEach((csvHeader) => {
        const similarityScore = stringSimilarity(field.label, csvHeader)
        if (similarityScore > bestScore) {
          bestMatch = csvHeader
          bestScore = similarityScore
        }
      })

      if (bestScore > 0.5) {
        // Only auto-map if similarity is above a certain threshold
        newFieldMappings[field.id] = bestMatch
      }
    })

    setFieldMappings(newFieldMappings)
  }

  // Handle field mapping changes manually
  const handleFieldMappingChange = (contentField, csvField) => {
    setFieldMappings((prev) => ({
      ...prev,
      [contentField]: csvField,
    }))
  }

  // Handle localization toggle
  const handleLocalizationToggle = (fieldId) => {
    setLocalizedFields((prev) => ({
      ...prev,
      [fieldId]: !prev[fieldId], // Toggle the localization state
    }))
  }

  const saveLocalizationForItem = async (item, instanceID) => {
    //go through all fields that need localization
    for (const fieldID in localizedFields) {
      //no need to localize if the field is not localized
      if (!localizedFields[fieldID]) continue

      const field = contentType.fields.find((field) => field.id === fieldID)

      //generate a localization key
      const localizationKey = generateLocalizationKey({
        type: contentType.type,
        instanceID,
        fieldID,
      })
      //save the localization item
      await localizationController.saveLocalizationItem(
        {
          key: localizationKey,
          language: localizationController.primaryLanguage,
          content: postFormatValue(item[fieldID]),
          type: field.type.toLowerCase(),
        },
        //don't show notifications
        false
      )
    }
  }

  const cancelSave = () => {
    shouldBreakSave.current = true
    // Reset progress
    setTotalItemsCount(0)
    setCurrentProcessingItemIndex(0)
  }

  const save = async (data) => {
    // reset break the save
    shouldBreakSave.current = false

    const { type } = contentType

    setTotalItemsCount(data.length)

    // Save each content item
    for (const item of data) {
      //break if the user cancels the save
      if (shouldBreakSave.current) break

      setCurrentProcessingItemIndex(data.indexOf(item))

      const response = await controller.saveContent(
        {
          payload: {
            content: { type, ...item },
          },
        },
        //don't show notifications
        false
      )
      //break in case of an error
      if (response.error) return setError("Error saving content")

      //no localization to process
      if (!Object.keys(localizedFields).length) continue
      //break if the user cancels the save
      if (shouldBreakSave.current) break
      const instanceID = response.result
      //save the localization
      await saveLocalizationForItem(item, instanceID)
      //update the localization array in the item
      const localization = Object.keys(localizedFields).map((fieldID) => ({
        field: fieldID,
        localizationKey: generateLocalizationKey({
          type,
          instanceID,
          fieldID,
        }),
      }))
      //break if the user cancels the save
      if (shouldBreakSave.current) break
      //save the item again
      await controller.saveContent({
        payload: {
          localization,
          _id: instanceID,
          content: { type, ...item },
        },
      })
    }

    // If all data is saved successfully, close the dialog
    onClose()

    //reset the break
    shouldBreakSave.current = false
  }

  // Perform import
  const handleImport = () => {
    if (!csvData || !contentType) {
      setError("No CSV data or content type available")
      return
    }

    // Map CSV rows to content type instances
    const mappedContent = csvData.map((row) => {
      const contentInstance = {}
      contentType.fields.forEach((field) => {
        const csvField = fieldMappings[field.id]

        if (csvField && csvField !== "dont-import" && row[csvField]) {
          contentInstance[field.id] = row[csvField]
        }
      })

      return postFormatFields(contentInstance, contentType)
    })

    //filter empty objects
    const filteredContent = mappedContent.filter(
      (item) => Object.keys(item).length
    )

    if (onImport) onImport(filteredContent)

    save(filteredContent)
  }

  if (!isOpen) return null

  return (
    <Modal
      className="w-[320px] sm:w-[500px] bg-admin_paper text-admin_text text-sm sm:text-base p-4 shadow-lg"
      zIndex={500}
      isOpen={isOpen}
      onClose={onClose}
      title={`Import ${contentType.type}`}
    >
      {/* File Upload Input */}
      <div className="mb-4">
        <input
          type="file"
          accept=".csv"
          onChange={handleFileUpload}
          className="border border-neutral_1 p-2"
        />
      </div>

      {/* Text Area for Pasting CSV Data */}
      <div className="mb-4">
        <textarea
          placeholder="Paste CSV data here"
          className="w-full h-32 p-2 border border-neutral_1 bg-admin_canvas"
          value={csvText}
          onChange={(e) => setCsvText(e.target.value)}
        ></textarea>
        <Button variant="outlined" onClick={handlePasteCsv} className="mt-2">
          Parse Pasted CSV
        </Button>
      </div>

      {/* Field Mapping Section */}
      {!!csvData && (
        <div>
          <h3 className="font-medium mb-2">Map CSV Fields to Content Fields</h3>
          <table className="min-w-full mb-4">
            <thead>
              <tr>
                <th className="text-left">Content Field</th>
                <th className="text-left">CSV Field</th>
                <th className="text-left">Localized</th>
                {/* New column for localization */}
              </tr>
            </thead>
            <tbody>
              {contentType.fields.map((field) => (
                <tr key={field.id}>
                  <td className="border border-neutral_1 p-2">{field.label}</td>
                  <td className="border border-neutral_1 p-2">
                    <select
                      value={fieldMappings[field.id] || ""}
                      onChange={(e) =>
                        handleFieldMappingChange(field.id, e.target.value)
                      }
                      className="w-full bg-admin_canvas text-admin_text border border-neutral_1 p-1"
                    >
                      <option value="">Select CSV field</option>
                      <option value="dont-import">Don't Import</option>{" "}
                      {/* Add Don't Import option */}
                      {Object.keys(csvData[0]).map((csvField) => (
                        <option key={csvField} value={csvField}>
                          {csvField}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td className="border border-neutral_1 p-2 text-center">
                    {(field.type === "Text" ||
                      field.type === "Image" ||
                      field.type === "File") && (
                      <Checkbox
                        isOn={!!localizedFields[field.id]}
                        onChange={() => handleLocalizationToggle(field.id)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {error && <p className="text-red-500">{error}</p>}

      {/* Import and Cancel Buttons */}
      <div className="flex justify-end mt-4">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="action" onClick={handleImport} className="ml-4">
          Import
        </Button>
      </div>

      {/* Progress Dialog */}
      {!!totalItemsCount && (
        <ProgressDialog
          isOpen={totalItemsCount}
          progress={currentProcessingItemIndex / totalItemsCount}
          title="Importing content"
          onCancel={cancelSave}
          message={`Importing item ${
            currentProcessingItemIndex + 1
          } of ${totalItemsCount}`}
        />
      )}
    </Modal>
  )
}

export default ContentImportDialog
