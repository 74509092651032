// svgCache.js
const SVGCache = {
    cache: new Map(),
    enabled: true,
    benchmark: false,
    totalTimeSaved: 0,
    fetchTimes: new Map(), // Store fetch times for each URL

    setEnabled(enabled) {
        this.enabled = enabled;
        if (!enabled) {
            this.clearCache();
        }
    },

    setBenchmark(enabled) {
        this.benchmark = enabled;
        if (!enabled) {
            this.totalTimeSaved = 0;
            this.fetchTimes.clear();
        }
    },

    getStats() {
        return {
            totalTimeSaved: this.totalTimeSaved,
            cacheSize: this.cache.size,
            averageSaving: this.cache.size ? this.totalTimeSaved / this.cache.size : 0
        };
    },

    async getSVG(url, recolor, useCache = true) {
        // Start timing only the actual execution, not the promise creation
        const processStart = performance.now();

        const cacheKey = `${url}-${recolor}`;

        // Skip cache if globally disabled or instance disabled
        if (!this.enabled || !useCache) {
            const svgText = await this.fetchAndProcessSVG(url, recolor);

            if (this.benchmark) {
                const processEnd = performance.now();
                const processTime = processEnd - processStart;
                this.fetchTimes.set(cacheKey, processTime);
                console.log(`⏱️ Fresh fetch and process for ${url}: ${processTime.toFixed(2)}ms`);
            }

            return svgText;
        }

        // Check cache
        if (this.cache.has(cacheKey)) {
            if (this.benchmark) {
                const processEnd = performance.now();
                const cacheTime = processEnd - processStart;
                const originalTime = this.fetchTimes.get(cacheKey) || 0;
                const timeSaved = Math.max(0, originalTime - cacheTime); // Ensure we don't get negative savings
                this.totalTimeSaved += timeSaved;

                console.log(`
🚀 Cache hit for ${url}
   Original process time: ${originalTime.toFixed(2)}ms
   Cache retrieval time: ${cacheTime.toFixed(2)}ms
   Time saved: ${timeSaved.toFixed(2)}ms
   Total time saved: ${this.totalTimeSaved.toFixed(2)}ms
`);
            }
            return this.cache.get(cacheKey);
        }

        // Fetch, process and cache
        const svgText = await this.fetchAndProcessSVG(url, recolor);

        if (svgText) {
            this.cache.set(cacheKey, svgText);

            if (this.benchmark) {
                const processEnd = performance.now();
                const processTime = processEnd - processStart;
                this.fetchTimes.set(cacheKey, processTime);
                console.log(`📥 Cached new SVG ${url}: ${processTime.toFixed(2)}ms`);
            }
        }

        return svgText;
    },

    async fetchAndProcessSVG(url, recolor) {
        try {
            // Start timing the fetch operation
            const fetchStart = performance.now();
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch SVG: ${response.statusText}`);
            }

            let svgText = await response.text();
            const fetchEnd = performance.now();

            if (this.benchmark) {
                console.log(`🌐 Network fetch time for ${url}: ${(fetchEnd - fetchStart).toFixed(2)}ms`);
            }

            // Start timing the processing operation
            const processStart = performance.now();

            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
            const svgElement = svgDoc.documentElement;

            svgElement.setAttribute("width", "100%");
            svgElement.setAttribute("height", "100%");

            if (recolor) {
                const allElements = svgElement.querySelectorAll("*");
                allElements.forEach((element, idx) => {
                    element.hasAttribute("fill") && element.setAttribute("fill", "currentColor");
                    element.hasAttribute("stroke") && element.setAttribute("stroke", "currentColor");
                });
            }

            const result = new XMLSerializer().serializeToString(svgElement);

            if (this.benchmark) {
                const processEnd = performance.now();
                console.log(`⚙️ SVG processing time for ${url}: ${(processEnd - processStart).toFixed(2)}ms`);
            }

            return result;
        } catch (error) {
            console.error("Error fetching SVG:", error);
            return null;
        }
    },


    clearCache() {
        this.cache.clear();
        if (this.benchmark) {
            console.log(`🗑️ Cache cleared. Total time saved before clear: ${this.totalTimeSaved.toFixed(2)}ms`);
        }
    },

    removeFromCache(url, recolor) {
        const cacheKey = `${url}-${recolor}`;
        this.cache.delete(cacheKey);
        this.fetchTimes.delete(cacheKey);
    }
};

export default SVGCache;