import { wrapSelection } from "../../tools"
import TransformGizmo from "./transform-gizmo"
import OptionsMenu from "./options-menu"

const DEFAULT_IMAGE_CONFIG = {
  maxWidth: "100%",
  display: "block",
  margin: "1rem auto",
  width: "50%",
  objectFit: "contain",
  borderRadius: "0.375rem",
}

const createImageElement = (url, alt = "") => {
  const img = document.createElement("img")
  img.src = url
  img.alt = alt || "Inserted image"
  img.classList.add("editor-image")

  // Apply default styles
  Object.entries(DEFAULT_IMAGE_CONFIG).forEach(([key, value]) => {
    img.style[key] = value
  })

  return img
}

const insertImage = (img, selectionRange, setSelectionRange) => {
  if (!selectionRange) {
    const editor = document.querySelector(".editor")
    editor.appendChild(img)
    const range = document.createRange()
    range.setStartAfter(img)
    range.setEndAfter(img)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    setSelectionRange(range)
    return
  }

  const range = selectionRange.cloneRange()
  range.deleteContents()
  range.insertNode(img)
  range.setStartAfter(img)
  range.setEndAfter(img)
  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
  setSelectionRange(range)
}

const addImage = async (data) => {
  const { selectionRange, setSelectionRange, options = {} } = data

  // Handle different image sources
  let url
  if (options.url) {
    url = options.url
  } else if (options.uploadFile) {
    // Handle file upload if implemented
    url = await options.uploadFile()
  } else {
    // Default to random image
    url = `https://picsum.photos/800/600?random=${Math.floor(
      Math.random() * 1000
    )}`
  }

  if (!url) return

  const img = createImageElement(url, options.alt)
  insertImage(img, selectionRange, setSelectionRange)

  // Add caption if provided
  if (options.caption) {
    const captionElement = document.createElement("div")
    captionElement.textContent = options.caption
    captionElement.className = "image-caption text-center text-sm mt-2"
    img.insertAdjacentElement("afterend", captionElement)
  }

  return img
}

const replaceImage = (data) => {
  const { url, selectedImage } = data
  if (!selectedImage) return
  // Replace the image
  selectedImage.src = url
}

const deleteImage = (data) => {
  const { selectedImage } = data
  if (!selectedImage) return
  // Remove the image
  selectedImage.remove()
}

const setSize = (data) => {
  const { selectedImage, size } = data
  if (!selectedImage) return
  // Update the image size
  selectedImage.style.width = `${size}`
  //set to contain
  selectedImage.style.objectFit = "cover"
}

const updateImageAlignment = (data) => {
  const { selectedImage, alignment } = data
  if (!selectedImage) return

  // Update image styles based on alignment
  switch (alignment) {
    case "left":
      selectedImage.style.float = "left"
      selectedImage.style.marginRight = "1rem"
      selectedImage.style.marginLeft = "0"
      break
    case "center":
      selectedImage.style.float = "none"
      selectedImage.style.margin = "1rem auto"
      selectedImage.style.display = "block"
      break
    case "right":
      selectedImage.style.float = "right"
      selectedImage.style.marginLeft = "1rem"
      selectedImage.style.marginRight = "0"
      break
  }

  // Handle caption alignment if exists
  const caption = selectedImage.nextElementSibling
  if (caption?.classList.contains("image-caption")) {
    caption.style.textAlign = alignment
  }
}

const Image = ({ action, data }) => {
  switch (action) {
    case "add":
      return addImage(data)
    case "replace":
      return replaceImage(data)
    case "delete":
      return deleteImage(data)
    case "align":
      return updateImageAlignment(data)
    case "size":
      return setSize(data)
    case "transformGizmo":
      return TransformGizmo(data)
    case "optionsMenu":
      return OptionsMenu(data)
    default:
      console.warn(`Unknown image action: ${action}`)
      return null
  }
}

// Add static configuration
Image.config = DEFAULT_IMAGE_CONFIG

// Add utility functions
Image.utils = {
  createImageElement,
  insertImage,
}

export default Image
