import useAuth, { persistKey } from "./actions/auth"
import BreakAuth from "./actions/break-await-auth"
import useSigninWithProvider from "./actions/signin-with-provider"
import useLogout from "./actions/logout"
import useProfile from "./actions/profile"

const { SetBreakAwaitAuth } = BreakAuth

//Export the interface
export default {
  useAuth,
  persistKey,
  SetBreakAwaitAuth,
  useSigninWithProvider,
  useLogout,
  useProfile,
}
