export default {
  big: {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30",
    container:
      "w-full md:w-[755px] h-full md:h-auto md:max-h-[calc(100vh-2rem)] bg-theme_white overflow-hidden md:rounded-[24px] pt-8 md:pt-12 pb-8",
    header: "relative flex justify-between items-center px-4 md:px-10 mb-8",
    closeButton: "absolute right-4 md:right-10 lg:right-10 top-1 z-10",
    closeIcon: "h-7 w-7 text-gray_2 hover:text-gray_1",
    title:
      "flex-1 text-[24px] md:text-[28px] xl:text-[32px] font-bold text-text leading-tight my-0",
    headerContent: "flex items-center",
    contentWrapper:
      "w-full max-h-[calc(100vh-10rem)] overflow-y-auto px-4",
    content: "w-full md:px-6 md:pb-4",
  },
  
  //Used for Success, MagicLink, Provider, etc.
  medium: {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30",
    container:
      "w-full md:w-[755px] h-full md:h-auto md:max-h-[calc(100vh-2rem)] bg-theme_white md:rounded-[24px] py-8",
    header: "relative flex justify-between items-center px-10 mb-0",
    closeButton: "absolute right-6 md:right-8 top-1 z-10",
    closeIcon: "h-7 w-7 text-gray_2 hover:text-gray_1",
    title:
      "flex-1 text-[24px] md:text-[28px] xl:text-[32px] font-bold text-text text-center md:text-left leading-tight mt-[100px] mb-0 md:my-0",
    headerContent: "flex items-center",
    contentWrapper:
      "w-full max-h-[calc(100vh-10rem)] overflow-y-auto px-4",
    content: "w-full md:px-6 md:py-4",
  },

  wide: {
    overlay:
      "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30",
    container:
      "bg-theme_white overflow-hidden rounded-[24px] py-0 md:mx-4",
    header: "relative flex justify-between items-center",
    closeButton: "absolute top-4 right-4 z-10",
    closeIcon: "h-7 w-7 text-gray_2 hover:text-gray_1",
    title: "flex-1 text-[24px] md:text-[28px] xl:text-[32px] font-bold text-text leading-tight my-0",
    headerContent: "flex items-center",
    contentWrapper: "w-full h-full px-0",
    content: "w-full h-full",
  },
}
