export default {
  // Default variant
  //-------------------------------------------------------------------------
  default: {
    root: "relative w-full items-center",
    label: "block text-sm font-medium text-gray-700 mb-1",
    required: "text-red-500",
    input_wrapper: "relative flex items-center",
    icon_wrapper: "absolute left-3 flex items-center pointer-events-none",
    input:
      "rounded-full w-full px-3 py-2 bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
    input_with_icon: "pl-10",
    textarea:
      "w-full px-3 py-2 bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
    error_text: "mt-1 text-sm text-red-500",
    error_state: "border-red-500",
  },

  // Compact variant with smaller padding and text
  //-------------------------------------------------------------------------
  compact: {
    root: "relative w-full items-center",
    label: "block text-xs font-medium text-gray-700 mb-0.5",
    required: "text-red-500",
    input_wrapper: "relative flex items-center",
    icon_wrapper: "absolute left-2 flex items-center pointer-events-none",
    input:
      "rounded-full w-full px-2 py-1 text-sm bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent",
    input_with_icon: "pl-8",
    textarea:
      "w-full px-2 py-1 text-sm bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-primary focus:border-transparent",
    error_text: "mt-0.5 text-xs text-red-500",
    error_state: "border-red-500",
  },

  // Large variant with increased padding and text size
  //-------------------------------------------------------------------------
  large: {
    root: "relative w-full items-center",
    label: "block text-base font-medium text-gray-700 mb-2",
    required: "text-red-500",
    input_wrapper: "relative flex items-center",
    icon_wrapper: "absolute left-4 flex items-center pointer-events-none",
    input:
      "rounded-full w-full px-4 py-3 text-lg bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
    input_with_icon: "pl-12",
    textarea:
      "w-full px-4 py-3 text-lg bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
    error_text: "mt-2 text-sm text-red-500",
    error_state: "border-red-500",
  },

  // Floating label variant
  //-------------------------------------------------------------------------
  floating: {
    root: "relative w-full items-center pt-5",
    label:
      "absolute left-3 -top-2 px-1 text-sm font-medium text-gray-700 bg-admin_canvas transition-all",
    required: "text-red-500",
    input_wrapper: "relative flex items-center",
    icon_wrapper: "absolute left-3 flex items-center pointer-events-none",
    input:
      "rounded-full w-full px-3 py-2 bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
    input_with_icon: "pl-10",
    textarea:
      "w-full px-3 py-2 bg-admin_canvas focus:bg-admin_canvas border border-neutral_1 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent",
    error_text: "mt-1 text-sm text-red-500",
    error_state: "border-red-500",
  },

  // Borderless variant
  //-------------------------------------------------------------------------
  borderless: {
    root: "relative w-full items-center",
    label: "block text-sm font-medium text-gray-700 mb-1",
    required: "text-red-500",
    input_wrapper: "relative flex items-center",
    icon_wrapper: "absolute left-3 flex items-center pointer-events-none",
    input:
      "rounded-full w-full px-3 py-2 bg-admin_canvas focus:bg-admin_canvas shadow-sm focus:outline-none focus:ring-2 focus:ring-primary",
    input_with_icon: "pl-10",
    textarea:
      "w-full px-3 py-2 bg-admin_canvas focus:bg-admin_canvas rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-primary",
    error_text: "mt-1 text-sm text-red-500",
    error_state: "ring-2 ring-red-500",
  },
}
