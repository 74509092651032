import evaluateVisibleIf from "./evaluate-visibility"
// Function to validate the fields
/**
 * Validates form fields with support for async validators
 */
const validateFields = async ({
  contentType,
  localFieldValues,
  parentValues,
  setErrors,
  validators,
}) => {
  try {
    const newErrors = {}

    // Check for duplicate fields
    localFieldValues?.fields?.forEach((field, index) => {
      const duplicate = localFieldValues.fields.some(
        (otherField, otherIndex) =>
          otherIndex !== index && otherField.id === field.id
      )
      if (duplicate) newErrors.fields = `Duplicate field: ${field.id}`
    })

    //filter the fields that are not visible
    //this will prevent validation on hidden fields
    const visibleFields = contentType.fields.filter((field) =>
      evaluateVisibleIf(field.visibleIf, localFieldValues)
    )

    // Process each content type field
    await Promise.all(
      visibleFields.map(async (field) => {
        // Check for empty array value
        const isEmptyArray =
          (localFieldValues[field.id] &&
            localFieldValues[field.id].length === 0) ||
          (Array.isArray(localFieldValues[field.id]) &&
            localFieldValues[field.id].length === 1 &&
            localFieldValues[field.id][0] === "")

        //if field.require is a function, pass the localFieldValues to it
        //if it's boolean, use it directly
        const isRequired =
          typeof field.required === "function"
            ? field.required(localFieldValues)
            : field.required

        // Validate required fields
        if (
          isRequired &&
          (localFieldValues[field.id] === undefined ||
            localFieldValues[field.id] === null ||
            localFieldValues[field.id] === "" ||
            isEmptyArray)
        ) {
          newErrors[field.id] = `${field.label || field.id} is required`
        }

        // Validate unique fields
        if (field.unique) {
          const pValues = parentValues()
          const duplicate = pValues.fields?.some((otherField, otherIndex) => {
            const index = pValues.fields.findIndex(
              (item) => item.id === field.id
            )
            return otherField.id === field.id && otherIndex !== index
          })
          if (duplicate) {
            newErrors[field.id] = `${field.label || field.id} must be unique`
          }
        }
      })
    )

    // Apply custom validators if provided
    if (validators) {
      const customValidators = validators(newErrors)
      await Promise.all(
        Object.entries(customValidators).map(async ([fieldId, validator]) => {
          try {
            const validationResult = await validator(
              localFieldValues[fieldId],
              localFieldValues
            )

            // If validator returns an error message, add it to errors
            if (typeof validationResult === "string") {
              newErrors[fieldId] = validationResult
            }
            // If validator returns an object with error details
            else if (validationResult && typeof validationResult === "object") {
              Object.assign(newErrors, validationResult)
            }
          } catch (error) {
            // Handle validator execution errors
            newErrors[fieldId] = error.message || "Validation failed"
            console.error(`Validation error for field ${fieldId}:`, error)
          }
        })
      )
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  } catch (error) {
    console.error("Validation error:", error)
    setErrors({
      _form: "Form validation failed. Please try again.",
    })
    return false
  }
}

export default validateFields
