const BundleItemFormatter = {
    preFormat: (fieldValues, Type) => {
        if (!fieldValues || !fieldValues.bundle) return fieldValues;
        return fieldValues;
    },

    postFormat: (fieldValues, Type) => {
        if (!fieldValues || !fieldValues.bundle) return fieldValues;

        // Create a deep copy to avoid modifying the original
        const formatted = { ...fieldValues };

        // Convert price object to ObjectId if it exists
        if (formatted.price && formatted.price._id) {
            formatted.price = formatted.price._id;
        }

        // Format thumbnail to just the URL string if it's an object
        if (formatted.thumbnail && formatted.thumbnail.url) {
            formatted.thumbnail = formatted.thumbnail.url;
        }

        // Format bundle items
        if (Array.isArray(formatted.bundle)) {
            formatted.bundle = formatted.bundle.map(item => ({
                product: item.product._id, // Convert nested product object to just its ID
                quantity: Number(item.quantity), // Ensure quantity is a number
                price: item.price._id // Convert nested price object to just its ID
            }));
        }

        // Convert type object to string if necessary
        if (formatted.type && formatted.type.value) {
            formatted.type = formatted.type.value;
        }

        // Ensure amount is a number
        if (formatted.amount) {
            formatted.amount = Number(formatted.amount);
        }

        // Ensure dates are in correct format
        if (formatted.created) {
            formatted.created = new Date(formatted.created);
        }
        if (formatted.updated) {
            formatted.updated = new Date(formatted.updated);
        }

        return formatted;
    }
};

export default BundleItemFormatter;