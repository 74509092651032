export default {
  //action-admin
  action:
    "w-auto min-w-[88px] text-white text-[16px] font-medium bg-primary hover:bg-primary_hov rounded-full py-2 px-4 transition",

  //primary-admin-disabled
  action_disabled:
    "w-auto min-w-[88px] bg-blue_primary/50 text-white text-[16px] font-medium rounded-full py-2 px-4 cursor-not-allowed",

  //outlined-admin
  outlined:
    "w-auto min-w-[88px] border border-primary hover:border-primary_hov bg-transparent hover:bg-primary_hov text-primary hover:text-white font-medium rounded-full py-2 px-4 transition",

  //outlined-admin-disabled
  outlined_disabled:
    "w-auto min-w-[88px] border border-neutral-300 text-neutral-300 font-medium rounded-full py-2 px-4 cursor-not-allowed",

  //AquaMail Button Variants=====================================
  //80px, gradient
  primary_accent_xl:
    "w-auto h-[52px] md:h-[60px] lg:h-[72px] xl:h-[80px] flex items-center justify-center bg-gradient-to-r from-primary_hov to-primary hover:bg-gradient-to-r hover:from-primary_hov hover:to-primary_hov text-white text-[20px] md:text-[24px] lg:text-[28px] xl:text-[33px] font-bold rounded-full px-[20px] md:px-[24px] lg:px-[26px] xl:px-[32px] transition",

  // Primary variants
  //80px
  primary_xl:
    "w-auto h-[52px] md:h-[60px] lg:h-[72px] xl:h-[80px] flex items-center justify-center bg-primary hover:bg-primary_hov text-white text-[18px] md:text-[22px] lg:text-[28px] xl:text-[33px] font-bold rounded-full px-[20px] md:px-[24px] lg:px-[26px] xl:px-[32px] transition",

  //60px
  primary_l:
    "w-auto h-[45px] md:h-[52px] lg:h-[60px] flex items-center justify-center bg-primary hover:bg-primary_hov text-white text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px] font-bold hover:bg-gradient-to-t rounded-full px-[20px] md:px-[24px] lg:px-[30px] xl:px-[40px] transition",

  //58px
  primary_m:
    "w-auto h-[50px] lg:h-[58px] flex items-center justify-center gap-[14px] bg-primary hover:bg-primary_hov text-white text-[20px] md:text-[22px] lg:text-[24px] xl:text-[24px] font-bold rounded-full px-[16px] md:px-[18px] lg:px-[20px] xl:px-[20px] transition",

  //48px
  primary_s:
    "w-auto h-[40px] md:h-[44px] lg:h-[48px] flex items-center justify-center bg-primary hover:bg-primary_hov text-white text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-bold rounded-full px-[16px] md:px-[18px] xl:px-[20px] transition",

  //80px, orange background
  primary_alt_xl:
    "w-auto h-[52px] md:h-[60px] lg:h-[72px] xl:h-[80px] flex items-center justify-center bg-primary_alt hover:bg-primary_hov text-white text-[18px] md:text-[22px] lg:text-[28px] xl:text-[33px] font-bold rounded-full px-[20px] md:px-[24px] lg:px-[26px] xl:px-[32px] transition",

  //60px, orange background
  primary_alt_l:
    "w-auto h-[45px] md:h-[52px] lg:h-[60px] flex items-center justify-center bg-primary_alt hover:bg-primary_hov text-white text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px] font-bold hover:bg-gradient-to-t rounded-full px-[20px] md:px-[24px] lg:px-[30px] xl:px-[40px] transition",

  //58px, orange background
  primary_alt_m:
    "w-auto h-[50px] lg:h-[58px] flex items-center justify-center gap-[14px] bg-primary_alt hover:bg-primary_hov text-white text-[20px] md:text-[22px] lg:text-[24px] xl:text-[24px] font-bold rounded-full px-[16px] md:px-[18px] lg:px-[20px] xl:px-[20px] transition",

  //48px, orange background
  primary_alt_s:
    "w-auto h-[40px] md:h-[44px] lg:h-[48px] flex items-center justify-center bg-primary_alt hover:bg-primary_hov text-white text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-bold rounded-full px-[16px] md:px-[18px] xl:px-[20px] transition",

  // Back to top button
  primary_FAB:
    "h-[80px] flex items-center justify-center gap-[10px] bg-primary hover:bg-primary_hov text-white text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px] leading-tight font-bold rounded-full px-[26px] transition",

  // Secondary variants
  //80px
  secondary_xl:
    "w-auto h-[52px] md:h-[60px] lg:h-[72px] xl:h-[80px] flex items-center justify-center border-[1px] border-primary hover:border-primary_hov bg-transparent hover:bg-primary_hov text-primary hover:text-white text-[20px] md:text-[24px] lg:text-[28px] xl:text-[33px] font-bold rounded-full px-[20px] md:px-[24px] lg:px-[26px] xl:px-[32px] transition",

  //60px
  secondary_l:
    "w-auto h-[45px] md:h-[52px] lg:h-[60px] flex items-center justify-center border-[1px] border-primary hover:border-primary_hov bg-transparent hover:bg-primary_hov text-primary hover:text-white text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px] font-bold rounded-full px-[20px] md:px-[24px] lg:px-[26px] xl:px-[32px] transition",

  //58px
  secondary_m: 
    "w-auto h-[50px] lg:h-[58px] flex items-center justify-center border-[1px] border-primary hover:border-primary_hov bg-transparent hover:bg-primary_hov text-primary hover:text-white text-[20px] md:text-[22px] lg:text-[24px] xl:text-[26px] font-bold rounded-full px-[20px] md:px-[24px] lg:px-[26px] xl:px-[32px] transition",

  //48px
  secondary_s: "w-auto h-[40px] md:h-[44px] lg:h-[48px] flex items-center justify-center border-[1px] border-primary hover:border-primary_hov bg-transparent hover:bg-primary_hov text-primary hover:text-white text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-bold rounded-full px-[16px] md:px-[18px] xl:px-[20px] transition",

  //34px (clear search button)
  secondary_xs: "w-auto h-[30px] lg:h-[34px] flex items-center justify-center border-[1px] border-primary hover:border-primary_hov bg-transparent hover:bg-primary_hov text-primary hover:text-white text-[12px] md:text-[14px] lg:text-[16px] font-medium rounded-full px-4 transition",

  card_button:
    "w-full flex flex-col items-left justify-between bg-primary_lt hover:bg-primary hover:text-white group rounded-[24px] px-5 md:px-10 py-5 shadow-custom_card transition ease-in-out",

  card_button_centered:
  "w-full flex flex-col items-center justify-center text-primary hover:text-white tracking-custom_1 font-bold bg-primary_lt hover:bg-primary hover:text-white group rounded-[24px] shadow-custom_card transition",

  text_button:
  "w-auto flex items-center justify-center text-primary hover:text-primary_hov text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] font-normal transition",

  //Text_btn in gray version (for help links, etc.)
  text_btn_ghost:
    "text-[14px] md:text-[16px] text-gray_1 hover:text-primary leading-tight font-normal transition duration-200",

  // Footer and navbar navigation - menuItems
  nav_menuitem:
    "text-[16px] 2xl:text-[20px] text-left leading-tight font-normal text-primary hover:text-primary_hov transition-all",

  // Sign in with Apple / Google - general button (bg-colors and platform icons to be assigned per case)
  sign_in_option:
    "w-full max-w-[374px] rounded-full py-[14px] px-[30px] flex items-center justify-center gap-[6px] text-white text-[14px] sm:text-[16px] md:text-[18px] xl:text-[20px] leading-tight font-bold hover:bg-opacity-90 transition duration-200",

  sign_in_option_disabled:
    "w-full max-w-[374px] rounded-full py-[14px] px-[30px] flex items-center justify-center gap-[6px] text-primary/50 text-[14px] sm:text-[16px] md:text-[18px] xl:text-[20px] leading-tight font-bold border-[1px] border-primary/30 cursor-not-allowed",

  // Used in Pagination component
  nav_icon:
    "w-12 h-12 md:w-14 md:h-14 lg:w-[60px] lg:h-[60px] flex items-center justify-center rounded-full text-text hover:text-primary text-[18px] md:text-[20px] lg:text-[22px] font-normal transition",

  like_btn:
    "h-8 md:h-10 flex items-center justify-start gap-1 border-transparent hover:text-primary hover:border-b-2 hover:border-primary active:scale-95 transition-all duration-200 ease-in-out cursor-pointer focus:outline-none rounded-sm",

    like_btn_big:
    "w-auto h-[40px] md:h-[44px] lg:h-[48px] flex items-center justify-center border-[1px] border-primary hover:border-primary_hov bg-transparent text-primary text-[16px] font-normal hover:font-bold rounded-full px-[16px] md:px-[18px] xl:px-[20px] transition",



  //NOT UPDATED YET----------------------------------------------

  // Social button variants
  social:
    "w-10 h-10 rounded-full flex items-center justify-center hover:text-blue_primary transition",
  social_active:
    "w-10 h-10 rounded-full bg-blue_primary text-white flex items-center justify-center",

  // Platform buttons
  platform_xl_black:
    "w-[220px] h-[65px] rounded-lg px-4 py-2 flex items-center justify-center gap-2 transition",
  platform_l_black:
    "w-[190px] h-[55px] rounded-lg px-4 py-2 flex items-center justify-center gap-2 transition",
  platform_l_outlined:
    "w-[190px] h-[55px] border border-gray-200 hover:border-gray-900 rounded-[12px] px-4 py-2 flex items-center justify-center transition",

  // Switch button (for the Teams/Individual toggle)
  switch: "bg-neutral-100 rounded-full px-6 py-2 flex gap-4 w-fit",
  switch_option: "px-4 py-2 rounded-full transition",
  switch_option_active:
    "px-4 py-2 rounded-full bg-blue_primary text-white transition",
}
