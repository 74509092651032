import React, { useState, useEffect, useRef } from "react"
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import Input from "aldoo-ra/Input"
import { useFieldDragController } from "../controllers/field-drag-controller"
import _ from "lodash"

export default function ObjectEditor({
  onChange,
  value,
  field,
  readOnly = false,
  showInfo = true,
  parentObject,
}) {
  const getInitialValue = () => {
    if (Array.isArray(value)) return value
    if (value) return [{ key: "", value: "" }]
    return []
  }

  const [localValue, setLocalValue] = useState(getInitialValue)
  const inputRefs = useRef([])
  const isInternalChange = useRef(false)

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  // Unified function to update value and notify parent
  const updateValue = (newValue) => {
    isInternalChange.current = true
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  // Drag controller support - moved after updateValue definition
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, updateValue)

  // Handle external value changes
  useEffect(() => {
    if (!isInternalChange.current && !_.isEqual(value, localValue)) {
      setLocalValue(getInitialValue())
    }
    isInternalChange.current = false
  }, [value])

  const handleValueChange = (key, updatedValue, index) => {
    try {
      const updatedValues = [...localValue]
      if (!updatedValues[index]) {
        updatedValues[index] = {}
      }
      updatedValues[index] = {
        ...updatedValues[index],
        [key]: updatedValue,
      }
      updateValue(updatedValues)
    } catch (error) {
      console.error("Error in handleValueChange:", error)
    }
  }

  const addNewInput = () => {
    if (readOnly) return

    const newValues = [...localValue, { key: "", value: "" }]
    updateValue(newValues)

    setTimeout(() => {
      const lastInputIndex = localValue.length
      inputRefs.current[lastInputIndex]?.key?.focus()
    }, 0)
  }

  const removeInput = (index) => {
    if (readOnly) return
    const updatedValues = localValue.filter((_, i) => i !== index)
    updateValue(updatedValues)
  }

  const clearAll = () => {
    if (readOnly) return
    updateValue([])
  }

  const handleKeyDown = (e, index) => {
    if (readOnly) return
    if (e.key === "Enter") {
      e.preventDefault()
      addNewInput()
    }
  }

  const renderItem = (item, index) => {
    // Safety check for item
    if (!item) return null

    return (
      <div
        key={index}
        className="mt-2 gap-2 flex flex-row items-center w-full"
        draggable={!readOnly && localValue.length > 1}
        onDragStart={() => !readOnly && handleDragStart(index)}
        onDragOver={(e) => {
          if (readOnly) return
          e.preventDefault()
          handleDragOver(index)
        }}
        onDragEnd={!readOnly ? handleDragEnd : undefined}
      >
        {!readOnly && localValue.length > 1 && (
          <Bars3Icon className="w-5 h-5 cursor-move text-gray-500 mr-2" />
        )}
        <Input
          readOnly={readOnly}
          ref={(el) =>
            (inputRefs.current[index] = {
              ...inputRefs.current[index],
              key: el,
            })
          }
          className="flex-grow text-admin_text bg-admin_paper"
          value={item.key || ""}
          placeholder="Key"
          onChange={(e) => handleValueChange("key", e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
        <Input
          readOnly={readOnly}
          ref={(el) =>
            (inputRefs.current[index] = {
              ...inputRefs.current[index],
              value: el,
            })
          }
          className="flex-grow text-admin_text bg-admin_paper"
          value={item.value || ""}
          placeholder="Value"
          onChange={(e) => handleValueChange("value", e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />

        {!readOnly && (
          <Button
            type="outline"
            className="text-admin_text hover:text-white hover:bg-red-700 rounded-full p-2 ml-2"
            onClick={() => removeInput(index)}
          >
            <XMarkIcon className="w-6 h-6" />
          </Button>
        )}
      </div>
    )
  }

  return (
    <div className="object-editor">
      <span className="ml-10 text-sm text-admin_text">
        {localValue.length} {localValue.length === 1 ? "item" : "items"}
      </span>

      {Array.isArray(localValue) &&
        localValue.map((item, index) => renderItem(item, index))}

      <div className="flex flex-row justify-between items-center mt-4">
        {showInfo && (
          <div className="flex flex-col">
            <label className="block font-semibold text-admin_text">
              {field.label}
              {isRequired && (
                <span className="ml-2 text-error">(required)</span>
              )}
            </label>
            {field.description && (
              <label className="block text-sm text-admin_text">
                {field.description}
              </label>
            )}
          </div>
        )}

        {!readOnly && (
          <div className="flex flex-grow justify-end items-center gap-5 w-[55%]">
            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={addNewInput}
              disabled={readOnly}
            >
              Add Item
            </Button>

            {localValue.length > 0 && (
              <Button
                variant="outlined"
                className="text-admin_text"
                onClick={clearAll}
                disabled={readOnly}
              >
                Clear All
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
