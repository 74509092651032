//Create a components that is a buttons with a globe icon and a language name
//when clicked, it will change the language

import Icon from "aldoo-ra/Icon"
import Localization from "aldoo-ra/Localization"
import Menu from "aldoo-ra/Menu"
import React, { useState } from "react"
import Button from "aldoo-ra/Button"
import { useEffect } from "react"

export default function SiteLanguageSelector({
  showMenuMode = "hover",
  menuPosition = "bottom",
}) {
  const { t, currentLanguageName, currentLanguage, setLanguage, languages } =
    Localization()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  const menu = isClient
    ? {
        label: currentLanguage?.toUpperCase(),
        items:
          languages?.map &&
          languages?.map((language) => ({
            options: {
              action: () => setLanguage(language.code),
            },
            label: language.code.toUpperCase(),
          })),
      }
    : { label: "", items: [] }

  if (!isClient) {
    return <div className="w-6 h-6" /> // Placeholder to maintain layout
  }

  return (
    <div>
      <Menu
        menuData={menu}
        hideOnItemClick
        renderAs="vlist.menu"
        maxItemsPerColumn={10}
        menuAlign="right"
        showMenuMode={showMenuMode}
        menuPosition={menuPosition}
        itemRender={(item) => {
          return (
            <div className="flex flex-col gap-[8px] mb-2">
              <Button variant="nav_menuitem" className="text-left mb-[8px]">
                {item.label}
              </Button>
            </div>
          )
        }}
        rootItemRender={(item) => {
          return (
            <Button
              variant="nav_menuitem"
              className="flex flex-row justify-end items-center flex-shrink-0 gap-[6px] px-0"
            >
              <Icon id="globe_icon" className="w-6 h-6 flex-shrink-0" />

              <div>{currentLanguage.toUpperCase()}</div>
            </Button>
          )
        }}
      />
    </div>
  )
}
