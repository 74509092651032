import CMSData from "./cms-data"

/**
 * Get a text bundle from the CMS and return the content
 * @param {*} param0
 */
const TextBundle = ({ id }) => {
  const { data } = CMSData({
    contentType: "TextBundle",
    match: { "content.id": id },
  })
  return data[0]?.data
}

export default TextBundle
