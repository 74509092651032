import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  forwardRef,
} from "react"
import Checkbox from "aldoo-ra/Checkbox"

const DropDownSelect = forwardRef(
  (
    {
      options = [],
      value = [],
      placeholder = "Select...",
      onChange,
      onItemRender,
      itemClassName = "",
      valueField = "value",
      menuPosition = "bottom",
      multiSelection = false,
      shouldResetSelection = false,
      zIndex = 100,
      fixedWidth,
      showItemNamesInSelection = false,
      searchEnabled = false,
      maxHeight, // New prop for controlling maximum height
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [searchQuery, setSearchQuery] = useState("")
    const dropdownRef = useRef(null)
    const buttonRef = useRef(null)
    const searchInputRef = useRef(null)

    const toggleDropdown = () => {
      if (!isOpen) {
        // Opening dropdown
        setIsOpen(true)
        if (searchEnabled) {
          setShowSearch(true)
          // Focus the search input after rendering
          setTimeout(() => {
            if (searchInputRef.current) {
              searchInputRef.current.focus()
            }
          }, 0)
        }
      } else {
        // Closing dropdown
        setIsOpen(false)
        setShowSearch(false)
        setSearchQuery("")
      }
    }

    //make sure the value is array
    if (!Array.isArray(value)) value = [value]

    const resetSelection = () => {
      onChange && onChange([])
    }

    const handleCheckboxChange = (option, checked) => {
      let updatedSelection
      if (multiSelection) {
        if (checked) {
          updatedSelection = [
            ...value.filter((item) => item[valueField] !== option[valueField]),
            option,
          ]
        } else {
          updatedSelection = value.filter(
            (item) => item[valueField] !== option[valueField]
          )
        }
      } else {
        updatedSelection = checked ? [option] : []
        setIsOpen(false)
        setShowSearch(false)
        setSearchQuery("")
      }

      onChange && onChange(updatedSelection)
    }

    const handleSelectAll = (checked) => {
      if (!multiSelection) return
      onChange && onChange(checked ? [...options] : [])
    }

    const filteredOptions = useMemo(() => {
      if (!searchQuery) return options
      return options.filter((option) => {
        const searchValue = (
          option.name ||
          option.label ||
          option[valueField] ||
          ""
        ).toLowerCase()
        return searchValue.includes(searchQuery.toLowerCase())
      })
    }, [options, searchQuery, valueField])

    const getDisplayLabel = useMemo(() => {
      const labelValue = Array.isArray(value) ? value : [value]

      if (
        !labelValue ||
        labelValue.length === 0 ||
        !labelValue[0] ||
        (typeof labelValue[0] === "object" &&
          Object.keys(labelValue[0]).length === 0)
      ) {
        if (options && options.length) return placeholder
        return "No options available"
      }

      if (labelValue.length === 1) {
        return onItemRender
          ? onItemRender(labelValue[0])
          : labelValue[0].name || labelValue[0].label
      }

      return showItemNamesInSelection
        ? labelValue
            .map((item) =>
              onItemRender
                ? onItemRender(item)
                : item.name || item.label || item[valueField]
            )
            .join(", ")
        : `${labelValue.length} selected`
    }, [value, placeholder, onItemRender, showItemNamesInSelection, valueField])

    const getMenuPositionStyles = useCallback(() => {
      if (!buttonRef.current || !dropdownRef.current) return {}

      const buttonRect = buttonRef.current.getBoundingClientRect()

      const styles = {
        width: `${buttonRect.width}px`,
      }

      switch (menuPosition) {
        case "top":
          styles.bottom = `${buttonRect.height}px`
          styles.left = `${0}px`
          break
        case "bottom":
          styles.top = `${buttonRect.height}px`
          styles.left = `${0}px`
          break
        default:
          styles.top = `${buttonRect.height}px`
          styles.left = `${0}px`
      }

      return styles
    }, [menuPosition])

    // Adjust the dropdown position when it opens
    useEffect(() => {
      if (isOpen && dropdownRef.current) {
        const positionStyles = getMenuPositionStyles()
        Object.assign(dropdownRef.current.style, positionStyles)
      }
    }, [isOpen, getMenuPositionStyles])

    // Close dropdown when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target) &&
          !buttonRef.current.contains(event.target)
        ) {
          setIsOpen(false)
          setShowSearch(false)
          setSearchQuery("")
        }
      }

      if (isOpen) {
        document.addEventListener("mousedown", handleClickOutside)
      } else {
        document.removeEventListener("mousedown", handleClickOutside)
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [isOpen])

    return (
      <div
        className={`relative inline-block text-left ${
          fixedWidth !== undefined ? "" : "w-full"
        }`}
        style={fixedWidth ? { width: fixedWidth } : undefined}
      >
        {/* Dropdown button */}
        <div className="flex justify-between items-center relative">
          <button
            ref={buttonRef}
            type="button"
            className="inline-flex justify-between w-full rounded-md border border-bordered shadow-sm px-4 py-2 bg-admin_paper text-sm font-medium text-text focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary_lt"
            id="options-menu"
            aria-expanded={isOpen}
            aria-haspopup="true"
            onClick={toggleDropdown}
            style={{
              paddingRight: value.length > 0 ? "2.5rem" : "1rem",
            }}
          >
            {getDisplayLabel}
          </button>

          {/* Reset Button inside the input */}
          {options && options.length > 0 && value.length > 0 && (
            <button
              className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 text-text hover:text-primary"
              onClick={resetSelection}
            >
              &#x2716;
            </button>
          )}
        </div>

        {/* Dropdown panel */}
        {isOpen && options.length > 1 && (
          <div
            ref={dropdownRef}
            className="absolute border border-bordered origin-top-right rounded-md shadow-lg bg-admin_paper ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            style={{ zIndex }}
          >
            {/* Search Input */}
            {searchEnabled && showSearch && (
              <div className="p-2 border-b border-neutral_2">
                <input
                  ref={searchInputRef}
                  type="text"
                  className="w-full px-3 py-1 text-sm border rounded focus:outline-none focus:ring-1 focus:ring-primary_lt"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            )}

            <div
              className="rounded-lg overflow-hidden max-h-60 overflow-y-auto"
              style={maxHeight ? { maxHeight, height: maxHeight } : undefined}
            >
              {/* Select All Checkbox (only if multi-selection is enabled) */}
              {multiSelection && filteredOptions.length > 1 && (
                <div
                  className="px-4 py-2 mt-1 hover:bg-admin_canvas"
                  onClick={() =>
                    handleSelectAll(value.length !== options.length)
                  }
                >
                  <Checkbox
                    labelClassName={itemClassName}
                    isOn={value.length === options.length}
                    onChange={(checked) => handleSelectAll(checked)}
                    label={
                      value.length === options.length
                        ? "Deselect All"
                        : "Select All"
                    }
                  />
                  <div className="border-t border-neutral_2 my-2"></div>
                </div>
              )}

              {/* Render filtered options */}
              {Array.isArray(filteredOptions) &&
                filteredOptions.map((option, index) => {
                  const isSelected =
                    value &&
                    value.some(
                      (item) =>
                        item &&
                        option &&
                        item[valueField] === option[valueField]
                    )
                  return (
                    <div
                      key={index}
                      className="px-4 py-2 hover:bg-admin_canvas"
                      onClick={() => handleCheckboxChange(option, !isSelected)}
                    >
                      <Checkbox
                        labelClassName={itemClassName}
                        isOn={isSelected}
                        onChange={(checked) =>
                          handleCheckboxChange(option, checked)
                        }
                        label={
                          onItemRender
                            ? onItemRender(option)
                            : option.name || option.label || option[valueField]
                        }
                        multiSelection={multiSelection}
                      />
                    </div>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    )
  }
)

export default DropDownSelect
