import React, { useState } from "react"
import Modal from "aldoo-ra/Modal"
import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import Button from "aldoo-ra/Button"
import {
  PlusIcon,
  CreditCardIcon,
  WalletIcon,
} from "@heroicons/react/24/outline"
import Checkbox from "aldoo-ra/Checkbox"
import usePaymentSubmission from "./use-payment-submission"

const PaymentMethodIcon = ({ type }) => {
  switch (type?.toLowerCase()) {
    case "card":
      return <CreditCardIcon className="h-6 w-6 lg:h-7 lg:w-7 text-gray_2" />
    default:
      return <WalletIcon className="h-6 w-6 lg:h-7 lg:w-7 text-gray_2 mt-4 md:mt-5" />
  }
}

const PaymentMethodDetails = ({ method, texts }) => {
  if (!method) return null

  if (method?.type?.toLowerCase() === "card") {
    return (
      <div>
        <Typography 
          variant="body_24_accent"
          className="text-text my-0"
        >
          {method.brand} •••• {method.last4}
        </Typography>

        <Typography 
          variant="info_16"
          className="!text-gray_1 m-0"
        >
          {texts?.expires || "{{ Missing }} Expires"} {method.expiryMonth}/{method.expiryYear}
        </Typography>
      </div>
    )
  }

  return (
    <>
      <Typography 
        variant="body_22_accent"
        className="text-text"
      >
        {method.displayName || method.type}
      </Typography>

      {method.details && (
        <Typography 
          variant="info_16"
          className="text-gray_1 mt-0"
        >
          {method.details}
        </Typography>
      )}
    </>
  )
}

const ReusePaymentDialog = ({ store, onClose, onSelectNewPayment }) => {
  const texts = TextBundle({ id: "aldoo-store" })
  const [useSaved, setUseSaved] = useState(true)
  const [useNew, setUseNew] = useState(false)
  const [loading, setLoading] = useState(false)

  const { handleSubmitWithSavedMethod } = usePaymentSubmission(store)

  const { paymentInfo, setPaymentInfo, onPaymentError } = store
  const { savedPaymentMethod } = paymentInfo

  const handleSavedToggle = (checked) => {
    setUseSaved(checked)
    if (checked) setUseNew(false)
  }

  const handleNewToggle = (checked) => {
    setUseNew(checked)
    if (checked) setUseSaved(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (useNew) {
      onSelectNewPayment()
      return
    }

    if (useSaved) {
      try {
        setLoading(true)
        await handleSubmitWithSavedMethod()
        onClose()
      } catch (error) {
        console.error("Payment failed:", error)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      title={texts?.selectPaymentMethod || "Select Payment Method"}
      isOpen={!!savedPaymentMethod}
      onClose={onClose}
      variant="medium"
      className="md:max-w-[550px]"
    >
      <form onSubmit={handleSubmit} className="w-full max-w-[468px] space-y-8 mx-auto md:mx-0">
        <div className="space-y-4 pt-8 pb-4">
          <div className="rounded-[24px] hover:bg-primary_lt/50 shadow-custom_card transition-colors px-8 md:px-4 py-8">
            <div className="flex items-center">
              <Checkbox
              variant="aqua"
                isOn={useSaved}
                onChange={handleSavedToggle}
                label={
                  <div className="flex items-center space-x-4 ml-2 md:ml-6">
                    <PaymentMethodIcon type={savedPaymentMethod?.type} />

                    <div className="">
                      <PaymentMethodDetails method={savedPaymentMethod} texts={texts}/>
                    </div>
                  </div>
                }
              />
            </div>
          </div>

          <div className="rounded-[24px] hover:bg-primary_lt/50 shadow-custom_card transition-colors px-8 md:px-4 py-8">
            <div className="flex items-center">
              <Checkbox
                variant="aqua"
                isOn={useNew}
                onChange={handleNewToggle}
                label={
                  <div className="flex items-center space-x-4 ml-2 md:ml-6">
                    <PlusIcon className="h-6 w-6 lg:h-7 lg:w-7 text-gray_2" />

                    <div className="flex flex-col">
                      <Typography 
                        variant="body_22_accent"
                        className="text-text my-0"
                      >
                        {texts?.newPaymentMethod || "{{ Missing }} New Payment Method"}
                      </Typography>

                      <Typography 
                        variant="info_16"
                        className="text-gray_1 my-0"
                      >
                        {texts?.addNewCard || "{{ Missing }} Add a new payment method"}
                      </Typography>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between space-x-3">
          <Button
            type="button"
            variant="secondary_s"
            onClick={onClose}
            disabled={loading}
          >
            {texts?.cancel || "{{ Missing }} Cancel"}
          </Button>

          <Button 
            type="submit" 
            variant="primary_s"
            disabled={loading || (!useSaved && !useNew)}
          >
            {loading
              ? texts?.processing || "{{ Missing }} Processing..."
              : useSaved
              ? texts?.payWithSaved || "{{ Missing }} Pay with Saved Method"
              : texts?.continue || "{{ Missing }} Continue"}
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default ReusePaymentDialog
