import React, { useRef, useEffect, useState } from "react"
import DropDown from "aldoo-ra/Dropdown"
import Spinner from "aldoo-ra/Spinner"
import { getFieldByLocalizationKey } from "../get-cms-info-by-key"
import { getFieldEditor } from "aldoo-ra/CMS/field-editor-resolver"
import LoadingOverlay from "aldoo-ra/LoadingOverlay"

/**
 * TextItemEditor Component
 *
 * @param {Object} props
 * @param {Object} translations - The translation data for the languages
 * @param {Function} handleContentChange - Function to handle content change for the selected language
 * @param {String} selectedLang - The currently selected language code
 * @param {String} primaryLanguage - The primary language code
 * @param {Object} controller - The controller object containing necessary methods and data for translations
 */
export default function TextItemEditor({
  localizationKey,
  translations,
  handleContentChange,
  selectedLang,
  primaryLanguage,
  controller,
  parentObject,
}) {
  //get the field by name from the fieldInfo
  //contains the field information such as id, name, type, etc.
  let field = getFieldByLocalizationKey(localizationKey)

  //set a default field if not found
  if (!field)
    field = { type: "Text", multiple: false, default: "", multiline: true }

  //not yet loaded
  if (!field) return <Spinner />

  const FieldEditor = getFieldEditor(field)

  const defaultValue = field.multiple ? [] : field.default || ""
  //get the primary language value and fieldInfo
  const primaryValue = translations[primaryLanguage]?.content || defaultValue
  const value = translations[selectedLang]?.content || defaultValue

  return (
    <div className="min-h-[500px] ">
      <div className="flex justify-between flex-col gap-2">
        <div className="flex flex-row justify-between">
          {/* Display the selected language name */}
          <span className="font-semibold text-admin_text text-lg">
            {controller.getLanguageName(selectedLang)}
          </span>
          {/* Auto-translate options dropdown */}
          <div className="flex flex-row w-1/2">
            <LoadingOverlay
              title="Translating"
              message="Please wait while we translate the content"
              loading={controller.isAutoTranslating}
            />
            <DropDown
              className="w-full"
              isMenu={true}
              placeholder="Auto Translate"
              options={
                //if the selected language is the primary language, do not show the primary language in the auto-translate options
                selectedLang === primaryLanguage
                  ? controller.autoTranslateOptions.filter(
                      (option) => option.value !== "key"
                    )
                  : controller.autoTranslateOptions
              }
              onChange={(item) => {
                controller.setSelectedAutoTranslateOption(item.value)
              }}
              sortBy="index"
              sortDirection="desc"
              menuPosition="bottom"
              onItemRender={(item) => item.name}
            />
          </div>
        </div>

        {FieldEditor && (
          <FieldEditor
            parentObject={parentObject}
            editButtonTitle={`Edit ${controller.getLanguageName(selectedLang)}`}
            field={field}
            value={value}
            onChange={(changed) => {
              if (changed === undefined) return
              handleContentChange(changed)
            }}
          />
        )}
      </div>

      {/* Render primary language textarea if selectedLang is not the primary */}
      {selectedLang !== primaryLanguage && (
        <div className="mt-4 flex flex-col gap-2">
          <span className="font-semibold text-admin_text">
            {`${controller.getLanguageName(
              primaryLanguage
            )} - Primary Language`}
          </span>

          {/* Primary language textarea (read-only) */}
          {FieldEditor && (
            <FieldEditor
              editButtonTitle={`View in ${controller.getLanguageName(
                primaryLanguage
              )}`}
              readOnly={true}
              field={field}
              value={primaryValue}
              // showInfo={false}
            />
          )}
        </div>
      )}
    </div>
  )
}
