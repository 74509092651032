// components/Typography/variants.jsx
export default {
  // Heading 1 variants
  "h1-xl": "text-[60px] font-bold leading-[70px]",
  "h1-lm": "text-[48px] font-bold leading-[60px]",
  "h1-s": "text-[48px] font-bold leading-[60px]",

  // Heading 2 variants
  "h2-xl": "text-[46px] font-bold leading-[60px]",
  "h2-lm": "text-[38px] font-bold leading-[50px]",
  "h2-s": "text-[38px] font-bold leading-[50px]",

  // Heading 3 variants
  "h3-xl": "text-[24px] font-bold leading-[30px]",
  "h3-lm": "text-[24px] font-bold leading-[30px]",
  "h3-s": "text-[24px] font-bold leading-[30px]",

  // Body 1 variants
  "body1-xl": "text-[20px] font-normal leading-[28px]",
  "body1-lm": "text-[17px] font-normal leading-[26px]",
  "body1-s": "text-[17px] font-normal leading-[26px]",

  // Body 1 Accent variants
  "body1-accent-xl": "text-[20px] font-bold leading-[28px]",
  "body1-accent-lm": "text-[17px] font-bold leading-[26px]",
  "body1-accent-s": "text-[17px] font-bold leading-[26px]",

  // Body 2 variants
  "body2-xl": "text-[17px] font-normal leading-[26px]",
  "body2-lm": "text-[17px] font-normal leading-[26px]",
  "body2-s": "text-[17px] font-normal leading-[26px]",

  // Button/Link Text 1 variants (BTN1)
  "btn1-xl": "text-[17px] font-bold leading-[24px]",
  "btn1-lm": "text-[17px] font-medium leading-[24px]",
  "btn1-s": "text-[17px] font-medium leading-[24px]",

  // Button/Link Text 2 variants (BTN2)
  "btn2-xl": "text-[26px] font-bold leading-[26px]",
  "btn2-lm": "text-[24px] font-bold leading-[24px]",
  "btn2-s": "text-[24px] font-bold leading-[24px]",

  // Text 1 variants (TXT1)
  "txt1-xl": "text-[15px] font-normal leading-[18px]",
  "txt1-lm": "text-[15px] font-normal leading-[18px]",
  "txt1-s": "text-[15px] font-normal leading-[18px]",
}
