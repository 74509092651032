import React, { useEffect, useState } from "react"
import SVGCache from "./svg-cache"

const DynamicSVG = ({ url, recolor = true, className, cache = true }) => {
  if (!url || !url.includes(".svg")) return null
  const [svgContent, setSvgContent] = useState(null)

  useEffect(() => {
    const loadSVG = async () => {
      const cachedSVG = await SVGCache.getSVG(url, recolor, cache)
      if (cachedSVG) {
        setSvgContent(cachedSVG)
      }
    }

    loadSVG()
  }, [url, recolor, cache])

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  )
}

export default DynamicSVG
