import { io } from "socket.io-client"
import useSigninUserWithCredentials from "./signin-with-credentials"
import config from "aldoo-ra/Config"
const { API_URL } = config


const startProviderAuth = ({ provider, onCompleted }) => {
    //If this is an email provider, sign in with email
    if (provider.id === "email") return onCompleted({ email: provider.data })

    const startPopupCheck = (popup, onCompleted) => {
        const check = setInterval(() => {
            if (popup?.closed || popup?.closed === undefined) {
                clearInterval(check)
                onCompleted(false)
            }
        }, 100)

        return () => clearInterval(check)
    }

    const openPopup = () => {
        const width = 600
        const height = 700
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2

        return window.open(
            "",
            "",
            `toolbar=no, location=no, directories=no, status=no, menubar=no,
      scrollbars=no, resizable=no, copyhistory=no, width=${width},
      height=${height}, top=${top}, left=${left}`
        )
    }

    //create a socket connection to the API_URL
    const socket = io(API_URL)
    let popup = openPopup()
    let stopCheck

    socket.on(`${provider.id}`, (user) => {
        stopCheck()
        popup?.close()
        onCompleted(user)
        socket.disconnect()
    })

    socket.on("connect", () => {
        popup.location.href = `${API_URL}/${provider.id}?socketId=${socket.id}`
        stopCheck = startPopupCheck(popup, () => {
            onCompleted()
            socket.disconnect()
        })
    })
}

const useSigninWithProvider = () => {
    const { state, error, signinUser } = useSigninUserWithCredentials()
    const signin = async function (provider) {
        //3rd party provider, start the auth process
        startProviderAuth({
            provider,
            onCompleted: (user) => {
                //TODO: handle the error here if the user is not signed in
                if (!user) return
                //sign in the user with the provider
                //The user object is return by the 3rd party provider
                //and contains the user's email, name, avatar, etc
                signinUser(user)
            },
        })
    }
    return { state, error, signin }
}

export default useSigninWithProvider