import { IsDeviceLogged } from '../api'
import { sleep } from "aldoo-ra/Time"

import BreakAuth from './break-await-auth'

const { GetBreakAwaitAuth, SetBreakAwaitAuth } = BreakAuth


//check for a logged device every X seconds
const awaitAuth = async (auth) => {
    //reset login break, external systems may break it
    SetBreakAwaitAuth(false)

    while (true) {
        //check if we need to break the loop
        if (GetBreakAwaitAuth()) break
        //wait for 1 second
        await sleep(1000)
        //check if the device is logged
        const deviceLoginState = await IsDeviceLogged(auth)
        //Return true on successful device login
        if (deviceLoginState.login) return deviceLoginState
    }
}

export default awaitAuth