import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { CheckCircleIcon } from "@heroicons/react/24/outline"

export default function PaymentSuccess({ store, children }) {
  const texts = TextBundle({ id: "aldoo-store" })
  //Store interface
  const { paymentReceipt } = store
  const { paymentInfo } = paymentReceipt

  const getSuccessMessage = () => {
    //Free
    if (paymentInfo.type === "free_subscription") {
      return (
        texts?.free_subscription_success ||
        "Your free subscription has been activated successfully!"
      )
    }

    if (paymentInfo.type === "free_purchase") {
      return (
        texts?.free_purchase_success ||
        "Your free purchase has been activated successfully!"
      )
    }

    //Trial
    if (paymentInfo.hasTrial) {
      return texts?.trial_success || "Your trial has been activated"
    }

    //Subscription
    if (paymentInfo.type === "subscription") {
      return (
        texts?.subscription_success ||
        "Your subscription has been activated successfully!"
      )
    }

    //Direct payment
    if (paymentInfo.type === "purchase") {
      return (
        texts?.payment_success || "Your payment was processed successfully!"
      )
    }
  }

  return (
    <div className="p-6 flex flex-col items-center text-center">
      <CheckCircleIcon className="w-16 h-16 text-green-500 mb-4" />

      <Typography variant="h2" className="mb-4">
        {texts?.thank_you || "Thank You!"}
      </Typography>

      <Typography variant="body1" className="mb-6">
        {getSuccessMessage()}
      </Typography>

      {children}
    </div>
  )
}
