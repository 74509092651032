import React, { forwardRef } from "react"
import VariantReader from "aldoo-ra/VariantReader"
import { XMarkIcon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"

const Input = forwardRef(
  (
    {
      label,
      value = "",
      onChange,
      placeholder = "",
      type = "text",
      error = "",
      name = "",
      className = "",
      labelClassName = "",
      required = false,
      multiline = false,
      onKeyDown,
      onBlur,
      onClear,
      readOnly = false,
      icon = null,
      variant = "default",
      styleOverrides = {},
      showClearButton = false,
      clearButtonVariant = "default",
      clearButtonClassName = "",
      autoFocus = false,
    },
    ref
  ) => {
    const variants = VariantReader.useVariant({
      componentName: "Input",
      variant,
      styleOverrides,
    })

    const handleClear = () => {
      if (onChange) {
        // Create a synthetic event to mimic input change
        const event = {
          target: { value: "", name },
          preventDefault: () => {},
        }
        onChange(event)
      }
      onClear && onClear()
    }

    const inputProps = {
      onBlur,
      onKeyDown,
      value,
      onChange,
      placeholder,
      name,
      readOnly,
      ref,
      autoFocus,
      className: `${variants.input}
        ${icon ? variants.input_with_icon : ""}
        ${error ? variants.error_state : ""}
        ${showClearButton ? "pr-8" : ""} // Add padding for clear button
        ${className}`.trim(),
    }

    return (
      <div className={`${variants.root} ${className}`.trim()}>
        {label && (
          <label className={`${variants.label} ${labelClassName}`.trim()}>
            {label} {required && <span className={variants.required}>*</span>}
          </label>
        )}
        <div className={`${variants.input_wrapper} relative`}>
          {icon && <div className={variants.icon_wrapper}>{icon}</div>}
          {multiline ? (
            <textarea
              {...inputProps}
              className={`${variants.textarea}
                ${icon ? variants.input_with_icon : ""}
                ${error ? variants.error_state : ""}
                ${className}`.trim()}
            />
          ) : (
            <input type={type} {...inputProps} />
          )}
          {showClearButton && value && !readOnly && (
            <Button
              variant={clearButtonVariant}
              type="button"
              onClick={handleClear}
              className={
                "absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 " +
                clearButtonClassName
              }
              aria-label="Clear input"
            >
              <XMarkIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
        {error && <p className={variants.error_text}>{error}</p>}
      </div>
    )
  }
)

Input.displayName = "Input"
export default Input
