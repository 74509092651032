import { useEffect, useState } from "react"
import TypeResolver from "aldoo-ra/CMS/type-resolver"
import DatabaseModelTypeResolver from "aldoo-ra/CMS/database-model-type-resolver"

/**
 * Hook to resolve the CMS field if not provided.
 *
 * @param {String} fieldInfo - Field information in the format 'Type.fieldID'
 * @returns {Object} The resolved field
 */
export const getFieldByLocalizationKey = (key) => {
  const [field, setField] = useState()
  const { getContentType, types } = TypeResolver()
  const { getType: getDBContentType } = DatabaseModelTypeResolver()

  useEffect(() => {
    if (!key) return
    // Split fieldInfo and get the type and field name
    const [type, fieldID] = key.split("-")
    let Type = getContentType(type)
    //try to get the type from the database model
    if (!Type) Type = getDBContentType(type)
    //There was no type found in both the content types and the database models
    if (!Type) return
    // Find the appropriate field in the type's fields
    const resolvedField = Type.fields.find((field) => field.id === fieldID)
    setField(resolvedField)

    return () => {
      setField(null)
    }
  }, [key, types])

  return field
}
