export default {
  // Default variant - Standard table layout
  default: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-2",
    title: "text-text text-xl font-semibold",
    searchInput:
      "text-text bg-canvas outline-none ml-10 flex-1 w-[200px] h-[30px] px-2 py-1 border border-neutral_2 rounded",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table: "w-full table-fixed md:table-auto",
    thead: "sticky top-0 z-10 bg-paper text-text",
    headerRow: "text-left bg-neutral_1 text-text font-normal",
    headerCell: "px-2 py-2",
    row: "h-[60px] text-text text-sm font-normal",
    cell: "px-2 py-2",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text",
    footer: "mt-5",
    getRowStyle: (index) =>
      index % 2 === 0 ? "bg-paper" : "bg-paper bg-opacity-65",
    getHeaderCellStyle: (headerClassName) =>
      `px-2 py-2 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) => `px-2 py-2 ${itemClassName || ""}`,
  },

  // Compact variant - Smaller rows and tighter spacing
  compact: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-1",
    title: "text-text text-lg font-medium",
    searchInput:
      "text-text bg-canvas outline-none ml-6 flex-1 w-[180px] h-[25px] px-2 py-1 border border-neutral_2 rounded text-sm",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table: "w-full table-fixed md:table-auto",
    thead: "sticky top-0 z-10 bg-paper text-text",
    headerRow: "text-left bg-neutral_1 text-text font-normal text-sm",
    headerCell: "px-2 py-1",
    row: "h-[40px] text-text text-xs font-normal",
    cell: "px-2 py-1",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text text-sm",
    footer: "mt-3",
    getRowStyle: (index) =>
      index % 2 === 0 ? "bg-paper" : "bg-paper bg-opacity-65",
    getHeaderCellStyle: (headerClassName) =>
      `px-2 py-1 text-sm ${headerClassName || ""}`,
    getCellStyle: (itemClassName) => `px-2 py-1 ${itemClassName || ""}`,
  },

  // Cards variant - Card-like rows with shadows
  cards: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-4",
    title: "text-text text-xl font-bold",
    searchInput:
      "text-text bg-canvas outline-none ml-10 flex-1 w-[220px] h-[35px] px-3 py-2 border border-neutral_2 rounded-lg shadow-sm",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table:
      "w-full table-fixed md:table-auto border-separate border-spacing-y-2",
    thead: "sticky top-0 z-10 bg-transparent text-text",
    headerRow: "text-left text-text font-semibold",
    headerCell: "px-4 py-3",
    row: "h-[70px] text-text text-sm font-medium transition-all duration-200",
    cell: "px-4 py-3",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text font-medium",
    footer: "mt-6",
    getRowStyle: () => "bg-paper shadow-sm hover:shadow-md rounded-lg",
    getHeaderCellStyle: (headerClassName) =>
      `px-4 py-3 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) => `px-4 py-3 ${itemClassName || ""}`,
  },

  // Dashboard variant - Optimized for dashboard displays
  dashboard: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-3",
    title: "text-text text-lg font-semibold",
    searchInput:
      "text-text bg-canvas outline-none ml-8 flex-1 w-[200px] h-[30px] px-2 py-1 border border-neutral_2 rounded",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-[300px] flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table: "w-full table-fixed md:table-auto",
    thead: "sticky top-0 z-10 bg-paper text-text",
    headerRow: "text-left bg-neutral_1 text-text font-medium",
    headerCell: "px-3 py-2",
    row: "h-[55px] text-text text-sm font-normal transition-colors duration-200",
    cell: "px-3 py-2",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text",
    footer: "mt-4",
    getRowStyle: (index) =>
      `${
        index % 2 === 0 ? "bg-paper" : "bg-paper bg-opacity-65"
      } hover:bg-neutral_1`,
    getHeaderCellStyle: (headerClassName) =>
      `px-3 py-2 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) => `px-3 py-2 ${itemClassName || ""}`,
  },

  // Report variant - Formal styling for reports
  report: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-5",
    title: "text-text text-2xl font-bold",
    searchInput:
      "text-text bg-canvas outline-none ml-12 flex-1 w-[250px] h-[35px] px-3 py-2 border border-neutral_2 rounded",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table: "w-full table-fixed md:table-auto",
    thead: "sticky top-0 z-10 bg-paper text-text",
    headerRow: "text-left bg-neutral_1 text-text font-semibold",
    headerCell: "px-4 py-3 border-b-2 border-neutral_2",
    row: "h-[65px] text-text text-sm font-normal",
    cell: "px-4 py-3 border-b border-neutral_1",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text font-medium",
    footer: "mt-6",
    getRowStyle: (index) =>
      `bg-paper ${index % 2 === 0 ? "bg-opacity-100" : "bg-opacity-50"}`,
    getHeaderCellStyle: (headerClassName) =>
      `px-4 py-3 border-b-2 border-neutral_2 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) =>
      `px-4 py-3 border-b border-neutral_1 ${itemClassName || ""}`,
  },

  // Simple variant - Clean and minimal styling
  simple: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-2",
    title: "text-text text-lg font-medium",
    searchInput:
      "text-text bg-canvas outline-none ml-8 flex-1 w-[200px] h-[30px] px-2 py-1 border border-neutral_2 rounded",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table: "w-full table-fixed md:table-auto",
    thead: "sticky top-0 z-10 bg-paper text-text",
    headerRow: "text-left text-text font-medium",
    headerCell: "px-3 py-2 border-b border-neutral_2",
    row: "h-[50px] text-text text-sm font-normal border-b border-neutral_1",
    cell: "px-3 py-2",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text",
    footer: "mt-4",
    getRowStyle: () => "hover:bg-neutral_1 transition-colors duration-200",
    getHeaderCellStyle: (headerClassName) =>
      `px-3 py-2 border-b border-neutral_2 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) => `px-3 py-2 ${itemClassName || ""}`,
  },

  // Minimal variant - Most basic styling without footer
  minimal: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-2",
    title: "text-text text-base font-medium",
    searchInput:
      "text-text bg-canvas outline-none ml-6 flex-1 w-[180px] h-[28px] px-2 py-1 border border-neutral_2 rounded",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table: "w-full table-fixed md:table-auto",
    thead: "sticky top-0 z-10 bg-paper text-text",
    headerRow: "text-left text-text font-normal",
    headerCell: "px-2 py-2",
    row: "h-[45px] text-text text-sm font-normal",
    cell: "px-2 py-2",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text text-sm",
    footer: "mt-3",
    getRowStyle: () => "border-b border-neutral_1",
    getHeaderCellStyle: (headerClassName) =>
      `px-2 py-2 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) => `px-2 py-2 ${itemClassName || ""}`,
  },

  // Dense variant - Compact layout with borders
  dense: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-1",
    title: "text-text text-base font-medium",
    searchInput:
      "text-text bg-canvas outline-none ml-4 flex-1 w-[160px] h-[25px] px-2 py-1 border border-neutral_2 rounded text-sm",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table: "w-full table-fixed md:table-auto border-collapse",
    thead: "sticky top-0 z-10 bg-paper text-text",
    headerRow: "text-left bg-neutral_1 text-text font-medium text-xs",
    headerCell: "px-2 py-1 border border-neutral_2",
    row: "h-[35px] text-text text-xs font-normal",
    cell: "px-2 py-1 border border-neutral_1",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text text-xs",
    footer: "mt-2",
    getRowStyle: (index) =>
      index % 2 === 0 ? "bg-paper" : "bg-paper bg-opacity-65",
    getHeaderCellStyle: (headerClassName) =>
      `px-2 py-1 border border-neutral_2 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) =>
      `px-2 py-1 border border-neutral_1 ${itemClassName || ""}`,
  },
}
