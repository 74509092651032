export default {
  default: {
    editors: {
      default:
        "w-1/2 mx-auto  hover:bg-white_to_black border p-5 border-neutral_1 border-opacity-30",
      full: "w-full mx-auto hover:bg-white_to_black border p-5 border-neutral_1 border-opacity-30",
    },
    fields: {
      default:
        "w-1/2 mx-auto  hover:bg-white_to_black border p-5 border-neutral_1 border-opacity-30",
      full: "w-full mx-auto hover:bg-white_to_black border p-5 border-neutral_1 border-opacity-30",
    },
    groups: {
      default: {
        root: "mb-1",
        panel:
          "shadow-lg h-[50px] w-full flex justify-between items-center cursor-pointer border-t border-neutral_1 p-4 text-text bg-primary rounded",
        panel_content:
          "h-[30px] flex flex-row items-center justify-between w-full",
        panel_title: "text-white text-lg mr-5 font-semibold",
        panel_description: "text-sm text-white ml-auto",
        panel_arrow: "text-white ml-2",
        panel_expand_icon: "w-6 h-6",
        content: "flex flex-row flex-wrap",
      },
    },
  },
}
