import React, { useState, useEffect } from "react"
import Modal from "aldoo-ra/Modal"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"
import CMSRender from "aldoo-ra/CMS/cms-render"
import { showNotification } from "aldoo-ra/Notification"
import PubSub from "pubsub-js"
import Localization from "aldoo-ra/Localization"
// Import the API function for sending partner contact data
import { SendUserSiteContact } from "aldoo-ra/ContactPanel/api"

const ContactPanelPartners = () => {
  const { tjsx } = Localization()

  const [fullName, setFullName] = useState("")
  const [company, setCompany] = useState("")
  const [industry, setIndustry] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  // Update the specific field's value and clear its error
  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value)
    if (errors[field]) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }))
    }
  }

  useEffect(() => {
    const token = PubSub.subscribe("open-contacts-partners", () => {
      setIsOpen(true)
    })
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  // Reset form when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setFullName("")
      setCompany("")
      setIndustry("")
      setEmail("")
      setMessage("")
      setErrors({})
      setSubmitError(null)
      setIsSubmitting(false)
    }
  }, [isOpen])

  const validateForm = () => {
    const errors = {}
    if (!fullName) errors.fullName = `Please enter your full name.`
    if (!email) errors.email = `Please enter your email address.`
    else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = `Please enter a valid email address.`
    }
    if (!industry) errors.industry = `Please select an industry.`
    if (!message) errors.message = `Please enter your message.`

    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitError(null)
    const validationErrors = validateForm()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }

    setErrors({})
    setIsSubmitting(true)

    try {
      // Construct the payload
      const payload = {
        fullName,
        company,
        industry,
        email,
        message,
      }

      // Send the data using the API function
      const response = await SendUserSiteContact(payload)

      if (response.error) {
        setSubmitError(`Server error: ${response.error}`)
        return
      }

      if (response.result) {
        showNotification({
          message: `Thank you! We'll review your message immediately.`,
          className: "bg-primary text-admin_text_inv",
        })
        setIsOpen(false)
      }
    } catch (error) {
      setSubmitError(
        `An error occurred while submitting your message. Please try again.`
      )
    }
    setIsSubmitting(false)
  }

  return (
    <CMSRender
      contentType="ContactPanelPartners"
      renderItem={(dialog) => {
        return (
          <Modal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            variant="big"
            zIndex={400}
            title={dialog.panel_title}
          >
            <div>
              <form onSubmit={handleSubmit}>
                {/* Full Name */}
                <div className="mb-6">
                  <label
                    className={`${
                      errors.fullName ? "text-error" : "text-text"
                    }`}
                  >
                    <Typography variant="form_label">
                      {dialog.full_name_label}
                    </Typography>
                  </label>

                  <input
                    type="text"
                    value={fullName}
                    onChange={handleInputChange(setFullName, "fullName")}
                    className={`w-full text-text border ${
                      errors.fullName ? "border-error" : "border-bordered"
                    } rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary py-2 px-4`}
                  />
                  {errors.fullName && (
                    <p className="text-error mt-2">{errors.fullName}</p>
                  )}
                </div>

                {/* Company */}
                <div className="mb-6">
                  <label className="text-text">
                    <Typography variant="form_label">
                      {dialog.company_label}
                    </Typography>
                  </label>

                  <input
                    type="text"
                    value={company}
                    onChange={handleInputChange(setCompany, "company")}
                    className="w-full text-text border border-bordered rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary py-2 px-4"
                  />
                </div>

                {/* Industry */}
                <div className="mb-6">
                  <label
                    className={`${
                      errors.industry ? "text-error" : "text-text"
                    }`}
                  >
                    <Typography variant="form_label">
                      {dialog.industry_label}
                    </Typography>
                  </label>

                  <Typography className="relative">
                    <select
                      value={industry}
                      onChange={handleInputChange(setIndustry, "industry")}
                      className={`w-full appearance-none text-gray_1 border ${
                        errors.industry ? "border-error" : "border-bordered"
                      } rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary pl-5 pr-10 py-2`}
                    >
                      <option value="">{`Select your industry`}</option>
                      <option value="technology">{`Technology`}</option>
                      <option value="healthcare">{`Healthcare`}</option>
                      <option value="finance">{`Finance`}</option>
                      <option value="education">{`Education`}</option>
                      <option value="other">{`Other`}</option>
                    </select>

                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                      <svg
                        className="fill-current h-4 w-4 text-text"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </Typography>
                  {errors.industry && (
                    <p className="text-error mt-2">{errors.industry}</p>
                  )}
                </div>

                {/* Email */}
                <div className="mb-6">
                  <label
                    className={`${errors.email ? "text-error" : "text-text"}`}
                  >
                    <Typography variant="form_label">
                      {dialog.email_label}
                    </Typography>
                  </label>

                  <input
                    type="email"
                    value={email}
                    onChange={handleInputChange(setEmail, "email")}
                    className={`w-full text-text border ${
                      errors.email ? "border-error" : "border-bordered"
                    } rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary py-2 px-4`}
                  />
                  {errors.email && (
                    <p className="text-error mt-2">{errors.email}</p>
                  )}
                </div>

                {/* Message */}
                <div className="mb-6">
                  <label
                    className={`${errors.message ? "text-error" : "text-text"}`}
                  >
                    <Typography variant="form_label">
                      {dialog.message_label}
                    </Typography>
                  </label>

                  <textarea
                    value={message}
                    onChange={handleInputChange(setMessage, "message")}
                    className={`w-full text-text border ${
                      errors.message ? "border-error" : "border-bordered"
                    } rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary py-2 px-4`}
                    rows="4"
                  />
                  {errors.message && (
                    <p className="text-error mt-2">{errors.message}</p>
                  )}
                </div>

                {/* Submit Button */}
                <div className="mb-4">
                  <Button
                    type="submit"
                    variant="primary_s"
                    className="mx-auto"
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? `Sending...`
                      : dialog.submit_button[0].label}
                  </Button>

                  {submitError && (
                    <p className="text-error mt-4">{submitError}</p>
                  )}
                </div>

                {/* Agreement */}
                <Typography variant="form_footnote">
                  {tjsx(dialog.agreement_text, {
                    tc: (
                      <a
                        key={"tc"}
                        href={
                          dialog.agreement_links &&
                          dialog.agreement_links[0].link &&
                          dialog.agreement_links[0].link[0]?.url
                        }
                        target="_blank"
                        className="text-primary hover:underline"
                      >
                        {dialog.agreement_links &&
                          dialog.agreement_links[0]?.label}
                      </a>
                    ),
                    pp: (
                      <a
                        key={"pp"}
                        href={
                          dialog.agreement_links[1] &&
                          dialog.agreement_links[1].link &&
                          dialog.agreement_links[1].link[0]?.url
                        }
                        target="_blank"
                        className="text-primary hover:underline"
                      >
                        {dialog.agreement_links && dialog.agreement_links[1].label}
                      </a>
                    ),
                  })}
                </Typography>
              </form>
            </div>
          </Modal>
        )
      }}
    />
  )
}

export default ContactPanelPartners
