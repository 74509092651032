// components/CMS/StatisticsCards.js
import React from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import Typography from "aldoo-ra/Typography"

const StatisticsCardsShort = () => {
  return (
    <div className="flex flex-row w-full items-start justify-center md:justify-start gap-3 md:gap-5">
      <CMSRender
        contentType="StatisticsCardGroup"
        match={{ "content.id": "HomePageStatistics" }}
        renderItem={(group) => {
          return group.cards.map((stat, id) => (
            <div
              key={id}
              className="flex flex-row h-[53px] md:h-auto items-start md:items-center justify-center mx-auto md:mx-0"
            >
              {/* Card 1: Standard Card */}
              {id === 0 && (
                <div className="flex flex-col w-[80px] md:w-auto h-[53px] md:h-[64px] lg:h-[72px] xl:h-[86px] items-center justify-between text-center">
                  <img
                    src={stat.icon}
                    alt={stat.label}
                    className="w-4 md:w-8 h-4 md:h-6 xl:h-8 text-text"
                  />

                  <Typography 
                    variant="body_24_accent"
                    className="text-text text-base lg:!text-[22px] xl:!text-[24px] my-0"
                  >
                    {stat.title}
                  </Typography>

                  <Typography 
                    variant="info_14"
                    className="text-text !text-[10px] lg:!text-[14px] uppercase my-0"
                  >
                    {stat.info}
                  </Typography>
                </div>
              )}

              {/* Card 2: Single Icon Card */}
              {id === 1 && stat.icon && (
                <img
                  src={stat.icon}
                  alt={stat.title}
                  className="h-[53px] md:h-[70px] lg:h-[80px] xl:h-[92px]"
                />
              )}
            </div>
          ))
        }}
      />
    </div> 
  )
}

export default StatisticsCardsShort
