import React, { useState, useRef } from 'react'
import extDB from './extensions.json'
import Button from 'aldoo-ra/Button' // Assuming Button component exists

// Function to look up MIME types from file extensions
const mimeLookUp = (ext) => {
  return extDB[ext] || ''
}

export default function FileUpload({
  label,
  onFileChange,
  formats = [], // Expected formats as extensions, e.g. ['png', 'docx', 'xlsx']
  maxSize = 5 * 1024 * 1024, // Default max file size is 5MB
  error = '',
  className = '',
  multiple = false,
  showSelectedFiles = true,
  onError,
}) {
  const [fileNames, setFileNames] = useState([]) // Track file names
  const fileInputRef = useRef(null) // Reference for the hidden file input

  // Convert formats from extensions to MIME types
  const allowedMimeTypes = formats
    .map((ext) => mimeLookUp(ext))
    .filter((type) => type) // Ensure valid MIME types

  const validate = (file) => {
    const ext = file.name.split('.').pop().toLowerCase()
    const mimeType = mimeLookUp(ext)

    // Check if the file is of the correct format by checking MIME types
    const isValidFormat =
      allowedMimeTypes.length === 0 || allowedMimeTypes.includes(mimeType)

    if (!isValidFormat) {
      const allowedExtensions = formats.join(', ')
      return `Invalid file type. Allowed types are: ${allowedExtensions}`
    }

    const maxSizeInBytes = maxSize * 1024 * 1024
    // Check file size
    if (file.size > maxSizeInBytes) {
      return `${file.name} exceeds the limit of ${maxSize} MB`
    }

    return ''
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files)
    if (files.length === 0) return

    // Validate the files
    const errors = files.map(validate).filter((error) => error)
    if (errors.length > 0) {
      onError && onError(errors)
      return
    }

    // Clear the error messages
    onError([])

    // Update state with selected file names
    setFileNames(files.map((file) => file.name))
    onFileChange(multiple ? files : files[0])
    e.target.value = null // Reset the input
  }

  // Trigger file input click when the button is clicked
  const handleClick = () => {
    fileInputRef.current.click()
  }

  return (
    <div className={`mb-4 ${className}`}>
      {/* Hidden File Input */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        className="hidden"
        accept={
          allowedMimeTypes.length > 0 ? allowedMimeTypes.join(',') : '*/*'
        } // Use MIME types for accept
        multiple={multiple}
      />

      <div className="flex flex-row gap-2 items-center justify-start">
        {/* Add Button */}
        <Button variant="outlined" className="text-admin_text" onClick={handleClick}>
          {multiple ? 'Select Files' : 'Select File'}
        </Button>

        {/* Label */}
        {label && (
          <label className="block text-sm font-medium text-neutral_3">
            {label}
          </label>
        )}

        {/* Display Selected File Names */}
        {showSelectedFiles && (
          <p className="flex-grow mt-2 text-sm text-neutral_3">
            {fileNames?.join(', ')}
          </p>
        )}
      </div>

      {/* Error Message */}
      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  )
}
