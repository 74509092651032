//------------------------------------------------
// Field formatter
// This will convert the text field to an option field
// For example: if the field is a dropdown, it will convert the text to an option
// This is useful for the CMS field editors
// Example: if the field offers options,
// the text value "Option" will be converted to an option object like
//  { value: 'option', name: 'Option' }
// When exporting the data, the value will be converted back to the text
//  'Option'
//------------------------------------------------

import { isInternalType } from "../field-formatter"

export default {
  //fields inside instances of ContentType
  type: "Object",
  preFormat: (fieldValues, Type) => {
    // Don't change fields inside internally used types
    if (isInternalType(Type)) return fieldValues
    //No values, return
    if (!fieldValues) return fieldValues

    const fieldTypes = Type.fields

    for (const field of fieldTypes) {
      if (!field) continue
      if (field.type !== "Object") continue

      const value = fieldValues[field.id]
      if (!value || typeof value !== "object" || Array.isArray(value)) continue

      // Convert object to array of key-value pairs
      fieldValues[field.id] = Object.entries(value).map(([key, value]) => ({
        key,
        value,
      }))
    }

    return fieldValues
  },
  postFormat: (fieldValues, Type) => {
    // Don't change fields inside internally used types
    if (isInternalType(Type)) return fieldValues
    //implement postformat
    const fieldTypes = Type.fields

    for (const field of fieldTypes) {
      if (!field) continue

      //skip non objects
      if (field.type !== "Object") continue
      if (!fieldValues[field.id]) continue
      //example: [ { key: 1, value: 'value1' }, { key: 2, value: 'value2' } ]
      //convert to { 1: 'value1', 2: 'value2' }
      if (
        Array.isArray(fieldValues[field.id]) &&
        fieldValues[field.id].length > 0 &&
        fieldValues[field.id].every((item) => {
          return item.key && item.value && typeof item.key === "string"
        })
      ) {
        const toObject = fieldValues[field.id].reduce((acc, item) => {
          acc[item.key] = item.value
          return acc
        }, {})

        fieldValues[field.id] = toObject
      }
    }

    return fieldValues
  },
  postFormatValue: (value) => {
    //if value is an array of object in the form of { key: 'key', value: 'value' }
    //convert it to an array of strings where:
    // [ "{{key}}: value" ]
    if (
      Array.isArray(value) &&
      value.length > 0 &&
      //every item has key and value fields
      value.every((item) => {
        return item.key && item.value
      })
    )
      return value.map((item) => `{{${item.key}}}: ${item.value}`)

    //return the original value
    return value
  },
  //implement preformat value
  preFormatValue: (value) => {
    //if value is an array of strings in the form of "{{key}}: value"
    //convert it to an array of objects where:
    // [ { key: 'key', value: 'value' } ]
    if (
      Array.isArray(value) &&
      value.length > 0 &&
      //every item is a string
      value.every((item) => typeof item === "string")
    ) {
      return value.map((item) => {
        const [key, value] = item.split(":")
        return { key: key.replace(/{{|}}/g, "").trim(), value: value.trim() }
      })
    }

    //return the original value
    return value
  },
}
