//------------------------------------------------
// Helper: Pre-format a single field or array of fields
//------------------------------------------------
export const preFormatValue = (value) => {
  // If the value is a string (URL), convert it to an object with a 'url' property
  if (typeof value === "string") return { url: value }

  // If the value is an array of strings (URLs), convert each to an object with a 'url' property
  if (Array.isArray(value) && value.every((item) => typeof item === "string")) {
    return value.map((url) => ({ url }))
  }

  return value
}

//------------------------------------------------
// Helper: Post-format a single field or array of fields
//------------------------------------------------
export const postFormatValue = (value) => {

  // If the value is a single object with a 'url' property, return the 'url' string
  if (
    typeof value === "object" &&
    value?.url &&
    Object.keys(value).length === 1
  ) {
    return value.url
  }

  // If the value is an array of objects with 'url' properties, convert to an array of URLs
  if (Array.isArray(value) && value.every((item) => item?.url)) {
    return value.map((item) => item.url)
  }

  return value
}

//------------------------------------------------
// Pre-format the field values for a specific type (e.g., Image, Video, etc.)
//------------------------------------------------
export const preFormat = (fieldValues, Type, TargetFieldType) => {
  const fieldsType = Type.fields

  // Loop through the field types
  fieldsType?.forEach((field) => {
    if (field.type !== TargetFieldType) return // Only process the target field type
    if (!fieldValues[field.id]) return // Skip if there's no value for the field

    // Pre-format the field value
    fieldValues[field.id] = preFormatValue(fieldValues[field.id])
  })
}

//------------------------------------------------
// Post-format the field values for a specific type (e.g., Image, Video, etc.)
//------------------------------------------------
export const postFormat = (fieldValues, Type, TargetFieldType) => {
  const fieldsType = Type.fields

  // Loop through the field types and check if the field is the target type
  fieldsType.forEach((field) => {
    if (field.type !== TargetFieldType) return // Only process the target field type
    if (!fieldValues[field.id]) return // Skip if there's no value for the field

    // Post-format the field value
    fieldValues[field.id] = postFormatValue(fieldValues[field.id])
  })
}
