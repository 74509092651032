import { PaymentElement } from "@stripe/react-stripe-js"
import usePaymentSubmission from "./use-payment-submission"

const CheckoutForm = ({ store }) => {
  const { handleSubmit, ready, setReady, isReady } = usePaymentSubmission(store)

  const { hideCheckout, showCart, getFragment } = store

  const CheckoutFormFooter = getFragment("checkout-form-footer")

  const handleBackToCart = () => {
    hideCheckout()
    showCart()
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        await handleSubmit()
      }}
      style={{ width: "100%" }}
    >
      <div className="w-full mb-4">
        <PaymentElement onReady={() => setReady(true)} />
      </div>

      <div
        className={`w-full transition-all duration-300 ease-in-out ${
          !ready ? "opacity-0 translate-y-4" : "opacity-100 translate-y-0"
        }`}
      >
        <CheckoutFormFooter
          store={store}
          handleBackToCart={handleBackToCart}
          isReady={isReady}
        />
      </div>
    </form>
  )
}

export default CheckoutForm
