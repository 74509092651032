import React, { useState, useEffect, useRef } from "react"
import { XMarkIcon, Bars3Icon, PencilIcon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import ContentEditor from "aldoo-ra/CMS/content-editor/index"
import TypeResolver from "aldoo-ra/CMS/type-resolver"
import Modal from "aldoo-ra/Modal"
import { useFieldDragController } from "aldoo-ra/CMS/controllers/field-drag-controller"
import { showNotification } from "aldoo-ra/Notification"
import Typography from "aldoo-ra/Typography"
import _ from "lodash"

const bundleItemOverview = (item) => {
  if (!item?.product?.name) return "No product selected"

  const quantity = item.quantity > 1 ? ` × ${item.quantity}` : ""
  const priceOverride = item.price ? " (Custom Price)" : ""

  return `${item.product.name}${quantity}${priceOverride}`
}

function BundleItemEditor({
  onChange,
  value,
  field,
  parentValues,
  parentObject,
}) {
  const [localValue, setLocalValue] = useState(value ?? [])
  const [showDialog, setShowDialog] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [itemContentValues, setItemContentValues] = useState({})
  const isInternalChange = useRef(false)

  const { getContentType } = TypeResolver()

  // Unified function to update value and notify parent
  const updateValue = (newValue) => {
    isInternalChange.current = true
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  // Drag controller support
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, updateValue)

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  // Handle external value changes
  useEffect(() => {
    if (!isInternalChange.current && !_.isEqual(value, localValue)) {
      setLocalValue(value ?? [])
    }
    isInternalChange.current = false
  }, [value])

  const handleEdit = (index) => {
    setSelectedIndex(index)
    setItemContentValues(localValue[index] || {})
    setShowDialog(true)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
    setSelectedIndex(null)
  }

  const handleSaveItem = (newValues) => {
    const updatedValues = [...localValue]

    // Ensure quantity is at least 1
    newValues.quantity = Math.max(1, newValues.quantity || 1)

    if (selectedIndex === -1) {
      // Adding new item
      updatedValues.push(newValues)
    } else {
      // Updating existing item
      updatedValues[selectedIndex] = newValues
    }

    updateValue(updatedValues)
    handleCloseDialog()
  }

  const addNewItem = () => {
    setSelectedIndex(-1)
    setItemContentValues({})
    setShowDialog(true)
  }

  const removeItem = (index) => {
    const updatedValues = localValue.filter((_, i) => i !== index)
    updateValue(updatedValues)
  }

  const clearAll = () => {
    updateValue([])
  }

  const renderItem = (item, index) => (
    <div
      key={index}
      className="mt-2 gap-2 flex flex-row items-center w-full"
      draggable
      onDragStart={() => handleDragStart(index)}
      onDragOver={(e) => {
        e.preventDefault()
        handleDragOver(index)
      }}
      onDragEnd={handleDragEnd}
    >
      {localValue.length > 1 && (
        <Bars3Icon className="w-5 h-5 cursor-move text-gray-500 mr-2" />
      )}

      <Typography className="text-admin_text flex flex-row gap-1 items-center">
        {bundleItemOverview(item)}
      </Typography>

      <Button
        variant="text"
        className="ml-5 text-admin_text"
        onClick={() => handleEdit(index)}
      >
        <PencilIcon className="mx-auto w-6 h-6" />
      </Button>

      <Button
        type="outline"
        className="text-admin_text hover:text-white hover:bg-red-700 rounded-full p-2 ml-2"
        onClick={() => removeItem(index)}
      >
        <XMarkIcon className="w-6 h-6" />
      </Button>
    </div>
  )

  const validators = (errors) => ({
    product: (value) => {
      if (!value) {
        errors.product = "Product is required"
      }
    },
    quantity: (value) => {
      if (!value || value < 1) {
        errors.quantity = "Quantity must be at least 1"
      }
    },
  })

  return (
    <div className="array-editor">
      <span className="ml-10 text-sm text-admin_text">
        {localValue.length} {localValue.length === 1 ? "item" : "items"}
      </span>

      {localValue?.map((value, index) => renderItem(value, index))}

      <div className="flex flex-row justify-between items-center mt-4">
        <div className="flex flex-col">
          <label className="block font-semibold text-admin_text">
            {field.label}
            {isRequired && <span className="ml-2 text-error">(required)</span>}
          </label>
          {field.description && (
            <label className="block text-sm text-admin_text">
              {field.description}
            </label>
          )}
        </div>

        {localValue && (
          <div className="flex flex-grow justify-end items-center gap-5 w-[55%]">
            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={addNewItem}
            >
              Add Item
            </Button>

            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={clearAll}
            >
              Clear All
            </Button>
          </div>
        )}
      </div>

      {showDialog && (
        <Modal
          isOpen={showDialog}
          onClose={handleCloseDialog}
          closeOnEscape
          variant="compact"
        >
          <ContentEditor
            title={
              selectedIndex === -1 ? "Add Bundle Item" : "Edit Bundle Item"
            }
            contentType={field.item}
            parentValues={parentValues}
            fieldValues={itemContentValues}
            validators={validators}
            onSave={handleSaveItem}
            onClickSave={handleCloseDialog}
            onError={(errors) => {
              showNotification({
                message: Object.values(errors).find((e) => e),
                className: "bg-red-500 text-white",
              })
            }}
          />
        </Modal>
      )}
    </div>
  )
}

export default BundleItemEditor
