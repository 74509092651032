import React from "react"
import PubSub from "pubsub-js"
import MenuGroup from "aldoo-ra/MenuGroup"
import TrustPilotRate from "@components/Site/TrustPilotRate"
import Copyright from "../Site/Copyright"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"

const FooterDesktop = () => {
  return (
    <div className="hidden xl:block w-full xl:px-px1_xl pt-12 pb-6">
      {/* Footer Menu - upper part */}
      <div className="flex flex-row w-full max-w-w1_xl items-end justify-between mx-auto">
        {/* MenuGroup Columns */}
        <div className="w-[900px] flex flex-row justify-between">
          <MenuGroup
            name="Download Group"
            renderAs={"vlist"}
            variant="vlist_footer"
            rootItemRender={(item) => {
              return (
                <Typography
                  variant="body_24_accent"
                  className="text-primary mt-0 mb-5"
                >
                  {item.label}
                </Typography>
              )
            }}
            itemRender={(item) => {
              return <Button variant="nav_menuitem">
                {item.label}
              </Button>
            }}
          />

          <MenuGroup
            name="Footer Resources"
            renderAs={"vlist"}
            variant="vlist_footer"
            rootItemRender={(item) => {
              return (
                <Typography
                  variant="body_24_accent"
                  className="text-primary mt-0 mb-5"
                >
                  {item.label}
                </Typography>
              )
            }}
            itemRender={(item) => {
              return <Button variant="nav_menuitem">
                {item.label}
              </Button>
            }}
          />

          {/* <MenuGroup
            name="Footer Aqua Mail Store"
            renderAs={"vlist"}
            variant="vlist_footer"
            rootItemRender={(item) => {
              return (
                <Typography
                  variant="body_24_accent"
                  className="text-primary mt-0 mb-5"
                >
                  {item.label}
                </Typography>
              )
            }}
            itemRender={(item) => {
              return <Button variant="nav_menuitem">
                {item.label}
              </Button>
            }}
          /> */}

          <MenuGroup
            name="Footer Menu Other"
            renderAs={"vlist"}
            variant="vlist_footer"
            rootItemRender={(item) => {
              return (
                <Typography
                  variant="body_24_accent"
                  className="text-primary mt-0 mb-5"
                >
                  {item.label}
                </Typography>
              )
            }}
            itemRender={(item) => {
              return <Button variant="nav_menuitem">
                {item.label}
              </Button>
            }}
          />
        </div>

        {/* TrustPilot and Social Share */}
        <div className="w-[400px] flex flex-col items-end mb-10">
          {/* TrustPilot - scaling the imported component */}
          <div className="text-primary">
            <TrustPilotRate />
          </div>

          {/* SiteSocialShare */}
          <MenuGroup
            name="SiteSocialShare"
            renderAs="hlist.icons"
          />

          {/* App Stores */}
          <div className="mt-9">
            <MenuGroup name="MobileStoresBadges" renderAs="hlist.icons" />
          </div>
        </div>
      </div>

      {/* Bottom Notes */}
      <div className="w-full flex gap-[54px] justify-center items-center mt-8 mx-auto">
        <Copyright />

        <MenuGroup
          name="FooterNotes"
          renderAs="hlist"
          itemRender={(item) => {
            return (
              <Button
                variant="nav_menuitem"
                className="!text-xs"
              >
                {item?.label}
              </Button>
            )
          }}
        />
      </div>
    </div>
  )
}

export default FooterDesktop
