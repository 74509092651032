import { useState, useEffect } from "react"
import useLoginWithCredentials from "./login-with-credentials"
import useCreateUserWithCredentials from "./create-with-credentials"
import { VerifyCredentials } from "../api"
import emailValidator from "email-validator"
import ensureDevice from "./ensure-device"

const emailNeedsChecking = (credentials) => {
    //if there is an email field in the auth object and no 3rd party credentials like facebook_id, apple_id, google_id
    //we can validate the email
    const { email, google_id, apple_id, facebook_id } = credentials
    //we can continue since there is at least one other credential
    if (google_id || apple_id || facebook_id) return
    //empty email was passed
    if (!email) return "email_required"
    //the email was invalid
    if (!emailValidator.validate(email)) return "invalid_email"
}

const useSigninUserWithCredentials = () => {
    const [state, setState] = useState()
    const [error, setError] = useState()
    //login
    const {
        startLogin,
        error: loginError,
        state: loginState,
    } = useLoginWithCredentials()
    //create user
    const {
        createUser,
        error: createError,
        state: createState,
    } = useCreateUserWithCredentials()

    //merge the login and create error/state to the signin error/state
    useEffect(() => {
        if (loginError) {
            setError(loginError)
            setState(undefined)
        }
        if (createError) {
            setError(createError)
            setState(undefined)
        }
        if (loginState) {
            setState(loginState)
            setError(undefined)
        }
        if (createState) {
            setState(createState)
            setError(undefined)
        }
    }, [loginError, createError, loginState, createState])

    const signinUser = async (credentials) => {
        setState(undefined)
        setState("pending")

        //see if the user input is valid
        const emailCheck = emailNeedsChecking(credentials)
        if (emailCheck) {
            setError(emailCheck)
            return setState(undefined)
        }

        const deviceID = await ensureDevice(credentials)
        //make sure we have deviceID
        credentials = {
            ...credentials,
            deviceID,
        }

        const { result, error } = await VerifyCredentials(credentials)

        //if there is a result those are valid credentials and we need to login the user
        if (result) return await startLogin(credentials)
        //Create the user if the credentials don't exist
        if (error == "invalid_user") await createUser(credentials)
    }

    return { state, error, signinUser }
}

export default useSigninUserWithCredentials