/**
 * Prints error messages to the console
 */
export default ({ response, url, payload, options }) => {

    if (!response || !response.error) return

    const { error } = response

    if (error === "rate_limited") {
        console.log("Rate Limited:", url)
        return
    }
    if (error === "invalid_request") {
        console.log("Invalid Request:", url, payload)
        return
    }
}