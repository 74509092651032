import renderMenuItem from "../items/render-menu-item"
import renderIcon from "../items/render-icon"
import VariantReader from "aldoo-ra/VariantReader"

const isInteractive = (item) => item?.options?.action || item?.link

// Component for rendering items in horizontal list mode
const HListItems = ({
  menuData,
  handleAction,
  itemRender,
  listLabel,
  renderAs,
  variant,
  styleOverrides,
}) => {
  let { items } = menuData || {}
  if (!items) items = [menuData]

  // Apply icon render if `renderAs` contains ".icons"
  if (renderAs.includes(".icons") && items) {
    items.forEach((item) => {
      if (!item) return
      item.render = renderIcon
    })
  }
  const variants = VariantReader.useVariant({
    componentName: "Menu",
    variant: variant || renderAs,
    styleOverrides,
  })
  const { menu, menu_item } = variants

  return (
    <ul className={menu}>
      {/* Render root item if provided */}
      {listLabel && <li className={menu_item}>{listLabel(menuData)}</li>}
      {/* Render the rest of the items */}
      {items.map((item, index) => {
        const cursorPointer = isInteractive(item) ? "cursor-pointer" : ""
        return (
          <li
            key={index}
            onClick={() => handleAction(item)} // Use handleAction for item clicks
            className={menu_item + " " + cursorPointer}
            role="menuitem"
          >
            {/* Use itemRender if provided, otherwise default to renderMenuItem */}
            {itemRender ? itemRender(item, index) : renderMenuItem(item)}
          </li>
        )
      })}
    </ul>
  )
}

export default HListItems
