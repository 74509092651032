// components/Typography/variants.jsx
export default {
  //"Your All-in-one email app"
  title1: "text-[3rem] md:text-[3.75rem] lg:text-[5.5rem] xl:text-[7rem] text-[clamp(3rem, 2vw, 8.25rem)] text-center font-bold md:whitespace-nowrap leading-normal tracking-custom_1",

  // "The ultimate email experience"
  //h-80px
  title2: "text-[40px] md:text-[48px] lg:text-[60px] xl:text-[80px] 2xl:text-[100px] leading-normal tracking-custom_1 font-bold text-text",

  //h-72px
  h1: "text-[40px] md:text-[44px] lg:text-[56px] xl:text-[68px] 2xl:text-[72px] leading-[60px] md:leading-[80px] lg:leading-tight tracking-custom_1 font-bold text-text",

  //h-48px
  h2: "text-[40px] lg:text-[52px] xl:text-[60px] leading-[60px] md:leading-[80px] lg:leading-tight tracking-custom_1 font-bold text-text",

  //h-40px
  h3: "text-[24px] md:text-[28px] lg:text-[34px] xl:text-[40px] leading-tight tracking-custom_1 font-bold text-text",

  //h-40px, color is set per case
  h3_accent: "text-[24px] md:text-[28px] lg:text-[34px] xl:text-[40px] leading-tight tracking-custom_1 font-black",

  //h-44px
  h4: "text-[24px] md:text-[28px] lg:text-[40px] xl:text-[44px] leading-tight tracking-custom_1 font-bold text-text",

  //h-34px, h5 color is set per case
  h5: "text-[24px] md:text-[34px] leading-tight tracking-custom_1  font-bold",

  //h-56px, banner title, color is set per case
  title_56: "text-[32px] md:text-[40px] lg:text-[46px] xl:text-[56px] leading-tight tracking-custom_1  font-light",

  //h-33px (product header page subtitles)
  subtitle_signature: "text-[16px] md:text-[24px] lg:text-[28px] xl:text-[33px] font-signature leading-tight tracking-custom_2 font-normal text-text my-0",

  //h-32px, color is set per case
  subtitle_32_heavy: "text-[24px] md:text-[26px] lg:text-[28px] xl:text-[32px] leading-tight tracking-custom_1 font-black",

  //h-32px, color is set per case
  subtitle_32_accent: "text-[16px] md:text-[26px] lg:text-[28px] xl:text-[32px] leading-tight tracking-custom_1 font-bold",

  //h-32px
  subtitle_32: "text-[16px] md:text-[26px] lg:text-[28px] xl:text-[32px] leading-tight tracking-custom_1 font-normal text-text",

  //h-30px, color is set per case
  subtitle_30: "text-[16px] md:text-[18px] lg:text-[24px] xl:text-[30px] leading-tight tracking-custom_1 font-normal",

   //h-30px, color is set per case
   subtitle_30_accent: "text-[16px] md:text-[18px] lg:text-[24px] xl:text-[30px] leading-tight tracking-custom_1 font-bold",

  //h-27px, color is set per case
  body_27: "text-[16px] md:text-[18px] lg:text-[22px] xl:text-[27px] leading-normal font-normal tracking-custom_1",

  //h-24px, color is set per case
  body_24_accent: "text-[16px] md:text-[18px] lg:text-[20px] xl:text-[24px] leading-normal tracking-custom_1 font-bold",

  //h-24-16px, color is set per case
  body_24: "text-[16px] md:text-[18px] lg:text-[20px] xl:text-[24px] leading-normal tracking-custom_1 font-normal",

  //h-24-14px, color is set per case
  body_24_minor: "text-[14px] md:text-[18px] lg:text-[20px] xl:text-[24px] leading-normal tracking-custom_1 font-normal",

  //h-22px, color is set per case
  body_22_light: "text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] leading-normal tracking-custom_1 font-light",

  //h-22px, color is set per case
  body_22: "text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] leading-normal tracking-custom_1 font-normal",

  //h-22px, color is set per case
  body_22_accent: "text-[16px] md:text-[18px] lg:text-[20px] xl:text-[22px] leading-normal tracking-custom_1 font-bold",

  //h-20px, (blog-card heading)
  heading_20: "text-[16px] md:text-[18px] xl:text-[20px] leading-normal tracking-custom_1 font-bold",

  //h-20-14px, color is set per case
  body_20_light: "text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-normal tracking-custom_1 font-light",

  //h-20-14px, color is set per case
  body_20: "text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-normal tracking-custom_1 font-normal",

  //h-20-14px, color is set per case
  body_20_accent: "text-[14px] md:text-[16px] lg:text-[18px] xl:text-[20px] leading-normal tracking-custom_1 font-bold",

  //h-20-16px, color is set per case
  body_20_major: "text-[16px] lg:text-[18px] xl:text-[20px] leading-normal tracking-custom_1 font-normal",

  //h-20-16px, color is set per case
  body_20_accent_major: "text-[16px] lg:text-[18px] xl:text-[20px] leading-normal tracking-custom_1 font-bold",

  //h-18-16px, color is set per case
  info_18_light: "text-[16px] md:text-[18px] leading-normal tracking-custom_1 font-light",

  //h-18-14px, color is set per case
  info_18_light_minor: "text-[14px] md:text-[16px] lg:text-[18px] leading-normal tracking-custom_1 font-light",

  //h-18px, color is set per case
  info_18: "text-[16px] md:text-[18px] leading-normal tracking-custom_1 font-normal",

  //h-18px, color is set per case
  info_18_accent: "text-[16px] md:text-[18px] leading-normal tracking-custom_1 font-bold",

  //h-17px, color is set per case
  info_17_medium: "text-[12px] lg:text-[17px] leading-none tracking-custom_1 font-medium",

  //h-16px
  info_16_accent: "text-[12px] md:text-[14px] lg:text-[16px] leading-normal tracking-custom_1 font-bold text-text",

  //h-16px, color is set per case
  info_16_medium: "text-[12px] md:text-[14px] lg:text-[16px] leading-none tracking-custom_wide_1 font-medium",

  //h-16px, color is set per case
  info_16: "text-[12px] md:text-[14px] lg:text-[16px] leading-normal tracking-custom_1 font-normal",

  //h-15px, color is set per case (or is it only gray?)
  info_15: "text-[11px] md:text-[12px] lg:text-[14px] xl:text-[15px] leading-normal tracking-custom_1 font-normal",

  //h-14px
  info_14: "text-[14px] leading-normal font-normal tracking-custom_1 text-text",

  //h-12px, color is set per case (only gray?)
  info_12: "text-[10px] md:text-[12px] leading-normal tracking-custom_1 font-normal",

  //h-12px, color is set per case (only gray?)
  info_12_accent: "text-[10px] md:text-[12px] leading-normal tracking-custom_1 font-bold",

  //h-16px, form labels
  form_label: "text-[14px] md:text-[16px] leading-none tracking-custom_wide_1 font-medium text-text mt-0 mb-4",

  //h-14px, form footnote
  form_footnote: "w-full text-center text-[12px] md:text-[16px] text-gray_1 mx-auto my-0",

  //h-16px, error messages
  error: "text-[14px] md:text-[16px] text-error leading-normal tracking-custom_1 font-normal",
}
