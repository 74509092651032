const formattersByType = {}

const addPreSaveFormatter = (type, formatter) => {
  formattersByType[type] = formatter
}

const getPreSaveFormatter = (type) => {
  return formattersByType[type]
}

export { addPreSaveFormatter, getPreSaveFormatter }
