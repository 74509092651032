import { priceDisplayFormat } from "aldoo-ra/Admin/Store/Prices/display-formatter"
import { url } from "aldoo-ra/CMS/url"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import { XMarkIcon } from "@heroicons/react/24/outline"
import NumericStepper from "aldoo-ra/NumericStepper"
/**
 * This is how we render a product in the Shopping Cart
 */
export default function Product({ product }) {
  return (
    <div className="flex justify-between bg-gray-100 rounded-[10px] flex-row gap-5 items-center p-5">
      <img
        className="w-20 h-20 object-cover rounded-[5px] outline outline-primary shadow-lg outline-2"
        src={url(product.thumbnail)}
        alt={product.name}
      />
      <Typography variant="body1-accent-s">{product.name}</Typography>
      <Typography variant="body1-accent-xl">
        {priceDisplayFormat(product.amount, product.currency)}
      </Typography>

      <NumericStepper
        variant="default"
        readOnly
        styleOverrides={{
          buttonVariant: "outlined",
        }}
        min={1}
        max={10}
        value={product.quantity}
        onStep={(direction) => {
          direction > 0 ? product.add() : product.remove()
        }}
      />
      <Button
        className="w-8 h-8 items-center rounded-full outline outline-primary hover:bg-primary_lt"
        variant=""
        onClick={() => product.clear()}
      >
        <XMarkIcon className="mx-auto w-5 h-5 text-primary" />
      </Button>
    </div>
  )
}
