export default {
  action:
    "h-[40px] bg-primary hover:bg-primary_dk text-white font-bold py-2 px-4 rounded-full transition duration-200 ease-in-out ",
  action_red:
    "h-[40px] bg-red-500 hover:bg-primary_dk text-white font-bold py-2 px-4 rounded-full transition duration-200 ease-in-out ",
  store: "w-[250px] rounded-md py-5 px-5 rounded",
  outlined: "h-[40px] border-solid border font-bold py-2 px-4  rounded-full",
  ["outlined-primary"]:
    "h-[40px] border-solid border font-bold py-2 px-4  rounded-full text-link hover:text-white border-link hover:border-primary hover:bg-primary",
  icon: "h-[40px] border-0 font-bold py-2 px-4 rounded hover:bg-primary_lt",
  user_settings:
    "w-full h-full flex items-center bg-transparent hover:bg-transparent hover:font-bold text-admin_text hover:text-link",
  social_connect: "h-[40px] text-white font-bold py-2 px-4 rounded-full",
  viewer_controls:
    "h-[40px] text-admin_text hover:text-white text-sm font-bold px-3 py-1 bg-neutral_1 hover:bg-neutral_2 rounded-full",
  viewer_controls_small:
    "h-[28px] text-admin_text text-sm font-bold px-3 py-1 bg-neutral_1 hover:bg-neutral_2 rounded",
  primary: "py-2 px-4",
}
