// index.jsx
import React from "react"
import { createRoot } from "react-dom/client"
import ButtonOptionsMenu from "./options-menu"

const DEFAULT_BUTTON_CONFIG = {
  stylePresets: {
    primary:
      "bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg shadow-sm",
    secondary:
      "bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded-lg shadow-sm",
    outline:
      "border-2 border-blue-500 hover:bg-blue-50 text-blue-500 px-6 py-2 rounded-lg",
    ghost: "hover:bg-gray-100 text-gray-700 px-6 py-2 rounded-lg",
    gradient:
      "bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white px-6 py-2 rounded-lg shadow-sm",
  },
  alignments: {
    left: "float-left mr-4",
    center: "mx-auto block",
    right: "float-right ml-4",
  },
}

const createButtonElement = (text = "Click me", options = {}) => {
  const button = document.createElement("a")
  button.href = options.href || "#"
  button.className = `editor-button inline-flex items-center justify-center ${
    options.stylePreset || DEFAULT_BUTTON_CONFIG.stylePresets.primary
  }`
  button.textContent = text
  button.contentEditable = false

  if (options.alignment) {
    const alignmentClass = DEFAULT_BUTTON_CONFIG.alignments[options.alignment]
    const wrapper = document.createElement("div")
    wrapper.className = `editor-button-wrapper ${alignmentClass}`
    wrapper.contentEditable = false
    wrapper.appendChild(button)
    return wrapper
  }

  return button
}

const insertButton = (buttonElement, selectionRange, setSelectionRange) => {
  if (!selectionRange) {
    const editor = document.querySelector(".editor")
    editor.appendChild(buttonElement)
    const range = document.createRange()
    range.setStartAfter(buttonElement)
    range.setEndAfter(buttonElement)
    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    setSelectionRange(range)
    return
  }

  const range = selectionRange.cloneRange()
  range.deleteContents()
  range.insertNode(buttonElement)
  range.setStartAfter(buttonElement)
  range.setEndAfter(buttonElement)
  const selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
  setSelectionRange(range)
}

const addButton = async (data) => {
  const { selectionRange, setSelectionRange, options = {} } = data

  // Get selected text if there is any
  let buttonText = "Click me"
  if (selectionRange && !selectionRange.collapsed) {
    buttonText = selectionRange.toString().trim()
  }

  // Use selected text or fallback to options.text or default
  const text = buttonText || options.text || "Click me"
  const button = createButtonElement(text, options)
  insertButton(button, selectionRange, setSelectionRange)
  return button
}

const updateButtonStyle = (data) => {
  const { selectedButton, stylePreset } = data
  if (!selectedButton) return

  const presetClass = DEFAULT_BUTTON_CONFIG.stylePresets[stylePreset]
  // Remove existing style preset classes
  Object.values(DEFAULT_BUTTON_CONFIG.stylePresets).forEach((className) => {
    const classes = className.split(" ")
    classes.forEach((cls) => selectedButton.classList.remove(cls))
  })
  // Add new preset classes
  presetClass.split(" ").forEach((cls) => selectedButton.classList.add(cls))
}

const updateButtonAlignment = (data) => {
  const { selectedButton, alignment } = data

  if (!selectedButton) return

  const button = selectedButton.classList.contains("editor-button")
    ? selectedButton
    : selectedButton.querySelector(".editor-button")

  const wrapper = button.parentElement

  // Remove existing alignment classes
  Object.values(DEFAULT_BUTTON_CONFIG.alignments).forEach((className) => {
    const classes = className.split(" ")
    classes.forEach((cls) => wrapper.classList.remove(cls))
  })

  // Add new alignment classes
  const alignmentClass = DEFAULT_BUTTON_CONFIG.alignments[alignment]
  alignmentClass.split(" ").forEach((cls) => wrapper.classList.add(cls))
}

const updateButtonText = (data) => {
  const { selectedButton, text } = data
  if (!selectedButton) return

  const button = selectedButton.classList.contains("editor-button")
    ? selectedButton
    : selectedButton.querySelector(".editor-button")
  button.textContent = text
}

const updateButtonLink = (data) => {
  const { selectedButton, href } = data
  if (!selectedButton) return

  const button = selectedButton.classList.contains("editor-button")
    ? selectedButton
    : selectedButton.querySelector(".editor-button")
  button.href = href
}

const deleteButton = (data) => {
  const { selectedButton } = data
  if (!selectedButton) return

  const wrapper = selectedButton.classList.contains("editor-button-wrapper")
    ? selectedButton
    : selectedButton.closest(".editor-button-wrapper")
  wrapper ? wrapper.remove() : selectedButton.remove()
}

const updateButtonBackground = (data) => {
  const { selectedButton, imageUrl } = data
  if (!selectedButton) return

  const button = selectedButton.classList.contains("editor-button")
    ? selectedButton
    : selectedButton.querySelector(".editor-button")

  // Add background image and necessary styles
  button.style.backgroundImage = `url(${imageUrl})`
  button.style.backgroundSize = "cover"
  button.style.backgroundPosition = "center"
  button.style.backgroundRepeat = "no-repeat"

  // Add a semi-transparent overlay to ensure text remains readable
  button.style.position = "relative"
  button.style.zIndex = "1"

  // Remove any existing overlay
  const existingOverlay = button.querySelector(".button-overlay")
  if (existingOverlay) {
    existingOverlay.remove()
  }

  // Create overlay element
  const overlay = document.createElement("div")
  overlay.className = "button-overlay absolute inset-0 opacity-50 -z-[1]"

  // Set overlay color based on button style
  if (button.classList.contains("text-white")) {
    overlay.style.backgroundColor = "rgba(0, 0, 0, 0.4)"
  } else {
    overlay.style.backgroundColor = "rgba(255, 255, 255, 0.8)"
  }

  button.insertBefore(overlay, button.firstChild)
}

const removeButtonBackground = (data) => {
  const { selectedButton } = data
  if (!selectedButton) return

  const button = selectedButton.classList.contains("editor-button")
    ? selectedButton
    : selectedButton.querySelector(".editor-button")

  // Remove background image and related styles
  button.style.backgroundImage = ""
  button.style.backgroundSize = ""
  button.style.backgroundPosition = ""
  button.style.backgroundRepeat = ""

  // Remove overlay if it exists
  const overlay = button.querySelector(".button-overlay")
  if (overlay) {
    overlay.remove()
  }
}

const Button = ({ action, data }) => {
  switch (action) {
    case "add":
      return addButton(data)
    case "style":
      return updateButtonStyle(data)
    case "align":
      return updateButtonAlignment(data)
    case "text":
      return updateButtonText(data)
    case "link":
      return updateButtonLink(data)
    case "delete":
      return deleteButton(data)
    case "bgImage":
      return updateButtonBackground(data)
    case "removeBgImage":
      return removeButtonBackground(data)
    case "optionsMenu":
      return ButtonOptionsMenu(data)
    default:
      console.warn(`Unknown button action: ${action}`)
      return null
  }
}

Button.config = DEFAULT_BUTTON_CONFIG
Button.utils = { createButtonElement, insertButton }

export default Button
