import React, { useState, useCallback, useEffect } from "react"
import { VariantReader } from "aldoo-ra/VariantReader"
import Button from "aldoo-ra/Button"
import Input from "aldoo-ra/Input"

const NumericStepper = ({
  value,
  onChange,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  step = 1,
  label,
  required = false,
  disabled = false,
  readOnly = false,
  variant = "default",
  styleOverrides = {},
  className = "",
  error = "",
  buttonClassName = "",
  inputClassName = "",
  decrementButtonClassName = "",
  incrementButtonClassName = "",
  onStep,
}) => {
  const [internalValue, setInternalValue] = useState(value)

  const variants = VariantReader.useVariant({
    componentName: "NumericStepper",
    variant,
    styleOverrides,
  })

  useEffect(() => {
    if (value !== internalValue) {
      onChange && onChange(internalValue)
    }
  }, [internalValue])

  const handleInputChange = useCallback(
    (e) => {
      const newValue = e.target.value === "" ? "" : Number(e.target.value)

      if (
        newValue === "" ||
        (!isNaN(newValue) && newValue >= min && newValue <= max)
      ) {
        const direction = newValue > value ? 1 : -1
        //announce the direction
        onStep && onStep(direction)
        setInternalValue(newValue)
      }
    },
    [min, max, onChange]
  )

  const handleIncrement = useCallback(() => {
    const newValue = Number(value) + step
    if (newValue <= max) {
      const direction = newValue > value ? 1 : -1
      //announce the direction
      onStep && onStep(direction)

      setInternalValue(newValue)
    }
  }, [value, step, max, onChange])

  const handleDecrement = useCallback(() => {
    const newValue = Number(value) - step
    if (newValue >= min) {
      const direction = newValue > value ? 1 : -1
      onStep && onStep(direction)
      setInternalValue(newValue)
    }
  }, [value, step, min, onChange])

  return (
    <div className={`${variants.wrapper} ${className}`}>
      <div className={variants.controlsWrapper}>
        {!readOnly && (
          <Button
            variant={variants.buttonVariant}
            onClick={handleDecrement}
            disabled={readOnly || disabled || value <= min}
            className={`${variants.button} ${variants.decrementButton} ${buttonClassName} ${decrementButtonClassName}`}
          >
            -
          </Button>
        )}

        <Input
          //   type="number"
          value={value}
          onChange={handleInputChange}
          label={label}
          required={required}
          error={error}
          min={min}
          max={max}
          step={step}
          readOnly={readOnly || disabled}
          variant={variants.inputVariant}
          className={`${variants.input} ${inputClassName}`}
        />

        {!readOnly && (
          <Button
            variant={variants.buttonVariant}
            onClick={handleIncrement}
            disabled={readOnly || disabled || value >= max}
            className={`${variants.button} ${variants.incrementButton} ${buttonClassName} ${incrementButtonClassName}`}
          >
            +
          </Button>
        )}
      </div>
    </div>
  )
}

export default NumericStepper
