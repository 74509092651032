import { preFormat, postFormat } from './file-with-type'
//------------------------------------------------
// Image field formatter
//------------------------------------------------
export default {
  type: 'Image',
  preFormat: (fieldValues, Type) => {
    return preFormat(fieldValues, Type, 'Image')
  },
  postFormat: (fieldValues, Type) => {
    return postFormat(fieldValues, Type, 'Image')
  },
}
