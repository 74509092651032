import HListItems from "./layout/hlist-items"
import VListItems from "./layout/vlist-items"
import PanelItems from "./layout/panel-items"
import VListItemsMenu from "./layout/vlist-items-menu"
import resolveActionHandler from "./tools/resolve-action-handler"

const renderComponents = {
  "vlist.menu": VListItemsMenu,
  hlist: HListItems,
  "hlist.icons": HListItems,
  vlist: VListItems,
  panel: PanelItems,
}

// Main menu component
/**
 *
 menuData,
  actionMap,
  hideOnItemClick = true,
  renderAs = "vlist",
  variant,
  itemWidth,
  itemRender,
  rootItemRender,
  //in case of horizontal list, the label is added as the first item in the list
  listLabel,
  //if true, the items will be treated as menu items with a link
  //and will navigate to the link on click
  itemsAsLinks = false,
  //define custom styles for the menu parts
  styleOverrides,
  maxItemsPerColumn,
  menuAlign,
  submenuMode,
  showMenuMode = "hover",
  //if true, the menu root will be skipped
  skipRootItem,
  onItemClick,
  //expand to move the others down
  containMenu
}
 */

const DEFAULT_OPTIONS = {
  hideOnItemClick: true,
  renderAs: "vlist",
  itemsAsLinks: false,
  showMenuMode: "hover",
  skipRootItem: false,
  menuItemAnimationDelay: 50,
}

const Menu = (options) => {
  //apply default options
  options = { ...DEFAULT_OPTIONS, ...options }

  const { menuData, renderAs } = options
  //filter all items that are enabled === false
  menuData &&
    (menuData.items = menuData.items?.filter((item) => item.enabled !== false))

  //resolve the action handler for the menu based on the provided actionMap
  const handleAction = resolveActionHandler(options)

  //Component parameters
  const parameters = {
    ...options,
    items: menuData?.items ?? [menuData],
    handleAction,
  }

  const Component = renderComponents[renderAs]
  if (!Component) return null
  return <Component {...parameters} />
}

export default Menu
