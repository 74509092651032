import { useState } from "react"
import { motion } from "framer-motion"
import { VariantReader } from "aldoo-ra/VariantReader"

const ToggleSwitch = ({
  isOn,
  onChange,
  variant = "primary",
  styleOverrides,
}) => {
  //ready styles
  const variants = VariantReader.useVariant({
    componentName: "ToggleSwitch",
    variant,
    styleOverrides,
  })

  const { container, background, active, inactive, toggle } = variants

  const handleToggle = () => {
    const newValue = !isOn
    if (onChange) onChange(newValue)
  }

  return (
    <div className={container} onClick={handleToggle}>
      <motion.div
        className={`${background} ${isOn ? active : inactive}`}
        transition={{ duration: 0.2 }}
      />
      <motion.div
        className={toggle}
        drag="x"
        dragConstraints={{ left: 0, right: 30 }}
        dragElastic={0}
        onDragEnd={(event, info) => {
          if (info.offset.x > 15) {
            onChange?.(true)
          } else {
            onChange?.(false)
          }
        }}
        animate={{ x: isOn ? 32 : 0 }}
      />
    </div>
  )
}

export default ToggleSwitch
