import { useState, useRef, useEffect } from "react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import renderMenuItem from "../items/render-menu-item"
import VariantReader from "aldoo-ra/VariantReader"
import RootMenuItem from "../items/root-menu-item"
import createColumns from "../tools/create-columns"
import PubSub from "pubsub-js"

const SubmenuMode = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
}

const DEFAULT_OPTIONS = {
  hideOnItemClick: true,
  menuAlign: "left",
  submenuMode: SubmenuMode.HORIZONTAL,
  showDelay: 200, // Added default show delay
  hideDelay: 300,
}

const VListItemsMenu = (parameters) => {
  parameters = { ...DEFAULT_OPTIONS, ...parameters }

  const {
    items,
    itemRender,
    handleAction,
    styleOverrides,
    renderAs,
    hideOnItemClick,
    itemWidth,
    maxItemsPerColumn = -1,
    menuAlign,
    submenuMode,
    variant,
    parentElement,
    actionMap,
    showDelay,
    hideDelay,
  } = parameters

  const [visibleSubmenu, setVisibleSubmenu] = useState([])
  const itemsRef = useRef([])
  const containerRef = useRef(null)
  const [menuID, setMenuID] = useState(null)
  const showTimeoutRef = useRef(null)
  const hideTimeoutRef = useRef(null)

  // Cleanup timeouts on unmount
  useEffect(() => {
    return () => {
      if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current)
      if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current)
    }
  }, [])

  const variants = VariantReader.useVariant({
    componentName: "Menu",
    variant: variant || renderAs,
    styleOverrides,
  })

  const { menu_item, menu, submenu_item_offset, menu_column } = variants

  const subItems = (items) => {
    if (items.some((item) => item.items)) {
      return items
        .map((subItem) => {
          if (items.length > 0) return subItem.items
          return subItem
        })
        .reduce((acc, val) => acc.concat(val), [])
    }
    return items
  }

  const handleMouseEnter = (index) => {
    // Clear any existing timeouts
    if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current)
    if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current)

    // Set new show timeout
    showTimeoutRef.current = setTimeout(() => {
      setVisibleSubmenu(items[index]?.items?.length > 0 ? [index] : [])
    }, showDelay)
  }

  const handleMouseLeave = () => {
    // Clear any existing timeouts
    if (showTimeoutRef.current) clearTimeout(showTimeoutRef.current)
    if (hideTimeoutRef.current) clearTimeout(hideTimeoutRef.current)

    // Set new hide timeout
    hideTimeoutRef.current = setTimeout(() => {
      setVisibleSubmenu([])
    }, hideDelay)
  }

  const handleSubmenuMouseEnter = () => {
    // Clear hide timeout when entering submenu
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current)
      hideTimeoutRef.current = null
    }
  }

  const isRootLevel = !parentElement
  const hasVisibleSubitems =
    visibleSubmenu.length > 0 && items[visibleSubmenu[0]]?.items?.length > 0

  const renderItems = (items) => {
    if (!items) return null

    const override = {
      ...styleOverrides,
      menu_item: `${submenu_item_offset} ${menu_item}`,
    }

    const visibleItems = items
      .map((item, index) => item.items?.length > 0 && subItems(item.items))
      .filter(Boolean)
      .flat()

    const columns = createColumns(visibleItems, maxItemsPerColumn)

    return (
      <div className="flex flex-row">
        {columns.map((columnItems, columnIndex) => (
          <div
            key={columnIndex}
            className={`flex flex-col ${
              columnIndex > 0 ? "border-l border-gray-200 ml-4 pl-4" : ""
            }`}
          >
            <VListItemsMenu
              items={columnItems}
              parentElement={itemsRef.current[visibleSubmenu[0]]}
              itemRender={itemRender}
              itemWidth={itemWidth}
              handleAction={handleAction}
              actionMap={actionMap}
              renderAs={renderAs}
              skipRoot={true}
              hideOnItemClick={hideOnItemClick}
              styleOverrides={override}
              submenuMode={submenuMode}
              maxItemsPerColumn={maxItemsPerColumn}
              menuAlign={menuAlign}
            />
          </div>
        ))}
      </div>
    )
  }

  const renderMainMenuColumns = () => {
    const columns = createColumns(items, maxItemsPerColumn)

    return (
      <div className="flex flex-row">
        {columns.map((columnItems, columnIndex) => (
          <div key={columnIndex} className={`flex flex-col ${menu_column}`}>
            <ul className={menu}>
              {columnItems.map((item, index) => {
                const globalIndex = columnIndex * maxItemsPerColumn + index
                return (
                  <li
                    key={index}
                    role="menuitem"
                    className={`${menu_item}`}
                    style={{ width: itemWidth || "auto" }}
                    onMouseEnter={() => handleMouseEnter(globalIndex)}
                    ref={(el) => {
                      itemsRef.current[globalIndex] = el
                    }}
                  >
                    <div
                      className="flex flex-row items-center"
                      onClick={() => {
                        handleAction && handleAction(item)
                        if (hideOnItemClick) {
                          PubSub.publish("menu:hide", menuID)
                        }
                      }}
                    >
                      {itemRender
                        ? itemRender(item, index)
                        : renderMenuItem(item)}
                      {item.items?.length > 0 && (
                        <ChevronDownIcon
                          className={`w-4 h-4 ml-2 flex-shrink-0 transition-transform duration-200
                            ${
                              visibleSubmenu.includes(globalIndex)
                                ? submenuMode === SubmenuMode.HORIZONTAL
                                  ? "-rotate-90"
                                  : "rotate-0"
                                : submenuMode === SubmenuMode.HORIZONTAL
                                ? "rotate-0"
                                : "-rotate-90"
                            }`}
                        />
                      )}
                    </div>
                    {submenuMode === SubmenuMode.VERTICAL &&
                      visibleSubmenu.includes(globalIndex) &&
                      item.items?.length > 0 &&
                      renderItems([item])}
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </div>
    )
  }

  return (
    <RootMenuItem {...parameters} getMenuID={setMenuID}>
      <div
        ref={containerRef}
        className={isRootLevel ? "flex flex-row" : ""}
        onMouseLeave={handleMouseLeave}
      >
        {isRootLevel ? (
          renderMainMenuColumns()
        ) : (
          <ul className={menu} onMouseEnter={handleSubmenuMouseEnter}>
            {items.map((item, index) => (
              <li
                key={index}
                role="menuitem"
                className={`${menu_item}`}
                style={{ width: itemWidth || "auto" }}
                onMouseEnter={() => handleMouseEnter(index)}
                ref={(el) => {
                  itemsRef.current[index] = el
                }}
              >
                <div
                  className="flex flex-row items-center"
                  onClick={() => {
                    handleAction && handleAction(item)
                    if (hideOnItemClick) {
                      PubSub.publish("menu:hide", menuID)
                    }
                  }}
                >
                  {itemRender ? itemRender(item) : renderMenuItem(item)}
                  {item.items?.length > 0 && (
                    <ChevronDownIcon
                      className={`w-4 h-4 ml-2 flex-shrink-0 transition-transform duration-200
                        ${
                          visibleSubmenu.includes(index)
                            ? submenuMode === SubmenuMode.HORIZONTAL
                              ? "-rotate-90"
                              : "rotate-0"
                            : submenuMode === SubmenuMode.HORIZONTAL
                            ? "rotate-0"
                            : "-rotate-90"
                        }`}
                    />
                  )}
                </div>
                {submenuMode === SubmenuMode.VERTICAL &&
                  visibleSubmenu.includes(index) &&
                  renderItems(items)}
              </li>
            ))}
          </ul>
        )}

        {submenuMode === SubmenuMode.HORIZONTAL &&
          hasVisibleSubitems &&
          isRootLevel && (
            <>
              <div className="w-px bg-gray-200 mx-4" />
              <div onMouseEnter={handleSubmenuMouseEnter}>
                {renderItems(items)}
              </div>
            </>
          )}
      </div>
    </RootMenuItem>
  )
}

export default VListItemsMenu
