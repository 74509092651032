// options-menu.jsx
import React from "react"
import { createRoot } from "react-dom/client"
import Menu from "../../../../Menu"
import { runCommand } from "aldoo-ra/Commander"

const ButtonOptionsMenu = ({ selectedButton, onClose }) => {
  // Helper to find the actual button element

  const getButtonElement = () => {
    return selectedButton.classList.contains("editor-button")
      ? selectedButton
      : selectedButton.querySelector(".editor-button")
  }

  const render = (item) => {
    return (
      <div className="flex flex-row items-center gap-2">
        {item.icon}
        <span>{item.label}</span>
      </div>
    )
  }

  const menuData = {
    label: "Options",
    items: [
      {
        label: "Style",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 20h16M4 12h16M4 4h16"
            />
          </svg>
        ),
        items: [
          {
            label: "Primary",
            options: { action: "button.style", stylePreset: "primary" },
            render,
          },
          {
            label: "Secondary",
            options: { action: "button.style", stylePreset: "secondary" },
            render,
          },
          {
            label: "Outline",
            options: { action: "button.style", stylePreset: "outline" },
            render,
          },
          {
            label: "Ghost",
            options: { action: "button.style", stylePreset: "ghost" },
            render,
          },
          {
            label: "Gradient",
            options: { action: "button.style", stylePreset: "gradient" },
            render,
          },
        ],
      },
      {
        label: "Alignment",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
            />
          </svg>
        ),
        items: [
          {
            label: "Left",
            options: { action: "button.align", alignment: "left" },
            render,
          },
          {
            label: "Center",
            options: { action: "button.align", alignment: "center" },
            render,
          },
          {
            label: "Right",
            options: { action: "button.align", alignment: "right" },
            render,
          },
        ],
      },
      {
        label: "Link Settings",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
            />
          </svg>
        ),
        items: [
          {
            label: "Set URL",
            options: { action: "button.link" },
            render,
          },
          {
            label: "Open in New Tab",
            options: { action: "button.target" },
            render,
          },
        ],
      },
      {
        label: "Edit Text",
        options: { action: "button.text" },
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
            />
          </svg>
        ),
      },
      {
        label: "Background Image",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        ),
        items: [
          {
            label: "Set Image",
            options: { action: "button.bgImage" },
            render,
          },
          {
            label: "Remove Image",
            options: { action: "button.removeBgImage" },
            render,
          },
        ],
      },
      {
        label: "Delete Button",
        options: { action: "button.delete" },
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        ),
        className: "text-red-500 hover:text-red-600",
      },
    ],
  }

  const actionMap = {
    "button.style": ({ options }) => {
      const { stylePreset } = options
      runCommand("button.style", { selectedButton, stylePreset })
      onClose()
    },
    "button.align": ({ options }) => {
      const { alignment } = options
      runCommand("button.align", { selectedButton, alignment })
      onClose()
    },
    "button.text": () => {
      const button = getButtonElement()
      const newText = prompt("Enter button text:", button.textContent)
      if (newText !== null) {
        runCommand("button.text", { selectedButton, text: newText })
      }
      onClose()
    },
    "button.link": () => {
      const button = getButtonElement()
      const newUrl = prompt("Enter button URL:", button.href)
      if (newUrl !== null) {
        runCommand("button.link", { selectedButton, href: newUrl })
      }
      onClose()
    },
    "button.target": () => {
      const button = getButtonElement()
      const currentTarget = button.getAttribute("target")
      const newTarget = currentTarget === "_blank" ? null : "_blank"
      runCommand("button.target", { selectedButton, target: newTarget })
      onClose()
    },
    "button.bgImage": () => {
      const imageUrl = prompt("Enter background image URL:")
      if (imageUrl) {
        runCommand("button.bgImage", { selectedButton, imageUrl })
      }
      onClose()
    },
    "button.removeBgImage": () => {
      runCommand("button.removeBgImage", { selectedButton })
      onClose()
    },
    "button.delete": () => {
      runCommand("button.delete", { selectedButton })
      onClose()
    },
  }

  const handleClickOutside = (e) => {
    if (!menuContainer.contains(e.target) && e.target !== selectedButton) {
      onClose()
    }
  }

  const menuRect = selectedButton.getBoundingClientRect()

  return (
    <div
      className="fixed z-[1000] ae-button-options-menu"
      style={{
        top: `${menuRect.top + 10}px`,
        left: `${menuRect.left + +10}px`,
      }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className="bg-admin_paper border border-neutral_1 rounded-lg shadow-lg">
        <Menu
          actionMap={actionMap}
          menuData={menuData}
          renderAs="vlist.menu"
          itemWidth="200px"
          submenuMode="vertical"
          hideOnItemClick={false}
          containMenu
        />
      </div>
    </div>
  )
}

const removeExistingMenu = () => {
  const existingMenu = document.querySelector(
    ".ae-button-options-menu-container"
  )
  if (existingMenu) {
    existingMenu.remove()
  }
}

const OptionsMenu = (selectedButton) => {
  if (!selectedButton) return
  removeExistingMenu()

  const menuContainer = document.createElement("div")
  menuContainer.className = "ae-button-options-menu-container"
  document.body.appendChild(menuContainer)

  const handleClickOutside = (e) => {
    if (!menuContainer.contains(e.target) && e.target !== selectedButton) {
      removeExistingMenu()
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }

  document.addEventListener("mousedown", handleClickOutside)

  const root = createRoot(menuContainer)
  root.render(
    <ButtonOptionsMenu
      selectedButton={selectedButton}
      onClose={() => {
        removeExistingMenu()
        document.removeEventListener("mousedown", handleClickOutside)
      }}
    />
  )
}

export default OptionsMenu
