const deployment = {
    APP_NAME: 'Aqua Mail',
    PLATFORM_API_URL: 'https://api.aqua-mail.com',
    PLATFORM_API_VERSION: 'v1',
    STRIPE_API_KEY: 'pk_test_51KHlvMDqI0V60CRMmr5mNWnTqC2u90xXiuxpKou4KpoTH6CxG6mbevcGgIAUGm9xMMUOesVEMAtXDnfPHbri369M00zU24Ryxx',
    APP_DOMAIN: 'aqua-mail.com',
    SUPPORT_CHAT_API: 'https://api.aqua-mail.com/v1/chat',
    CMS_STATIC_URL: 'https://api.aqua-mail.com/cms/static',
    GTM_ID: "GTM-K5TT68R8",
    FACEBOOK_PIXEL: "",
    FACEBOOK_OPTIONS: false,
    TRACKING_GDPR: false,
    GOOGLE_ANALYTICS_ID: "",
    ENABLE_DEBUG_OVERLAY: false
};

export default deployment;
