import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { CheckCircleIcon } from "@heroicons/react/24/outline"

export default function PaymentSuccess({ store, children }) {
  const texts = TextBundle({ id: "aldoo-store" })
  //Store interface
  const { paymentReceipt } = store
  const { paymentInfo } = paymentReceipt

  const getSuccessMessage = () => {
    //Free
    if (paymentInfo.type === "free_subscription") {
      return (
        texts?.free_subscription_success ||
        "{{ Missing }} Your free subscription has been activated successfully!"
      )
    }

    if (paymentInfo.type === "free_purchase") {
      return (
        texts?.free_purchase_success ||
        "{{ Missing }} Your free purchase has been activated successfully!"
      )
    }

    //Trial
    if (paymentInfo.hasTrial) {
      return texts?.trial_success || "{{ Missing }} Your trial has been activated."
    }

    //Subscription
    if (paymentInfo.type === "subscription") {
      return (
        texts?.subscription_success ||
        "{{ Missing }} Your subscription has been activated successfully!"
      )
    }

    //Direct payment
    if (paymentInfo.type === "purchase") {
      return (
        texts?.payment_success || "{{ Missing }} Your payment was processed successfully!"
      )
    }
  }

  return (
    <div className="p-6 flex flex-col items-center text-center">
      <CheckCircleIcon className="w-16 h-16 text-primary mb-4" />

      <Typography 
        component="h1"
        variant="h2" 
        className="text-text mb-4"
      >
        {texts?.thank_you || "{{ Missing }} Thank You!"}
      </Typography>

      <Typography 
        variant="info_18" 
        className="text-text/80 mb-6"
      >
        {getSuccessMessage()}
      </Typography>

      {children}
    </div>
  )
}
