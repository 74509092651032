/**
 * This file defines preformat and postformat functions for the CMS field editors.
 *
 * preformat - this is called when the data is loaded into the field.
 * The server may return data in a different format than the field editor expects.
 * This function allows you to convert the data into the correct format.
 *
 * postformat - this is called when the data is saved. The data representation on the server
 * may be different from the format that the field editor uses. This function allows you to
 */

//Formatters
import objectFormatter from "./field-formatters/object"
import contentTypeFieldFormatter from "./field-formatters/content-type-field"
import imageFormatter from "./field-formatters/image"
import videoFormatter from "./field-formatters/video"
import fileFormatter from "./field-formatters/file"
import contentFormatter from "./field-formatters/content"
import textFormatter from "./field-formatters/text"
import databaseModel from "./field-formatters/database-model"

const formatters = {}
export const register = (options) => {
  const { preFormat, postFormat } = options
  formatters[options.type] = { preFormat, postFormat }
}

export const addFormatter = (type, formatter) => {
  formatters[type] = formatter
}

export const getFormatter = (type) => {
  return formatters[type] || {}
}

//------------------------------------------------
//Register the default formatters
//------------------------------------------------
register(objectFormatter)
register(contentTypeFieldFormatter)
register(imageFormatter)
register(videoFormatter)
register(fileFormatter)
register(contentFormatter)
register(textFormatter)
register(databaseModel)

// Preformat function for the field and its Type
export const preFormatFields = async (fieldValues, Type) => {
  //run all formatters on the fieldValues
  for (const key of Object.keys(formatters)) {
    if (!formatters[key].preFormat) continue
    await formatters[key].preFormat(fieldValues, Type)
  }
  //return the formatted fieldValues
  return fieldValues
}

export const postFormatFields = async (fieldValues, Type) => {
  //run all formatters on the value
  for (const key of Object.keys(formatters)) {
    if (!formatters[key].postFormat) continue
    await formatters[key].postFormat(fieldValues, Type)
  }
  //return the formatted value
  return fieldValues
}

//Don't change fields inside internally used types
export const isInternalType = (Type) =>
  ["ContentType", "FieldType", "Group"].includes(Type.type)
