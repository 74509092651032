//define the api interface used by the CMS
//export the interface the the global API object so other systems can use it
//-------------------------------------
// Content Type System
//-------------------------------------
import { api } from "aldoo-ra/API"
//construct the endpoint
const cmsAPI = api("cms")

/**
 * Save the CMS content
 * @param {*} save_content
 * @returns
 */
export const SaveCMSContent = async (save_content) => {
  return await cmsAPI({ save_content })
}

export const GetCMSContent = async (get_content) => {
  return await cmsAPI({ get_content })
}

export const DeleteCMSContent = async (delete_content) => {
  return await cmsAPI({ delete_content })
}

export const RestoreCMSContent = async (restore_content) => {
  return await cmsAPI({ restore_content })
}

export const SaveCMSType = async (save_type) => {
  return await cmsAPI({ save_type })
}

export const GetCMSType = async (get_type) => {
  return await cmsAPI({ get_type: get_type || {} })
}

export const DeleteCMSType = async (delete_type) => {
  return await cmsAPI({ delete_type })
}

export const RestoreCMSType = async (restore_type) => {
  return await cmsAPI({ restore_type })
}

export const GetCMSTypeUsage = async (type_usage) => {
  return await cmsAPI({ type_usage })
}

export const UploadCMSFile = async (formData, setProgress) => {
  // Add the command field
  formData.append("file_upload", 1)

  // Make the POST request with multipart/form-data content type
  return await cmsAPI(formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      // Calculate the progress as a percentage
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      )
      // Update the progress state
      setProgress(progress)
    },
  })
}

//export the CMS API
export default {
  SaveCMSContent,
  GetCMSContent,
  DeleteCMSContent,
  RestoreCMSContent,
  SaveCMSType,
  GetCMSType,
  DeleteCMSType,
  RestoreCMSType,
  GetCMSTypeUsage,
  UploadCMSFile,
}
