import React, {
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
} from "react"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"

const PromptDialog = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  const [state, setState] = useState({
    title: "",
    message: "",
    onConfirm: () => {},
    onCancel: () => {},
    onClose: () => {},
    showYesBtn: true,
    showNoBtn: true,
    yesLabel: "",
    noLabel: "",
  })

  //Show the dialog
  const show = ({
    title,
    message,
    onConfirm,
    onCancel,
    onClose,
    showYesBtn = true,
    showNoBtn = true,
    yesLabel,
    noLabel,
  }) => {
    setIsOpen(true)

    setState({
      title,
      message,
      onConfirm,
      onCancel,
      onClose,
      showYesBtn,
      showNoBtn,
      yesLabel,
      noLabel,
    })
  }

  const {
    title,
    message,
    onConfirm,
    onCancel,
    onClose,
    showYesBtn,
    showNoBtn,
    yesLabel,
    noLabel,
  } = state
  //export the show function
  useImperativeHandle(ref, () => ({
    show,
  }))

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose()
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center"
      style={{ zIndex: 1000 }}
    >
      <div className="bg-admin_paper rounded-lg shadow-lg w-[340px] sm:w-[400px] p-6">
        <Typography variant="h3" className="mb-4">
          {title}
        </Typography>
        <hr className="border-t border-bordered mb-4" />
        <Typography className="mb-6 text-base text-admin_text_secondary ">
          {message}
        </Typography>
        <div className="flex justify-end space-x-4 mt-5">
          {showYesBtn && (
            <Button
              variant="outlined"
              onClick={() => {
                setIsOpen(false)
                if (onConfirm) onConfirm()
              }}
            >
              {yesLabel || `Yes`}
            </Button>
          )}
          {showNoBtn && (
            <Button
              variant="action"
              className="w-1/4 py-2 px-4 rounded-full"
              onClick={() => {
                setIsOpen(false)
                if (onCancel) onCancel()
              }}
            >
              {noLabel || `No`}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
})

let promptInstance = null

export const showPromptDialog = (options) => {
  if (promptInstance === null) return
  promptInstance.show(options)
}

// Show the prompt dialog and return a promise
// that resolves when the user confirms and rejects
export const showPromptDialogAsync = (options) => {
  return new Promise((resolve, reject) => {
    showPromptDialog({
      ...options,
      onConfirm: () => resolve(true),
      onCancel: () => resolve(false),
    })
  })
}

//PromptDialogProvider
const PromptDialogProvider = () => {
  const promptRef = useRef()

  useEffect(() => {
    promptInstance = promptRef.current
  }, [])

  return (
    <>
      <PromptDialog ref={promptRef} />
    </>
  )
}

export default PromptDialogProvider
