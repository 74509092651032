import { useEffect, useState } from "react"
import { ArrowPathIcon } from "@heroicons/react/24/solid"
import ProgressBar from "aldoo-ra/ProgressBar"
import Modal from "aldoo-ra/Modal"

const LoadingOverlay = ({ title, loading, progress, message }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(loading)
  }, [loading])

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="sm:w-[500px] h-full md:h-auto bg-admin_canvas text-sm sm:text-base"
      zIndex={1000}
      title={title || "Loading"}
    >
      <ArrowPathIcon className="mb-8 mx-auto w-6 h-6 text-primary animate-spin" />
      <div className="text-center text-primary mb-10">
        {message || "Please wait while we load the data"}
      </div>

      {progress !== undefined && (
        <ProgressBar
          title={progress + "%"}
          variant="default"
          progress={progress}
        />
      )}
    </Modal>
  )
}

export default LoadingOverlay
