export default {
  // Default variant - Standard progress bar
  default: {
    container: "relative flex flex-col justify-between w-full h-12",
    titleContainer: "absolute top-0 left-0 w-full flex justify-center",
    title: "text-center text-sm text-admin_text_secondary",
    progressContainer:
      "h-2 absolute bottom-0 left-0 w-full rounded-full bg-gray-200",
    progressFill:
      "h-2 progress-fill rounded-full bg-blue_primary transition duration-200 bg-primary",
  },

  // Slim variant - Thinner bar with smaller text
  slim: {
    container: "relative flex flex-col justify-between w-full h-8",
    titleContainer: "absolute top-0 left-0 w-full flex justify-center",
    title: "text-center text-xs text-admin_text_secondary",
    progressContainer:
      "h-1 absolute bottom-0 left-0 w-full rounded-full bg-gray-200",
    progressFill:
      "h-1 progress-fill rounded-full bg-blue_primary transition duration-200 bg-primary",
  },

  // Large variant - Bigger bar with larger text
  large: {
    container: "relative flex flex-col justify-between w-full h-16",
    titleContainer: "absolute top-0 left-0 w-full flex justify-center",
    title: "text-center text-base text-admin_text_secondary font-medium",
    progressContainer:
      "h-3 absolute bottom-0 left-0 w-full rounded-full bg-blue_secondary",
    progressFill:
      "h-3 progress-fill rounded-full bg-blue_primary transition duration-200",
  },

  // Primary variant - Blue color scheme
  primary: {
    container: "relative flex flex-col justify-between w-full h-12",
    titleContainer: "absolute top-0 left-0 w-full flex justify-center",
    title: "text-center text-sm text-admin_text_secondary",
    progressContainer:
      "h-2 absolute bottom-0 left-0 w-full rounded-full bg-neutral_1",
    progressFill:
      "h-2 progress-fill rounded-full bg-primary transition duration-200",
  },

  // Success variant - Green color scheme
  success: {
    container: "relative flex flex-col justify-between w-full h-12",
    titleContainer: "absolute top-0 left-0 w-full flex justify-center",
    title: "text-center text-sm text-admin_text_secondary",
    progressContainer:
      "h-2 absolute bottom-0 left-0 w-full rounded-full bg-neutral_1",
    progressFill:
      "h-2 progress-fill rounded-full bg-green-500 transition duration-200",
  },

  // Warning variant - Yellow color scheme
  warning: {
    container: "relative flex flex-col justify-between w-full h-12",
    titleContainer: "absolute top-0 left-0 w-full flex justify-center",
    title: "text-center text-sm text-admin_text_secondary",
    progressContainer:
      "h-2 absolute bottom-0 left-0 w-full rounded-full bg-neutral_1",
    progressFill:
      "h-2 progress-fill rounded-full bg-yellow-500 transition duration-200",
  },

  // Compact variant - Minimal height with no title
  compact: {
    container: "relative flex flex-col justify-between w-full h-2",
    titleContainer: "hidden",
    title: "hidden",
    progressContainer: "h-2 w-full rounded-full bg-blue_secondary",
    progressFill:
      "h-2 progress-fill rounded-full bg-blue_primary transition duration-200",
  },

  // Dashboard variant - Styled for dashboard use
  dashboard: {
    container: "relative flex flex-col justify-between w-full h-14",
    titleContainer: "absolute top-0 left-0 w-full flex justify-between px-1",
    title: "text-sm text-admin_text_secondary font-medium",
    progressContainer:
      "h-2 absolute bottom-0 left-0 w-full rounded-full bg-neutral_2",
    progressFill:
      "h-2 progress-fill rounded-full bg-primary transition duration-200 shadow-sm",
  },
}
