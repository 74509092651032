import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import Input from "aldoo-ra/Input"
import Localization from "aldoo-ra/Localization"

export default function ShoppingCartFooter({ store }) {
  const texts = TextBundle({ id: "aldoo-store" })
  const { t } = Localization()

  const {
    setPromoCode,
    setPromoCodeError,
    promoCode,
    getCartTotalValue,
    beginCheckout,
    applyPromoCode,
    promoCodeError,
    getPromoCodeInfo,
    resetPromoCode,
  } = store

  return (
    <div className="w-full flex flex-col items-end justify-between ml-auto">
      {/* Total */}
      <div className="w-full flex items-baseline justify-between gap-4 my-10">
        <Typography 
          variant="body_20_accent"
          className="text-text my-0"
        >
          {texts?.total || "{{ Missing }} Estimated Total"}:
        </Typography>

        <Typography 
          variant="h3" 
          className="text-text my-0"
        >
          {getCartTotalValue()}
        </Typography>
      </div>

      {/* Promo Code Group*/}
      <div className="w-full flex flex-col mb-0">
        <div className="flex flex-row items-center justify-between gap-4">
          <Input
            variant="aqua_input"
            className="w-full"
            // label={`${texts?.promo_code}`}
            showClearButton
            placeholder={texts?.promo_code_placeholder || "{{ Missing }} text"}
            value={promoCode}
            onClear={resetPromoCode}
            onChange={(e) => {
              setPromoCodeError(null)
              setPromoCode(e.target.value.toUpperCase())
            }}
          />

          <Button
            variant="secondary_s"
            className="min-w-[100px] md:min-w-[120px]"
            onClick={applyPromoCode}
          >
            {texts?.promo_code_apply || "{{ Missing }} Apply"}
          </Button>
        </div>

        {/* Promo Code Error message */}
        {
          <Typography variant="info_16_accent" className="my-0">
            {!promoCodeError
              ? getPromoCodeInfo() &&
                store.promoCode &&
                t(texts?.discount, {
                  discount: getPromoCodeInfo(),
                })
              : texts[promoCodeError] || promoCodeError}
          </Typography>
        }
      </div>

      <Button
        variant="primary_s"
        className="w-full mt-4"
        //TODO: Here would would plugin in some sales offers
        //TODO: Before the final checkout
        onClick={() => beginCheckout()}
      >
        {texts?.checkout}
        <ChevronRightIcon className="w-5 h-5 ml-3" />
      </Button>
    </div>
  )
}
