import Menu from "aldoo-ra/Menu"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"

export default function ProductPostPurchase({ item, onAction }) {
  return (
    <div className="flex flex-col items-center justify-center mb-5">
      <Typography variant="body_24_accent" className="mb-4">
        {item.message}
      </Typography>
      <Menu
        menuData={item?.actions[0]}
        renderAs="hlist"
        itemWidth="150px"
        itemsAsLinks={false}
        onItemClick={onAction}
        itemRender={(item) => {
          const url = item?.link[0]?.url
          return (
            <Button variant="primary_s">
              <a href={url}>{item?.label}</a>
            </Button>
          )
        }}
      />
    </div>
  )
}
