// cms-core.js
import { useState, useEffect, useRef } from "react"
import CMSController from "./controllers/cms-controller"
import { usePromise } from "aldoo-ra/usePromise"
import { absoluteURLFields } from "./url"
import useCMSLocalization from "./cms-localization"

const cache = {}

const CMSData = ({
  contentType,
  match,
  sort,
  itemsPerPage = 1000,
  page = 1,
  onData,
  manual = false,
}) => {
  const [isClient, setIsClient] = useState(false)
  const [payload, setPayload] = useState(null)
  const loading = useRef(false)
  const lastMatch = useRef(null)
  const [error, setError] = useState(null)
  const { getContent } = CMSController()
  const { readLocalization, resolveLocalization } = useCMSLocalization()

  // Handle client/server difference
  useEffect(() => {
    setIsClient(true)
  }, [])

  const fetchContent = async () => {
    if (loading.current) return
    try {
      loading.current = true
      const queryMatch = { ...match, "content.type": contentType }
      const query = {
        page,
        itemsPerPage,
        match: queryMatch,
        sort,
        expand: true,
      }
      const key = JSON.stringify({
        contentType,
        match,
        sort,
        itemsPerPage,
        page,
      })

      const cmsContent = cache[key] ? cache[key] : await getContent(query)

      if (!cache[key]) cache[key] = cmsContent

      if (cmsContent?.data) {
        await readLocalization(cmsContent.data)
        setPayload(cmsContent)
        if (onData) {
          onData({
            data: cmsContent.data,
            totalPages: cmsContent.totalPages,
          })
        }
      } else {
        setError("No content available")
      }
    } catch (err) {
      setError("Error fetching CMS content")
      console.error(err)
    } finally {
      loading.current = false
    }
  }

  const { execute, pending, reset } = usePromise(fetchContent, true)

  useEffect(() => {
    if (match && JSON.stringify(lastMatch.current) === JSON.stringify(match))
      return
    if (loading.current) return
    if (pending) return

    lastMatch.current = match
    if (reset) reset()
    if (!manual) execute()
  }, [match])

  useEffect(() => {
    if (loading.current) return
    if (pending) return
    if (execute && !manual) execute()
  }, [page])

  const processedData = payload?.data
    ? payload.data.map(resolveLocalization).map(absoluteURLFields)
    : []

  return {
    data: processedData,
    loading: !isClient || loading.current || pending,
    error,
    totalPages: payload?.totalPages || 0,
    refresh: execute,
    isClient,
  }
}

export default CMSData
