export default {
  // Cards variant - Card-like rows with shadows
  aqua_cards: {
    container: "flex flex-col h-full",
    header: "flex flex-row justify-left items-center mb-4",
    title: "text-text text-[24px] md:text-[34px] tracking-custom_1  font-bold pl-4",
    searchInput:
      "text-text bg-canvas outline-none ml-10 flex-1 w-[220px] h-[35px] px-3 py-2 border border-neutral_2 rounded-lg shadow-sm",
    headerControls: "flex-grow flex justify-end",
    tableWrapper:
      "h-auto flex-col overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-neutral_1 scrollbar-track-canvas",
    table:
      "w-full table-fixed md:table-auto border-separate border-spacing-y-2",
    thead: "sticky top-0 z-10 bg-theme_white text-text",
    headerRow: "text-left text-text text-sm md:text-base xl:text-[18px] font-semibold align-top",
    headerCell: "px-4 py-3",
    row: "h-[70px] text-text text-sm xl:text-base font-light transition-all duration-200",
    cell: "px-4 py-3",
    noDataContainer: "flex items-center justify-center w-full h-full",
    noDataText: "text-text font-medium",
    footer: "mt-6",
    getRowStyle: () => "bg-paper shadow-sm hover:shadow-md rounded-lg",
    getHeaderCellStyle: (headerClassName) =>
      `px-4 py-3 ${headerClassName || ""}`,
    getCellStyle: (itemClassName) => `px-4 py-3 ${itemClassName || ""}`,
  },
}