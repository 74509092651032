import { setParameterInSelection } from "../tools"

/**
 * The action can be Left | Center | Right
 * @param {*} param0
 */
const Color = ({ action }) => {
  if (action === "red") {
    setParameterInSelection("ae-color-red")
  }

  if (action === "blue") {
    setParameterInSelection("ae-color-blue")
  }
}

export default Color
