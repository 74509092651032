import React, { useEffect, useState, useCallback, useRef } from "react"
import Button from "aldoo-ra/Button"
import { XMarkIcon, ClipboardIcon } from "@heroicons/react/24/outline"

const ImagePreview = ({ isOpen, imageUrl, fileName, onClose }) => {
  const [scale, setScale] = useState(1)
  const [isDragging, setIsDragging] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 })
  const [copySuccess, setCopySuccess] = useState({
    filename: false,
    url: false,
  })
  const containerRef = useRef(null)

  const resetView = useCallback(() => {
    setScale(1)
    setPosition({ x: 0, y: 0 })
  }, [])

  const handleCopyFileName = async () => {
    try {
      await navigator.clipboard.writeText(fileName)
      setCopySuccess((prev) => ({ ...prev, filename: true }))
      setTimeout(
        () => setCopySuccess((prev) => ({ ...prev, filename: false })),
        2000
      )
    } catch (err) {
      console.error("Failed to copy filename:", err)
    }
  }

  const handleCopyImageUrl = async () => {
    try {
      await navigator.clipboard.writeText(imageUrl)
      setCopySuccess((prev) => ({ ...prev, url: true }))
      setTimeout(
        () => setCopySuccess((prev) => ({ ...prev, url: false })),
        2000
      )
    } catch (err) {
      console.error("Failed to copy image URL:", err)
    }
  }

  useEffect(() => {
    resetView()
  }, [imageUrl, isOpen, resetView])

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && isOpen) {
        onClose()
      }
    }
    document.addEventListener("keydown", handleEscape)
    return () => {
      document.removeEventListener("keydown", handleEscape)
    }
  }, [isOpen, onClose])

  const handleWheel = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()

    const delta = -Math.sign(e.deltaY)
    const zoomFactor = 0.1

    setScale((prevScale) => {
      const newScale = Math.min(
        Math.max(prevScale + delta * zoomFactor, 0.1),
        5
      )
      return newScale
    })
  }, [])

  useEffect(() => {
    const element = containerRef.current
    if (element && isOpen) {
      const wheelHandler = (e) => handleWheel(e)
      element.addEventListener("wheel", wheelHandler, { passive: false })
      return () => element.removeEventListener("wheel", wheelHandler)
    }
  }, [handleWheel, isOpen])

  const handleMouseDown = useCallback(
    (e) => {
      if (e.button === 0) {
        e.preventDefault()
        e.stopPropagation()
        setIsDragging(true)
        setDragStart({
          x: e.clientX - position.x,
          y: e.clientY - position.y,
        })
      }
    },
    [position]
  )

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault()
        const newX = e.clientX - dragStart.x
        const newY = e.clientY - dragStart.y
        setPosition({
          x: newX,
          y: newY,
        })
      }
    },
    [isDragging, dragStart]
  )

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  const handleSliderChange = (e) => {
    const value = parseFloat(e.target.value)
    setScale(value)
  }

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove)
      window.addEventListener("mouseup", handleMouseUp)
      return () => {
        window.removeEventListener("mousemove", handleMouseMove)
        window.removeEventListener("mouseup", handleMouseUp)
      }
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  useEffect(() => {
    if (isOpen && containerRef.current) {
      containerRef.current.focus()
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex flex-col z-50"
      style={{ zIndex: 1000 }}
      onClick={onClose}
    >
      {/* Top control panel */}
      <div
        className="w-full bg-black bg-opacity-70 p-4 flex items-center gap-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <button
              onClick={handleCopyFileName}
              className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200 text-white"
              aria-label="Copy filename"
            >
              <ClipboardIcon className="w-5 h-5" />
            </button>
            <span className="text-white text-sm">
              {copySuccess.filename ? "Copied!" : fileName}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <button
              onClick={handleCopyImageUrl}
              className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200 text-white"
              aria-label="Copy image URL"
            >
              <ClipboardIcon className="w-5 h-5" />
            </button>
            <span className="text-white text-sm">
              {copySuccess.url ? "Copied!" : "Image URL"}
            </span>
          </div>
        </div>

        <div className="flex-1 flex justify-end items-center gap-4">
          <Button
            variant="outlined"
            className="text-white hover:bg-gray-700 w-32"
            onClick={(e) => {
              e.stopPropagation()
              resetView()
            }}
          >
            Reset View
          </Button>
          <div className="flex items-center gap-2">
            <span className="text-white text-sm">0.1x</span>
            <input
              type="range"
              min="0.1"
              max="5"
              step="0.1"
              value={scale}
              onChange={handleSliderChange}
              className="w-48 h-2 bg-gray-600 rounded-lg appearance-none cursor-pointer"
            />
            <span className="text-white text-sm">5x</span>
            <span className="text-white text-sm ml-2 w-12">
              {scale.toFixed(1)}x
            </span>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation()
              onClose()
            }}
            className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200"
            aria-label="Close preview"
          >
            <XMarkIcon className="w-6 h-6 text-white" />
          </button>
        </div>
      </div>

      {/* Image container */}
      <div
        ref={containerRef}
        className="flex-1 w-full overflow-hidden flex items-center justify-center outline-none"
        style={{ cursor: isDragging ? "grabbing" : "grab" }}
        onMouseDown={handleMouseDown}
        tabIndex={0}
        onClick={(e) => e.stopPropagation()}
      >
        <img
          src={imageUrl}
          alt="Preview"
          className="select-none"
          style={{
            transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
            transition: isDragging ? "none" : "transform 0.1s ease-out",
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          draggable="false"
        />
      </div>
    </div>
  )
}

export default ImagePreview
