// index.jsx
import React from "react"
import { createRoot } from "react-dom/client"
import PreviewDialog from "./preview-dialog"

const togglePreview = (data) => {
  // Remove any existing preview first
  const existingPreview = document.querySelector(".preview-dialog-container")
  if (existingPreview) {
    existingPreview.remove()
    return
  }

  // Create container for preview dialog
  const container = document.createElement("div")
  container.className = "preview-dialog-container"
  document.body.appendChild(container)

  const root = createRoot(container)
  root.render(
    <PreviewDialog
      content={data.getContent()}
      onClose={() => {
        root.unmount()
        container.remove()
      }}
      openHtmlEditor={data.openHtmlEditor}
    />
  )

  // Return cleanup function
  return () => {
    root.unmount()
    container.remove()
  }
}

const PagePreview = ({ action, data }) => {
  switch (action) {
    case "toggle":
      return togglePreview(data)
    default:
      console.warn(`Unknown preview action: ${action}`)
      return null
  }
}

export default PagePreview
