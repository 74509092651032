import React, { useRef, useEffect, useState } from "react"
import Typography from "aldoo-ra/Typography"
import { VariantReader } from "aldoo-ra/VariantReader"

const ProgressBar = ({
  progress = 0,
  title,
  variant = "default",
  styleOverrides,
}) => {
  const styles = VariantReader.useVariant({
    componentName: "ProgressBar",
    variant,
    styleOverrides,
  })
  const progressRef = useRef()
  const [containerWidth, setContainerWidth] = useState(0)

  useEffect(() => {
    const progressBar = progressRef.current
    if (!progressBar) return

    // Create ResizeObserver to handle container width changes
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect
      setContainerWidth(width)
    })

    resizeObserver.observe(progressBar)

    // Initial width measurement
    setContainerWidth(progressBar.getBoundingClientRect().width)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  useEffect(() => {
    const progressBar = progressRef.current
    if (!progressBar || !containerWidth) return

    const progressFill = progressBar.children[0]
    // Ensure progress is between 0 and 1
    const normalizedProgress = Math.max(0, Math.min(1, progress))
    const fillWidth = Math.round(normalizedProgress * containerWidth)
    progressFill.style.width = `${fillWidth}px`
  }, [progress, containerWidth])

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography className={styles.title}>{title}</Typography>
      </div>
      <div ref={progressRef} className={styles.progressContainer}>
        <div className={styles.progressFill} />
      </div>
    </div>
  )
}

export default ProgressBar
