import React from "react"
import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"

const PaymentResultInvalidSession = () => {
  const texts = TextBundle({ id: "aldoo-store" })

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <Typography variant="error">
        {texts?.missing_client_secret || "Invalid payment session"}
      </Typography>
    </div>
  )
}

export default PaymentResultInvalidSession
