import React, { useState } from "react";
import MenuGroup from "aldoo-ra/MenuGroup";
import TrustPilotRate from "@components/Site/TrustPilotRate";
import Copyright from "../Site/Copyright";
import Typography from "aldoo-ra/Typography";
import Button from "aldoo-ra/Button";

const FooterMobile = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  const menuGroups = [
    { name: "Download Group", label: "Download", key: "download" },
    { name: "Footer Resources", label: "Resources", key: "resources" },
    { name: "Footer Aqua Mail Store", label: "Aqua Mail Store", key: "store" },
    { name: "Footer Menu Other", label: "Other", key: "other" },
  ];

  return (
    <div className="flex flex-col items-center xl:hidden w-full px-4 pt-8 pb-[80px] ">
      {/* Accordion for Menu Groups - Temporarily hidden */}
      {/* <div className="flex flex-col gap-4">
        {menuGroups.map(({ name, label, key }) => (
          <div key={key}>
            <div
              className="flex justify-between items-center py-2 border-b border-gray-300 cursor-pointer"
              onClick={() => toggleSection(key)}
            >
              <Typography variant="body_24_accent" className="text-primary">
                {label}
              </Typography>
              <Button variant="icon">{openSection === key ? "-" : "+"}</Button>
            </div>
            {openSection === key && (
              <MenuGroup
                name={name}
                renderAs={"vlist"}
                variant="vlist_footer"
                rootItemRender={(item) => (
                  <Typography
                    variant="body_24_accent"
                    className="hidden"
                  >
                    {item.label}
                  </Typography>
                )}
                itemRender={(item) => (
                  <Button variant="nav_menuitem">{item.label}</Button>
                )}
              />
            )}
          </div>
        ))}
      </div> */}

      {/* TrustPilot, Social Share, Terms, Copyriht */}
      <div className="flex flex-col items-center gap-6">
        <div className="text-primary">
          <TrustPilotRate />
        </div>

        <MenuGroup
          name="SiteSocialShare"
          renderAs="hlist.icons"
          styleOverrides={{
            menu_item: "p-0 m-0",
            menu: "flex gap-6 py-2 my-0",
          }}
        />

        <MenuGroup 
          name="MobileStoresBadges" 
          renderAs="hlist.icons" 
        />

        <MenuGroup
          name="FooterNotes"
          renderAs="hlist"
          styleOverrides={{
            menu_item: "mx-2 my-0 p-0",
          }}
          itemRender={(item) => {
            return (
              <Button
                variant="nav_menuitem"
                className="!text-xs"
              >
                {item?.label}
              </Button>
            )
          }}
        />

        {/* Copyright */}
        <div className="flex justify-center items-center">
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
