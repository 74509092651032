import { api } from "aldoo-ra/API"
//construct the endpoint
const messageAPI = api("user-message")


export const SendUserSiteContact = async (payload) => {
    //the command
    payload = {
        ...payload,
        site_contact: true,
    }
    const formData = new FormData()
    for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value)
    }

    const response = await messageAPI(formData, {
        headers: { "Content-Type": "multipart/form-data" },
    })
    return response.data
}