// edit-link-menu.jsx
import React, { useEffect, useRef } from "react"
import { createRoot } from "react-dom/client"
import { runCommand } from "aldoo-ra/Commander"
import LinkDialog from "./link-dialog"

const EditLinkMenu = ({ link, onClose }) => {
  const menuRef = useRef(null)
  const editorContainer = document.querySelector(".editor")

  // Get link position
  const rect = link.getBoundingClientRect()

  // Calculate position relative to editor
  const menuPosition = {
    top: rect.bottom,
    left: rect.left,
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        const menuContainer = document.querySelector(".link-menu-container")
        if (menuContainer) {
          menuContainer.remove()
        }
      }
    }

    const handleEscape = (event) => {
      if (event.key === "Escape") {
        const menuContainer = document.querySelector(".link-menu-container")
        if (menuContainer) {
          menuContainer.remove()
        }
      }
    }

    const handleEditorScroll = () => {
      const menuContainer = document.querySelector(".link-menu-container")
      if (menuContainer) {
        menuContainer.remove()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("keydown", handleEscape)
    editorContainer.addEventListener("scroll", handleEditorScroll)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("keydown", handleEscape)
      editorContainer.removeEventListener("scroll", handleEditorScroll)
      const menuContainer = document.querySelector(".link-menu-container")
      if (menuContainer) {
        menuContainer.remove()
      }
    }
  }, [])

  const handleEdit = () => {
    const menuContainer = document.querySelector(".link-menu-container")
    if (menuContainer) {
      menuContainer.remove()
    }

    const container = document.createElement("div")
    document.body.appendChild(container)

    const root = createRoot(container)
    root.render(
      <LinkDialog
        defaultUrl={link.href}
        defaultIsDownload={link.hasAttribute("download")}
        defaultOpenInSameTab={link.target === "_self"}
        onClose={(result) => {
          if (result) {
            runCommand("link.update", {
              target: link,
              url: result.url,
              isDownload: result.isDownload,
              openInSameTab: result.openInSameTab,
            })
          }
          root.unmount()
          container.remove()
        }}
      />
    )
  }

  const handleDelete = () => {
    const menuContainer = document.querySelector(".link-menu-container")
    if (menuContainer) {
      menuContainer.remove()
    }
    runCommand("link.delete", { target: link })
  }

  const openInNewTab = () => {
    window.open(link.href, "_blank")
  }

  return (
    <div
      ref={menuRef}
      className="absolute z-[1000]"
      style={{
        top: `${menuPosition.top}px`,
        left: `${menuPosition.left}px`,
      }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className="bg-white rounded-lg shadow-lg border border-gray-200 py-2 min-w-[200px]">
        <div className="px-3 py-1 border-b border-gray-200">
          <a
            href={link.href}
            className="text-blue-600 hover:text-blue-800 text-sm truncate block max-w-full"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.href}
          </a>
        </div>

        <div className="p-1 flex gap-1">
          <button
            className="p-2 hover:bg-gray-100 rounded-lg"
            onClick={openInNewTab}
            title="Open link in new tab"
          >
            <svg
              className="w-5 h-5 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
              />
            </svg>
          </button>
          <button
            className="p-2 hover:bg-gray-100 rounded-lg"
            onClick={handleEdit}
            title="Edit link"
          >
            <svg
              className="w-5 h-5 text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
          </button>
          <button
            className="p-2 hover:bg-gray-100 rounded-lg"
            onClick={handleDelete}
            title="Remove link"
          >
            <svg
              className="w-5 h-5 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditLinkMenu
