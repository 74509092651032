// JavaScript function to toggle the theme
import { useEffect } from "react"

const setTheme = (theme) => {
  // Save the theme to local storage
  localStorage.setItem("theme", theme)
  document.documentElement.setAttribute("data-theme", theme)
}

const getTheme = () => {
  return localStorage.getItem("theme")
}

export default function ThemeController(args) {
  const { onThemeChange } = args || {}
  useEffect(() => {
    const theme = localStorage.getItem("theme")
    //if the theme is in local storage, set it
    if (theme) setTheme(theme)

    if (onThemeChange) onThemeChange(theme)
  }, [])

  useEffect(() => {
    const htmlElement = document.documentElement

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === "data-theme"
        ) {
          const newTheme = htmlElement.getAttribute("data-theme")
          if (onThemeChange) onThemeChange(newTheme)
        }
      })
    })

    observer.observe(htmlElement, {
      attributes: true, // Observe attribute changes
    })

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect()
    }
  }, [])

  return {
    setTheme,
    getTheme,
    onThemeChange,
  }
}
