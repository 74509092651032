import React, { useState, useEffect } from "react"
import Modal from "aldoo-ra/Modal"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"
// Lazy load the ToggleSwitch component
import ToggleSwitch from "aldoo-ra/ToggleSwitch"
import { showNotification } from "aldoo-ra/Notification"
import { SendUserSiteContact } from "aldoo-ra/ContactPanel/api"
import PubSub from "pubsub-js"

const ContactPanel = () => {
  const [questionType, setQuestionType] = useState("")
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [agreed, setAgreed] = useState(false)
  const [file, setFile] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  useEffect(() => {
    const token = PubSub.subscribe("open-contacts", () => {
      setIsOpen(true)
    })
    return () => {
      PubSub.unsubscribe(token)
    }
  }, [])

  //reset the form when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setQuestionType("")
      setMessage("")
      setEmail("")
      setAgreed(false)
      setFile(null)
      setErrors({})
      setSubmitError(null)
      setIsSubmitting(false)
    }
  }, [isOpen])

  const handleQuestionTypeChange = (e) => {
    setQuestionType(e.target.value)
  }

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleAgreementChange = (value) => {
    setAgreed(value)
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    setFile(selectedFile)
  }

  const validateForm = () => {
    const errors = {}
    if (!questionType) errors.questionType = `Please select a question type.`
    if (!message) errors.message = `Please enter a message.`
    if (!email) errors.email = `Please enter an email.`
    if (!agreed) errors.agreed = `You must agree to the terms.`

    //if there is a file and it's more than 20MB, show an error
    if (file && file.size > 20 * 1024 * 1024) {
      errors.file = `File size must be less than 20MB.`
    }

    return errors
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmitError(null)
    const validationErrors = validateForm()
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors)
      return
    }
    setErrors({})

    setIsSubmitting(true)
    try {
      const payload = {
        questionType,
        message,
        email,
        file,
      }

      const response = await SendUserSiteContact(payload)

      if (response.error) {
        setSubmitError(t(`Server error: %error%`, response.error))
        return
      }

      if (response.result) {
        showNotification({
          message: `Thank you! We'll review your message immediately.`,
          className: "bg-primary text-admin_text_inv",
        })
      }
    } catch (error) {
      setSubmitError(
        `An error occurred while submitting your message. Please try again.`
      )
    }
    setIsSubmitting(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      variant="big"
      zIndex={400}
      title={`Contact Us`}
    >
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            {/* Ask a Question */}
            <label
              className={`${
                errors.questionType ? "text-error" : "text-text"
              }`}
            >
              <Typography variant="form_label">
                {`Ask a Question`}
              </Typography>
            </label>

            <Typography className="relative">
              <select
                value={questionType}
                onChange={handleQuestionTypeChange}
                className={`w-full appearance-none text-gray_1 border ${
                  errors.questionType ? "border-error" : "border-bordered"
                } rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary pl-5 pr-10 py-2`}
              >
                <option value="">{`Select an option`}</option>
                <option value="report_issue">{`Report an Issue`}</option>
                <option value="ask_question">{`Ask a Question`}</option>
                <option value="suggest_feature">{`Suggest a Feature`}</option>
                <option value="contact_sales">{`Contact Sales`}</option>
              </select>

              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
                <svg 
                  className="fill-current h-4 w-4 text-text" 
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </Typography>
          </div>

          {/* Message */}
          <div className="mb-6">
            <label
              className={`${
                errors.message ? "text-error" : "text-text"
              }`}
            >
              <Typography variant="form_label">
                {`Message`}
              </Typography>
            </label>

            <textarea
              value={message}
              onChange={handleMessageChange}
              className={`w-full outline-none text-text border ${
                errors.message ? "border-error" : "border-bordered"
              } rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary py-2 px-4`}
              rows="4"
            ></textarea>
          </div>

          {/* Email */}
          <div className="mb-6">
            <label
              className={`${
                errors.email ? "text-error" : "text-text"
              }`}
            >
              <Typography variant="form_label">
                {`Email`}
              </Typography>
            </label>

            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              className={`w-full outline-none text-text border ${
                errors.email ? "border-error" : "border-bordered"
              } rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary py-2 px-4`}
            />
          </div>

          {/* Attach File */}
          <div className="mb-8">
            <label>
              <Typography variant="form_label">
                {`Attach File (optional)`}
              </Typography>
            </label>

            <input
              type="file"
              onChange={handleFileChange}
              className="w-full text-admin_text border border-bordered rounded-[16px] focus:outline-none focus:border-none focus:ring-2 focus:ring-primary py-2 px-4"
            />

            {errors.file && (
              <p className="text-error text-sm mt-2">{errors.file}</p>
            )}
          </div>

          {/* Agreement */}
          <div className="mb-6">
            <div className="flex items-center gap-4 text-text">
              <ToggleSwitch isOn={agreed} onChange={handleAgreementChange} />

              <Typography>
                {`I agree to the`}{" "}
                <a href="/terms-of-use" className="text-primary">
                  {`Terms of Service`}
                </a>{" "}
                {`and`}{" "}
                <a href="/privacy-policy" className="text-primary">
                  {`Privacy Policy`}
                </a>
              </Typography>
            </div>
            <div
              className={`${
                errors.agreed ? "visible" : "invisible"
              } w-full mt-2 h-0.5 bg-error`}
            ></div>
          </div>

          {/* Submit Button */}
          <div className="mb-4">
            <Button
              type="submit"
              variant="primary_s"
              className="mx-auto"
              disabled={isSubmitting}
            >
              {isSubmitting ? `Sending...` : `Submit Request`}
            </Button>

            {Object.keys(errors).length > 0 && (
              <p className="text-error mb-4 text-center">
                {`Please correct the highlighted fields above.`}
              </p>
            )}
            {submitError && <p className="text-error">{submitError}</p>}
          </div>
        </form>

        <div className="w-full flex items-center justify-center">
          <Typography
            variant="form_footnote"
          >
            {`All files are treated confidentially.`}
          </Typography>
        </div>
      </div>
    </Modal>
  )
}

export default ContactPanel