import React, { useState, useEffect, useRef } from "react"
import { useFieldDragController } from "../controllers/field-drag-controller"
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import DatePicker from "aldoo-ra/DatePicker"
import _ from "lodash"

export default function DateEditor({
  onChange,
  value,
  field,
  parentValues,
  parentObject,
  showInfo = true,
  readOnly = false,
}) {
  const getInitialValue = () => {
    // If we have a direct value, use it
    if (value !== undefined && value !== "") {
      return value
    }

    // Handle default value
    let defaultValue = field.default
    if (defaultValue !== undefined) {
      if (field.multiple) {
        return Array.isArray(defaultValue) ? defaultValue : [defaultValue]
      }
      return defaultValue
    }

    // Return empty array for multiple or null for single
    return field.multiple ? [] : null
  }

  const [localValue, setLocalValue] = useState(getInitialValue)
  const isInternalChange = useRef(false)

  // Unified function to update value and notify parent
  const updateValue = (newValue) => {
    isInternalChange.current = true
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  // Drag controller support - moved after updateValue definition
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, updateValue)

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  // Handle external value changes
  useEffect(() => {
    if (!isInternalChange.current && !_.isEqual(value, localValue)) {
      const newValue = getInitialValue()
      setLocalValue(newValue)
    }
    isInternalChange.current = false
  }, [value, field.default, field.multiple])

  const handleValueChange = (updatedValue, index) => {
    try {
      if (field.multiple) {
        const newValues = Array.isArray(localValue) ? [...localValue] : []

        if (index !== undefined) {
          newValues[index] = updatedValue
        } else {
          newValues.push(updatedValue)
        }

        updateValue(newValues)
      } else {
        updateValue(updatedValue)
      }
    } catch (error) {
      console.error("Error in handleValueChange:", error)
    }
  }

  const addNewDate = () => {
    if (readOnly) return
    handleValueChange({ startDate: new Date() })
  }

  const removeDate = (index) => {
    if (readOnly) return
    const updatedValues = localValue.filter((_, i) => i !== index)
    updateValue(updatedValues)
  }

  const clearAll = () => {
    if (readOnly) return
    updateValue(field.multiple ? [] : null)
  }

  const renderItem = (item, index) => (
    <div
      key={index}
      className="mt-2 gap-2 flex flex-row items-center w-full"
      draggable={!readOnly && field.multiple && localValue?.length > 1}
      onDragStart={() => (!readOnly ? handleDragStart(index) : null)}
      onDragOver={(e) => {
        if (readOnly) return
        e.preventDefault()
        handleDragOver(index)
      }}
      onDragEnd={!readOnly ? handleDragEnd : null}
    >
      {!readOnly && field.multiple && localValue?.length > 1 && (
        <Bars3Icon className="w-5 h-5 cursor-move text-gray-500 mr-2" />
      )}

      <DatePicker
        value={item}
        onChange={(date) => handleValueChange(date, index)}
        range={field.fieldEditor === "DateRangeEditor"}
        dateFormat="DD.MM.YY"
        readOnly={readOnly}
      />

      {!readOnly && field.multiple && localValue?.length > 1 && (
        <Button
          type="outline"
          className="text-white hover:bg-red-700 rounded-full p-2"
          onClick={() => removeDate(index)}
          disabled={readOnly}
        >
          <XMarkIcon className="w-6 h-6" />
        </Button>
      )}
    </div>
  )

  return (
    <div className="date-editor">
      {Array.isArray(localValue)
        ? localValue?.map((value, index) => renderItem(value, index))
        : renderItem(localValue, 0)}

      <div className="flex flex-row justify-between items-center">
        {showInfo && (
          <div className="flex flex-col mt-2 mr-5">
            <label className="block font-semibold text-admin_text">
              {field.label}
              {isRequired && (
                <span className="ml-2 text-error">(required)</span>
              )}
            </label>
            {field.description && (
              <label className="block text-sm text-admin_text">
                {field.description}
              </label>
            )}
          </div>
        )}

        <div className="flex items-center space-x-4 mt-4">
          {field.multiple &&
            Array.isArray(localValue) &&
            localValue?.length > 0 && (
              <span className="text-sm text-admin_text">
                {localValue.length} {localValue.length === 1 ? "item" : "items"}
              </span>
            )}

          {!readOnly && field.multiple && (
            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={addNewDate}
              disabled={readOnly}
            >
              Add Date
            </Button>
          )}

          {!readOnly &&
            field.multiple &&
            Array.isArray(localValue) &&
            localValue?.length > 0 && (
              <Button
                variant="outlined"
                className="text-admin_text"
                onClick={clearAll}
                disabled={readOnly}
              >
                Clear
              </Button>
            )}
        </div>
      </div>
    </div>
  )
}
