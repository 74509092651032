import { ChevronDownIcon as ExpandIcon } from "@heroicons/react/24/outline"
import FieldSettingsButton from "../field-settings-button"
import { hasFieldEditor, getFieldEditor } from "../field-editor-resolver"
import evaluateVisibleIf from "./evaluate-visibility"
import { useState } from "react"
import VariantReader from "../../VariantReader"
import defaultVariants from "../variants"

function GroupController({
  contentType,
  dataItem,
  save,
  parentValues,
  localFieldValues,
  setLocalFieldValues,
  errors,
  setErrors,
  enableFieldSettings,
  variant = "default",
  styleOverrides,
}) {
  let variants = VariantReader.useVariant({
    componentName: "CMS",
    variant,
    styleOverrides,
  })

  if (Object.keys(variants).length === 0) variants = defaultVariants[variant]

  //groups
  const [expandedGroups, setExpandedGroups] = useState(() => {
    const initialExpandedState = {}
    contentType?.groups?.forEach((group) => {
      initialExpandedState[group.id] = group.expanded || false
    })
    return initialExpandedState
  })

  const renderComponent = (field) => {
    //set the initial component type to the field type
    const Component = getFieldEditor(field, dataItem)

    // Check if the component is available
    if (!Component) return null
    // Check if the field is read-only
    // This happens if the field has a localization setting turned on
    const readOnly =
      field.localization &&
      dataItem.localization &&
      dataItem.localization.find((item) => item.field === field.id)

    return (
      <Component
        readOnly={!!readOnly}
        parentValues={parentValues}
        parentObject={localFieldValues}
        field={field}
        value={localFieldValues[field.id]}
        onChange={(value) => {
          if (value === undefined) return
          setLocalFieldValues((prev) => ({ ...prev, [field.id]: value }))
          setErrors((prev) => ({ ...prev, [field.id]: null })) // Reset error on change
        }}
      />
    )
  }

  /**
   * Check if a group has any visible fields
   * like contentType.fields where filedName = "fields"
   * @param {*} group
   * @returns
   */
  const hasVisibleFieldsForGroup = (group, fieldName) => {
    // If there are no fields in the content type, return false
    if (!contentType?.fields) {
      return false
    }

    // Filter fields that belong to this group
    const groupFields = contentType[fieldName]?.filter(
      (field) => field.group === group.id
    )

    // If no fields belong to this group, return false
    if (!groupFields || groupFields.length === 0) return false

    // Check if any field in the group is visible based on their visibleIf condition
    const result = groupFields.some((field) => {
      // If field has no visibleIf condition, it's always visible
      if (!field.visibleIf) return true

      // Evaluate the visibleIf condition using the existing evaluateVisibleIf function
      return evaluateVisibleIf(field.visibleIf, localFieldValues)
    })

    return result
  }

  const isGroupEmpty = (group) => {
    return (
      contentType.fields.some((field) => field.group === group.id) ||
      contentType.editors?.some((editor) => editor.group === group.id)
    )
  }

  const toggleGroup = (groupId) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupId]: !prev[groupId],
    }))
  }

  const renderEditor = (editor) => {
    if (!hasFieldEditor({ type: editor.id })) return null
    const Component = getFieldEditor({ type: editor.id })

    //-------------------------------------------------------------------------
    // Resolve the field render
    //-------------------------------------------------------------------------
    let render = editor.render
    //if this is an array, then this is in form of [{ key: "key", value: "value" }]
    if (Array.isArray(editor.render)) {
      //convert the field.render from array to object
      render = editor.render.reduce((acc, item) => {
        acc[item.key] = item.value
        return acc
      }, {})
    }
    const className = render?.variant
      ? variants.editors[render?.variant]
      : variants.editors?.default ?? defaultVariants.editors.default
    //-------------------------------------------------------------------------

    return (
      <div key={editor.id} className={className}>
        <Component
          editor={editor}
          parentObject={localFieldValues}
          //TODO: May be add on change for editors
        />
      </div>
    )
  }

  const renderField = (field) => {
    const isVisible = evaluateVisibleIf(field.visibleIf, localFieldValues)

    //-------------------------------------------------------------------------
    // Resolve the field render
    //-------------------------------------------------------------------------
    let render = field.render
    //if this is an array, then this is in form of [{ key: "key", value: "value" }]
    if (Array.isArray(field.render)) {
      //convert the field.render from array to object
      render = field.render.reduce((acc, item) => {
        acc[item.key] = item.value
        return acc
      }, {})
    }
    const className = render?.variant
      ? variants.fields[render?.variant]
      : variants.fields?.default ?? defaultVariants.fields.default
    //-------------------------------------------------------------------------

    return isVisible && hasFieldEditor(field) ? (
      <div key={field.id} className={className}>
        {renderComponent(field)}
        {/* Localization support per field */}
        {/* If the field.localization is undefined, it will default to true */}
        {enableFieldSettings && !!field.localization && (
          <FieldSettingsButton
            contentType={contentType}
            dataItem={dataItem}
            onContentSave={save}
            field={field}
            fieldValues={localFieldValues}
            setFieldValues={setLocalFieldValues}
            onSettingChange={(newDataItem) => {
              //this will make the component ready only as the
              //data is now handled by the localization system
              dataItem = newDataItem

              //if there is no content
              //that means this is DatabaseModel field
              if (!dataItem.content) {
                setLocalFieldValues(dataItem)
              } else {
                //if there is a content, then we need to update the localFieldValues
                //as this is a CMS field
                //refresh the component
                setLocalFieldValues({ ...localFieldValues })
              }
            }}
          />
        )}
        {/* Field data errors */}
        {errors[field.id] && <p className="text-red-500">{errors[field.id]}</p>}
      </div>
    ) : null
  }

  const renderGroup = (group) => {
    const isExpanded = expandedGroups[group.id] || !group.id

    if (
      !isGroupEmpty(group) ||
      (!hasVisibleFieldsForGroup(group, "fields") &&
        !hasVisibleFieldsForGroup(group, "editors"))
    )
      return null

    const groupVariant = group.render?.variant
      ? variants.groups[group.render?.variant]
      : variants.groups?.default ?? defaultVariants.groups.default

    return (
      <div key={group.id || "default"} className={groupVariant.root}>
        <button
          className={groupVariant.panel}
          onClick={() => toggleGroup(group.id)}
        >
          <div className={groupVariant.panel_content}>
            <h3 className={groupVariant.panel_title}>{group.label}</h3>
            {group.description && (
              <p className={groupVariant.panel_description}>
                {group.description}
              </p>
            )}
          </div>
          <span
            className={`${groupVariant.panel_arrow} transform ${
              isExpanded ? "rotate-180" : ""
            }`}
          >
            <ExpandIcon className={groupVariant.panel_expand_icon} />
          </span>
        </button>
        {isExpanded && (
          <div className={groupVariant.content}>
            {/* render fields */}
            {contentType?.fields
              ?.filter((field) => field.group === group.id)
              .map(renderField)}

            {/* render editors */}
            {contentType?.editors
              ?.filter((field) => field.group === group.id)
              .map(renderEditor)}
          </div>
        )}
      </div>
    )
  }

  const render = () => {
    return [
      //render groups
      ...(contentType?.groups?.map(renderGroup) || []),
      //render fields that are not in a group
      ...[
        renderGroup({
          label: "Fields",
          isExpanded: true,
          description: "",
        }),
      ],
    ]
  }

  return {
    expandedGroups,
    setExpandedGroups,
    render,
  }
}

export default GroupController
