/**
 * Sometimes the fields like DatabaseModel need an external api
 * to know how to manage their data.
 * The type definitions specify apis that are found by a key.
 * The DatabaseModel ( or any other editor ) can get the apis by this key
 */

const apis = {}

export const addApi = (key, api) => {
  apis[key] = api
}

export const getApi = (key) => {
  return apis[key] || {}
}
