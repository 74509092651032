export default {
  primary: {
    container: "relative w-14 h-6 rounded-full cursor-pointer",
    background:
      "absolute inset-0 flex items-center justify-between px-1 rounded-full",
    active: "bg-admin_primary",
    inactive: "bg-admin_neutral_1",
    toggle: "absolute top-0 w-6 h-6 bg-white rounded-full shadow-md",
  },
}
