import React from "react"

const SelectionMenu = ({ visible, runCommand }) => {
  if (!visible) return null

  const menuItems = [
    { label: "Print", command: "print" },
    { label: "Align Left", command: "align.left" },
    { label: "Center", command: "align.center" },
    { label: "Align Right", command: "align.right" },
    { label: "Title", command: "typography.title" },
    { label: "Body", command: "typography.body" },
    { label: "Red", command: "color.red" },
    { label: "Blue", command: "color.blue" },
    { label: "Small", command: "fontSize.small" },
    { label: "Medium", command: "fontSize.medium" },
    { label: "Large", command: "fontSize.large" },
  ]

  return (
    <div className="absolute right-0 top-0 transform translate-x-full ml-4 bg-admin_paper border rounded-lg p-4">
      <div className="flex flex-col gap-2">
        {menuItems.map(({ label, command }) => (
          <button
            key={command}
            onClick={() => runCommand(command)}
            className="text-admin_text hover:text-blue-500 transition-colors text-left px-3 py-2 rounded hover:bg-gray-100"
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  )
}

export default SelectionMenu
