export default {
  //Root Menu Item
  //-------------------------------------------------------------------------
  "vlist.menu": {
    root: "relative inline-block",
    root_item: "flex items-center cursor-pointer px-4 py-2 rounded-md ",
    submenu: "absolute top-full mt-10 p-5 bg-white shadow-lg rounded-lg z-[1200]",
    menu: "list-none space-y-5 p-0 m-0",
    submenu_item_offset: "ml-5 mt-5",
    menu_column: "border-l border-gray-200 ml-4 pl-10",
    menu_item:
      "hover:bg-neutral_1 flex flex-col p-0 m-0 items-left justify-between rounded-md cursor-pointer text-admin_text",
  },

  vlist: {
    root_item: "text-admin_text text-xl font-semibold pl-2 pr-4 py-2",
    /* Removed this space-y-1 setting since it made the row offsets different */
    // menu: "space-y-1",
    menu_column: "border-l border-gray-200 ml-4 pl-4",
    menu_item:
      "list-none text-admin_text flex items-center justify-between px-2 py-0 hover:bg-neutral_1 rounded-md cursor-pointer",
  },

  "hlist.icons": {
    menu: "flex flex-row items-center gap-5",
    menu_item: "",
  },
  hlist: {
    menu: "flex flex-row items-center gap-5",
    menu_item:
      "flex items-center list-none px-4 py-2 rounded-md text-admin_text",
  },

  panel: {
    root: "relative inline-block",
    root_item: "flex items-center cursor-pointer px-4 py-2 rounded-md ",
    submenu:
      "absolute top-full mt-5 p-5 left-0 bg-white shadow-lg rounded-lg z-10",
    menu: "grid grid-cols-2 gap-0 min-w-[700px] m-0 p-0",
    menu_item:
      "m-0 p-0 list-none flex items-start cursor-pointer hover:bg-neutral_1 px-4 py-2 rounded-md",
    menu_item_image: "w-16 h-16 shadow-md",
    menu_item_title: "text-lg font-semibold",
  },
}
