import { wrapSelection, setParameter } from "../tools"

const alignmentTypes = {
  left: "ae-align-left",
  center: "ae-align-center",
  right: "ae-align-right",
}

/**
 * Handles text alignment
 * @param {Object} params - Plugin parameters
 * @param {string} params.action - Alignment type (left|center|right)
 */
const Align = ({ action }) => {
  // Handle text alignment by wrapping in paragraph
  const node = wrapSelection("p")

  // If no node to align, exit
  if (!node) return

  // Remove any existing alignment classes
  Object.values(alignmentTypes).forEach((className) => {
    node.classList.remove(className)
  })

  // Apply new alignment if not left (default)
  const alignClass = alignmentTypes[action]
  if (alignClass) {
    setParameter(node, alignClass)
  }
}

// Add static types for type checking and auto-completion
Align.types = alignmentTypes

export default Align
