import React from "react"
import Typography from "aldoo-ra/Typography"
import Button from "aldoo-ra/Button"
import { ShoppingBagIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { navigate } from "vike/client/router"

export default function ShoppingCartEmpty({ store }) {
  return (
    <div className="flex flex-col items-center justify-center py-16 px-4 bg-gray-50 rounded-lg">
      <div className="bg-primary/10 p-6 rounded-full mb-6">
        <ShoppingBagIcon className="w-12 h-12 text-primary" />
      </div>

      <Typography variant="h4" className="text-center mb-3">
        Your cart is empty
      </Typography>

      <Typography
        variant="body1"
        className="text-center text-gray-600 max-w-md mb-8"
      >
        Looks like you haven't added anything to your cart yet. Discover our
        amazing products and start filling your cart with items you'll love!
      </Typography>

      <Button
        variant="outlined"
        className="flex items-center gap-2 px-6 py-3"
        onClick={() => {
          store.hideCart()
          navigate("/pricing")
        }}
      >
        Start Shopping
        <ArrowRightIcon className="w-4 h-4" />
      </Button>
    </div>
  )
}
