import React from "react"
import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import { ShoppingCartIcon } from "@heroicons/react/24/solid"

export default function ShoppingCartTitle({ store }) {
  const texts = TextBundle({ id: "aldoo-store" })

  return (
    <div className="flex flex-row items-center gap-2 md:gap-3 text-text">
      <ShoppingCartIcon className="w-6 md:w-8 h-6 md:h-8" />

      <Typography
        component="h1"
        variant="subtitle_32_accent"
        className="my-0"
      >
        {texts?.shopping_cart || "{{ Missing }} cart title"}
      </Typography>
    </div>
  )
}
