import React, { useState, useEffect, useCallback } from "react"
import { Editor } from "@monaco-editor/react"
import Modal from "aldoo-ra/Modal"
import Button from "aldoo-ra/Button"

const HtmlEditor = ({ source, isOpen, onUpdate, onClose }) => {
  const [content, setContent] = useState("")

  useEffect(() => {
    // Set the content based on the source prop when it changes
    if (source) {
      setContent(source)
    }
  }, [source])

  // Function to handle the update action
  const handleUpdate = useCallback(() => {
    // Call the onUpdate function passed as a prop with the updated content
    if (onUpdate) {
      const result = content.replace(/<\/br>/g, "")
      onUpdate(result)
    }
  }, [content, onUpdate])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEscape={true}
      className="w-[900px] h-[665px] bg-gray-800 text-white rounded-lg"
    >
      <div className="flex flex-col h-full">
        <div className="flex justify-center items-center">
          <h3 className="text-2xl font-bold mb-4">HTML Editor</h3>
        </div>
        <div className="flex-grow overflow-hidden h-[500px]">
          <Editor
            width="100%"
            height="100%"
            language="html"
            value={content}
            onChange={(value) => setContent(value)}
            theme="vs-dark"
            options={{
              minimap: { enabled: false },
              scrollBeyondLastLine: false,
              lineNumbers: "on",
              wordWrap: "on",
            }}
          />
        </div>
        <Button
          onClick={handleUpdate}
          className="mt-4 w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Update
        </Button>
      </div>
    </Modal>
  )
}

export default HtmlEditor
