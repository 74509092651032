import React from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import Menu from "aldoo-ra/Menu"

const MenuGroup = ({ ...options }) => {
  //if itemsAsLinks is not defined in options, set it to true
  if (options.itemsAsLinks === undefined) options.itemsAsLinks = true
  return (
    <div className="flex flex-col gap-10">
      <CMSRender
        contentType="MenuItem"
        match={{ "content.name": options.name, "content.enabled" : true }}
        preProcessItem={options.preProcessItem}
        onData={options.onData}
        renderItem={(menu) => {
          return <Menu {...options} menuData={menu} />
        }}
      />
    </div>
  )
}

export default MenuGroup
