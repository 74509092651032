import { replaceTag, unwrapSelection, wrapSelection } from "../tools"

/**
 * The action can be Left | Center | Right
 * @param {*} param0
 */
const Typography = ({ action }) => {
  if (action == "title") {
    const selectedText = window.getSelection().toString()
    const element = wrapSelection("h2")
    element.id = selectedText.toLowerCase().replace(/\s/g, "-")
  }
  if (action == "body") {
    unwrapSelection("h2")
  }
}
export default Typography
