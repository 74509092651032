import Menu from "aldoo-ra/Menu"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"
import Value from "aldoo-ra/Value"

export default function ProductPostPurchase({ item, onAction }) {
  const [platform, setPlatform] = Value("site_platform")

  return (
    <div className="flex flex-col items-center justify-bet mb-5">
      <Typography 
        variant="body_24_accent" 
        className="text-text mb-4">
        {item.message}
      </Typography>
      
      {/* Features button */}
      <Menu
        menuData={item?.actions[0]}
        renderAs="hlist"
        itemsAsLinks={false}
        onItemClick={onAction}
        itemRender={(item) => {
          const url =
            (item?.link[0]?.parameters &&
              item?.link[0]?.parameters[platform]) ||
            item?.link[0]?.url
          return (
            <Button variant="primary_s">
              <a href={url}>{item?.label}</a>
            </Button>
          )
        }}
      />
    </div>
  )
}
