import { useEffect, useRef } from "react"
import PlatformAuth from "aldoo-ra/PlatformAuth"
const { persistKey } = PlatformAuth
const localTabId = Math.random().toString(36).substr(2, 9) // Generate a random tab ID

export default function EnsureStableLogin() {
  const inMemoryID = useRef(null)
  const checker = useRef(null)

  const stopChecker = () => {
    if (checker.current) clearInterval(checker.current)
  }

  const startChecker = () => {
    if (checker.current) clearInterval(checker.current)
    checker.current = setInterval(() => {
      //get the local storage credentials
      const auth = JSON.parse(localStorage.getItem(persistKey))
      const activeTab = localStorage.getItem("activeTab")
      const localTab = sessionStorage.getItem("activeTab")

      //the user is performing the action on the current tab
      //so no need to check
      if (activeTab === localTab) return
      //the user has logged out or logged in with a different account
      if (inMemoryID.current && inMemoryID.current != auth?._id) {
        window.location.reload()
      }

      //the user has logged in
      if (!inMemoryID.current && auth?._id) window.location.reload()
    }, 1000)
  }

  useEffect(() => {
    //keep the same tab active when the user interacts with it
    const activeTab = sessionStorage.getItem("activeTab") || localTabId

    const markTabAsActive = () => {
      localStorage.setItem("activeTab", activeTab)
    }

    // Mark the tab as active when the component mounts
    // only if there is no active tab in the session storage
    if (!sessionStorage.getItem("activeTab")) {
      markTabAsActive()
      sessionStorage.setItem("activeTab", activeTab)
    }

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        stopChecker()
      } else {
        // If the tab is not visible, start the checker
        startChecker()
      }
    }

    document.addEventListener("visibilitychange", handleVisibilityChange)

    // Mark the tab as active whenever the user interacts with it
    window.addEventListener("focus", markTabAsActive)

    return () => {
      window.removeEventListener("focus", markTabAsActive)
      document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem(persistKey))
    //get the local storage credentials
    inMemoryID.current = auth?._id

    startChecker()

    return () => clearInterval(checker.current)
  }, [])
}
