// components/Icon.jsx
import React from "react"
import CMSRender from "aldoo-ra/CMS/cms-render"
import Typography from "aldoo-ra/Typography"
import Localization from "aldoo-ra/Localization"

const Text = (params) => {
  const { id, data: replacement } = params
  const { t, tjsx } = Localization()

  const containsMarkup =
    replacement &&
    Object.entries(replacement).some(([key, value]) => {
      return typeof value === "object"
    })

  const replacer = containsMarkup ? tjsx : t

  return (
    <CMSRender
      contentType="Text"
      match={{ "content.id": id }}
      renderItem={(data) => {
        return (
          <Typography {...params}>
            {replacer(data.text, replacement)}
          </Typography>
        )
      }}
    />
  )
}

export default Text
