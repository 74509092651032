import React, { useState, useEffect, useRef } from "react"
import Button from "aldoo-ra/Button"
import BlogEditor from "aldoo-ra/Blog/Editor"
import Modal from "aldoo-ra/Modal"
import { showPromptDialog } from "../../PromptDialog"
import _ from "lodash"

export default function BlogContentEditor({
  onChange,
  value,
  field,
  parentValues,
  parentObject,
  showInfo = true,
  readOnly = false,
  editButtonTitle,
}) {
  const getInitialValue = () => {
    // If we have a direct value, use it
    if (value !== undefined && value !== "") {
      return value
    }
    // Otherwise check for default value
    if (field.default !== undefined) {
      return field.multiple ? [field.default] : field.default
    }
    // Return empty string instead of null/undefined for editor
    return ""
  }

  const [localValue, setLocalValue] = useState(getInitialValue())
  const [openContentEditor, setOpenContentEditor] = useState(false)
  const isInternalChange = useRef(false)

  const title = parentObject && (parentObject.name || parentObject.title)

  if (!editButtonTitle) {
    editButtonTitle = readOnly ? "View" : "Edit"
  }

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  // Unified function to update value and notify parent
  const updateValue = (newValue) => {
    isInternalChange.current = true
    setLocalValue(newValue || "") // Ensure we never set null/undefined
    onChange?.(newValue || "")
  }

  // Handle external value changes
  useEffect(() => {
    if (!isInternalChange.current && !_.isEqual(value, localValue)) {
      const newValue = getInitialValue()
      setLocalValue(newValue)
    }
    isInternalChange.current = false
  }, [value, field.default, field.multiple])

  const editContent = () => {
    if (readOnly && !localValue) {
      showPromptDialog({
        title: "No content yet",
        message: "Please add content first",
        showNoBtn: false,
        yesLabel: "Ok",
      })
      return
    }
    setOpenContentEditor(true)
  }

  const handleSave = (content) => {
    updateValue(content)
    setOpenContentEditor(false)
  }

  const handleClose = () => {
    setOpenContentEditor(false)
  }

  const renderItem = (item, index) => {
    if (!item) return null

    return (
      <div className="flex text-admin_text items-center space-x-2" key={index}>
        {/* Add any content preview if needed */}
      </div>
    )
  }

  return (
    <div className="text-editor">
      {renderItem(localValue, 0)}

      <div className="flex flex-row justify-between items-center">
        {showInfo && (
          <div className="flex flex-col mt-2 mr-5">
            <label className="block font-semibold text-admin_text">
              {field.label}
              {isRequired && (
                <span className="ml-2 text-error">(required)</span>
              )}
            </label>
            {field.description && (
              <label className="block text-sm text-admin_text">
                {field.description}
              </label>
            )}
          </div>
        )}

        <div className="flex items-center space-x-4 mt-4">
          <Button
            variant="outlined"
            className="text-admin_text"
            onClick={editContent}
          >
            {editButtonTitle}
          </Button>
        </div>
      </div>

      {openContentEditor && (
        <Modal
          isOpen={true}
          onClose={handleClose}
          closeOnEscape
          variant="fullScreen"
          size="fullscreen"
        >
          <BlogEditor
            readOnly={readOnly}
            title={title}
            content={localValue || ""}
            onSave={handleSave}
          />
        </Modal>
      )}
    </div>
  )
}
