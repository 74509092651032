import React from "react"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import Button from "aldoo-ra/Button"
import Localization from "aldoo-ra/Localization"

export default function ShoppingCartHeaderContent({ store }) {
  const texts = TextBundle({ id: "aldoo-store" })
  const { t } = Localization()
  const { cart } = store

  return (
    <>
      <span className="mr-5 px-4 py-3 text-gray-500 rounded-full bg-gray-200 px-2">
        {t(texts?.items, { count: cart.length })}
      </span>
      <Button
        variant="outlined"
        className="flex items-center gap-2 px-2 mr-[50px] items-center"
        onClick={() => {
          store.emptyCart()
        }}
      >
        {texts?.empty_cart}
      </Button>
    </>
  )
}
