import React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"
import Spinner from "aldoo-ra/Spinner"

const CheckoutFormFooter = ({ store, handleBackToCart, isReady }) => {
  const texts = TextBundle({ id: "aldoo-store" })
  const { getCartTotalValue, paymentInfo } = store

  return (
    <div className="flex justify-between items-center mt-8 gap-4">
      {/* Back to Cart Button */}
      <Button
        variant="outlined"
        onClick={handleBackToCart}
        className="flex items-center"
      >
        <ChevronLeftIcon className="w-5 h-5 mr-2" />
        <Typography variant="">
          {texts?.back_to_cart || "Back to Cart"}
        </Typography>
      </Button>

      {/* Total Amount Display */}
      <div className="flex flex-col items-center">
        <Typography className="m-0 p-0" variant="body1-accent-s">
          {texts?.total}:
        </Typography>
        <Typography className="m-0 p-0" variant="h3">
          {getCartTotalValue()}
        </Typography>
      </div>

      {/* Payment Button */}
      <Button
        variant="action"
        type="submit"
        disabled={!isReady}
        className="min-w-[200px] flex justify-center text-center items-center"
      >
        <Spinner
          variant="loading"
          className={`${!isReady ? "block" : "hidden"} w-5 h-5 mr-2 text-white`}
        />

        {isReady && (
          <>
            <Typography variant="">
              {paymentInfo.hasTrial
                ? texts?.save_payment_method
                : texts?.make_payment}
            </Typography>
            <ChevronRightIcon className="ml-2 w-5 h-5" />
          </>
        )}
      </Button>
    </div>
  )
}

export default CheckoutFormFooter
