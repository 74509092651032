import config from "aldoo-ra/Config"
import React from "react"
import HeaderMenuDesktop from "./HeaderMenuDesktop"
import HeaderMenuMobile from "./HeaderMenuMobile"

export default function Navbar() {
  return (
    <div // Ensures the layout sections following the header are not hidden behind it
      className="relative flex h-0 lg:h-[80px] w-full flex-col items-center justify-center"
      style={{ zIndex: 200 }}
    >
      <div className="hidden lg:flex">
        <HeaderMenuDesktop />
      </div>

      <div className="flex lg:hidden">
        <HeaderMenuMobile />
      </div>
    </div>
  )
}