export default function ShoppingCartList({ store }) {
  const { cart } = store
  //this is how we render a product in the cart
  const ShoppingCartProduct = store.getFragment("shopping-cart-product")
  return (
    <div className="flex flex-col gap-2 ">
      {cart.map((product, index) => (
        <ShoppingCartProduct key={index} product={product} />
      ))}
    </div>
  )
}
