import CMSData from "aldoo-ra/CMS/cms-data"

export default function ProductPostPurchase({ store, product_id, onAction }) {
  const { getFragment } = store

  const ProductPostPurchaseFragment = getFragment("product-post-purchase")

  const { data } = CMSData({
    contentType: "PaymentSuccessMessage",
    match: {
      $or: [
        { "content.product.item": product_id },
        { "content.id": "default" },
      ],
    },
  })

  if (!data) return null

  //Find the item that matches the product id
  //If there is no item that matches the product id, return the default message
  const item =
    data.length > 1 ? data.find((item) => item.id !== "default") : data[0]

  //If there is no item, return null
  if (!item) return

  return <ProductPostPurchaseFragment item={item} onAction={onAction} />
}
