import { setParameterInSelection } from "../tools"

/**
 * The action can be Left | Center | Right
 * @param {*} param0
 */
const FontSize = ({ action }) => {
  if (action === "small") {
    setParameterInSelection("ae-fontsize-small")
  }

  if (action === "medium") {
    setParameterInSelection("ae-fontsize-medium")
  }

  if (action === "large") {
    setParameterInSelection("ae-fontsize-large")
  }
}

export default FontSize
