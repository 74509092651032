export default {
  default: {
    container: "inline-flex items-center cursor-pointer text-admin_text",
    checkbox:
      "w-6 h-6 border-2 border-gray-400 rounded-full flex items-center justify-center",
    checkbox_checked: "bg-primary",
    checkbox_unchecked: "bg-none",
    checkmark: "h-4 w-4 text-text",
    label: "text-sm font-medium",
    label_left: "mr-2",
    label_right: "ml-2",
    placeholder: "w-4 h-4 bg-transparent",
  },
  // You can add additional variants here
  compact: {
    container: "inline-flex items-center cursor-pointer text-admin_text",
    checkbox:
      "w-4 h-4 border-2 border-gray-400 rounded-full flex items-center justify-center",
    checkbox_checked: "bg-primary",
    checkbox_unchecked: "bg-none",
    checkmark: "h-3 w-3 text-white",
    label: "text-xs font-medium",
    label_left: "mr-1",
    label_right: "ml-1",
    placeholder: "w-3 h-3 bg-transparent",
  },
  large: {
    container: "inline-flex items-center cursor-pointer text-admin_text",
    checkbox:
      "w-8 h-8 border-2 border-gray-400 rounded-full flex items-center justify-center",
    checkbox_checked: "bg-primary",
    checkbox_unchecked: "bg-none",
    checkmark: "h-6 w-6 text-white",
    label: "text-base font-medium",
    label_left: "mr-3",
    label_right: "ml-3",
    placeholder: "w-6 h-6 bg-transparent",
  },
}
