import { useEffect, useRef, useState } from 'react'
import CMSController from './cms-controller'

export function useFieldEditorController({
  value,
  field,
  onChange,
  category = 'files',
}) {
  const [localValue, setLocalValue] = useState(value)
  const [errors, setErrors] = useState([])
  const controller = CMSController()
  const isMounted = useRef(false) // Track if the component is mounted

  useEffect(() => {
    if (!value) return
    if (JSON.stringify(localValue) === JSON.stringify(value)) return
    setLocalValue(value)
  }, [value])

  // Trigger onChange when localValue changes, syncing back to the parent
  useEffect(() => {
    // Prevent onChange on the first mount
    if (!isMounted.current) {
      isMounted.current = true
      return
    }

    if (JSON.stringify(localValue) === JSON.stringify(value)) return
    // Trigger onChange only when the value changes
    onChange(localValue)

  }, [localValue])

  const handleChange = (files) => {
    if (!files || files.length === 0) return

    // Ensure files are an array
    if (!Array.isArray(files)) files = [files]

    let currentItems = localValue ?? []
    if (!Array.isArray(currentItems)) currentItems = [currentItems]

    const newFiles = files.filter((file) => {
      return !currentItems.some(
        (item) =>
          item.file?.name === file.name &&
          item.file?.size === file.size &&
          item.file?.lastModified === file.lastModified,
      )
    })

    if (newFiles.length === 0) {
      setErrors(['Some files are duplicates and have not been added.'])
      return
    }

    const newItems = newFiles.map((file) => ({
      url: URL.createObjectURL(file),
      file,
      progress: 0,
    }))

    const updated = field.multiple
      ? [...currentItems, ...newItems]
      : newItems[0]

    // Update local values
    setLocalValue(updated)

    // Start file upload process
    controller.uploadFiles({
      uploadItems: newItems,
      category,
      sourceItems: updated,
      setItems: setLocalValue,
    })
  }

  const onRemoveItem = (index) => {
    if (Array.isArray(localValue)) {
      const updatedItems = localValue.filter((_, i) => i !== index)
      setLocalValue(updatedItems)
    } else {
      setLocalValue(null)
    }
  }

  return {
    localValue,
    setLocalValue,
    errors,
    setErrors,
    handleChange,
    onRemoveItem,
  }
}
