export default {
  aqua: {
    container: "inline-flex items-center cursor-pointer text-admin_text",
    checkbox:
      "w-6 h-6 border-2 border-primary rounded-full flex items-center justify-center",
    checkbox_checked: "bg-primary",
    checkbox_unchecked: "bg-none",
    checkmark: "h-4 w-4 text-white",
    label: "text-sm font-medium",
    label_left: "mr-2",
    label_right: "ml-2",
    placeholder: "w-4 h-4 bg-transparent",
  },
}