import React, { useState, useEffect } from "react"
import FileUpload from "../../FileUpload"
import { XMarkIcon, Bars3Icon, EyeIcon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import FileIcon from "../../FileUpload/file-icon"
import Tooltip from "../../Tooltip"
import { useFieldEditorController } from "../controllers/field-file-editor-controller"
import { useFieldDragController } from "../controllers/field-drag-controller"
import { url, filename } from "../url"

// Function to extract file extension from a filename
const getFileExtension = (filename) => {
  return filename.split(".").pop()
}

export default function FileEditor({
  onChange,
  value,
  field, //if true will show label and description
  showInfo = true,
  //if true the field will be read only
  readOnly = false,
  parentObject,
}) {
  //Field editor controller
  const {
    localValue,
    setLocalValue,
    handleChange,
    onRemoveItem,
    errors,
    setErrors,
  } = useFieldEditorController({
    value,
    field,
    onChange,
    category: "files",
  })

  // Drag controller
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, setLocalValue)

  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [currentPreviewFile, setCurrentPreviewFile] = useState(null)
  const [currentPreviewFileName, setCurrentPreviewFileName] = useState(null)

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  const openPreview = (fileUrl, fileName) => {
    setCurrentPreviewFile(fileUrl)
    setCurrentPreviewFileName(fileName)
    setIsPreviewOpen(true)
  }

  const closePreview = () => {
    setIsPreviewOpen(false)
    setCurrentPreviewFile(null)
    setCurrentPreviewFileName(null)
  }

  const renderItem = (item, index) => {
    return (
      item && (
        <Tooltip key={index} content={filename(item?.url)} position="top">
          <div
            key={index}
            className="relative flex-none w-32 h-32 overflow-hidden group"
            draggable={!readOnly} // Make the file draggable unless readOnly
            onDragStart={() => !readOnly && handleDragStart(index)}
            onDragOver={(e) => {
              if (readOnly) return
              e.preventDefault()
              handleDragOver(index)
            }}
            onDragEnd={!readOnly ? handleDragEnd : null}
          >
            <div
              className="w-full h-full rounded-md cursor-pointer"
              onClick={() => openPreview(url(item?.url), filename(item?.url))} // Click to preview file
            >
              <FileIcon extension={getFileExtension(filename(item?.url))} />
            </div>

            {/* Drag Handle */}
            {!readOnly && (
              <div className="absolute top-1 left-1 bg-gray-500 text-white rounded-full p-1 cursor-move opacity-0 group-hover:opacity-100 transition-opacity">
                <Bars3Icon className="w-4 h-4" />
              </div>
            )}
            {/* Remove button */}
            {!readOnly && (
              <button
                className="absolute top-1 right-1 text-white bg-red-600 rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-700 focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity"
                onClick={() => onRemoveItem(index)}
              >
                <XMarkIcon className="w-4 h-4" />
              </button>
            )}
            <span className="mt-2 text-center text-xs text-admin_text block">
              {filename(item?.url)}
            </span>
          </div>
        </Tooltip>
      )
    )
  }

  return (
    <div className="file-editor">
      <div className="flex flex-row justify-between items-center">
        {/* Show field label and description if showInfo is true */}
        {showInfo && (
          <div className="flex flex-col mb-2">
            <label className="block font-semibold text-admin_text">
              {field.label}
              {isRequired && (
                <span className="ml-2 text-error">(required)</span>
              )}
            </label>
            {field.description && (
              <label className="block text-sm text-admin_text">
                {field.description}
              </label>
            )}
          </div>
        )}

        {/* File upload button, disabled if readOnly */}
        {!readOnly && (
          <FileUpload
            showSelectedFiles={false}
            multiple={field.multiple}
            required={field.required}
            formats={field.formats} // Use extensions for formats, e.g. ['png', 'docx', 'xlsx']
            maxSize={field.maxFileSize}
            onError={(errors) => setErrors(errors)}
            onFileChange={handleChange}
          />
        )}
      </div>

      {/* Number of loaded files */}
      {field.multiple && localValue.length > 0 && !readOnly && (
        <div className="text-sm text-admin_text mb-2 flex flex-row items-center">
          {/* Clear all button */}
          <Button
            variant="outlined"
            className="text-admin_text mr-5"
            onClick={() => {
              value[field.id] = []
              setLocalValue([])
            }}
          >
            {`${field.multiple ? "Clear All" : "Clear"}`}
          </Button>
          {field.multiple && (
            <div className="text-sm text-admin_text">
              {localValue.length} {localValue.length === 1 ? "file" : "files"}
            </div>
          )}
        </div>
      )}

      {/* Display files horizontally with drag and drop support */}
      <div className="flex gap-4 overflow-x-auto py-4">
        {Array.isArray(localValue)
          ? localValue?.map((value, index) => renderItem(value, index))
          : renderItem(localValue, 0)}
      </div>

      {/* Display errors */}
      {errors.length > 0 && (
        <div className="text-error text-center w-full mb-4">
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      {/* Full screen file preview */}
      {isPreviewOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 cursor-pointer"
          style={{ zIndex: 1000 }} // Ensures the preview is on top of other elements
          onClick={closePreview}
        >
          <div className="relative max-w-full max-h-full">
            <div className="items-center flex flex-col gap-5 max-w-full max-h-full bg-admin_paper p-4 text-center">
              <span className="text-admin_text text-sm">
                {currentPreviewFileName}
              </span>
              <Button
                className="mb-4 w-32"
                variant="action"
                onClick={() => window.open(currentPreviewFile, "_blank")}
              >
                View File
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
