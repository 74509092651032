// StoreRender.js
// Define both sets of modules statically for elements
const defaultElements = import.meta.glob("aldoo-ra/Store/elements/*.jsx", {
  eager: true,
})
const projectElements = import.meta.glob("@components/Store/elements/*.jsx", {
  eager: true,
})

// Define both sets of modules for fragments
const defaultFragments = import.meta.glob("aldoo-ra/Store/fragments/*.jsx", {
  eager: true,
})
const projectFragments = import.meta.glob("@components/Store/fragments/*.jsx", {
  eager: true,
})

const getComponentName = (path) => {
  if (path.includes("/elements/") || path.includes("/fragments/")) {
    const parts = path.split(/\/(?:elements|fragments)\//)
    return parts[1].replace(".jsx", "")
  }
  return path.split("/").slice(-2)[0]
}

// Create maps of element variants
const DEFAULT_MODULES = Object.entries(defaultElements).reduce(
  (acc, [path, module]) => {
    const componentName = getComponentName(path)
    acc[componentName] = module.default
    return acc
  },
  {}
)

const PROJECT_MODULES = Object.entries(projectElements).reduce(
  (acc, [path, module]) => {
    const componentName = getComponentName(path)
    acc[componentName] = module.default
    return acc
  },
  {}
)

// Create maps of fragment variants
const DEFAULT_FRAGMENTS = Object.entries(defaultFragments).reduce(
  (acc, [path, module]) => {
    const componentName = getComponentName(path)
    acc[componentName] = module.default
    return acc
  },
  {}
)

const PROJECT_FRAGMENTS = Object.entries(projectFragments).reduce(
  (acc, [path, module]) => {
    const componentName = getComponentName(path)
    acc[componentName] = module.default
    return acc
  },
  {}
)

export default class StoreRender {
  /**
   * Get a component from initial map, falling back to default map if not found
   * @param {string} name - The name of the component to retrieve
   * @param {Object} initialMap - The first map to check for the component
   * @param {Object} defaultMap - The fallback map to check if not found in initial map
   * @returns {React.Component|null} The requested component or null if not found
   */
  static getElementFromMap(name, initialMap, defaultMap) {
    // First try the initial map
    let element = initialMap[name]
    if (element) return element

    // Try the default map
    element = defaultMap[name]
    if (element) return element

    // No element found
    return null
  }

  /**
   * Get an element for rendering from the project or the default elements
   * @param {string} name - The name of the element to retrieve
   * @returns {React.Component|null} The requested component or null if not found
   */
  static getElement(name) {
    return this.getElementFromMap(name, PROJECT_MODULES, DEFAULT_MODULES)
  }

  /**
   * Get a fragment for rendering from the project or the default fragments
   * @param {string} name - The name of the fragment to retrieve
   * @returns {React.Component|null} The requested fragment component or null if not found
   */
  static getFragment(name) {
    return this.getElementFromMap(name, PROJECT_FRAGMENTS, DEFAULT_FRAGMENTS)
  }
}
