/**
 * Interface for the localization controller used to manage the localization of the application.
 */
import React, { useEffect, useRef, useState } from "react"
import { showNotification } from "aldoo-ra/Notification"
import {
  preFormatValue as filePreFormatValue,
  postFormatValue as filePostFormatValue,
} from "aldoo-ra/CMS/field-formatters/file-with-type"
//object formatter
import ObjectFieldFormatter from "aldoo-ra/CMS/field-formatters/object"
const {
  preFormatValue: objectPreFormatValue,
  postFormatValue: objectPostFormatValue,
} = ObjectFieldFormatter

import {
  AddLocalizationKey,
  AddLanguages,
  SaveLanguage,
  SaveLocalizationItem,
  GetLocalizationKey,
  GetLocalizationKeys,
  GetLanguageStatistics,
  DeleteLocalizationKeys,
  DeleteLanguage,
  AutoTranslateKey,
  AutoTranslateLanguage,
  ExportLocalization,
  ExportLocalizationStats,
  UploadImportStats,
  ImportLocalization,
  GetTaskProgress,
  StopTaskProgress,
} from "./api"

export default function LocalizationController() {
  const [selectedAutoTranslateOption, setSelectedAutoTranslateOption] =
    useState("")
  const refreshStatistics = useRef(null)

  const [languages, setLanguages] = useState([]) // Store the list of languages
  const [isAutoTranslating, setIsAutoTranslating] = useState(false)
  const [primaryLanguage, setPrimaryLanguage] = useState("en")
  const [languageAutoTranslateData, setLanguageAutoTranslateData] =
    useState(null) // Store task progress data
  const translationPollInterval = useRef(null) // To manage polling for task progress
  const [autoTranslateTaskID, setAutoTranslateTaskID] = useState("")

  //when content is changed, refresh the statistics
  const onRefreshStatistics = async (callback) => {
    refreshStatistics.current = callback
  }

  const autoTranslateOptions = [
    { name: "Selected language only", value: "key", index: 3 },
    {
      name: "All missing languages for this key",
      value: "all_missing",
      index: 2,
    },
    { name: "All languages for this key", value: "all", index: 1 },
  ]

  const addLocalizationKey = async ({ key, type, content, language }) => {
    // Add the key
    const payload = {
      key,
      type,
      content,
      language,
    }

    const showError = () => {
      showNotification({
        message: "Failed to add the localization key",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await AddLocalizationKey(payload)

      if (response.result) {
        showNotification({
          message: `Localization key added successfully`,
          className: "bg-primary text-admin_text_inv",
        })

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()
      } else showError
    } catch (error) {
      showError()
    }
  }
  const addLanguages = async (languages) => {
    // Add the languages
    const payload = {
      languages,
    }

    const showError = () => {
      showNotification({
        message: "Failed to add the languages",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await AddLanguages(payload)

      if (response.result) {
        showNotification({
          message: `Languages added successfully`,
          className: "bg-primary text-admin_text_inv",
        })

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()
      } else showError
    } catch (error) {
      showError()
    }
  }

  const saveLanguage = async (language) => {
    const showError = () => {
      showNotification({
        message: "Failed to save the language",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await SaveLanguage(language)

      if (response.result) {
        showNotification({
          message: `Language saved successfully`,
          className: "bg-primary text-admin_text_inv",
        })

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()
      } else showError
    } catch (error) {
      showError()
    }
  }

  const saveLocalizationItem = async (
    payload,
    autoShowNotifications = true
  ) => {
    const showError = () => {
      showNotification({
        message: "Failed to save the localization item",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await SaveLocalizationItem(payload)

      if (response.result) {
        autoShowNotifications &&
          showNotification({
            message: `Localization item saved successfully`,
            className: "bg-primary text-admin_text_inv",
          })

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()
      } else {
        autoShowNotifications && showError()
      }
    } catch (error) {
      showError()
    }
  }

  const getLocalizationKey = async (key) => {
    try {
      const response = await GetLocalizationKey(key)
      return response
    } catch (error) {
      console.error("Failed to get localization keys", error)
    }
  }

  const applyPreFormatters = (content, type) => {
    //text formatting
    if (type === "text") return content
    //object formatting
    if (type === "object") return objectPreFormatValue(content)

    const fileFormats = ["file", "audio", "video", "image"]
    //file formatting
    if (~fileFormats.indexOf(type)) return filePreFormatValue(content)

    //default return
    return content
  }

  const applyPostFormatters = (content, type) => {
    //text formatting
    if (type?.toLowerCase() === "text") return content
    //object formatting
    if (type?.toLowerCase() === "object") return objectPostFormatValue(content)

    const fileFormats = ["file", "audio", "video", "image"]
    //file formatting
    if (~fileFormats.indexOf(type)) return filePostFormatValue(content)
    //default return
    return content
  }

  /**
   * Given a localization key, get the localization item and preformat the translations.
   * @param {*} key
   * @returns
   */
  const getLocalizationKeyPreFormat = async (key) => {
    const result = await getLocalizationKey(key)
    const item = result[0]

    //if there is an item we need to preformat the contents of the translations
    if (!item) return

    const translations = item.translations
    for (const lang in translations) {
      translations[lang].content = applyPreFormatters(
        translations[lang].content,
        item.type
      )
    }

    return item
  }

  const getLocalizationKeys = async (language, keys) => {
    try {
      const response = await GetLocalizationKeys(language, keys)
      return response
    } catch (error) {
      console.error("Failed to get localization keys", error)
    }
  }

  const getLanguageStatistics = async () => {
    try {
      const response = await GetLanguageStatistics()
      return response
    } catch (error) {
      console.error("Failed to get language statistics", error)
    }
  }

  const deleteLocalizationKeys = async (keys, language) => {
    const showError = () => {
      showNotification({
        message: "Failed to delete the localization item",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await DeleteLocalizationKeys(keys, language)

      if (response.result) {
        showNotification({
          message: `Localization item deleted successfully`,
          className: "bg-primary text-admin_text_inv",
        })

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()
      } else showError
    } catch (error) {
      showError()
    }
  }

  const deleteLanguage = async (code) => {
    const showError = () => {
      showNotification({
        message: "Failed to delete the language",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await DeleteLanguage(code)

      if (response.result) {
        showNotification({
          message: `Language deleted successfully`,
          className: "bg-primary text-admin_text_inv",
        })

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()
      } else showError
    } catch (error) {
      showError()
    }
  }

  const stopAutoTranslateLanguage = async () => {
    if (!autoTranslateTaskID) return

    const response = await StopTaskProgress(autoTranslateTaskID)

    if (response.result) {
      showNotification({
        message: `Auto-translation stopped successfully`,
        className: "bg-primary text-admin_text_inv",
      })
    } else {
      showNotification({
        message: "Failed to stop auto-translation",
        className: "bg-red-500 text-white",
      })
    }

    //stop the task polling
    if (translationPollInterval.current) {
      clearInterval(translationPollInterval.current)
    }

    setAutoTranslateTaskID("")
    setIsAutoTranslating(false)
    setLanguageAutoTranslateData(null)

    //refresh
    if (refreshStatistics.current) refreshStatistics.current()
  }

  const autoTranslate = async (key, languageIn, languageOut, option) => {
    setIsAutoTranslating(true)

    const showError = (error) => {
      setIsAutoTranslating(false)
      showNotification({
        message: error || "Failed to auto translate the key",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await AutoTranslateKey({
        key,
        languageIn,
        languageOut,
        option,
      })

      if (response.result) {
        showNotification({
          message: `Key auto translated successfully`,
          className: "bg-primary text-admin_text_inv",
        })

        setSelectedAutoTranslateOption("")

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()
        //get the newly translated item
        const translatedItem = await getLocalizationKeyPreFormat(key)

        setIsAutoTranslating(false)

        return translatedItem
      } else {
        showError(response.error)
      }
    } catch (error) {
      showError()
    }
  }

  // Method to poll task progress
  const pollTaskProgress = async (taskID) => {
    try {
      const response = await GetTaskProgress(taskID)
      setLanguageAutoTranslateData(response)

      // If the task is completed or failed, stop polling
      if (response.status === "completed" || response.error) {
        clearInterval(translationPollInterval.current)
        setIsAutoTranslating(false)
        //reset the auto translate task id
        setAutoTranslateTaskID("")

        if (response.status === "completed") {
          setLanguageAutoTranslateData(null)
          //refresh
          if (refreshStatistics.current) refreshStatistics.current()
          showNotification({
            message: `Auto-translation completed successfully`,
            className: "bg-primary text-admin_text_inv",
          })
        } else if (response.error) {
          // showNotification({
          //   message: response.error,
          //   className: 'bg-red-500 text-white',
          // })
        }
      }
    } catch (error) {
      console.error("Failed to get task progress", error)
    }
  }

  // Auto translate language method
  const autoTranslateLanguage = async (languageIn, languageOut, option) => {
    setIsAutoTranslating(true)

    const showError = (message = "Failed to auto translate") => {
      setIsAutoTranslating(false)
      //reset the auto translate task id
      setAutoTranslateTaskID("")
      showNotification({
        message,
        className: "bg-red-500 text-white",
      })
    }

    try {
      // Start the translation task
      const response = await AutoTranslateLanguage({
        languageIn,
        languageOut,
        option,
      })

      if (response.taskID) {
        setAutoTranslateTaskID(response.taskID)
        // Start polling for task progress
        translationPollInterval.current = setInterval(() => {
          pollTaskProgress(response.taskID)
        }, 500) // Poll every second
      } else {
        //find the language code {{code}}
        const languageCode = response.error.match(/{{(.*?)}}/)[1]
        //replace the language code with the language name
        const message = response.error.replace(
          `{{${languageCode}}}`,
          getLanguageName(languageCode)
        )
        showError(message)
      }
    } catch (error) {
      showError()
    }
  }

  const getLanguageName = (languageCode) => {
    return (
      languages?.find((lang) => lang.code === languageCode)?.name ??
      languageCode
    )
  }

  const exportLocalization = async ({ option, selectedLanguages }) => {
    const showError = () => {
      showNotification({
        message: "Failed to export localization data",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await ExportLocalization({
        option,
        selectedLanguages,
      })

      if (response) {
        showNotification({
          message: `Localization data exported successfully`,
          className: "bg-primary text-admin_text_inv",
        })
        return response
      } else showError()
    } catch (error) {
      showError()
    }
  }

  const exportLocalizationStats = async ({ option, selectedLanguages }) => {
    const showError = () => {
      showNotification({
        message: "Failed to get localization stats",
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await ExportLocalizationStats({
        option,
        selectedLanguages,
      })

      if (response.error === "empty_export") {
        showNotification({
          message: "No data will be exported with the selected options",
          className: "bg-red-500 text-white",
        })
      }

      return response
    } catch (error) {
      showError()
    }
  }

  const uploadImportStats = async (file) => {
    const showError = (message = "Failed to upload localization") => {
      showNotification({
        message,
        className: "bg-red-500 text-white",
      })
    }

    try {
      const response = await UploadImportStats(file)

      if (!response.error) {
        showNotification({
          message: `Loaded localization successfully`,
          className: "bg-primary text-admin_text_inv",
        })
        return response
      } else showError(response.error)
    } catch (error) {
      showError()
    }
  }

  const importLocalization = async ({ taskProgressId, selectedLanguages }) => {
    const showError = (message = "Failed to import localization data") => {
      showNotification({
        message,
        className: "bg-red-500 text-white",
      })
    }

    if (!selectedLanguages.length) {
      showError("Please select at least one language")
      return
    }

    //check if every selected language has at least 1 import option to it
    const hasImportOption = selectedLanguages.every(
      (lang) => lang.all || lang.all_missing || lang.existing_only
    )

    if (!hasImportOption) {
      showError("Please select a import option for each language")
      return
    }

    try {
      const response = await ImportLocalization({
        taskProgressId,
        selectedLanguages,
      })

      if (response.result) {
        showNotification({
          message: (
            <div className="flex flex-col gap-2">
              <span>{`Imported ${response.importedKeys} keys`}</span>
              <span>{`in ${response.languages.length} languages`}</span>
            </div>
          ),
          className: "bg-primary text-admin_text_inv",
        })

        //refresh the statistics
        if (refreshStatistics.current) refreshStatistics.current()

        return response
      }

      if (response.error) {
        showError(response.error)
      }
    } catch (error) {
      showError()
    }
  }

  // Method to clear translation progress polling
  useEffect(() => {
    return () => {
      if (translationPollInterval.current) {
        clearInterval(translationPollInterval.current)
      }
    }
  }, [])

  //return the interface
  return {
    getLocalizationKeyPreFormat,
    getLocalizationKeys,
    importLocalization,
    uploadImportStats,
    exportLocalizationStats,
    exportLocalization,
    stopAutoTranslateLanguage,
    getLanguageName,
    languages,
    setLanguages,
    autoTranslateLanguage,
    languageAutoTranslateData,
    setLanguageAutoTranslateData,
    primaryLanguage,
    setPrimaryLanguage,
    isAutoTranslating,
    getLocalizationKey,
    autoTranslate,
    deleteLanguage,
    deleteLocalizationKeys,
    saveLanguage,
    addLanguages,
    addLocalizationKey,
    onRefreshStatistics,
    getLanguageStatistics,
    saveLocalizationItem,
    setSelectedAutoTranslateOption,
    selectedAutoTranslateOption,
    autoTranslateOptions,
    applyPreFormatters,
    applyPostFormatters,
  }
}
