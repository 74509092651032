const config = {
  APP_NAME: 'Aqua Mail',
  APP_DOMAIN: "", // leave it empty to automatically determine the domain
  API_URL: "", // leave it empty to use the app domain
  ANALYTICS_URL: 'https://cc-api-st.aldoo.com',
  SUPPORT_CHAT_API: "",
  CMS_STATIC_URL: "",

  // Talk with the platform users
  PLATFORM_API_URL: 'http://localhost:7040',
  PLATFORM_API_VERSION: 'v1',
  STRIPE_API_KEY: 'pk_test_51K9Y5AE2k8zaBmhTXyhlWwzoSOF9aar1YfqKE27h9XuXpQKOEeuuVja20H3mQBewaDjBRw9Pr7d7qJ6jdnyEmzGQ0069k8akt9',

  // GTM Integration
  GTM_ID: "", // replace with your GTM container ID

  FACEBOOK_PIXEL: false,
  FACEBOOK_OPTIONS: "",
  TRACKING_GDPR: false,
  GOOGLE_ANALYTICS_ID: "",

  //If true will print additional information on api calls that respond with an error object
  API_DIAGNOSTICS: false,

}

export default config