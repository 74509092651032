import VariantReader from "aldoo-ra/VariantReader"
import renderMenuItem from "../items/render-menu-item"
import createColumns from "../tools/create-columns"

const VListItems = ({
  items,
  menuData,
  itemWidth,
  itemRender,
  rootItemRender,
  renderAs,
  styleOverrides,
  handleAction,
  maxItemsPerColumn = -1, // Default value for max items per column
  skipRootItem,
  variant,
}) => {
  //read styles
  const variants = VariantReader.useVariant({
    componentName: "Menu",
    variant: variant || renderAs,
    styleOverrides,
  })

  const { root_item, menu, menu_item, menu_column } = variants

  const rootRender = rootItemRender ? (
    rootItemRender(menuData)
  ) : (
    <div className={root_item}>{menuData.label}</div>
  )

  const columns = createColumns(items, maxItemsPerColumn)

  return (
    <div className="flex flex-col">
      {!skipRootItem && rootRender}
      <div className="flex flex-row">
        {columns.map((columnItems, columnIndex) => (
          <div
            key={columnIndex}
            className={`flex flex-col ${columnIndex > 0 ? menu_column : ""}`}
          >
            <ul className={menu}>
              {columnItems.map((item, index) => (
                <li
                  key={index}
                  role="menuitem"
                  className={menu_item}
                  style={{ width: itemWidth || "auto" }}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      handleAction(item)
                    }}
                  >
                    {itemRender
                      ? itemRender(item, index)
                      : renderMenuItem(item)}
                  </div>
                  {item.items?.length > 0 && (
                    <ul className="mt-1 space-y-1 pl-4">
                      {item.items.map((subItem, subIndex) => (
                        <li
                          key={`${index}-${subIndex}`}
                          className="list-none cursor-pointer"
                          onClick={() => handleAction(subItem)}
                        >
                          <div className="px-2 py-1 hover:bg-neutral-100 rounded-md">
                            {renderMenuItem(subItem)}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  )
}

export default VListItems
