import React, { useState, useEffect, useRef } from "react"
import FileUpload from "../../FileUpload"
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import { url, filename } from "../url"
import { useFieldEditorController } from "../controllers/field-file-editor-controller"
import { useFieldDragController } from "../controllers/field-drag-controller"
import ImagePreview from "../preview/image-preview"

export default function ImageEditor({
  onChange,
  value,
  field,
  //if true will show label and description
  showInfo = true,
  //if true the field will be read only
  readOnly = false,
  parentObject,
}) {
  //Field editor controller
  const {
    localValue,
    setLocalValue,
    handleChange,
    onRemoveItem,
    errors,
    setErrors,
  } = useFieldEditorController({
    value,
    field,
    onChange,
    category: "images",
  })

  // Drag controller
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, setLocalValue)

  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [currentPreviewImage, setCurrentPreviewImage] = useState(null)
  const [currentPreviewFileName, setCurrentPreviewFileName] = useState(null)

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  // Close preview when 'Escape' key is pressed
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape" && isPreviewOpen) {
        closePreview()
      }
    }

    document.addEventListener("keydown", handleEscape)

    return () => {
      document.removeEventListener("keydown", handleEscape)
    }
  }, [isPreviewOpen])

  const openPreview = (image, fileName) => {
    setCurrentPreviewImage(image)
    setCurrentPreviewFileName(fileName)
    setIsPreviewOpen(true)
  }

  const closePreview = () => {
    setIsPreviewOpen(false)
    setCurrentPreviewImage(null)
    setCurrentPreviewFileName(null)
  }

  const renderItem = (item, index) => {
    return !item || !item.url ? null : (
      <div
        key={index}
        className="relative flex-none w-32 h-32 border border-gray-400 rounded-md overflow-hidden group"
        draggable={!readOnly}
        onDragStart={() => !readOnly && handleDragStart(index)}
        onDragOver={(e) => {
          if (readOnly) return
          e.preventDefault()
          handleDragOver(index)
        }}
        onDragEnd={!readOnly ? handleDragEnd : null}
      >
        <img
          src={url(item.url)}
          alt="Uploaded"
          className="w-full h-full object-cover rounded-md cursor-pointer"
          onClick={() => openPreview(url(item.url), filename(item.url))}
        />
        {/* Upload progress */}
        {item.progress > 0 && item.progress < 100 && (
          <div
            className="absolute top-0 left-0 right-0 bg-primary"
            style={{ width: `${item.progress}%` }}
          >
            {/* show the progress as a number*/}
            <div className="ml-1 w-full text-xs text-white">
              {item.progress}%
            </div>
          </div>
        )}

        {!readOnly && (
          <div className="absolute top-1 left-1 bg-gray-500 text-white rounded-full p-1 cursor-move opacity-0 group-hover:opacity-100 transition-opacity">
            <Bars3Icon className="w-4 h-4" />
          </div>
        )}

        {!readOnly && (
          <button
            className="absolute top-1 right-1 text-white bg-red-600 rounded-full w-6 h-6 flex items-center justify-center hover:bg-red-700 focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity"
            onClick={() => onRemoveItem(index)}
          >
            <XMarkIcon className="w-4 h-4" />
          </button>
        )}

        <span className="mt-2 text-center text-xs text-admin_text block">
          {filename(item.url)}
        </span>
      </div>
    )
  }

  return (
    <div className="image-editor">
      <div className="flex flex-row justify-between items-center">
        {showInfo && (
          <div className="flex flex-col mb-2">
            <label className="block font-semibold text-admin_text">
              {field.label}
              {isRequired && (
                <span className="ml-2 text-error">(required)</span>
              )}
            </label>
            <label className="block text-sm text-admin_text">
              {field.description}
            </label>
          </div>
        )}

        {!readOnly && (
          <FileUpload
            showSelectedFiles={false}
            multiple={field.multiple}
            required={field.required}
            formats={field.formats}
            maxSize={field.maxFileSize}
            onError={(errors) => setErrors(errors)}
            onFileChange={handleChange}
          />
        )}
      </div>

      {!readOnly && field.multiple && localValue?.length > 0 && (
        <div className="text-sm text-admin_text mb-2 flex flex-row items-center">
          <Button
            variant="outlined"
            className="text-admin_text mr-5"
            onClick={() => setLocalValue([])}
          >
            {`${field.multiple ? "Clear All" : "Clear"}`}
          </Button>
          {field.multiple && (
            <div className="text-sm text-admin_text">
              {localValue.length} {localValue.length === 1 ? "image" : "images"}
            </div>
          )}
        </div>
      )}

      <div className="flex gap-4 overflow-x-auto py-4">
        {Array.isArray(localValue)
          ? localValue?.map((value, index) => renderItem(value, index))
          : renderItem(localValue, 0)}
      </div>

      {errors.length > 0 && (
        <div className="text-error text-center w-full mb-4">
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      <ImagePreview
        isOpen={isPreviewOpen}
        imageUrl={currentPreviewImage}
        fileName={currentPreviewFileName}
        onClose={closePreview}
      />
    </div>
  )
}
