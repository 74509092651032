import React, { useState, useRef, useEffect, useCallback, useMemo } from "react"

const resolveSortFunction = (sortBy, sortDirection) => {
  if (!sortBy) {
    return () => 0
  }

  return (a, b) => {
    if (a[sortBy] < b[sortBy]) {
      return sortDirection === "asc" ? -1 : 1
    }
    if (a[sortBy] > b[sortBy]) {
      return sortDirection === "asc" ? 1 : -1
    }
    return 0
  }
}

const Dropdown = ({
  className = "",
  options,
  value,
  onChange,
  menuPosition = "bottom",
  onItemRender,
  markSelected = true,
  sortBy,
  sortDirection = "asc",
  placeholder,
  isMenu = false,
  searchEnabled = false,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    !placeholder && (value || options[0])
  )
  const [isOpen, setIsOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const dropdownRef = useRef(null)
  const buttonRef = useRef(null)
  const searchInputRef = useRef(null)

  useEffect(() => {
    if (value) {
      setSelectedOption(value)
    }
  }, [value])

  const toggleDropdown = () => {
    if (!isOpen) {
      setIsOpen(true)
      if (searchEnabled) {
        setShowSearch(true)
        // Focus the search input after rendering
        setTimeout(() => {
          if (searchInputRef.current) {
            searchInputRef.current.focus()
          }
        }, 0)
      }
    } else {
      setIsOpen(false)
      setShowSearch(false)
      setSearchQuery("")
    }
  }

  const handleOptionClick = (option) => {
    if (!isMenu) setSelectedOption(option)
    setIsOpen(false)
    setShowSearch(false)
    setSearchQuery("")
    if (onChange) onChange(option)
  }

  const filteredOptions = useMemo(() => {
    if (!searchQuery) return options
    return options.filter((option) => {
      if (typeof option === "string") {
        return option.toLowerCase().includes(searchQuery.toLowerCase())
      }
      // If onItemRender is provided, we'll try to match against the rendered string
      const searchValue = onItemRender
        ? String(onItemRender(option)).toLowerCase()
        : String(option).toLowerCase()
      return searchValue.includes(searchQuery.toLowerCase())
    })
  }, [options, searchQuery, onItemRender])

  const getMenuPositionStyles = useCallback(() => {
    if (!buttonRef.current || !dropdownRef.current) return {}

    const buttonRect = buttonRef.current.getBoundingClientRect()

    const styles = {
      width: `${buttonRect.width}px`,
    }

    switch (menuPosition) {
      case "top":
        styles.bottom = `${buttonRect.height}px`
        styles.left = `${0}px`
        break
      case "bottom":
        styles.top = `${buttonRect.height}px`
        styles.left = `${0}px`
        break
      default:
        styles.top = `${buttonRect.height}px`
        styles.left = `${0}px`
    }

    return styles
  }, [menuPosition])

  useEffect(() => {
    if (isOpen && dropdownRef.current) {
      const positionStyles = getMenuPositionStyles()
      Object.assign(dropdownRef.current.style, positionStyles)
    }
  }, [isOpen, getMenuPositionStyles])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false)
        setShowSearch(false)
        setSearchQuery("")
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    } else {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen])

  return (
    <div className={`relative inline-block text-left ${className}`}>
      <div>
        <button
          ref={buttonRef}
          type="button"
          className="inline-flex justify-between w-full rounded-md border border-bordered shadow-sm px-4 py-2 bg-admin_paper text-sm font-medium text-admin_text focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary_lt"
          id="options-menu"
          aria-expanded={isOpen}
          aria-haspopup="true"
          onClick={toggleDropdown}
        >
          {!selectedOption && <div className="">{placeholder}</div>}
          {selectedOption && onItemRender
            ? onItemRender(selectedOption)
            : selectedOption}
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && filteredOptions.length > 0 && (
        <div
          ref={dropdownRef}
          className="absolute z-10 mt-2 rounded-md shadow-lg bg-admin_paper ring-1 ring-black ring-opacity-5"
        >
          {searchEnabled && showSearch && (
            <div className="p-2 border-b border-neutral_2">
              <input
                ref={searchInputRef}
                type="text"
                className="w-full px-3 py-1 text-sm border rounded focus:outline-none focus:ring-1 focus:ring-primary_lt"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          )}

          <div className="rounded-lg overflow-hidden">
            {filteredOptions
              .sort(resolveSortFunction(sortBy, sortDirection))
              .map((option, index) => (
                <div
                  key={index}
                  className={`block px-4 py-2 text-sm cursor-pointer ${
                    option === selectedOption
                      ? "bg-admin_paper text-admin_text"
                      : "bg-neutral text-admin_text_secondary"
                  } hover:bg-neutral_1 hover:text-admin_text flex justify-between items-center`}
                  role="menuitem"
                  onClick={() => handleOptionClick(option)}
                >
                  {onItemRender ? onItemRender(option) : option}
                  {option === selectedOption && markSelected && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="ml-2 h-5 w-5 text-primary"
                      fill="currentColor"
                      width="24px"
                      height="24px"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 16.2l-3.5-3.5L4 14l5 5L20 8.5l-1.5-1.5z" />
                    </svg>
                  )}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
