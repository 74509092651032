import { useState } from 'react'

export const useFieldDragController = (localValue, setLocalValue) => {
  const [draggingIndex, setDraggingIndex] = useState(null)

  const handleDragStart = (index) => {
    setDraggingIndex(index)
  }

  const handleDragOver = (index) => {
    if (draggingIndex === index) return

    const updatedItems = [...localValue]
    const draggedItem = updatedItems.splice(draggingIndex, 1)[0]
    updatedItems.splice(index, 0, draggedItem)

    setLocalValue(updatedItems)
    setDraggingIndex(index)
  }

  const handleDragEnd = () => {
    setDraggingIndex(null)
  }

  return {
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  }
}
