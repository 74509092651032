import { addFieldEditor } from "aldoo-ra/CMS/field-editor-resolver"
import { addApi } from "aldoo-ra/CMS/field-api-resolver"
import { addFormatter } from "aldoo-ra/CMS/field-formatter"
import { addDisplayFormatter } from "aldoo-ra/CMS/field-display-format-resolver"
import { addPreSaveFormatter } from "aldoo-ra/CMS/content-pre-save-formatter"

import StoreProductAPI from "aldoo-ra/Admin/Store/Products/api"
import StoreProductFormatter from "./Store/Products/formatters/store-product-formatter"

//Store price interfaces: api, editor, formatter
import StorePriceAPI from "aldoo-ra/Admin/Store/Prices/api"
import StorePriceEditor from "aldoo-ra/Admin/Store/Products/editors/store-price-editor"
import StorePriceFormatter from "aldoo-ra/Admin/Store/Prices/formatters/store-price-formatter"
import PriceModelEditor from "aldoo-ra/Admin/Store/Prices/editors/price-model-editor"

//bundles
import BundleItemFormatter from "aldoo-ra/Admin/Store/Products/formatters/store-bundle-formatter"
import BundleItemEditor from "aldoo-ra/Admin/Store/Products/editors/store-bundle-editor"


//Mail Tester
import MailTemplateTester from "./MailTemplates/template-tester"
//Register the MailTemplateTester field editor
addFieldEditor("MailTemplateTester", MailTemplateTester)


// Define the subtypes first
export const PlatformProductType = {
    id: "PlatformProduct",
    label: "Platform Product Configuration",
    labelField: "platform",
    fields: [
        {
            id: "platform",
            label: "Platform",
            type: "Text",
            required: true,
            options: ["iOS", "Android", "Web", "Windows"],
            description: "Platform identifier",
            default: "iOS"
        },
        {
            id: "id",
            label: "Product ID",
            type: "Text",
            description: "Platform-specific product identifier",
            //set required if the free is false
            required: (item) => {
                return !item.free
            }
        },
        {
            id: "free",
            label: "Free",
            type: "Boolean",
            description: "This product is Free",
        }
    ]
};

export const PriceModelType = {
    id: "CurrencyConfig",
    label: "Currency Configuration",
    groups: [
        {
            id: "config",
            label: "Configuration",
            description: "Basic configuration for the store price.",
            expanded: true,
        },
        {
            id: "price",
            label: "Price",
            description: "Set the price amount and currency",
        },
        {
            id: "platform",
            label: "Platforms",
            description: "Add platform-specific product IDs",
        },
        {
            id: "payment-and-subscription",
            label: "Payment & Subscription",
            description: "Select a payment model and duration",
        },
        {
            id: "trial",
            label: "Trial",
            description: "Trial configuration",
        }
    ],
    fields: [
        {
            id: "primary",
            group: "config",
            label: "Primary",
            type: "Boolean",
            description: "Primary price prices are used for product purchasing",
            render: { variant: "full" },
        },
        {
            id: "real_product_id",
            group: "platform",
            label: "Platform Products",
            type: "Array",
            description: "Platform-specific product IDs (required for real currency)",
            visibleIf: {
                type: { in: ["real"] }
            },
            item: PlatformProductType,
            render: { variant: "full" },
        },
        {
            id: "amount",
            group: "price",
            label: "Amount",
            type: "Number",
            required: true,
            description: "Price amount in the specified currency",
        },

        {
            id: "currency",
            group: "price",
            label: "Currency",
            type: "Text",
            required: true,
            description: "Currency identifier (e.g., USD, coins, gems)",
            localization: true
        },

        {
            id: "type",
            group: "price",
            label: "Currency Type",
            type: "Text",
            required: true,
            options: ["relative", "virtual", "real"],
            default: "real",
            description: "Type of currency configuration",
        },
        {
            id: "duration",
            group: "payment-and-subscription",
            label: "Duration",
            type: "Text",
            description: "If this is a recurring price, specify the duration (e.g., week, month, year)",
            options: ["one-time-payment", "week", "month", "year", "custom"],
            default: "one-time-payment",
        },

        {
            id: "auto_renew",
            group: "payment-and-subscription",
            label: "Auto Renew",
            type: "Boolean",
            description: "Set this if your price is a subscription that auto-renews",
            visibleIf: {
                duration: { nin: ["one-time-payment"] }
            },
        },
        {
            id: "duration_in_days",
            group: "payment-and-subscription",
            label: "Duration In Days",
            type: "Text",
            description: "Enter a number of days for the duration",
            visibleIf: {
                duration: { in: ["custom"] }
            },
        },
        {
            id: "trial_duration",
            group: "trial",
            label: "Trial Duration",
            type: "Text",
            description: "Enter the trial duration in days",
            options: ["no-trial", "week", "month", "year", "custom"],
            default: "no-trial",
            render: { menuPosition: "top" },
        },
        {
            id: "trial_duration_in_days",
            group: "trial",
            label: "Trial Duration In Days",
            type: "Text",
            description: "Enter the trial duration in days",
            visibleIf: {
                trial_duration: { in: ["custom"] }
            }
        }, {
            id: "requires_payment_method",
            group: "trial",
            label: "Require Payment Method",
            type: "Boolean",
            description: "Require payment method for before starting a trial",
            default: true,
            visibleIf: {
                trial_duration: { nin: ["no-trial"] }
            }
        }

    ]
};

// Bundle item configuration type
export const BundleItemType = {
    id: "BundleItem",
    label: "Bundle Item Configuration",
    groups: [
        {
            id: "product",
            label: "Product",
            description: "Select the product to include in the bundle",
            expanded: true,
        },
        {
            id: "quantity",
            label: "Quantity",
            description: "Configure the quantity",
        },
        {
            id: "pricing",
            label: "Pricing",
            description: "Optional price override for this bundle item",
        }
    ],
    fields: [
        {
            id: "product",
            group: "product",
            label: "Product",
            type: "DatabaseModel",
            item: "StoreProduct",
            required: true,
            searchFields: ["pid", "name"],
            overview: "productSearchResult",
            description: "Product to include in the bundle",
            render: { variant: "full" },
        },
        {
            id: "quantity",
            group: "quantity",
            label: "Quantity",
            type: "Number",
            required: true,
            min: 1,
            default: 1,
            description: "Number of units of this product in the bundle",
            render: { variant: "full" },
        },
        {
            id: "price",
            group: "pricing",
            label: "Price Override",
            type: "DatabaseModel",
            item: "StorePrice",
            searchFields: ["price_id"],
            overview: "priceSearchResult",
            description: "Optional price override for this product in the bundle",
            render: { variant: "full" },
        }
    ]
};

// Register formatters and editors
addFormatter("BundleItem", BundleItemFormatter);
addFieldEditor("BundleItemEditor", BundleItemEditor);

addDisplayFormatter("productSearchResult", (item) => {
    if (!item) return "No product selected";
    return item.name;
});

// Display formatter for bundle items in search/selection
addDisplayFormatter("BundleItem", (item) => {
    if (!item || !item.product) return "No product selected";

    const quantity = item.quantity > 1 ? ` × ${item.quantity}` : '';
    const priceOverride = item.price ? ' (Custom Price)' : '';

    return `${item.product.name}${quantity}${priceOverride}`;
});


// Main StorePrice type
export const StorePrice = {
    type: "StorePrice",
    label: "Store Price Configuration",
    groups: [
        {
            id: "config",
            label: "Configuration",
            description: "Basic configuration for the store price.",
            expanded: true,
        },
        {
            id: "price",
            label: "Price Model",
            description: "Add supported price models",
        },
        {
            id: "meta",
            label: "Metadata",
            description: "Meta information",
        }
    ],
    fields: [
        {
            id: "price_id",
            label: "Price ID",
            type: "Text",
            required: true,
            unique: true,
            description: "Unique identifier for the price configuration",
            group: "config",
        },
        {
            id: "enabled",
            label: "Enabled",
            type: "Boolean",
            description: "Whether this price configuration is active",
            default: true,
            group: "config",
        },
        {
            id: "price_model",
            label: "Price Models",
            type: "Array",
            required: true,
            description: "Configure pricing across different platforms and plans",
            group: "price",
            fieldEditor: "PriceModelEditor",
            item: PriceModelType,
            render: { variant: "full" },
        },

        {
            id: "meta",
            render: { variant: "full" },
            label: "Metadata",
            type: "Object",
            description: "Additional metadata for the price configuration",
            group: "meta",
        }
    ]
};

addApi("StorePrice", StorePriceAPI)
addFormatter("StorePrice", StorePriceFormatter)
addFieldEditor("StorePrice", StorePriceEditor)
//add the currency config editor to the field editor resolver
addFieldEditor("PriceModelEditor", PriceModelEditor)


export const StoreProduct = {
    type: "StoreProduct",
    formatter: "StoreProduct",
    label: "Store Product Configuration",
    groups: [
        {
            id: "availability",
            label: "Availability",
            description: "Product availability and visibility settings.",
        },
        {
            id: "basic",
            label: "Basic Information",
            description: "Core product information and configuration.",
            expanded: false,
        },
        {
            id: "details",
            label: "Product Details",
            description: "Additional product specifications and settings.",
        },
        {
            id: "bundle",
            label: "Bundle Configuration",
            description: "Configure bundle contents and pricing",
            visibleIf: {
                type: { in: ["bundle"] }
            }
        },
        {
            id: "pricing",
            label: "Pricing",
            description: "Product pricing configuration.",
            expanded: true,
        },
        {
            id: "meta",
            label: "Metadata",
            description: "Additional metadata and custom fields.",
        }
    ],
    fields: [
        {
            id: "pid",
            label: "Product ID",
            type: "Text",
            required: true,
            unique: true,
            description: "Unique identifier for the product (e.g., pt-module-princess)",
            group: "basic",
        },
        {
            id: "type",
            label: "Product Type",
            type: "Text",
            required: true,
            description: "The type of product being configured",
            options: ["license", "bundle", "subscription"],
            group: "basic",
        },
        {
            id: "name",
            label: "Product Name",
            type: "Text",
            required: true,
            localization: true,
            description: "The display name of the product on the store (defaults to English)",
            group: "basic",
        },
        {
            id: "thumbnail",
            label: "Thumbnail",
            type: "Image",
            description: "Product thumbnail image",
            group: "basic",
        },
        {
            id: "description",
            label: "Description",
            type: "Text",
            localization: true,
            required: true,
            multiline: true,
            description: "Detailed product description (defaults to English)",
            group: "basic",
            render: { variant: "default" },
        },
        {
            id: "category",
            label: "Category",
            type: "Text",
            description: "The category under which the product appears in the store",
            group: "details",
        },
        {
            id: "enabled",
            label: "Enabled",
            type: "Boolean",
            description: "Controls product visibility to the end-user",
            default: true,
            group: "availability",
        },
        {
            id: "amount",
            label: "Amount",
            type: "Number",
            description: "Value amount (applicable for currency type products)",
            default: 1,
            group: "details",
            conditional: {
                field: "type",
                value: "coins"
            }
        },
        {
            id: "consumable",
            label: "Consumable",
            type: "Boolean",
            description: "Whether the product is consumed immediately instead of being added to inventory",
            group: "details",
        },
        {
            id: "price",
            label: "Price Configuration",
            type: "DatabaseModel",
            item: StorePrice,
            required: true,
            searchFields: ["price_id"],
            //display formatter
            overview: "priceSearchResult",
            description: "Associated price configuration for the product",
            render: { variant: "full" },
            group: "pricing",
        },
        {
            id: "meta",
            label: "Metadata",
            type: "Object",
            description: "Additional metadata for app-specific business logic",
            group: "meta",
            render: { variant: "full" },
        },
        {
            id: "bundle",
            label: "Bundle Items",
            type: "Array",
            group: "bundle",
            description: "Products included in this bundle",
            visibleIf: {
                type: { in: ["bundle"] }
            },
            fieldEditor: "BundleItemEditor",
            item: BundleItemType,
            render: { variant: "full" },
        },
        {
            id: "created",
            label: "Created Date",
            type: "DateTime",
            readOnly: true,
            description: "Date when the product was created",
            group: "meta",
        },
        {
            id: "updated",
            label: "Last Updated",
            type: "DateTime",
            readOnly: true,
            description: "Date when the product was last modified",
            group: "meta",
        },
    ]
};

//Export Store Price API
addApi("StoreProduct", StoreProductAPI)
addFormatter("StoreProduct", StoreProductFormatter)

addDisplayFormatter("StoreProduct", (item) => {

    if (!item || !item.price) return "No price configuration"

    let price = item.price

    let primaryPrice = price.price_model?.find((price) => price.primary)

    if (typeof primaryPrice?.duration === "string") {
        price = postFormat(price, StorePrice)
        //set again but this time formatter
        primaryPrice = price.price_model.find((price) => price.primary)
    }

    if (!primaryPrice) return "No price configuration"
    const purchaseType = parseInt(primaryPrice.duration)
        ? "Subscription"
        : "Purchase"
    const table = { [365]: "year", [30]: "month", [7]: "week", [1]: "day" }

    let period = primaryPrice.duration
    period = table[parseInt(period)] || (period ? `${period} days` : "")
    period = period ? `/ ${period}` : ""
    const timing = primaryPrice.auto_renew ? "Auto-Renew" : "One-Time"
    return `${item.name} ${purchaseType}`
})

addPreSaveFormatter("BlogPost", (item) => {
    //create a slug from the title
    item.slug = item.title
        .toLowerCase()                      // Convert to lowercase
        .replace(/[^\w\s-]/g, '')          // Remove special characters except spaces and hyphens
        .trim()                            // Remove leading/trailing spaces
        .replace(/\s+/g, '-')              // Replace spaces with hyphens
        .replace(/-+/g, '-');              // Replace multiple hyphens with single hyphen

    return item
})

addPreSaveFormatter("HCArticle", (item) => {
    //create a slug from the title
    item.slug = item.name
        .toLowerCase()                      // Convert to lowercase
        .replace(/[^\w\s-]/g, '')          // Remove special characters except spaces and hyphens
        .trim()                            // Remove leading/trailing spaces
        .replace(/\s+/g, '-')              // Replace spaces with hyphens
        .replace(/-+/g, '-');              // Replace multiple hyphens with single hyphen

    return item
})

addPreSaveFormatter("HCCategory", (item) => {
    //create a slug from the title
    item.slug = item.name
        .toLowerCase()                      // Convert to lowercase
        .replace(/[^\w\s-]/g, '')          // Remove special characters except spaces and hyphens
        .trim()                            // Remove leading/trailing spaces
        .replace(/\s+/g, '-')              // Replace spaces with hyphens
        .replace(/-+/g, '-');              // Replace multiple hyphens with single hyphen

    return item
})