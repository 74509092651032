const buffer = []
const UNDO_LEVELS = 100
let currentIndex = -1 // Pointer to track the current state in the buffer

/**
 * History component that manages state history with an onRestore callback
 * @param {Object} param - An object containing action, state, and onRestore callback
 */
const History = ({ action, data, options }) => {
  // Undo: Go one step back in the history buffer
  if (action === "undo") {
    //no more steps
    if (currentIndex <= 0) return
    currentIndex -= 1
    const previousState = buffer[currentIndex]
    return previousState
  }

  // Redo: Go one step forward in the history buffer
  if (action === "redo") {
    //no more steps
    if (currentIndex >= buffer.length - 1) return

    currentIndex += 1
    const nextState = buffer[currentIndex]
    return nextState
  }

  // Add: Only add if it's different from the last state
  if (action === "add") {
    // Don't add if it's the same as the last state
    if (buffer.length > 0 && buffer[buffer.length - 1] === data) {
      return false
    }

    // If the currentIndex is not at the end of the buffer, truncate forward history
    if (currentIndex < buffer.length - 1) {
      buffer.splice(currentIndex + 1)
    }

    // Add the new state to the buffer
    buffer.push(data)
    currentIndex = buffer.length - 1

    // If the buffer exceeds the UNDO_LEVELS limit, remove the oldest state
    if (buffer.length > UNDO_LEVELS) {
      buffer.shift()
      currentIndex = Math.max(0, currentIndex - 1)
    }
    return true
  }

  // Clear: Clear the history buffer
  if (action === "clear") {
    buffer.length = 0
    currentIndex = -1
    return
  }
}

// Example usage
export default History
