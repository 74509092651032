// index.jsx
import React from "react"
import { createRoot } from "react-dom/client"
import LinkDialog from "./link-dialog"
import EditLinkMenu from "./edit-menu"

const addLink = async (data) => {
  const { selectionRange, setSelectionRange } = data
  if (!selectionRange || selectionRange.collapsed) return

  // Get the selected text
  const selectedText = selectionRange.toString()
  if (!selectedText) return

  // Create a container for the dialog
  const container = document.createElement("div")
  document.body.appendChild(container)

  // Create promise to handle dialog result
  return new Promise((resolve) => {
    const root = createRoot(container)
    root.render(
      <LinkDialog
        onClose={(result) => {
          root.unmount()
          container.remove()
          resolve(result)
        }}
      />
    )
  }).then((result) => {
    if (!result) return // Dialog cancelled

    const { url, isDownload, openInSameTab } = result

    // Create link element
    const link = document.createElement("a")
    link.href = url
    link.textContent = selectedText
    link.className =
      "editor-link text-blue-600 hover:text-blue-800 underline hover:no-underline"
    // Add download attribute
    if (isDownload) link.setAttribute("download", "")
    // Open in same tab
    link.setAttribute("target", openInSameTab ? "_self" : "_blank")

    // Insert the link
    const range = selectionRange.cloneRange()
    range.deleteContents()
    range.insertNode(link)
    range.setStartAfter(link)
    range.setEndAfter(link)

    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
    setSelectionRange(range)

    return link
  })
}

const editLink = (data) => {
  const { target } = data
  if (!target || !target.classList.contains("editor-link")) return

  // Remove any existing menu first
  const existingMenu = document.querySelector(".link-menu-container")
  if (existingMenu) {
    existingMenu.remove()
  }

  // Create container for edit menu
  const container = document.createElement("div")
  container.className = "link-menu-container"
  document.body.appendChild(container)

  const root = createRoot(container)
  root.render(
    <EditLinkMenu
      link={target}
      onClose={() => {
        root.unmount()
        container.remove()
      }}
    />
  )

  // Return cleanup function
  return () => {
    root.unmount()
    container.remove()
  }
}

const deleteLink = (data) => {
  const { target } = data
  if (!target || !target.classList.contains("editor-link")) return

  const text = target.textContent
  const textNode = document.createTextNode(text)
  target.parentNode.replaceChild(textNode, target)
}

const updateLink = (data) => {
  const { target, url, isDownload, openInSameTab } = data
  if (!target || !target.classList.contains("editor-link")) return

  //set the new url
  target.href = url

  //set the download attribute
  if (isDownload) {
    target.setAttribute("download", "")
  } else {
    target.removeAttribute("download")
  }

  //set the target attribute
  target.setAttribute("target", openInSameTab ? "_self" : "_blank")
}

const Link = ({ action, data }) => {
  switch (action) {
    case "add":
      return addLink(data)
    case "edit":
      return editLink(data)
    case "delete":
      return deleteLink(data)
    case "update":
      return updateLink(data)
    default:
      console.warn(`Unknown link action: ${action}`)
      return null
  }
}

export default Link
