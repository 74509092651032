import React, { useState, useEffect, useRef } from "react"
import Modal from "aldoo-ra/Modal"
import Input from "aldoo-ra/Input"
import Button from "aldoo-ra/Button"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { previewImage, previewTitle } from "aldoo-ra/CMS/preview"
import { getApi } from "../field-api-resolver"
import parse from "html-react-parser"

export default function DatabaseModelSelectDialog({
  //if the dialog is open
  isOpen,
  //the function to call when the dialog is closed
  onClose,
  //the type of the content, mongoose model name
  type,
  //the function to call when a content is selected
  onSelect,
  //a function to create an overview of the content
  //displayed in the dialog
  overview,
  //the fields that we can search content by
  searchFields = ["name", "title", "label", "id"],
}) {
  const { Get } = getApi(type)

  const [searchQuery, setSearchQuery] = useState("") // For searching contents by name
  const [contentItems, setContentItems] = useState([]) // For holding the list of contents fetched from the API
  const [loading, setLoading] = useState(false) // To show a loading spinner when fetching data
  const searchInputRef = useRef(null) // Reference to the search input field

  // Function to load content items based on the type and search query
  const loadContentItems = async (query = "") => {
    setLoading(true) // Start loading

    const match = {
      deleted: false, // Fetch only non-deleted content
    }

    if (query) {
      //Generate match.or from the search fields
      match.$or = searchFields.map((field) => ({
        [field]: { $regex: query, $options: "i" },
      }))
    }

    //the get is not yet available
    if (!Get) return

    try {
      const result = await Get({
        match,
        page: 1,
        itemsPerPage: 1000,
      })

      setContentItems(result.data) // Set the fetched content items
    } catch (error) {
      console.error("Error loading content items:", error)
    } finally {
      setLoading(false) // End loading
    }
  }

  // Load content items whenever the dialog opens or the search query changes
  useEffect(() => {
    if (!isOpen) return
    loadContentItems(searchQuery)
    // Focus on the search input field when the dialog opens
    if (searchInputRef.current) searchInputRef.current.focus()
  }, [isOpen, searchQuery])

  // Handle content selection
  const handleSelectContent = (content) => {
    onSelect(content) // Pass the selected content back to the parent
    onClose() // Close the dialog
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEscape variant="centered">
      <div className="flex flex-col text-admin_text h-full">
        <div className="flex-shrink-0">
          <h2 className="text-xl mb-4">Select a {type}</h2>

          {/* Search Input */}
          <Input
            ref={searchInputRef}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name"
            icon={<MagnifyingGlassIcon className="w-6 h-6 text-neutral_3" />}
            className="bg-admin_canvas rounded-full"
          />
        </div>

        {/* Content Items Listing */}
        <div className="mt-5 overflow-y-auto h-[60vh]">
          {loading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : contentItems.length > 0 ? (
            contentItems.map((item) => (
              <div
                key={item._id}
                className="p-3 border-b border-neutral_1 flex justify-between items-center cursor-pointer hover:bg-admin_canvas mb-2"
                onClick={() => handleSelectContent(item)}
              >
                <div className="flex flex-row items-center">
                  <div className="w-12 h-12 bg-neutral_2 rounded-full flex items-center justify-center mr-4">
                    {previewImage(item) ? (
                      <img
                        src={previewImage(item)}
                        alt={`${item.name} icon`}
                        className="w-12 h-12 object-cover"
                      />
                    ) : (
                      <div className="w-12 h-12 bg-neutral_2 rounded-full flex items-center justify-center mr-4">
                        {item[searchFields[0]]?.charAt(0)?.toUpperCase() ||
                          "DB"}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <div className="font-semibold">{item[searchFields[0]]}</div>
                    <div className="text-sm text-neutral_3">
                      {overview && overview(item)}
                    </div>
                  </div>
                </div>
                <Button variant="link">
                  <ChevronRightIcon className="w-6 h-6" />
                </Button>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-500">No content found</p>
          )}
        </div>
      </div>
    </Modal>
  )
}
