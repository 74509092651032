import Value from "aldoo-ra/Value"
import { useEffect, useState } from "react"
import AldooStoreAPI from "aldoo-ra/Store/api"
import { priceDisplayFormat } from "../Admin/Store/Prices/display-formatter"
import StoreRender from "aldoo-ra/Store/store-render"

let storeInstance = null

/**
 * This component is used to handle the purchase product lifecycle.
 * It aim to maintain compatibility with the purchase flow introduced in google analytics
 * Session Start - the user
 * View Product -
 * Add To Cart -
 * Begin Checkout -
 * Purchase
 */
export default function AldooStore() {
  //This is the Aldoo Store cart that holds the products
  const [cart, setCart] = Value({
    key: "aldoo-store-cart",
    defaultValue: [],
    persistKey: "aldoo-store-cart",
  })
  //This is the Aldoo Store payment info that holds the payment details
  //Like a receipt in the shopping cart so the success screen can model its message
  const [paymentInfo, setPaymentInfo] = Value({
    key: "aldoo-payment-info",
    defaultValue: null,
    persistKey: "aldoo-payment-info",
  })

  const [promoCodeInfo, setPromoCodeInfo] = Value({
    key: "aldoo-promo-code-info",
    defaultValue: null,
    persistKey: "aldoo-promo-code-info",
  })

  const [promoCode, setPromoCode] = Value({
    key: "aldoo-promo-code",
    defaultValue: "",
    persistKey: "aldoo-promo-code",
  })

  //Once a payment is successful, we store the payment info here
  //Since the original payment info is cleared
  //The receipt holds: { paymentInfo, cart }
  const [paymentReceipt, setPaymentReceipt] = Value({
    key: "aldoo-payment-receipt",
    defaultValue: null,
    persistKey: "aldoo-payment-receipt",
  })

  const [cartVisible, setCartVisible] = useState(false)
  const [checkoutVisible, setCheckoutVisible] = useState(false)
  const [paymentSuccessVisible, setPaymentSuccessVisible] = useState(false)
  const [paymentError, setPaymentError] = useState(null)
  const [promoCodeError, setPromoCodeError] = useState("")

  //When the cart changes, the checkout should reinitialize a payment
  //Otherwise, the cart is just for viewing
  const [cartChanged, setCartChanged] = useState(false)

  useEffect(() => {
    //there is no paymentInfo or the cart hasn't changed
    if (!cartChanged) return
    // Clean up old subscription
    cleanupOldSubscription()
    // Clear existing payment info as it's no longer valid
    setPaymentInfo(null)
  }, [cartChanged])

  //on free purchases announce the payment success immediately
  useEffect(() => {
    if (paymentInfo && !paymentInfo.requiresPayment) onPaymentSuccess()
  }, [paymentInfo])

  //Upon new receipt , show the success screen
  useEffect(() => {
    if (!paymentReceipt || !paymentInfo) return
    //empty the cart & reset paymentInfo
    emptyCart()
    //show the payment success screen
    setPaymentSuccessVisible(true)
  }, [paymentReceipt])

  const cleanupOldSubscription = async () => {
    const { subscriptionId } = paymentInfo || {}

    if (!subscriptionId) return

    try {
      // Call the API endpoint that will invoke the server-side method
      const response = await AldooStoreAPI.cancelIncompleteSubscription(
        subscriptionId
      )
      // if (response.result) {
      //   console.log("Old subscription cancelled successfully")
      // }

      // if (response.error) {
      //   console.error("Error cancelling old subscription:", response.error)
      // }
    } catch (error) {
      // console.error("Error cleaning up old subscription:", error)
    }
  }

  /**
   * Get an element for rendering from the project or the default elements
   * @param {*} name
   */
  const getElement = (name) => {
    return StoreRender.getElement(name)
  }

  const getFragment = (name) => {
    return StoreRender.getFragment(name)
  }

  const refreshCart = () => {
    setCart([...storeInstance.cart])
  }

  const emptyCart = () => {
    setCart([])
    setPaymentInfo(null)
    setPaymentError(null)
    resetPromoCode()
    //mark as changed
    setCartChanged(true)
  }

  const showCart = () => {
    //reset the cart changed flag
    setCartChanged(false)
    setCartVisible(true)
  }

  const hideCart = () => {
    setCartVisible(false)
  }

  const showCheckout = () => {
    setCheckoutVisible(true)
  }

  const resetPromoCode = () => {
    setPromoCode("")
    setPromoCodeError("")
    setPromoCodeInfo(null)
    //mark as changed
    setCartChanged(true)
  }

  const hideCheckout = () => {
    setCheckoutVisible(false)
  }

  const beginCheckout = () => {
    setPaymentReceipt(null)
    setCartVisible(false)
    setCheckoutVisible(true)
  }

  /**
   * Add the product interface to the product object
   */
  const addProductInterface = (product) => {
    //remove one instance of the product from the cart
    product.remove = () => {
      //if the quantity is > 1, decrement the quantity
      if (product.quantity === 1) return
      //remove from the cart
      product.quantity -= 1
      refreshCart()
    }
    product.add = () => {
      //increment the quantity
      product.quantity += 1
      refreshCart()
    }
    //remove the product from the cart completely
    product.clear = () => {
      //remove from the cart
      const updatedCart = cart.filter(
        (item) => item.product_id !== product.product_id
      )
      setCart(updatedCart)
    }
  }

  /**
   * Add a product to the cart
   * @param {*} product  An object representing the product to be added to the cart
   * @param {*} proceedToCart  A boolean indicating whether to show the cart after adding the product
   * this object should have the following properties:
   * - id: the product id - this is the stable identifier for the product
   * - store_id: this is the product id as set in the Web store ( ex: Stripe, Shopify, etc)
   * - name: the product name
   * - price: the product price
   * - currency: the currency of the product
   * - quantity: the quantity of the product
   * - variant: the variant of the product
   */
  const addToCart = ({ product, proceedToCart = false }) => {
    //TODO: we support a single product purchase in version 1
    //empty the cart
    while (cart.length) cart.pop()

    //check if the product is already in the cart and increment the quantity
    const existingProduct = cart.find(
      (item) => item.product_id === product.product_id
    )
    if (existingProduct) {
      existingProduct.quantity += 1
      //make sure the product has the interface
      addProductInterface(existingProduct)
      //mark as changed
      setCartChanged(true)
      //refresh the cart
      refreshCart()
      if (proceedToCart) showCart()
      return
    }
    //add the product interface to the product
    addProductInterface(product)
    //set the initial quantity to 1
    product.quantity = 1
    //add the product to the cart
    cart.push(product)
    setCart([...cart])
    //mark as changed
    setCartChanged(true)
    //if proceedToCart is true, show the cart
    if (proceedToCart) showCart()
  }

  const applyPromoCode = async () => {
    //mark as changed
    setCartChanged(true)

    //validate the promo code
    const response = await AldooStoreAPI.getPromoCodeInfo(promoCode)
    if (response.error) {
      setPromoCodeError(response.error)
      return
    }

    if (response.status !== "valid") {
      setPromoCodeError(response.status)
      return
    }

    //set the promo code error to empty
    setPromoCodeError("")
    //set the promo code details
    setPromoCodeInfo(response)
  }

  // Client-side discount calculation
  const calculateDiscountedTotal = (total, promoCodeInfo) => {
    // Return original total if no promo code or there's an error
    if (!promoCodeInfo || promoCodeInfo.status !== "valid") {
      return total
    }

    // Check if purchase meets minimum amount requirement
    if (promoCodeInfo.minimumAmount && total < promoCodeInfo.minimumAmount) {
      return total
    }

    let discountedTotal = total

    // Use the type and amount fields instead of direct percentOff/amountOff
    switch (promoCodeInfo.type) {
      case "percentage": {
        // Calculate percentage discount using Math.floor for consistency
        const discountAmount = Math.floor(total * (promoCodeInfo.amount / 100))
        discountedTotal = total - discountAmount
        break
      }
      case "fixed": {
        // For fixed amount, directly subtract the discount
        discountedTotal = Math.max(0, total - promoCodeInfo.amount)
        break
      }
      default:
        return total
    }

    // Ensure we never return a negative amount
    return Math.max(0, discountedTotal)
  }

  const getCartTotalValue = () => {
    //if the cart is empty, return 0.00
    if (!cart.length) return "0.00"

    let total = 0
    cart.forEach((product) => {
      total += product.amount * product.quantity
    })

    total = calculateDiscountedTotal(total, promoCodeInfo)

    return priceDisplayFormat(total, cart[0]?.currency)
  }

  const getPromoCodeInfo = () => {
    if (!promoCodeInfo || promoCodeError) return ""

    const {
      promoCodeId,
      duration,
      durationInMonths = null,
      percentOff,
      amountOff,
      currency,
    } = promoCodeInfo

    if (!promoCodeId) return ""

    if (percentOff) return ` -${percentOff}%`
    if (amountOff) return ` -${priceDisplayFormat(amountOff, currency)}`

    return promoCodeInfo
  }

  /**
   * Here we are in a successful payment state
   */
  const onPaymentSuccess = () => {
    hideCheckout()
    //Set the info for the payment success screen
    //create copies of the cart and paymentInfo
    setPaymentReceipt({
      cart: [...storeInstance.cart],
      paymentInfo: { ...storeInstance.paymentInfo },
    })
  }

  /**
   * An error means that the payment has failed
   * @param {*} error
   */
  const onPaymentError = (error) => {
    setPaymentError(error)
    logPaymentError(error)
  }

  const logPaymentError = (error) => {
    //Here we report the error to AldooAnalytics so we can track
    //what is the reason behind the payment failure
  }

  /**
   * The payment has succeeded but there are warnings
   * such as the payment method being expiring soon
   * or it wasn't attached properly
   * @param {*} warning
   */
  const onPaymentWarning = (warning) => {
    console.log("Payment Warning", warning)
    logPaymentError(warning)
  }

  //Get the render elements
  const Cart = getElement("shopping-cart")
  const Checkout = getElement("checkout")
  const PaymentSuccess = getElement("payment-success")
  const PaymentError = getElement("payment-error")

  //export the store interface
  storeInstance = {
    cartChanged,
    onPaymentSuccess,
    onPaymentError,
    logPaymentError,
    onPaymentWarning,
    cartVisible,
    checkoutVisible,
    hideCheckout,
    showCheckout,
    showCart,
    hideCart,
    setCart,
    cart,
    promoCode,
    beginCheckout,
    getElement,
    getFragment,
    setPromoCode,
    resetPromoCode,
    refreshCart,
    emptyCart,
    addToCart,
    paymentSuccessVisible,
    setPaymentSuccessVisible,
    applyPromoCode,
    getPromoCodeInfo,
    promoCodeError,
    setPromoCodeError,
    getCartTotalValue,
    paymentInfo,
    setPaymentInfo,
    paymentError,
    setPaymentError,
    paymentReceipt,
  }

  // Render the store elements
  return (
    <>
      <Cart store={storeInstance} />
      <Checkout store={storeInstance} />
      <PaymentSuccess store={storeInstance} />
      <PaymentError store={storeInstance} />
    </>
  )
}

// Create a proxy to always access the current instance
export const store = new Proxy(
  {},
  {
    get: function (target, prop) {
      if (!storeInstance) {
        console.warn("Store instance not yet initialized")
        return undefined
      }
      return storeInstance[prop]
    },
  }
)
