import { api } from "aldoo-ra/API"
//construct the endpoint
const storeAPI = api("store")

//Initiate a pay for a product
export const initializePayment = async (payload) => {
    return await storeAPI({
        initiate_payment: {
            platform: "Web",
            ...payload
        }
    })
}

export const startTrialSubscription = async (payload) => {
    return await storeAPI({
        start_trial_subscription: {
            platform: "Web",
            ...payload
        }
    })
}

//Confirm a payment, this will add a payment method to the user
export const confirmPayment = async (payload) => {
    return await storeAPI({
        confirm_payment: {
            platform: "Web",
            ...payload
        }
    })
}

export const attachPaymentMethod = async ({
    payment_method_id,
    makeDefault,
}) => {
    return await storeAPI({
        attach_payment_method: {
            platform: "Web",
            payment_method_id,
            makeDefault,
        },
    })
}

export const detachPaymentMethod = async (paymentMethodId) => {
    return await storeAPI({
        detach_payment_method: {
            platform: "Web",
            paymentMethodId,
        },
    })
}

//get payment methods for the user
export const getPaymentMethods = async () => {
    return await storeAPI({
        get_payment_methods: {
            platform: "Web",
        },
    })
}

export const initializePaymentMethodSetup = async () => {
    return await storeAPI({
        initialize_payment_method_setup: {
            platform: "Web"
        }
    });
};

export const getPromoCodeInfo = async (promo_code) => {
    return await storeAPI({
        get_promo_code_info: {
            platform: "Web",
            promo_code: promo_code,
        },
    })
}

export const cancelIncompleteSubscription = async (subscriptionId) => {
    return await storeAPI({
        cancel_incomplete_subscription: {
            platform: "Web",
            subscriptionId,
        },
    })
}

export const cancelSubscription = async (subscriptionId) => {
    return await storeAPI({
        cancel_subscription: {
            platform: "Web",
            subscriptionId,
        },
    })
}

export const getInvoices = async ({ page, itemsPerPage, search }) => {
    return await storeAPI({
        get_invoices: {
            platform: "Web",
            page,
            itemsPerPage,
            search
        }
    });
}

export const getStoreAccount = async () => {
    return await storeAPI({ account: true })
}


export default {
    initializePayment,
    initializePaymentMethodSetup,
    startTrialSubscription,
    confirmPayment,
    getInvoices,
    attachPaymentMethod,
    detachPaymentMethod,
    getPaymentMethods,
    getPromoCodeInfo,
    cancelIncompleteSubscription,
    cancelSubscription,
    getStoreAccount
}