import MenuGroup from "aldoo-ra/MenuGroup"
import Button from "aldoo-ra/Button"
import { useState } from "react"
import Value from "aldoo-ra/Value"
import HamburgerMenu from "./hamburgerMenu"

const HeaderMenuMobile = () => {
  const [platform, setPlatform] = Value("site_platform")
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <div
      className="fixed flex h-[80px] bottom-0 left-0 w-full flex-col items-center justify-center bg-theme_white px-4 md:px-px1_l"
      style={{ zIndex: 190 }}
    >
      <div className="w-full h-[80px] flex items-center justify-between ">
        {/* Logo */}
        <div className="w-[40px]" onClick={closeMenu}>
          <MenuGroup
            name="Header Logo Mobile"
            renderAs="hlist.icons"
            itemWidth="40px"
          />
        </div>

        <div className="flex items-center">
          {/* Download Button */}
          <div onClick={closeMenu}>
            <MenuGroup
              name="Header Download"
              renderAs="hlist"
              itemWidth="150px"
              itemsAsLinks={false}
              itemRender={(item) => {
                const url =
                  (item?.link[0]?.parameters &&
                    item?.link[0]?.parameters[platform]) ||
                  item?.link[0]?.url
                return (
                  <Button variant="primary_s">
                    <a href={url} target="_blank">
                      {item?.label}
                    </a>
                  </Button>
                )
              }}
            />
          </div>

          <HamburgerMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
        </div>
      </div>
    </div>
  )
}

export default HeaderMenuMobile