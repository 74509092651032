/**
 * This component is responsible for listening for signin requests
 */

import { useEffect, useState } from "react"
import useSigninController from "./signin-controller"

export default function SignIn() {
  const controller = useSigninController()
  const [dialogs, setDialogs] = useState([])

  useEffect(() => {
    // Dynamically import all files in the components/Signin/Dialogs folder
    const importDialogs = async () => {
      try {
        const dialogModules = await Promise.all([
          import("@components/Signin/dialogs/signin.jsx"),
          import("@components/Signin/dialogs/magiclink.jsx"),
          import("@components/Signin/dialogs/provider.jsx"),
          import("@components/Signin/dialogs/success.jsx"),
        ])

        const dialogs = dialogModules.map((module) => ({
          Component: module.default,
          name: module.default.name,
        }))
        setDialogs(dialogs)
      } catch (error) {
        console.error(
          "Failed to load dialog components from components/Signin/Dialogs:",
          error
        )
        setDialogs([])
      }
    }

    importDialogs()
  }, [])

  return (
    <>
      {dialogs.length > 0 &&
        dialogs.map(({ Component, name }) => (
          <Component key={name} controller={controller} />
        ))}
    </>
  )
}
