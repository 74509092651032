import React from "react"
import { createRoot } from "react-dom/client"
import Menu from "../../../../Menu"
import { setParameter } from "../../tools"
import { runCommand, plugins, CommanderStateResolve } from "aldoo-ra/Commander"

const ImageOptionsMenu = ({ selectedImage, onClose }) => {
  //render for menu items
  const render = (item) => {
    return (
      <div className="flex flex-row items-center gap-2">
        {item.icon}
        <span>{item.label}</span>
      </div>
    )
  }

  const menuData = {
    label: "Options",
    items: [
      {
        label: "Align",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        ),
        items: [
          {
            label: "Left",
            options: { action: "image.align", alignment: "left" },
            render,
            icon: (
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
                />
              </svg>
            ),
          },
          {
            label: "Center",
            options: { action: "image.align", alignment: "center" },
            render,
            icon: (
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            ),
          },
          {
            label: "Right",
            options: { action: "image.align", alignment: "right" },
            render,
            icon: (
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h12"
                />
              </svg>
            ),
          },
        ],
      },
      {
        label: "Size",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 7v6h6m-6 0 9-9 9 9m-9-9v6h6"
            />
          </svg>
        ),
        items: [
          {
            label: "100%",
            options: { action: "image.size", size: "100%" },
            render,
          },
          {
            label: "50%",
            options: { action: "image.size", size: "50%" },
            render,
          },
          {
            label: "25%",
            options: { action: "image.size", size: "20%" },
            render,
          },
          // {
          //   label: "Reset Size",
          //   options: { action: "image.reset" },
          //   render,
          // },
        ],
      },
      {
        label: "Replace Image",
        render,
        options: { action: "image.replace" },
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        ),
      },
      {
        label: "Edit Alt Text",
        command: "image.alt",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
        ),
      },
      {
        label: "Add Caption",
        command: "image.caption",
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        ),
      },
      {
        label: "Delete",
        options: { action: "image.delete" },
        render,
        icon: (
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        ),
        className: "text-red-500 hover:text-red-600",
      },
    ],
  }

  const imageAction = ({ options }) => {
    const { action } = options
    runCommand(action, { selectedImage })
    onClose()
  }

  const actionMap = {
    "image.align": ({ options }) => {
      const { alignment } = options
      runCommand("image.align", { selectedImage, alignment })
      onClose()
    },
    "image.delete": imageAction,
    "image.size": ({ options }) => {
      const { size } = options
      runCommand("image.size", { selectedImage, size })
      onClose()
    },
    "image.replace": () => {
      const url = prompt("Enter new image URL:")
      if (url) {
        runCommand("image.replace", { selectedImage, url })
      }
      onClose()
    },
    alt: () => {
      const alt = prompt("Enter alt text:", selectedImage.alt)
      if (alt !== null) {
        runCommand("image.alt", { alt })
      }
      onClose()
    },
    caption: () => {
      const caption = prompt("Enter caption text:")
      if (caption === null) return onClose()

      runCommand("image.caption", { caption })
      onClose()
    },
    reset: () => {
      runCommand("image.reset")
      onClose()
    },
  }

  const imageRect = selectedImage.getBoundingClientRect()

  return (
    <div
      className="fixed z-[1000] ae-options-menu"
      style={{
        top: `${imageRect.top + 10}px`,
        left: `${imageRect.left + 10}px`,
      }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className="bg-admin_paper border border-neutral_1 rounded-lg shadow-lg">
        <Menu
          actionMap={actionMap}
          menuData={menuData}
          renderAs="vlist.menu"
          itemWidth="200px"
          submenuMode="vertical"
          hideOnItemClick={false}
          containMenu
        />
      </div>
    </div>
  )
}

const removeExistingMenu = () => {
  const existingMenu = document.querySelector(".ae-options-menu-container")
  if (existingMenu) {
    existingMenu.remove()
  }
}

const OptionsMenu = (selectedImage) => {
  if (!selectedImage) return
  removeExistingMenu()

  // Create container and position it relative to the image
  const menuContainer = document.createElement("div")
  menuContainer.className = "ae-options-menu-container"
  menuContainer.style.position = "absolute"
  menuContainer.style.top = "8px" // Add some padding from the top
  menuContainer.style.left = "8px" // Add some padding from the left
  menuContainer.style.zIndex = "1000"

  // Append to the image's parent to maintain relative positioning
  selectedImage.parentElement.appendChild(menuContainer)

  // Set position relative on the parent if not already set
  if (selectedImage.parentElement.style.position !== "relative") {
    selectedImage.parentElement.style.position = "relative"
  }

  const handleClickOutside = (e) => {
    if (!menuContainer.contains(e.target) && e.target !== selectedImage) {
      removeExistingMenu()
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }

  document.addEventListener("mousedown", handleClickOutside)

  const root = createRoot(menuContainer)
  root.render(
    <ImageOptionsMenu
      selectedImage={selectedImage}
      onClose={() => {
        removeExistingMenu()
        document.removeEventListener("mousedown", handleClickOutside)
      }}
    />
  )
}

export default OptionsMenu
