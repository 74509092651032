import Localization from "aldoo-ra/Localization"

/**
 * Safely clones objects using structuredClone if available, falling back to JSON parse/stringify
 * @param {Object} obj - The object to clone
 * @returns {Object} A deep clone of the input object
 */
const safeClone = (obj) => {
  if (typeof structuredClone === "function") {
    return structuredClone(obj)
  }
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Custom hook for CMS localization functionality
 * @returns {Object} Object containing readLocalization and resolveLocalization functions
 */
const useCMSLocalization = () => {
  const { load, t, translateWithLanguage, currentLanguage } = Localization()

  /**
   * Recursively extracts localization keys from an item
   * @param {Object} item - The item to extract keys from
   * @returns {Array<string>} Array of unique localization keys
   */
  const extractLocalizationKeys = (item) => {
    const keys = new Set()

    const extractKeys = (obj) => {
      if (!obj) return

      if (Array.isArray(obj.localization)) {
        obj.localization.forEach(({ localizationKey }) => {
          keys.add(localizationKey)
        })
      }

      Object.values(obj).forEach((value) => {
        if (value && typeof value === "object") {
          extractKeys(value)
        }
      })
    }

    extractKeys(item)
    return Array.from(keys)
  }

  /**
   * Loads localization data for the provided items
   * @param {Array<Object>} data - Array of items requiring localization
   */
  const readLocalization = async (data) => {
    const keysToLoad = new Set()

    data.forEach((item) => {
      const keys = extractLocalizationKeys(item)
      keys.forEach((key) => keysToLoad.add(key))
    })

    const uniqueKeys = Array.from(keysToLoad)
    await load(uniqueKeys)

    if (currentLanguage !== "en") await load(uniqueKeys, "en")
  }

  /**
   * Resolves localization for a specific field in an item
   * @param {Object} localizedObj - The object containing localization data
   * @param {Object} localizationItem - The localization details
   * @returns {string} The translated content
   */
  const resolveFieldLocalization = (localizedObj, localizationItem) => {
    const { localizationKey, field } = localizationItem
    let translation = t(localizationKey)
    if (translation === `{{${localizationKey}}}`) {
      translation = translateWithLanguage(localizationKey, "en")
    }
    if (localizedObj.content?.[field]) localizedObj.content[field] = translation
  }

  /**
   * Recursively resolves localization for an item
   * @param {Object} item - The item to localize
   * @returns {Object} The localized item
   */
  const resolveLocalization = (item) => {
    const resolveItem = (obj) => {
      if (!obj) return obj

      const localizedObj = safeClone(obj)

      // Handle array of strings case
      if (Array.isArray(localizedObj)) {
        return localizedObj.map((item) => resolveItem(item))
      }

      // Process localization array if present
      if (
        Array.isArray(localizedObj.localization) &&
        localizedObj.localization.length
      ) {
        localizedObj.localization.forEach((localizationItem) => {
          resolveFieldLocalization(localizedObj, localizationItem)
        })
      }

      // Recursively process nested content
      if (localizedObj.content) {
        Object.entries(localizedObj.content).forEach(([key, value]) => {
          const isArrayOfStrings =
            Array.isArray(value) && value.length && typeof value[0] === "string"

          if (!isArrayOfStrings && typeof value === "object") {
            localizedObj.content[key] = resolveItem(value)
          }
        })

        // Preserve ID in content if present
        if (localizedObj._id) localizedObj.content._id = localizedObj._id
      }

      return localizedObj.content ?? localizedObj
    }

    return resolveItem(safeClone(item))
  }

  return {
    readLocalization,
    resolveLocalization,
  }
}

export default useCMSLocalization
