import { api } from "aldoo-ra/API"
import Config from "aldoo-ra/Config"

const deploymentAPI = api("aldoo-deploy")
const DEFAULT_TIMEOUT = 5 * 60 * 1000
const DEFAULT_POLL_INTERVAL = 1000

const OPERATION_CONFIG = {
  APP_LIST: {
    timeout: 2 * 60 * 1000,
    pollInterval: 1000,
  },
  APP_CHECK_UPDATE: {
    timeout: 5 * 60 * 1000,
    pollInterval: 1000,
  },
  APP_RUN_UPDATE: {
    timeout: 30 * 60 * 1000,
    pollInterval: 5000,
  },
  APP_STATUS: {
    timeout: 60 * 1000,
    pollInterval: 2000,
  },
}

let instance = null

function createController() {
  if (instance) return instance

  const pollTaskStatus = async ({
    response,
    timeout = DEFAULT_TIMEOUT,
    pollInterval = DEFAULT_POLL_INTERVAL,
    onResponse,
  }) => {
    if (response.error || !response.task) {
      return response
    }

    const startTime = Date.now()
    const taskId = response.task

    while (true) {
      if (Date.now() - startTime > timeout) {
        return { error: "timeout" }
      }

      const response = await deploymentAPI({
        status: {
          task_id: taskId,
        },
      })

      if (onResponse) onResponse(response)

      if (response.error === "rate_limited") {
        return response
      }

      if (response.error) return response

      if (
        response.status === "finished" ||
        response.status === "failed" ||
        response.status === "error"
      ) {
        return response
      }

      await new Promise((resolve) => setTimeout(resolve, pollInterval))
    }
  }

  const getAppList = async (options = {}) => {
    const { onResponse } = options
    const response = await deploymentAPI({
      app_list: {
        app_env: Config.PLATFORM_API_URL.includes("stage-") ||
          Config.PLATFORM_API_URL.includes("localhost")
          ? "staging"
          : "production",
      },
    })
    return await pollTaskStatus({
      response,
      timeout: OPERATION_CONFIG.APP_LIST.timeout,
      pollInterval: OPERATION_CONFIG.APP_LIST.pollInterval,
      onResponse,
    })
  }

  const getAppStatuses = async (apps) => {
    if (!apps || !apps.length) {
      return new Map()
    }

    const appIds = apps.map((app) => app.id)
    const response = await deploymentAPI({
      get_app_updates: {
        app_ids: appIds,
      },
    })

    const result = await pollTaskStatus({
      response,
      timeout: OPERATION_CONFIG.APP_CHECK_UPDATE.timeout,
      pollInterval: OPERATION_CONFIG.APP_CHECK_UPDATE.pollInterval,
    })

    const appStatusesMap = new Map()

    if (result.error) {
      // If the batch request failed, set the error for all apps
      apps.forEach((app) => {
        appStatusesMap.set(app.id, { error: result.error })
      })
    } else if (result.result) {
      // Map the results to individual apps
      Object.entries(result.result).forEach(([appId, status]) => {
        appStatusesMap.set(appId, status)
      })

      // Set error for any apps that didn't get a status back
      apps.forEach((app) => {
        if (!appStatusesMap.has(app.id)) {
          appStatusesMap.set(app.id, { error: "No status returned" })
        }
      })
    }

    return appStatusesMap
  }

  const runAppUpdate = async ({ appId, onResponse }) => {
    if (!appId) {
      return { error: "Application ID is required" }
    }

    const response = await deploymentAPI({
      app_run_update: {
        app_id: appId,
      },
    })

    //the task progress is handled by the deployment-viewer via pollTaskStatus in the app status update
    return response
  }

  instance = {
    getAppList,
    getAppStatuses,
    runAppUpdate,
    pollTaskStatus,
  }

  return instance
}

export default createController
