import React, { useState, useEffect, useRef } from "react"
import { useFieldDragController } from "../controllers/field-drag-controller"
import Input from "aldoo-ra/Input"
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline"
import Button from "aldoo-ra/Button"
import DropDownSelect from "aldoo-ra/DropdownSelect"
import TypeResolver from "../type-resolver"
import DatabaseModelTypeResolver from "../database-model-type-resolver"
import _ from "lodash"
import Typography from "aldoo-ra/Typography"

const formatName = (name) => {
  if (!name || typeof name !== "string") {
    return String(name || "")
  }
  return name
    .replace(/([A-Z][a-z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
}

export default function TextEditor({
  onChange,
  value,
  field,
  parentObject,
  parentValues,
  showInfo = true,
  readOnly = false,
}) {
  const inputRefs = useRef([])
  const isInternalChange = useRef(false)

  const { availableTypeNames } = TypeResolver()
  const { availableTypeNames: availableModelTypeNames } =
    DatabaseModelTypeResolver()

  const resolveOptions = (field) => {
    let { options } = field

    if (!options) return []

    if (Array.isArray(options) && options.every((option) => option?.resolver)) {
      const match = options.find((option) => {
        if (!option.type?.in) return false

        const parentType = Array.isArray(parentObject?.type)
          ? parentObject.type[0]?.value
          : parentObject?.type?.value

        return option.type.in.includes(parentType)
      })

      if (!match) return options
      const { resolver } = match
      options = resolver
    }

    if (options === "{{TypeResolver.availableTypeNames}}") {
      return availableTypeNames().map((type) => ({
        value: type,
        name: formatName(type),
      }))
    }

    if (options === "{{DatabaseModelTypeResolver.availableTypeNames}}") {
      return availableModelTypeNames().map((type) => ({
        value: type,
        name: formatName(type),
      }))
    }

    if (options === "{{ContentType.fieldGroups}}") {
      const pValues = parentValues ? parentValues() : null
      return (
        pValues?.groups?.map((group) => ({
          value: group.id,
          name: formatName(group.label),
        })) || []
      )
    }

    if (Array.isArray(options)) {
      return options.map((option) => {
        if (typeof option === "string") {
          return {
            value: option,
            name: formatName(option),
          }
        }
        return option
      })
    }

    return options || []
  }

  const formatOptionValue = (val) => {
    if (val == null) return ""

    // For Input component (non-dropdown), we want just the value part
    if (!field.options?.length) {
      // If it's an object with value property, return just the value
      return val?.value ?? val
    }

    if (Array.isArray(val) && val.length > 0 && typeof val[0] === "object") {
      return val[0]
    }

    // If val is already in {value, name} format, return as is
    if (val && typeof val === "object" && "value" in val && "name" in val) {
      return val
    }

    // Get options and find matching option
    const options = resolveOptions(field)
    const matchingOption = options?.find((opt) => opt?.value === val)

    if (matchingOption) return matchingOption

    // Create new option with safe name formatting
    return { value: val, name: formatName(String(val)) }
  }

  const getInitialValue = () => {
    let defaultValue = field.default
    if (defaultValue !== undefined) {
      // Format single or multiple default values
      if (field.multiple) {
        defaultValue = Array.isArray(defaultValue)
          ? defaultValue.map(formatOptionValue)
          : [formatOptionValue(defaultValue)]
      } else {
        defaultValue = formatOptionValue(defaultValue)
      }
    }

    let initialValue =
      value !== undefined && value !== "" ? value : defaultValue

    // Handle multiple values
    if (field.multiple) {
      if (!initialValue) {
        initialValue = []
      } else if (!Array.isArray(initialValue)) {
        initialValue = [formatOptionValue(initialValue)]
      } else {
        initialValue = initialValue.map(formatOptionValue)
      }
    } else if (initialValue !== undefined) {
      initialValue = formatOptionValue(initialValue)
    }

    return initialValue ?? (field.multiple ? [] : "")
  }

  const [localValue, setLocalValue] = useState(getInitialValue)

  // Call onChange with initial value after mount
  useEffect(() => {
    const initialValue = getInitialValue()
    onChange?.(initialValue)
  }, []) // Empty dependency array ensures this only runs once after mount

  const isRequired =
    typeof field.required === "function"
      ? field.required(parentObject)
      : field.required

  //only set readOnly to true if field.readOnly is explicitly set to true
  if (field.readOnly === true) readOnly = true

  // Unified function to update value and notify parent
  const updateValue = (newValue) => {
    isInternalChange.current = true
    setLocalValue(newValue)
    onChange?.(newValue)
  }

  // Drag controller
  const { handleDragStart, handleDragOver, handleDragEnd } =
    useFieldDragController(localValue, updateValue)

  // Handle external value changes
  useEffect(() => {
    if (!isInternalChange.current) {
      const currentValueStr = field.options?.length
        ? JSON.stringify(
            localValue?.map?.((v) => v?.value) ?? localValue?.value
          )
        : JSON.stringify(localValue)
      const newValueStr = field.options?.length
        ? JSON.stringify(value?.map?.((v) => v?.value) ?? value?.value)
        : JSON.stringify(value)

      if (currentValueStr !== newValueStr) {
        const newValue = getInitialValue()
        setLocalValue(newValue)
      }
    }
    isInternalChange.current = false
  }, [value, field.default, field.multiple, field.options])

  const handleValueChange = (updatedValue, index) => {
    try {
      if (field.multiple) {
        const newValues = Array.isArray(localValue) ? [...localValue] : []

        if (index !== undefined) {
          newValues[index] = field.options?.length
            ? formatOptionValue(updatedValue)
            : updatedValue
        } else {
          newValues.push(
            field.options?.length
              ? formatOptionValue(updatedValue)
              : updatedValue
          )
        }

        updateValue(newValues)
      } else {
        updateValue(
          field.options?.length ? formatOptionValue(updatedValue) : updatedValue
        )
      }

      if (index !== undefined) {
        setTimeout(() => {
          inputRefs.current[index]?.focus()
        }, 0)
      }
    } catch (error) {
      console.error("Error in handleValueChange:", error)
      updateValue(field.multiple ? [] : "")
    }
  }

  const addNewInput = () => {
    handleValueChange("")
    setTimeout(() => {
      const lastInputIndex = localValue ? localValue.length : 0
      inputRefs.current[lastInputIndex]?.focus()
    }, 0)
  }

  const removeInput = (index) => {
    const updatedValues = localValue.filter((_, i) => i !== index)
    updateValue(updatedValues)
  }

  const clearAll = () => {
    updateValue(field.multiple ? [] : "")
  }

  const handleKeyDown = (e, index) => {
    if (!field.multiple || field.multiline) return
    if (e.key === "Enter") {
      e.preventDefault()
      addNewInput()
    }
  }

  const renderItem = (item, index) => (
    <div
      key={index}
      className="mt-2 gap-2 flex flex-row items-center w-full"
      draggable
      onDragStart={() => (!readOnly ? handleDragStart(index) : null)}
      onDragOver={(e) => {
        if (readOnly) return
        e.preventDefault()
        handleDragOver(index)
      }}
      onDragEnd={!readOnly ? handleDragEnd : null}
    >
      {!readOnly && field.multiple && localValue?.length > 1 && (
        <Bars3Icon className="w-5 h-5 cursor-move text-gray-500 mr-2" />
      )}

      {!!field.options?.length ? (
        <DropDownSelect
          menuPosition={field.render?.menuPosition || "bottom"}
          readOnly={readOnly}
          shouldResetSelection={field.multiple && localValue?.length > 0}
          zIndex={420}
          searchEnabled
          showItemNamesInSelection={true}
          multiSelection={field.multiple}
          placeholder={field.optionsLabel}
          options={resolveOptions(field)}
          onItemRender={(value) => value?.name}
          onSelectionRender={(value) => value?.name}
          ref={(el) => (inputRefs.current[index] = el)}
          className="flex-grow text-admin_text bg-admin_paper"
          value={item}
          onChange={(value) => handleValueChange(value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
        />
      ) : (
        <Input
          ref={(el) => (inputRefs.current[index] = el)}
          multiline={field.multiline}
          readOnly={readOnly}
          labelClassName="text-admin_text"
          className="flex-grow text-admin_text"
          value={(item?.value ?? item) || ""}
          onChange={(e) => handleValueChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          type={field.type === "Number" ? "number" : "text"}
        />
      )}

      {!readOnly && field.multiple && localValue?.length > 1 && (
        <Button
          type="outline"
          className="text-white hover:bg-red-700 rounded-full p-2"
          onClick={() => removeInput(index)}
        >
          <XMarkIcon className="w-6 h-6" />
        </Button>
      )}
    </div>
  )

  return (
    <div className="text-editor">
      {Array.isArray(localValue)
        ? localValue?.map((value, index) => renderItem(value, index))
        : renderItem(localValue, 0)}

      <div className="flex flex-row justify-between items-center">
        {showInfo && (
          <div className="flex flex-col mt-2 mr-5">
            <label className="block font-semibold text-admin_text">
              {field.label}
              {readOnly && (
                <span className="ml-2 text-admin_warning">(read-only)</span>
              )}

              {isRequired && (
                <span className="ml-2 text-error">(required)</span>
              )}
            </label>
            {field.description && (
              <label className="block text-sm text-admin_text">
                {field.description}
              </label>
            )}
          </div>
        )}

        <div className="flex items-center space-x-4 mt-4">
          {field.multiple &&
            Array.isArray(localValue) &&
            localValue?.length > 0 && (
              <span className="text-sm text-admin_text">
                {localValue.length} {localValue.length === 1 ? "item" : "items"}
              </span>
            )}

          {!readOnly && field.multiple && (
            <Button
              variant="outlined"
              className="text-admin_text"
              onClick={addNewInput}
            >
              Add
            </Button>
          )}

          {!readOnly &&
            field.multiple &&
            Array.isArray(localValue) &&
            localValue?.length > 0 && (
              <Button
                variant="outlined"
                className="text-admin_text"
                onClick={clearAll}
              >
                Clear
              </Button>
            )}
        </div>
      </div>
    </div>
  )
}
