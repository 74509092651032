import React, { useEffect } from "react"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import config from "aldoo-ra/Config"
import { navigate } from "vike/client/router"
import { windowSearchParams } from "aldoo-ra/SearchParams"
import { store } from "aldoo-ra/Store"

const { STRIPE_API_KEY } = config
const stripePromise = loadStripe(STRIPE_API_KEY)

/**
 * This component is mounted on a page that is redirected to after a payment
 * is processed. It verifies the payment status and updates the store accordingly.
 * It then uses the AldooStore to handle the payment result.
 */

// Component that handles the actual payment verification
const PaymentVerification = () => {
  const stripe = useStripe()

  // Get URL parameters using windowSearchParams
  const params = windowSearchParams()
  const paymentIntent = params?.payment_intent
  const setupIntent = params?.setup_intent
  const redirectStatus = params?.redirect_status
  const paymentIntentClientSecret = params?.payment_intent_client_secret
  const setupIntentClientSecret = params?.setup_intent_client_secret
  const customerId = params?.customerId

  const { onPaymentSuccess, onPaymentError, getFragment } = store

  const PaymentResultVerifyLoading = getFragment(
    "payment-result-verify-loading"
  )

  useEffect(() => {
    const verifyPayment = async () => {
      if (!stripe) return

      try {
        // Determine if this is a setup or payment intent
        const isSetup = !!setupIntent
        const clientSecret =
          setupIntentClientSecret || paymentIntentClientSecret
        const intentId = setupIntent || paymentIntent

        if (!clientSecret || !intentId || !redirectStatus) {
          console.error("Missing required payment parameters")
          // Redirect to the home page if payment info is missing
          navigate("/")
          return
        }

        let result
        const method = isSetup ? "retrieveSetupIntent" : "retrievePaymentIntent"
        // Retrieve the intent based on type
        result = await stripe[method](clientSecret)

        // Check the payment status
        const status = isSetup
          ? result.setupIntent?.status
          : result.paymentIntent?.status

        if (status === "succeeded") {
          // Handle successful payment
          onPaymentSuccess()
        } else {
          // Handle failed payment
          onPaymentError({
            error: `Payment ${status || "failed"}`,
            code: status,
          })
        }
      } catch (error) {
        console.error("Payment verification error:", error)
        onPaymentError({
          error: "failed_to_verify_payment",
        })
      }
    }

    verifyPayment()
  }, [
    stripe,
    store,
    paymentIntent,
    setupIntent,
    redirectStatus,
    paymentIntentClientSecret,
    setupIntentClientSecret,
  ])

  return <PaymentResultVerifyLoading />
}

// Main PaymentResult component that provides Stripe context
const PaymentResult = () => {
  const { paymentInfo, getFragment } = store

  const PaymentResultInvalidSession = getFragment(
    "payment-result-invalid-session"
  )
  // Redirect to the home page if payment info is missing
  useEffect(() => {
    if (paymentInfo) return
    navigate("/")
  }, [paymentInfo])

  const params = windowSearchParams()
  const clientSecret =
    params?.payment_intent_client_secret || params?.setup_intent_client_secret

  if (!clientSecret) return <PaymentResultInvalidSession />

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        loader: "always",
      }}
    >
      <PaymentVerification />
    </Elements>
  )
}

// Export for potential direct usage
export default PaymentResult
