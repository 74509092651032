import React from "react"
import Typography from "aldoo-ra/Typography"
import TextBundle from "aldoo-ra/CMS/text-bundle"

const CheckoutContainer = ({ store, children }) => {
  const { paymentInfo } = store
  const texts = TextBundle({ id: "aldoo-store" })
  return (
    <div className="flex justify-center max-w-[500px] mx-auto md:mx-0">
      {paymentInfo?.hasTrial && (
        <div className="mb-4">
          <Typography 
            variant="info_18"
            className="text-text my-0"
          >
            {texts?.trial_payment_message ||
              "{{ Missing }} Your free trial will begin after you add a payment method. You won't be charged until your trial ends."}
          </Typography>
        </div>
      )}
      {/* The Stripe Elements go here */}
      {children}
    </div>
  )
}

export default CheckoutContainer
