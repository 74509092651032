import React, { useState, useRef, useLayoutEffect } from 'react'

export default function OptionsMenu({
  title,
  options, // Array of options with { id, name, render } structure
  onItemSelect, // Callback when an item is selected
  menuPosition = 'bottom', // Positioning of the menu: top, bottom, left, right
  hideOnItemSelect = true, // Hide the menu when an item is clicked
  onClose, // Callback when the menu is closed (e.g., clicked outside)
  anchorRef, // Reference to the anchor element (like the cog button)
}) {
  const [isVisible, setIsVisible] = useState(true)
  const [menuStyles, setMenuStyles] = useState({})
  const menuRef = useRef(null)

  // Calculate the position once the component is mounted
  useLayoutEffect(() => {
    if (anchorRef.current && menuRef.current) {
      const anchorRect = anchorRef.current.getBoundingClientRect()
      const menuRect = menuRef.current.getBoundingClientRect()

      let positionStyles = {}
      switch (menuPosition) {
        case 'top':
          positionStyles = {
            left: anchorRect.x - menuRect.width / 2 + anchorRect.width / 2,
            top: anchorRect.y -menuRect.height  - 10,
          }
          break
        case 'bottom':
          break
        case 'left':
          break
        case 'right':
          break
        default:
      }

      setMenuStyles(positionStyles)
      setIsVisible(true) // Only show the menu after the position is calculated
    }
  }, [anchorRef, menuPosition])

  // Handle click outside the menu to close
  useLayoutEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        anchorRef.current &&
        !anchorRef.current.contains(event.target)
      ) {
        setIsVisible(false)
        if (onClose) onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose, anchorRef])

  // Handle item selection
  const handleItemClick = (option) => {
    if (onItemSelect) onItemSelect(option)
    if (hideOnItemSelect) {
      setIsVisible(false)
      if (onClose) onClose()
    }
  }

  if (!isVisible) return null

  return (
    <div
      ref={menuRef}
      className="fixed border border-neutral_1 text-admin_text bg-admin_canvas rounded-md shadow-lg"
      style={{ position: 'fixed', zIndex: 1,...menuStyles }}
    >
      {title && (
        <div className="px-4 py-2 border-b border-neutral_1">{title}</div>
      )}

      {options.map((option, index) => (
        <div key={option.id}>
          {option.name && (
            <div
              className="px-4 py-2 hover:bg-neutral_2 cursor-pointer"
              onClick={() => handleItemClick(option)}
            >
              {option.name}
            </div>
          )}
          {option.render && (
            <div className="px-4 py-2">
              {option.render()} {/* Render custom JSX if provided */}
            </div>
          )}
          {index < options.length - 1 && (
            <div className="border-t border-neutral_3 my-1"></div> // Divider
          )}
        </div>
      ))}
    </div>
  )
}
